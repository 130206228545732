// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import MuiDrawer from '@mui/material/Drawer';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import CssBaseline from '@mui/material/CssBaseline';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

// const drawerWidth = 240;

// const openedMixin = (theme) => ({
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//     }),
//     overflowX: 'hidden',
// });

// const closedMixin = (theme) => ({
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: `calc(${theme.spacing(7)} + 1px)`,
//     [theme.breakpoints.up('sm')]: {
//         width: `calc(${theme.spacing(8)} + 1px)`,
//     },
// });

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         width: drawerWidth,
//         flexShrink: 0,
//         whiteSpace: 'nowrap',
//         boxSizing: 'border-box',
//         ...(open && {
//             ...openedMixin(theme),
//             '& .MuiDrawer-paper': openedMixin(theme),
//         }),
//         ...(!open && {
//             ...closedMixin(theme),
//             '& .MuiDrawer-paper': closedMixin(theme),
//         }),
//     }),
// );

// export default function MiniDrawer() {
//     const theme = useTheme();
//     const [open, setOpen] = React.useState(false);

//     const handleDrawerOpen = () => {
//         setOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setOpen(false);
//     };

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <AppBar position="fixed" open={open}>
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         onClick={handleDrawerOpen}
//                         edge="start"
//                         sx={{
//                             marginRight: 5,
//                             ...(open && { display: 'none' }),
//                         }}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <Typography variant="h6" noWrap component="div">
//                         Mini variant drawer
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Drawer variant="permanent" open={open}>
//                 <DrawerHeader>
//                     <IconButton onClick={handleDrawerClose}>
//                         {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
//                     </IconButton>
//                 </DrawerHeader>
//                 <Divider />
//                 <List>
//                     {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//                         <ListItem key={text} disablePadding sx={{ display: 'block' }}>
//                             <ListItemButton
//                                 sx={{
//                                     minHeight: 48,
//                                     justifyContent: open ? 'initial' : 'center',
//                                     px: 2.5,
//                                 }}
//                             >
//                                 <ListItemIcon
//                                     sx={{
//                                         minWidth: 0,
//                                         mr: open ? 3 : 'auto',
//                                         justifyContent: 'center',
//                                     }}
//                                 >
//                                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                                 </ListItemIcon>
//                                 <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
//                             </ListItemButton>
//                         </ListItem>
//                     ))}
//                 </List>
//                 <Divider />
//                 <List>
//                     {['All mail', 'Trash', 'Spam'].map((text, index) => (
//                         <ListItem key={text} disablePadding sx={{ display: 'block' }}>
//                             <ListItemButton
//                                 sx={{
//                                     minHeight: 48,
//                                     justifyContent: open ? 'initial' : 'center',
//                                     px: 2.5,
//                                 }}
//                             >
//                                 <ListItemIcon
//                                     sx={{
//                                         minWidth: 0,
//                                         mr: open ? 3 : 'auto',
//                                         justifyContent: 'center',
//                                     }}
//                                 >
//                                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                                 </ListItemIcon>
//                                 <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
//                             </ListItemButton>
//                         </ListItem>
//                     ))}
//                 </List>
//             </Drawer>
//             <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//                 <DrawerHeader />
//                 <Typography paragraph>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//                     tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//                     enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//                     imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//                     Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//                     Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//                     adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//                     nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//                     leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//                     feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//                     consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//                     sapien faucibus et molestie ac.
//                 </Typography>
//                 <Typography paragraph>
//                     Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//                     eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//                     neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//                     tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//                     sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//                     tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//                     gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//                     et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//                     tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//                     eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//                     posuere sollicitudin aliquam ultrices sagittis orci a.
//                 </Typography>
//             </Box>
//         </Box>
//     );
// }

// import * as React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         flexGrow: 1,
//         padding: theme.spacing(3),
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: `-${drawerWidth}px`,
//         ...(open && {
//             transition: theme.transitions.create('margin', {
//                 easing: theme.transitions.easing.easeOut,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             marginLeft: 0,
//         }),
//     }),
// );

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//     transition: theme.transitions.create(['margin', 'width'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         width: `calc(100% - ${drawerWidth}px)`,
//         marginLeft: `${drawerWidth}px`,
//         transition: theme.transitions.create(['margin', 'width'], {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
// }));

// export default function MiniDrawer() {
//     const theme = useTheme();
//     const [open, setOpen] = React.useState(false);

//     const handleDrawerOpen = () => {
//         setOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setOpen(false);
//     };

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <AppBar position="fixed" open={open}>
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         onClick={handleDrawerOpen}
//                         edge="start"
//                         sx={{ mr: 2, ...(open && { display: 'none' }) }}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <Typography variant="h6" noWrap component="div">
//                         Persistent drawer
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Drawer
//                 sx={{
//                     width: drawerWidth,
//                     flexShrink: 0,
//                     '& .MuiDrawer-paper': {
//                         width: drawerWidth,
//                         boxSizing: 'border-box',
//                     },
//                 }}
//                 variant="persistent"
//                 anchor="left"
//                 open={open}
//             >
//                 <DrawerHeader>
//                     <IconButton onClick={handleDrawerClose}>
//                         {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//                     </IconButton>
//                 </DrawerHeader>
//                 <Divider />
//                 <List>
//                     {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//                         <ListItem key={text} disablePadding>
//                             <ListItemButton>
//                                 <ListItemIcon>
//                                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                                 </ListItemIcon>
//                                 <ListItemText primary={text} />
//                             </ListItemButton>
//                         </ListItem>
//                     ))}
//                 </List>
//                 <Divider />
//                 <List>
//                     {['All mail', 'Trash', 'Spam'].map((text, index) => (
//                         <ListItem key={text} disablePadding>
//                             <ListItemButton>
//                                 <ListItemIcon>
//                                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                                 </ListItemIcon>
//                                 <ListItemText primary={text} />
//                             </ListItemButton>
//                         </ListItem>
//                     ))}
//                 </List>
//             </Drawer>
//             <Main open={open}>
//                 <DrawerHeader />
//                 <Typography paragraph>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//                     tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//                     enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//                     imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//                     Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//                     Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//                     adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//                     nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//                     leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//                     feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//                     consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//                     sapien faucibus et molestie ac.
//                 </Typography>
//                 <Typography paragraph>
//                     Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//                     eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//                     neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//                     tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//                     sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//                     tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//                     gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//                     et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//                     tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//                     eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//                     posuere sollicitudin aliquam ultrices sagittis orci a.
//                 </Typography>
//             </Main>
//         </Box>
//     );
// }


// import * as React from 'react';
// import PropTypes from 'prop-types';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';

// const drawerWidth = 240;

// function MiniDrawer(props) {
//     const { window } = props;
//     const [mobileOpen, setMobileOpen] = React.useState(false);
//     const [isClosing, setIsClosing] = React.useState(false);

//     const handleDrawerClose = () => {
//         setIsClosing(true);
//         setMobileOpen(false);
//     };

//     const handleDrawerTransitionEnd = () => {
//         setIsClosing(false);
//     };

//     const handleDrawerToggle = () => {
//         if (!isClosing) {
//             setMobileOpen(!mobileOpen);
//         }
//     };

//     const drawer = (
//         <div>
//             <Toolbar />
//             <Divider />
//             <List>
//                 {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//                     <ListItem key={text} disablePadding>
//                         <ListItemButton>
//                             <ListItemIcon>
//                                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                             </ListItemIcon>
//                             <ListItemText primary={text} />
//                         </ListItemButton>
//                     </ListItem>
//                 ))}
//             </List>
//             <Divider />
//             <List>
//                 {['All mail', 'Trash', 'Spam'].map((text, index) => (
//                     <ListItem key={text} disablePadding>
//                         <ListItemButton>
//                             <ListItemIcon>
//                                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                             </ListItemIcon>
//                             <ListItemText primary={text} />
//                         </ListItemButton>
//                     </ListItem>
//                 ))}
//             </List>
//         </div>
//     );

//     // Remove this const when copying and pasting into your project.
//     const container = window !== undefined ? () => window().document.body : undefined;

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <AppBar
//                 position="fixed"
//                 sx={{
//                     width: { sm: `calc(100% - ${drawerWidth}px)` },
//                     ml: { sm: `${drawerWidth}px` },
//                 }}
//             >
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         edge="start"
//                         onClick={handleDrawerToggle}
//                         sx={{ mr: 2, display: { sm: 'none' } }}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <Typography variant="h6" noWrap component="div">
//                         Responsive drawer
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Box
//                 component="nav"
//                 sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
//                 aria-label="mailbox folders"
//             >
//                 {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//                 <Drawer
//                     container={container}
//                     variant="temporary"
//                     open={mobileOpen}
//                     onTransitionEnd={handleDrawerTransitionEnd}
//                     onClose={handleDrawerClose}
//                     ModalProps={{
//                         keepMounted: true, // Better open performance on mobile.
//                     }}
//                     sx={{
//                         display: { xs: 'block', sm: 'none' },
//                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//                     }}
//                 >
//                     {drawer}
//                 </Drawer>
//                 <Drawer
//                     variant="permanent"
//                     sx={{
//                         display: { xs: 'none', sm: 'block' },
//                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//                     }}
//                     open
//                 >
//                     {drawer}
//                 </Drawer>
//             </Box>
//             <Box
//                 component="main"
//                 sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
//             >
//                 <Toolbar />
//                 <Typography paragraph>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//                     tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//                     enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//                     imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//                     Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//                     Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//                     adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//                     nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//                     leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//                     feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//                     consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//                     sapien faucibus et molestie ac.
//                 </Typography>
//                 <Typography paragraph>
//                     Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//                     eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//                     neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//                     tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//                     sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//                     tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//                     gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//                     et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//                     tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//                     eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//                     posuere sollicitudin aliquam ultrices sagittis orci a.
//                 </Typography>
//             </Box>
//         </Box>
//     );
// }

// MiniDrawer.propTypes = {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * Remove this when copying and pasting into your project.
//      */
//     window: PropTypes.func,
// };

// export default MiniDrawer;



import React, { useEffect, useState } from 'react';
import './MiniDrawer.css'; // Make sure to create an App.css file with the provided styles
import { useLocation } from "react-router-dom";


import DataTable from 'react-data-table-component';
import * as environment from '../../coreservices/environment'


function MiniDrawer() {

    const location = useLocation();

    const datalist = location?.state?.state?.data
    console.log('====================================');
    console.log("datalist", datalist);
    console.log('====================================');


    const [activeLink, setActiveLink] = useState(null);

    const handleClick = (className) => {
        setActiveLink(className);
    };




    // State to store the data fetched from the server
    const [pdata, setpData] = useState([]);
    // State to track loading state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                // Set loading to true while fetching
                setLoading(true);

                // Make a GET request using fetch
                const response = await fetch(`${environment.apiUrl}/packageinformation`);

                // Check if the request was successful (status code 200)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // Parse the JSON response
                const result = await response.json();

                // Set the data in the state
                setpData(result);

                console.log('====================================');
                console.log("resultp", result);
                console.log('====================================');
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false after fetching, whether successful or not
                setLoading(false);
            }
        };

        // Call the fetch function
        fetchData();
    }, []); // Empty dependency array means this effect runs once, similar to componentDidMount






    const columns = [
        {
            name: 'Package Name',
            selector: row => row.pName,
        },
        {
            name: 'Package Price',
            selector: row => row.pPrice,
        },
        {
            name: 'Validity',
            selector: row => row.pValidity,
        },
        {
            name: 'Contact Limit',
            selector: row => row.pContactLimit,
        },
        {
            name: 'Test Report Limit',
            selector: row => row.pTestReportLimit,
        },
        {
            name: 'Others',
            selector: row => row.pOthers,
        },
    ];

    // const data = [
    //     {
    //         id: 1,
    //         pName: 'Basic',
    //         pPrice: '99',
    //         pValidity: '1 Month',
    //         pContactLimit: '3',
    //         pTestReportLimit: '3',
    //         pOthers: 1,
    //     },
    //     {
    //         id: 2,
    //         pName: 'Silver',
    //         pPrice: '299',
    //         pValidity: '3 Month',
    //         pContactLimit: '6',
    //         pTestReportLimit: '6',
    //         pOthers: 1,
    //     },
    //     {
    //         id: 3,
    //         pName: 'Golden',
    //         pPrice: '499',
    //         pValidity: '5 Month',
    //         pContactLimit: '10',
    //         pTestReportLimit: '50',
    //         pOthers: 1,
    //     },
    //     {
    //         id: 4,
    //         pName: 'Diamond',
    //         pPrice: '999',
    //         pValidity: '1 Year',
    //         pContactLimit: '50',
    //         pTestReportLimit: '100',
    //         pOthers: 1,
    //     },
    // ]




    return (
        <div>

            <div className="sidebar">
                <a
                    className={`'active' ${activeLink != 'activeb' && activeLink != 'activec' && activeLink != 'actived' ? 'active' : ''}`}
                    href="#home"
                    onClick={() => handleClick('activea')}
                >
                    Home
                </a>
                <a
                    className={`${activeLink === 'activeb' ? 'activeb' : ''}`}
                    href="#Homepageadmin"
                    onClick={() => handleClick('activeb')}
                >
                    News
                </a>
                <a
                    className={`${activeLink === 'activec' ? 'activec' : ''}`}
                    href="#contact"
                    onClick={() => handleClick('activec')}
                >
                    Contact
                </a>
                <a
                    className={`${activeLink === 'actived' ? 'actived' : ''}`}
                    href="#about"
                    onClick={() => handleClick('actived')}
                >
                    About
                </a>
            </div>

            <div className="content">
                <h2>Package Details</h2>
                <div className='card'>
                    <div className='card-body'>
                        <div>
                            <span className='heading-text fs-14'>
                                List
                            </span>
                            <div className='data-table-light-dark'>
                                <DataTable
                                    columns={columns}
                                    data={pdata}
                                    pagination={5}
                                    fixedHeader='true'
                                    // fixedHeaderScrollHeight='41vh'
                                    className='datatableclass'

                                // customStyles={customStyles}
                                />
                            </div>

                        </div>
                    </div>
                </div>


            </div>




        </div>
    );
}

export default MiniDrawer;







// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
// import './MiniDrawer.css';
// // import { useLocation } from "react-router-dom";


// import DataTable from 'react-data-table-component';
// const columns = [
//     {
//         name: 'Title',
//         selector: row => row.title,
//     },
//     {
//         name: 'Year',
//         selector: row => row.year,
//     },
//     {
//         name: 'Title',
//         selector: row => row.title,
//     },
//     {
//         name: 'Year',
//         selector: row => row.year,
//     },
//     {
//         name: 'Title',
//         selector: row => row.title,
//     },
//     {
//         name: 'Year',
//         selector: row => row.year,
//     },
// ];

// const data = [
//     {
//         id: 1,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 3,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 4,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 5,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 6,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
// ]
// const HomeContent = () => (
//     <div>
//         {/* <h2>Home Content</h2> */}
//         <div className="content">
//             <h2>Responsive Sidebar Example</h2>
//             <div className='card'>
//                 <div className='card-body'>
//                     <div>
//                         <span className='heading-text fs-14'>
//                             List
//                         </span>
//                         <div className='data-table-light-dark'>
//                             <DataTable
//                                 columns={columns}
//                                 data={data}
//                                 pagination={5}
//                                 fixedHeader='true'
//                                 // fixedHeaderScrollHeight='41vh'
//                                 className='datatableclass'

//                             // customStyles={customStyles}
//                             />
//                         </div>

//                     </div>
//                 </div>
//             </div>


//         </div>
//     </div>
// );

// const NewsContent = () => (
//     <div>
//         <h2>News Content</h2>
//         {/* Add content for News here */}
//     </div>
// );

// const ContactContent = () => (
//     <div>
//         <h2>Contact Content</h2>
//         {/* Add content for Contact here */}
//     </div>
// );

// const AboutContent = () => (
//     <div>
//         <h2>About Content</h2>
//         {/* Add content for About here */}
//     </div>
// );

// const MiniDrawer = () => {
//     const [activeLink, setActiveLink] = useState(null);

//     const handleClick = (className) => {
//         setActiveLink(className);
//     };

//     return (
//         <Router>
//             <div>
//                 <div className="sidebar">
//                     <Link
//                         className={`'active' ${activeLink !== 'activeb' && activeLink !== 'activec' && activeLink !== 'actived' ? 'active' : ''}`}
//                         to="/home"
//                         onClick={() => handleClick('activea')}
//                     >
//                         Home
//                     </Link>
//                     <Link
//                         className={`${activeLink === 'activeb' ? 'activeb' : ''}`}
//                         to="/news"
//                         onClick={() => handleClick('activeb')}
//                     >
//                         News
//                     </Link>
//                     <Link
//                         className={`${activeLink === 'activec' ? 'activec' : ''}`}
//                         to="/contact"
//                         onClick={() => handleClick('activec')}
//                     >
//                         Contact
//                     </Link>
//                     <Link
//                         className={`${activeLink === 'actived' ? 'actived' : ''}`}
//                         to="/about"
//                         onClick={() => handleClick('actived')}
//                     >
//                         About
//                     </Link>
//                 </div>

//                 <div className="content">
//                     <Switch>
//                         <Route path="/home" >
//                             <HomeContent />
//                         </Route>
//                         {/* <Route path="/home"
//                         component={HomeContent} /> */}
//                         <Route path="/news" >
//                             <NewsContent />
//                         </Route>
//                         <Route path="/contact" >
//                             <ContactContent />
//                         </Route>
//                         <Route path="/about" >
//                             <AboutContent />
//                         </Route>
//                         {/* <Route path="/news" component={NewsContent} />
//                         <Route path="/contact" component={ContactContent} />
//                         <Route path="/about" component={AboutContent} /> */}
//                     </Switch>
//                 </div>
//             </div>
//         </Router>
//     );
// };

// export default MiniDrawer;
