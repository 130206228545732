import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const HoroscopeModal = ({ showModal, handleClose }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = () => {
        alert('Record Submitted Successfully.')

        // Handle you   r submission logic here
        // You can access the selected file using the 'file' state
        console.log('File submitted:', file);
        // Reset the file state if needed
        setFile(null);
        // Close the modal
        handleClose();
    };

    return (
        <Modal centered show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Horoscope Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Upload File:</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HoroscopeModal;
