import {
  Box,
  // Button,
  Paper,
  Snackbar,
  SnackbarContent,
  Typography
} from "@material-ui/core";

import { Card } from "@material-ui/core";
import { CardContent, TextField } from '@mui/material';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { Link } from "react-router-dom";
// import Modal from '@mui/material/Modal';

import { Modal, Button } from 'react-bootstrap';

import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { db } from "../app/firebase";
import Profileimagegallery from "./Profileimagegallery";
import moment from "moment";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./Profile.scss";

import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../features/userSlice";
import firebase from "firebase";
import { batch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Profilepartnerpreference from "./Profilepartnerpreference";
import { Skeleton } from "@material-ui/lab";
import WithLoading from "./Withloading.js";
import {
  fetchAsyncProfileDetail,
  getSelectedProfile,
  removeSelectedProfile
} from "../features/profilesSlice";
import { shortlistNew, removeshortlist } from "../utils/Shortlist";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import globalUseStyles from "./globalstyles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { RequestPhoto } from "../utils/RequestPhoto";
import PhotoRequestMessage from "./PhotorequestsmessagesProfile";
import { RequestContact } from "../utils/RequestContact";
import { ContactViewed } from "../utils/ContactViewed";
import axios from 'axios';
import { maskUsername, maskUsernamefull } from "./Common/commonfunctions.js";
import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';

import * as environment from '../coreservices/environment'

const ListWithLoading = WithLoading(Profilepartnerpreference);
const useStyles = makeStyles((theme) => ({




  root: {
    maxWidth: "100%"
  },

  imageGallery: {
    position: "sticky",
    top: "0",
    "& .image-gallery-thumbnail": {
      "& .image-gallery-thumbnail-image": {
        height: "100px",
        objectFit: "cover"
      }
    }
  },
  actionButtons: {
    width: "auto",
    margin: "10px 5px",
    padding: "10px",

    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column-reverse",
      fontSize: "14px",
      "@media (max-width: 767px)": {
        fontSize: "11px"
      }
    },
    "& .MuiSvgIcon-root": {
      fontSize: "28px"
    },
    "@media (max-width: 767px)": {
      margin: "10px 5px"
    }
  },
  actionsbuttons: {
    display: "flex",
    justifyContent: "center"
  },
  actionButtonsHolder: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      // flexDirection: "column",
      alignItems: "center",
      button: {
        padding: "8px"
      }
    }
  },
  actions__msg: {
    display: "flex",
    flexDirection: "column"
  },
  subheading: {
    // background: theme.palette.primary.main,
    // padding: "10px",
    // borderRadius: "10px",
  },
  listgroup: {
    overflow: "hidden",
    padding: "10px",
    paddingBottom: "0",
    margin: "10px 0"
  },
  listdesc: {
    listStyle: "none",
    paddingLeft: "10px",
    width: "100%"
  },
  listdescli: {
    marginBottom: "10px",
    width: "100%",
    "@media (max-width: 767px)": {
      display: "flex"
    }
  },
  leftSideHead: {
    width: "175px",
    display: "block",
    float: "left",
    textTransform: "uppercase",
    position: "relative",
    "@media (max-width: 767px)": {
      width: "140px",
      "&::after": {
        content: '":"',
        right: "0",
        position: "absolute",
        top: "-1px"
      }
    }
  },

  rightSidedesc: {
    fontSize: "1rem",
    width: "calc(100% - 175px)",
    wordBreak: "break-word",
    "@media (max-width: 767px)": {
      width: "calc(100% - 140px)",
      paddingLeft: "5px"
    }
  },
  requestsection: {
    justifyContent: "center",
    maxWidth: "500px",
    margin: " 0 auto",
    display: "flex"
  },
  requestsectionButtons: {
    "& button": {
      margin: "10px 5px 0px 5px"
    }
  },
  requestsectioninner: {
    display: "flex",
    alignItems: "center",
    alignContent: "flex-start",
    flexDirection: "column"
  },
  requestmsgholder: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    maxWidth: "430px",
    margin: "0 auto"
  },
  requestmsg: {
    display: "inline",
    padding: "10px 30px",
    width: "100%",
    float: "left",
    textAlign: "center",
    maxWidth: "430px",
    margin: "10px auto",
    "@media (max-width: 767px)": {
      padding: "10px"
    }
  },
  profile__picholder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  profile__pic: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  profile__preferencesul: {
    paddingLeft: 0
  },
  profile__preferencesli: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 5px",
    fontSize: "16px",
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  },
  profile__match: {
    display: "flex",
    alignItems: "center"
  },
  acceptdecline: {
    margin: "0 5px"
  },
  mat__dummyprofilepic: {
    paddingBottom: "56.2%",
    position: "relative"
  },
  mat__dummyprofilepicholder: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  mat__dummyprofileimg: {
    width: "100%",
    height: "100%",
    opacity: "0.3"
  },
  mat__requestpic: {
    position: "absolute",
    top: "50%",
    left: "50%",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    //  theme.palette.primary.main,
    transform: "translate(-50%,-50%)"
    // color: theme.palette.primary.main
  }
}));
function Profile() {


  const searchParams = new URLSearchParams(window?.location?.search);
  const req_body_status = searchParams?.get('status');
  const req_body_userId = searchParams?.get('userId');

  useEffect(() => {
    if (req_body_status != "" && req_body_status != null && req_body_userId != "" && req_body_userId != null) {
      console.log('Status is', req_body_status);
      console.log('userId is', req_body_userId);
      if (req_body_status == "success") {
        alert('Success')
        checkPaymentStatus(req_body_userId)
      } else if (req_body_status == "failure") {
        alert('Failure')
      }
    }
  }, [req_body_status])

  useEffect(() => {
    if (localStorage.getItem('userId') != "" && localStorage.getItem('userId') != null) {
      checkPaymentStatus(localStorage.getItem('userId'))
    }
  }, [localStorage.getItem('userId')])

  const checkPaymentStatus = async (user_id) => {
    // Your subscription logic goes here
    console.log("user_id", user_id);
    try {
      const response = await fetch(`${environment.apiUrl}/paymentstatus/${user_id}`);
      if (response.ok) {
        const result = await response.json();
        console.log('====================================');
        console.log("dataPay", result);
        console.log('====================================');
        localStorage.setItem('packagePaymentStatus', result?.packagePaymentStatus);
        // window.location.reload();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  let packagePaymentStatus = localStorage.getItem('packagePaymentStatus')

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    'Option 1',
    'Option 2',
    'Option 3',
    // Add more options as needed
  ];


  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const pages = ['Products', 'Pricing', 'Blog'];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [Psychologicalmodel, setPsychologicalmodel] = React.useState(false)
  const history = useHistory();
  const handleTest = (val) => {
    console.log(localStorage.getItem('packagePaymentStatus'));
    let packagePaymentStatus = localStorage.getItem('packagePaymentStatus')

    console.log(JSON.parse(localStorage.getItem('user_data')));
    let user_data = JSON.parse(localStorage.getItem('user_data'))

    setOpen(false);
    if (val == 'psy') {
      history.push('/psychologicaltests', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
    }


    // BloodgroupingTests
    else if (val == 'blood') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "BloodgroupingTests" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/BloodgroupingTests', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }


      // console.log(JSON.parse(localStorage.getItem('user_data')));
      // let user_data = JSON.parse(localStorage.getItem('user_data'))
      // if (localStorage.getItem('authToken') == null) {
      //     history.push('/Homepagepackage');
      // } else {
      //     history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "Login" } });
      // }


    } else if (val == 'thal') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "ThalassemiaTests" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/ThalassemiaTests', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }


    } else if (val == 'std') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "SexuallytransmittedDiseases" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/SexuallytransmittedDiseases', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }


    } else if (val == 'infer') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "InfertilityScreening" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/InfertilityScreening', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }


    } else if (val == 'chr') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "Chronicdisorders" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/Chronicdisorders', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }


    } else if (val == 'hor') {
      if (packagePaymentStatus == 'NotPaid') {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "HoroscopeTest" } });
      } else if (packagePaymentStatus == 'Paid') {
        history.push('/HoroscopeTest', { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
      }



    }

  }
  const handleClick = () => {
    setOpen(true);
    // Navigate to the '/target' route when the button is clicked
    // history.push('/testyourself');
    console.log("click")
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setOpen(false)
  };




  const handlePsychologicalmodelclose = () => {
    setPsychologicalmodel(true);
  };

  const handlePsychologicalmodelopen = () => {
    setPsychologicalmodel(false);
  };






  const globalClasses = globalUseStyles();

  // const classes = useStyles();
  console.log("counttttttttttttttttttttttttttttttttt");

  const location = useLocation();


  const matchingData = location?.state?.state?.matchingData

  // if (matchingData) {
  // Assuming you want to select the second tab after some event or condition

  // Get the second tab element
  // const secondTab = document.querySelector('#tab-2');

  // Get the corresponding content pane for the second tab
  // const secondTabContent = document.querySelector('#simple-tabpanel-2');

  // Remove the 'active' class from all tabs
  // document.querySelectorAll('.nav-link').forEach(tab => {
  //   tab.classList.remove('active');
  // });

  // Remove the 'show active' classes from all content panes
  // document.querySelectorAll('.tab-pane').forEach(tabContent => {
  //   tabContent.classList.remove('show', 'active');
  // });

  // Add the 'active' class to the second tab
  // secondTab.classList.add('active');

  // Add the 'show active' classes to the second tab content pane
  // secondTabContent.classList.add('active');
  // }
  console.log("matchingData", matchingData);
  const [age, setAge] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);

  function calculateAge(date) {
    const birthdate = new Date(date);
    const currentDate = new Date();

    const years = differenceInYears(currentDate, birthdate);
    const months = differenceInMonths(currentDate, birthdate) % 12;
    const days = differenceInDays(currentDate, birthdate) % 30;
    console.log("agecalculator", years, months, days)
    setAge(`${years} years, ${months} months, ${days} days`);

    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const formatted = birthdate.toLocaleDateString('en-GB', options);

    setFormattedDate(formatted);
  }

  const formatTime = (isoString) => {
    const date = new Date(isoString);

    // Options to format the time as 'hh:mm AM/PM'
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    return date.toLocaleTimeString('en-US', options);
  };

  const [nuserId, setnuserId] = useState("")
  const [loggedUsersData, setloggedUsersData] = useState([""]);
  const [profileimage, setprofileimage] = useState();
  const [pic1, setpic1] = useState();
  const [pic2, setpic2] = useState();
  console.log('====================================');
  console.log("locationnnnn", location);
  console.log('====================================');
  const emailID = location?.state ? location?.state?.state?.email : localStorage.getItem('email');
  // const emailID = ""
  console.log('====================================');
  console.log("emailIDbbbb", emailID);
  console.log('====================================');

  useEffect(() => {
    if (emailID != '') {
      getUserByEmail()
    }

  }, [emailID])

  const handleClickPre = () => {
    getUserByEmail()
  };

  const getUserByEmail = async () => {

    try {
      const response = await fetch(`${environment.apiUrl}/signupprofiles/email/${emailID}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetchedData = await response.json();
      console.log('====================================');
      console.log("fetchedData", fetchedData);
      console.log('====================================');
      localStorage.setItem('userId', fetchedData.userId);
      localStorage.setItem('email', fetchedData.email);
      localStorage.setItem('role', 'user');

      setnuserId(fetchedData?.userId)
      setloggedUsersData(fetchedData);
      calculateAge(fetchedData.birthdate);
      setpic1(fetchedData.pic1);
      setpic2(fetchedData.pic2);
      setprofileimage(fetchedData.profilepic);

    } catch (error) {
      console.error(error.message);
    }

  }


  const [match_pref_Data, set_match_pref_Data] = useState({});
  const [match_final_Data, set_match_final_Data] = useState([]);
  const [match_final_Data_allprint_object, set_match_final_Data_allprint_object] = useState({});
  const [match_final_Data_allprint_array, set_final_Data_allprint_array] = useState([]);



  useEffect(() => {
    if (nuserId != '') {
      getUser_Matches()
    }

  }, [nuserId])

  const getUser_Matches = async () => {

    try {
      const response = await fetch(`${environment.apiUrl}/partnerpreferrence/findpref/${nuserId}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetched_pref_Data = await response.json();
      console.log('====================================');
      console.log("fetched_pref_Data", fetched_pref_Data);
      console.log('====================================');

      console.log('====================================');
      console.log("fetched_pref_Data.FinalData", fetched_pref_Data.FinalData);
      console.log("fetched_pref_Data.FinalData.length", fetched_pref_Data.FinalData.length);
      console.log('====================================');

      set_match_pref_Data(fetched_pref_Data);
      set_match_final_Data(fetched_pref_Data?.FinalData)
      getUserDetail(fetched_pref_Data?.FinalData)



    } catch (error) {
      console.error(error.message);
    }

  }









  function getUserDetail(data) {
    console.log("dataaaa", data)
    var matchingProfileDataById = data?.map((item, index) => {
      fetch(`${environment.apiUrl}/signupprofiles/getdet/${item.userId_matchers}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },

      })
        .then(response => {
          console.log("responseeeeeee", response)
          if (response.ok) {
            return response.json(); // Return parsed JSON if the response is successful
          }
          throw new Error('Network response was not ok.');
        })
        .then(result => {
          console.log("result_matchindData", result);
          set_match_final_Data_allprint_object(result);
          match_final_Data_allprint_array.push(result);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    })
  }







  // const userid = atob(location.pathname.split("/ProfileN/")[1]);
  const userid = atob(location.pathname.split("/ProfileN/")[1]);


  const [loadingpartnerPref, setLoadingpartnerPref] = useState(true);

  const dispatch = useDispatch();

  const [profilee, setprofilee] = useState([]);
  useEffect(() => {
    db.collection("users")
      .doc(userid)
      .get()
      .then((doc) => {

        setprofilee(loggedUsersData);
        setLoadingpartnerPref(false);

      })

      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, [userid]);


  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });


  // useEffect(() => {

  //   console.log("qwertyqwertyqwertyqwertyqwertyyyyy");
  //   setprofileimage(profilee.profilepic ? profilee.profilepic : "");
  //   setpic1(profilee.pic1 ? profilee.pic1 : "");
  //   setpic2(profilee.pic2 ? profilee.pic2 : "");
  //   setProfileeready(true);
  //   console.log(Object.keys(profilee).length + " profileeeeeeeee length");
  // }, [profilee]);
  //useEffect(() => {
  // console.log(profileimage);
  // console.log(pic1);
  // console.log(pic2);
  //}, [profileimage, pic1, pic2]);

  // console.log(profilee?.birthdate);
  //const d = new Date(profilee?.birthdate);
  // let date = d.getHours() + ":" + d.getMinutes() + ", " + d.toDateString();
  // console.log(d);

  // const [profileimages, setprofileimages] = useState([]);
  // useEffect(() => {
  //     if (!profileimages) {
  //         // setprofileimages("");
  //         setprofileimages((state) => [...state, profileimage]);
  //         setprofileimages((state) => [...state, pic1]);
  //         setprofileimages((state) => [...state, pic2]);
  //         console.log(profileimages);
  //     }
  // }, [profileimages]);

  // const timstamp =
  //     profilee &&
  //     profilee.bithdate &&
  //     profilee.birthdate.nanoseconds.toDate();
  // console.log(timstamp);
  // db.collection("users").doc(this.username).collection("booksList").add({
  //     password: this.password,
  //     name: this.name,
  //     rollno: this.rollno,
  // });
  const userDetails = useSelector(selectUser);
  const [loggedinUser, setloggedinUser] = useState(userDetails);
  const [userdata, setuserdata] = useState({})
  // console.log("logggedinuser", JSON.stringify(loggedinUser));
  var user = firebase.auth().currentUser;
  // console.log("dddddloogggeed", loggedinUser);

  // useEffect(() => {
  //   if (loggedinUser != {}) {
  //     // setuserdata(JSON.parse(loggedinUser))
  //     console.log("nameee", loggedinUser.name)
  //   }
  // }, [])

  useEffect(() => {
    var email;
    setloggedinUser(userDetails);
    if (user != null) {
      email = user.email;
    }
  }, [userDetails, user]);
  //var name, email, photoUrl, uid, emailVerified;
  var email;
  if (user != null) {
    email = user.email;
    //uid = user.uid;
    // The user's ID, unique to the Firebase project. Do NOT use // this value to authenticate with your backend server, if // you have one. Use User.getToken() instead.}
  }

  useEffect(() => {
    if (email) {
      setLoadingpartnerPref(true);
      db.collection("users")
        .doc(email)
        .onSnapshot((doc) => {
          console.log(JSON.stringify(doc.data()) + "logggggggggedusedsdata");
          // setloggedUsersData(doc.data());
          setloggedinUser(doc.data());
          setLoadingpartnerPref(false);
        });
    }


    // setloggedusersready(true);
    // }
  }, [email]);
  const [iserror, setIserror] = useState(true);

  const loadNow = () => {
    console.log(JSON.stringify(loggedUsersData) + "loggedduserrsssdataaa");
    console.log(JSON.stringify(profilee) + "profileee");

    db.collection("users")
      .doc(email)
      .collection("recentlyviewed")
      .doc(profilee?.email)
      .set({
        email: profilee?.email,
        name: profilee?.name,
        education: profilee?.education,
        star: profilee?.birthstar,
        raashi: profilee?.raashi,
        gothra: profilee?.gothra,
        dob: profilee?.birthdate,
        dp: profilee?.profilepic,
        viewedon: firebase.firestore.FieldValue.serverTimestamp()
      });

    db.collection("users")
      .doc(profilee.email)
      .collection("recentlyviewedme")
      .doc(email)
      .set({
        email: loggedUsersData?.email,
        name: loggedUsersData?.name,
        education: loggedUsersData?.education,
        star: loggedUsersData?.birthstar,
        raashi: loggedUsersData?.raashi,
        gothra: loggedUsersData?.gothra,
        dob: loggedUsersData?.birthdate,
        dp: loggedUsersData?.profilepic,
        viewedon: firebase.firestore.FieldValue.serverTimestamp()
      });
  };

  var userRef = db.collection("users").doc(email);
  const tobeshortlistedId = profilee?.email;

  async function nshortlist() {
    let shortlistReturn = await shortlistNew(
      email,
      tobeshortlistedId,
      profilee,
      email,
      loggedinUser.userData
    );
    const tried = await shortlistReturn;
    if (tried === "Success") {
      setsnackbarmsg("Successfully added to shortlist");
      setsnackbartype("success");
      setopenSnackbar(true);
    }
  }

  async function removeShortlistt(email, loggedinuserEmail) {
    console.log("removeeeeeeeeeeeeeeee");
    let removeShortlistreturn = await removeshortlist(email, loggedinuserEmail);
    const removedPromise = await removeShortlistreturn;
    if (removedPromise === "Success") {
      setsnackbarmsg(" Removed from shortlist");
      setsnackbartype("error");
      setopenSnackbar(true);
    }
  }


  const [openSnackbar, setopenSnackbar] = useState(false);
  const [snackbarmsg, setsnackbarmsg] = useState();
  const [snackbartype, setsnackbartype] = useState();

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSnackbar(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const sendInterest = () => {

    userRef.set(
      {
        interestssent: firebase.firestore.FieldValue.arrayUnion(
          tobeshortlistedId
        )
      },
      { merge: true }
    );

    db.collection("users")
      .doc(email)
      .collection("interestssent")
      .doc(profilee.email)
      .set({
        email: profilee.email,
        name: profilee.name,
        education: profilee.education,
        star: profilee.birthstar,
        raashi: profilee.raashi,
        gothra: profilee.gothra,
        dob: profilee.birthdate,
        dp: profilee.profilepic,
        accepted: false,
        read: false,
        sentDate: firebase.firestore.FieldValue.serverTimestamp()
      });


    db.collection("users")
      .doc(profilee.email)
      .set(
        {
          interestsreceived: firebase.firestore.FieldValue.arrayUnion(email)
        },
        { merge: true }
      );

    db.collection("users")
      .doc(profilee.email)
      .collection("interestsreceived")
      .doc(email)
      .set({
        email: loggedinUser.email,
        name: loggedinUser.name,
        education: loggedinUser.education,
        star: loggedinUser.birthstar,
        raashi: loggedinUser.raashi,
        gothra: loggedinUser.gothra,
        dob: loggedinUser.birthdate,
        dp: loggedinUser.profilepic,
        accepted: false,
        read: false,
        recievedDate: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        setsnackbarmsg(`Successfully sent interest to ${profilee.name}`);
        setsnackbartype("success");
        setopenSnackbar(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const [interestSent, setinterestSent] = useState();
  const [interestReceived, setinterestReceived] = useState();

  useEffect(() => {
    db.collection("users")
      .doc(email)
      .collection("interestssent")
      .doc(userid)
      .onSnapshot((doc) => {
        setinterestSent(doc.data());
      });
    db.collection("users")
      .doc(email)
      .collection("interestsreceived")
      .doc(userid)
      .onSnapshot(
        (doc) => {
          setinterestReceived(doc.data());
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);


  const acceptRequest = (requestEmail) => {
    db.collection("users")
      .doc(email)
      .collection("interestsreceived")
      .doc(requestEmail)
      .set(
        {
          accepted: true,
          read: true
        },
        { merge: true }
      );
    db.collection("users")
      .doc(requestEmail)
      .collection("interestssent")
      .doc(email)
      .set(
        {
          accepted: "accepted"
        },
        { merge: true }
      );
  };

  const declineRequest = (requestEmail) => {
    db.collection("users")
      .doc(email)
      .collection("interestsreceived")
      .doc(requestEmail)
      .set(
        {
          accepted: false,
          read: true
        },
        { merge: true }
      );
    db.collection("users")
      .doc(requestEmail)
      .collection("interestssent")
      .doc(email)
      .set(
        {
          accepted: "declined"
        },
        { merge: true }
      );
  };
  const viewContact = () => { };


  const requestContact = () => {
    userRef.set(
      {
        contactrequestssent: firebase.firestore.FieldValue.arrayUnion(
          tobeshortlistedId
        )
      },
      { merge: true }
    );

    db.collection("users")
      .doc(email)
      .collection("contactrequestssent")
      .doc(profilee.email)
      .set({
        email: profilee.email,
        name: profilee.name,
        education: profilee.education,
        star: profilee.birthstar,
        raashi: profilee.raashi,
        gothra: profilee.gothra,
        dob: profilee.birthdate,
        dp: profilee.profilepic,
        accepted: false,
        read: false,
        sentDate: firebase.firestore.FieldValue.serverTimestamp()
      });

    db.collection("users")
      .doc(profilee.email)
      .collection("contactrequestsreceived")
      .doc(email)
      .set({
        email: loggedinUser.userData.email,
        name: loggedinUser.userData.name,
        education: loggedinUser.userData.education,
        star: loggedinUser.userData.birthstar,
        raashi: loggedinUser.userData.raashi,
        gothra: loggedinUser.userData.gothra,
        dob: loggedinUser.userData.birthdate,
        dp: loggedinUser.userData.profilepic,
        accepted: false,
        read: false,
        recievedDate: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        setsnackbarmsg(`Successfully sent Contact request to ${profilee.name}`);
        setsnackbartype("success");
        setopenSnackbar(true);
        setOpenContact(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
  const [openContact, setOpenContact] = React.useState(false);

  const handleContactOpen = () => {
    setOpenContact(true);
    if (
      loggedinUser?.privacy?.contactInfo === "Everyone" ||
      !loggedinUser?.privacy?.contactInfo
    )
      if (
        profilee.privacy?.contactInfo === "Everyone" ||
        !profilee.privacy?.contactInfo
      )
        ContactViewed(profilee, loggedinUser, email);
  };

  const handleContactClose = () => {
    setOpenContact(false);
  };
  const [photoRequestSent, setphotoRequestSent] = useState();
  const [photoRequestReceived, setphotoRequestReceived] = useState();
  useEffect(() => {
    db.collection("users")
      .doc(email)
      .collection("photorequestssreceived")
      .doc(userid)
      .onSnapshot((doc) => {
        setphotoRequestReceived(doc.data());
        console.log(doc.data() + "ff");
      });

    db.collection("users")
      .doc(email)
      .collection("photorequestssent")
      .doc(userid)
      .onSnapshot((doc) => {
        setphotoRequestSent(doc.data());
      });
  }, []);
  // useEffect(() => {

  //   // const userEmail = new URLSearchParams(location.search).get('loginData');
  //   // if(userEmail){
  //   //   let userData_u = userEmail
  //   //    setloggedUsersData(userData_u)
  //   // }
  //   // console.log("userEmail123123",userEmail);
  //   const fetchDataUser = async () => {
  //     try {
  //       const response = await axios.get(`${environment.apiUrl}/signupprofiles`);
  //       console.log("responseuserrrrrr", response)
  //       // setloggedUsersData(response.data[0])
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   };
  //   fetchDataUser();
  // }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${environment.apiUrl}/profilepic`);
  //       console.log("responseeeeeeee", response)
  //       setprofileimage(response.data[0].profilepic)
  //       setpic1(response.data[0].pic1)
  //       setpic2(response.data[0].pic2)

  //     } catch (error) {
  //       console.error(error)
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const images = [
  //   {
  //     original: profileimage,
  //     thumbnail: profileimage
  //   },
  //   pic1 !== " "
  //     ? {
  //       original: pic1,
  //       thumbnail: pic1
  //     }
  //     : " ",
  //   pic2 !== ""
  //     ? {
  //       original: pic2,
  //       thumbnail: pic2
  //     }
  //     : ""
  // ];
  const images = [
    {
      original: profileimage,
      thumbnail: profileimage
    },
    pic1 !== " "
      ? {
        original: pic1,
        thumbnail: pic1
      }
      : " ",
    pic2 !== ""
      ? {
        original: pic2,
        thumbnail: pic2
      }
      : ""
  ];


  // useEffect(() => {
  //   if(){
  //     setprofileimage()
  //     setpic1()
  //     setpic2()
  //   }
  // }, [])
  // const history = useHistory();
  const updateProfile = () => {
    console.log("updateProfileeee", loggedUsersData)
    history.push(`/EditProfile`, { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser, tabids: 1 } });
  }

  const editProfileButton = () => {
    console.log("loggedUsersDataaaaa", loggedUsersData)
    history.push(`/signup?`, { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser } });
  }


  const handleTestYourself = (item) => {
    console.log("enter_navigate_test_yourself")
    history.push(`/testyourself`,
      { state: { ITEM: item } }
    );
  }




  const handleReadMore = (item) => {
    console.log("enter_navigate_handleReadMore")
    history.push(`/moreprofiledetails`,
      { state: { ITEM: item, loggedUsersData: loggedUsersData, match_pref_Data: match_pref_Data } }
    );
  }




  const [requestData, setrequestData] = useState([])
  const [UserName, set_UserName] = useState("")
  useEffect(() => {
    if (loggedUsersData?.userId) {
      fetchRequestData();
    }
  }, [loggedUsersData?.userId]);

  const fetchRequestData = async () => {
    try {
      const response = await fetch(`${environment.apiUrl}/request/findrequests/${loggedUsersData?.userId}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetchedData = await response.json();
      setrequestData(fetchedData)
      console.log('====================================');
      console.log("fetchedData", fetchedData);
      console.log('====================================');

      var matchingProfileDataById = fetchedData.map((item, index) => {
        fetch(`${environment.apiUrl}/signupprofiles/getdet/${item.userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },

        })
          .then(response => {
            console.log("responseeeeeee", response)
            if (response.ok) {
              return response.json(); // Return parsed JSON if the response is successful
            }
            throw new Error('Network response was not ok.');
          })
          .then(result => {
            console.log("result", result.firstName);
            // return result?.name;
            set_UserName(result.firstName)
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      })
    } catch (error) {
      console.error(error.message);
    }


  };


  const handleshowName_pic = (item) => {
    console.log("item", item)
    fetch(`${environment.apiUrl}/signupprofiles/getdet/${item.userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },

    })
      .then(response => {
        console.log("responseeeeeee", response)
        if (response.ok) {
          return response.json(); // Return parsed JSON if the response is successful
        }
        throw new Error('Network response was not ok.');
      })
      .then(result => {
        console.log("result", result);
        return result?.name;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }


  const [openRequest, setOpenRequest] = useState(false);
  const [itemRequestdata, setitemRequestdata] = useState({});

  const handleOpenRequest = (item) => {
    console.log(JSON.parse(localStorage.getItem('user_data')));
    let user_data = JSON.parse(localStorage.getItem('user_data'))
    if (packagePaymentStatus == 'NotPaid') {
      const userConfirmed = window.confirm("Please buy package to Accept.");
      if (userConfirmed) {
        history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "ProfileN" } });
      }

    } else if (packagePaymentStatus == 'Paid') {


      setOpenRequest(true);
      console.log("item_row", item);
      setitemRequestdata(item)

    }
  };









  const handleCloseRequest = () => {
    setOpenRequest(false);
  };

  const handleAcceptRequest = async () => {



    console.log("itemRequestdata", itemRequestdata);
    console.log("itemRequestdata?.RequestId", itemRequestdata?.RequestId);

    let reqData = {
      // userId: localStorage.getItem('userId'),
      // RequestUserId: ITEM.userId,
      // RequestId: "0",
      // RequestFor: testname,
      btStatus: true,
      // btRequested: true
    }
    try {
      const response = await fetch(`${environment.apiUrl}/request/updaterequestby_RequestId/${itemRequestdata?.RequestId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(reqData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data as needed
      const responseData = await response.json();
      fetchRequestData()
      console.log("responseData test", responseData);
    } catch (error) {
      console.error('Error during PUT request:', error);
    }




    // Handle the logic when the user accepts the request
    console.log('Request accepted');
    handleCloseRequest();
  };




  const [isTest, setisTest] = useState(false)

  const [isTesthunfif, setisTesthunfif] = useState(false)

  const [isTestModalOpen, setisTestModalOpen] = useState(false);
  const [testData, settestData] = useState({})
  const handleisTestModalClose = () => {
    setisTestModalOpen(false);
  };
  const handleViewTest = async (test) => {

    try {
      let testaddress = ''
      if (test == "satisfactiontest") {
        testaddress = 'satisfactiontest'
      }
      if (test == "personalitytest") {
        testaddress = 'personalitytest'
      }
      if (test == "sixteenpersonalityfactor") {
        testaddress = 'sixteenpersonalityfactor'
      }
      if (test == "fiftyquestions") {
        testaddress = 'fiftyquestion'
      }
      if (test == "hundredquestiontest") {
        testaddress = 'hundredquestiontest'
      }
      const response = await fetch(`${environment.apiUrl}/${testaddress}/${loggedUsersData?.userId}`);
      const result = await response.json();
      console.log('====================================');
      console.log("datatest", result);
      console.log('====================================');
      settestData(result);
      if (result.statusCode == 404) {
        alert('No Test data found')

      } else {
        if (test == "fiftyquestions" || test == "hundredquestiontest") {
          setisTesthunfif(true)
          setisTestModalOpen(true);
        }
        if (test == "satisfactiontest" || test == "personalitytest") {
          setisTest(true)
          setisTestModalOpen(true);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  return (
    <div>
      {/* {(Object.keys(profilee).length >1? */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.imageGallery}>
            {renderCount.current}
            { }
            {loggedinUser?.privacy?.photos !== "withrequest" &&
              profilee?.privacy?.photos !== "withrequest" ? (
              <Profileimagegallery user={userid} images={images} />
            ) : (
              <>
                {console.log(photoRequestSent?.accepted + "hg")}
                {loggedinUser?.photorequestssent?.includes(profilee?.email) ? (
                  photoRequestSent?.accepted ? (
                    <Profileimagegallery user={userid} images={images} />
                  ) : (
                    <div className={classes.mat__dummyprofilepic}>
                      <div className={classes.mat__dummyprofilepicholder}>
                        <AccountCircleIcon
                          className={classes.mat__dummyprofileimg}
                        />
                        <div
                          onClick={() =>
                            RequestPhoto(
                              profilee,
                              loggedinUser,
                              email,
                              setsnackbarmsg,
                              setsnackbartype,
                              setopenSnackbar
                            )
                          }
                          className={classes.mat__requestpic}
                        >
                          Request Photo
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className={classes.mat__dummyprofilepic}>
                    <div className={classes.mat__dummyprofilepicholder}>
                      <AccountCircleIcon
                        className={classes.mat__dummyprofileimg}
                      />
                      <div
                        onClick={() =>
                          RequestPhoto(
                            profilee,
                            loggedinUser,
                            email,
                            setsnackbarmsg,
                            setsnackbartype,
                            setopenSnackbar
                          )
                        }
                        className={classes.mat__requestpic}
                      >
                        Request Photo
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={classes.actionsbuttons}>
              <div className={classes.actionButtonsHolder}>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000}
                  onClose={handleClose2}
                >
                  <Alert onClose={handleClose2} severity={snackbartype}>
                    {snackbarmsg}
                  </Alert>
                </Snackbar>
                {loggedUsersData?.shortlisted?.indexOf(profilee?.email) > -1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.actionButtons}
                    endIcon={<BookmarkIcon>send</BookmarkIcon>}
                    onClick={() => removeShortlistt(profilee.email, email)}
                  >
                    Shortlisted
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.actionButtons}
                    endIcon={<BookmarkBorderIcon>send</BookmarkBorderIcon>}
                    onClick={nshortlist}
                  >
                    Shortlist
                  </Button>
                )}
                {loggedUsersData?.interestssent?.indexOf(profilee?.email) >
                  -1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.actionButtons}
                    onClick={sendInterest}
                    endIcon={<FavoriteIcon>send</FavoriteIcon>}
                  >
                    Interest Sent
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.actionButtons}
                    onClick={sendInterest}
                    endIcon={<FavoriteBorderIcon>send</FavoriteBorderIcon>}
                  >
                    Send Interest
                  </Button>
                )}
              </div>
              {profilee.privacy?.contactInfo}
              <div className={classes.actionButtonsHolder}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.actionButtons}
                  endIcon={<PhoneIphoneIcon>send</PhoneIphoneIcon>}
                  onClick={handleContactOpen}
                >
                  {loggedinUser?.contactsviewed?.indexOf(profilee?.email) > -1
                    ? "Contact Viewed"
                    : "View Contact"}
                </Button>
                {/*     <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.actionButtons}
                                endIcon={
                                    <ChatBubbleOutlineIcon>
                                        send
                                    </ChatBubbleOutlineIcon>
                                }
                            >
                                Send Message
                            </Button>*/}
              </div>
            </div>
            <Dialog
              open={openContact}
              onClose={handleContactClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              fullWidth="true"
            >
              <DialogTitle id="alert-dialog-title">Contact Info</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {loggedinUser?.privacy?.contactInfo === "Everyone" ||
                    loggedinUser?.privacy?.contactInfo === "" ? (
                    <div>
                      {profilee.privacy?.contactInfo === "Everyone" ||
                        !profilee.privacy?.contactInfo ? (
                        <div>
                          {/* {ContactViewed(profilee, loggedinUser, email)} */}
                          {profilee.name}'s contact #{" "}
                          <a href="tel:PHONE_NUM"> {profilee?.phone} </a>
                        </div>
                      ) : (
                        <div>
                          <b>
                            <i>{profilee.name}</i>
                          </b>{" "}
                          has hidden contact info please request for contact
                          info
                          <a
                            href="javasccript:void(0)"
                            onClick={() =>
                              RequestContact(
                                profilee,
                                loggedinUser,
                                email,
                                setsnackbarmsg,
                                setsnackbartype,
                                setopenSnackbar,
                                setOpenContact
                              )
                            }
                            className={globalClasses.viewLink}
                          >
                            {" "}
                            Request Contact{" "}
                          </a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p>
                        You have restricted your contact Info only for people
                        who request, either change your privacy settings or
                        request user for contact info
                      </p>
                    </div>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleContactClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <div className={classes.actions__msg}>
              {loggedUsersData?.interestsreceived?.indexOf(profilee?.email) >
                -1 ? (
                <Paper className={classes.requestmsg}>
                  <div className={classes.requestsection}>
                    {!interestReceived?.accepted ? (
                      <div className={classes.requestsectioninner}>
                        <span>
                          {interestReceived?.name} is awaiting for response
                        </span>
                        <div className={classes.requestsectionButtons}>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.acceptdecline}
                            onClick={(e) => acceptRequest(profilee.email)}
                          >
                            Accept
                          </Button>
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.acceptdecline}
                            onClick={(e) => declineRequest(profilee.email)}
                          >
                            <strong>Decline</strong>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.requestmsgholderr}>
                        {/* <Paper className={classes.requestmsg}> */}
                        You have
                        {interestReceived?.accepted
                          ? " accepted"
                          : " declined"}{" "}
                        <b>
                          {interestReceived?.name}
                          's{" "}
                        </b>
                        interest
                        {/* </Paper> */}
                      </div>
                    )}
                  </div>
                </Paper>
              ) : (
                <div></div>
              )}

              {loggedUsersData?.interestssent?.indexOf(profilee?.email) > -1 ? (
                <div className={classes.requestmsgholder} attr="tesssss">
                  <Paper className={classes.requestmsg}>
                    <>
                      {!interestSent?.accepted ? (
                        <div>
                          Awaiting response from {interestSent?.name} for your
                          request
                        </div>
                      ) : (
                        <div>
                          {loggedUsersData?.interestsreceived?.indexOf(
                            profilee.email
                          ) > -1 &&
                            loggedUsersData?.interestssent?.indexOf(
                              profilee.email
                            ) > -1 ? (
                            <>
                              & <br />
                            </>
                          ) : (
                            ""
                          )}{" "}
                          {/* &<br></br> */}
                          <strong> {interestSent?.name} </strong>
                          has
                          {interestSent?.accepted === "accepted"
                            ? " accepted"
                            : " declined"}{" "}
                          <strong>Your </strong>
                          request
                        </div>
                      )}
                    </>
                  </Paper>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <PhotoRequestMessage
              loggedUsersData={loggedUsersData}
              profilee={profilee}
              userid={userid}
              email={email}
              photoRequestSent={photoRequestSent}
              photoRequestReceived={photoRequestReceived}
            />
          </div>
        </Grid>


        <Grid item xs={12} sm={6}>

          <ul class="nav nav-tabs" role="tablist">

            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true">View / Update Profile</a>
            </li>

            <li class="nav-item" role="presentation" onClick={handleClickPre}>
              <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false">Matches {match_final_Data.length}</a>
            </li>

            <li class="nav-item" role="presentation" onClick={handleClick}>
              <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">Test Yourself</a>
            </li>


            <li class="nav-item" role="presentation">
              <a class="nav-link" id="simple-tab-3" data-bs-toggle="tab" href="#simple-tabpanel-3" role="tab" aria-controls="simple-tabpanel-3" aria-selected="false">Notifications {requestData.length}</a>
            </li>


            <li class="nav-item" role="presentation">
              <a class="nav-link" id="simple-tab-4" data-bs-toggle="tab" href="#simple-tabpanel-4" role="tab" aria-controls="simple-tabpanel-4" aria-selected="false">Tests </a>
            </li>

          </ul>

          <div class="tab-content pt-2" id="tab-content">

            {/* tab-0 */}
            <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 28 }}>Profile of {loggedUsersData.name}</div>
                <div
                //  onClick={editProfileButton} 
                //  style={{ fontSize: 28, cursor: 'pointer' }}
                >
                  {/* <i class="ri-edit-box-fill"></i> */}
                  <Button
                    onClick={() => updateProfile(loggedUsersData.userId)}
                    // to={{
                    //   pathname: "/EditProfile",
                    //   state: {
                    //     tabids: "0"
                    //   }
                    // }}
                    color="inherit"
                    underline="none"
                    className={classes.sublinksProfile}
                  >
                    <i style={{ fontSize: 28, cursor: 'pointer' }} class="ri-edit-box-fill"></i>
                  </Button>
                </div>
              </div>

              <Card className={classes.listgroup}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5" className={classes.subheading}>
                    Basic Details
                  </Typography>
                  <div>
                    <i class="ri-edit-box-fill"></i>
                  </div>
                </div>
                <ul className={classes.listdesc}>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>NAME</strong>
                    </span>
                    {/* <span className={classes.rightSidedesc}>{loggedUsersData.name}</span> */}
                    <span className={classes.rightSidedesc}>{loggedUsersData.firstName}</span><span>{" "}</span>
                    <span className={classes.rightSidedesc}>{loggedUsersData.lastName}</span>
                  </li>{" "}
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>AGE</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {age}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>HEIGHT</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.height}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Profile Created by</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.createdby}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Current Location</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.residingcity},{loggedUsersData.residingstate},
                      {loggedUsersData.residingcountry}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Marital Status</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.maritalstatus}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Mother Tongue</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.mothertongue}
                    </span>
                  </li>
                </ul>
              </Card>

              <Card className={classes.listgroup}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5">About & Hobbies</Typography>
                  <div>
                    <i class="ri-edit-box-fill"></i>
                  </div>
                </div>
                <ul className={classes.listdesc}>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>About Yourself(Bio)</strong>
                    </span>
                    <span className={classes.rightSidedesc}>{loggedUsersData.bio}</span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Hobbies</strong>
                    </span>
                    <span className={classes.rightSidedesc}>{loggedUsersData.hobbies}</span>
                  </li>

                </ul>
              </Card>

              <Card className={classes.listgroup}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5">Religion & Horoscope Details</Typography>
                  <div>
                    <i class="ri-edit-box-fill"></i>
                  </div>
                </div>
                <ul className={classes.listdesc}>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Religion</strong>
                    </span>
                    <span className={classes.rightSidedesc}>{loggedUsersData.caste}</span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Caste</strong>
                    </span>
                    <span className={classes.rightSidedesc}>{loggedUsersData.caste}</span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Sub Caste</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.subcaste}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Gothra</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.gothra}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Raashi</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.raashi}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Nakshatra</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.birthstar}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Date of Birth</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {formattedDate}
                      {/* {moment(profilee?.birthdate?.toDate()).format("ll")} */}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Place of Birth</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.birthcity},{loggedUsersData.birthstate},
                      {loggedUsersData.birthcountry}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Time of Birth</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {/* {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }).format(
                            profilee.birthtime?.toDate().getTime()
                        )} */}
                      {/* {new Date(
                        loggedUsersData?.birthtime?.toDate().getTime()
                      ).toLocaleTimeString()} */}

                      {formatTime(loggedUsersData?.birthtime)}
                    </span>
                  </li>
                </ul>
              </Card>

              <Card className={classes.listgroup}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5">Professional Details</Typography>
                  <div>
                    <i class="ri-edit-box-fill"></i>
                  </div>
                </div>
                <ul className={classes.listdesc}>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Highest Education</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.education}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Employment</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.employment}
                    </span>
                  </li>
                  {loggedUsersData.employment !== "Not Working" ? (
                    <>
                      <li className={classes.listdescli}>
                        <span className={classes.leftSideHead}>
                          <strong>Company Name</strong>
                        </span>
                        <span className={classes.rightSidedesc}>
                          {loggedUsersData.companyname}
                        </span>
                      </li>
                      <li className={classes.listdescli}>
                        <span className={classes.leftSideHead}>
                          <strong>Job nature</strong>
                        </span>
                        <span className={classes.rightSidedesc}>
                          {loggedUsersData.jobnature}
                        </span>
                      </li>
                      <li className={classes.listdescli}>
                        <span className={classes.leftSideHead}>
                          <strong>Annual Income</strong>
                        </span>
                        <span className={classes.rightSidedesc}>
                          {loggedUsersData.income} {loggedUsersData.Currency?.symbol}{" "}
                          {loggedUsersData.Currency?.cc}
                        </span>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </Card>

              <Card className={classes.listgroup}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5">Family Details</Typography>
                  <div>
                    <i class="ri-edit-box-fill"></i>
                  </div>
                </div>
                <ul className={classes.listdesc}>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Family Values</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.familyvalues}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Family Type</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData.familytype}
                    </span>
                  </li>
                  <li className={classes.listdescli}>
                    <span className={classes.leftSideHead}>
                      <strong>Family Status</strong>
                    </span>
                    <span className={classes.rightSidedesc}>
                      {loggedUsersData?.familystatus}
                    </span>
                  </li>
                </ul>
              </Card>

              <Card className={classes.listgroup}>
                <div className="partnerprefLink">
                  <h2>
                    {/* EDIT */}
                    Fill partner preferences details{" "}
                    <button
                      onClick={() => history.push(`/PartPref`, { state: { loggedUsersData: loggedUsersData, loggedinUser: loggedinUser, tabids: 4 } })}


                      // to={{
                      //   pathname: "/EditProfile",
                      //   state: {
                      //     tabids: "4"
                      //   }
                      // }}
                      color="inherit"
                      underline="none"
                      className={classes.sublinksProfile}
                      style={{ cursor: "pointer", backgroundColor: "#1877f2", padding: "4px 4px", color: "#fff" }}
                    >
                      Click here
                    </button>
                  </h2>
                </div>
                {/* <Typography variant="h5">Partner Preferences</Typography> */}
                {/* {loggedUsersData.partnerpreferencesflag ? ( */}
                {/* <ListWithLoading
                  isLoading={loadingpartnerPref}
                  loggedUsersData={loggedUsersData}
                  profilee={profilee}
                /> */}
                {/* ) : (
  ""
)} */}
                {/* {Object.keys(loggedUsersData).length &&
                  Object.keys(profilee).length ? (
                  <Profilepartnerpreference
                    loggedUsersData={loggedUsersData}
                    profilee={profilee}
                  />
                ) : (
                  <div className={classes.root}>
                    <Skeleton />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                  </div>
                )} */}
              </Card>

            </div>

            {/* tab-1 */}
            <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">

              {match_pref_Data.message == "No Matches" ?
                <div>
                  {match_pref_Data.message}
                </div>
                :
                <div >

                  {/* <Card sx={{ width: 250, borderRadius: 15, maxHeight: '100%', overflowY: 'auto' }}>
                    <CardContent> */}
                  {/* <form > */}
                  {/* <h2>Search</h2>
                 
                  <div style={{ width: '100%', display: "flex", flexDirection: "row", alignItems: "center", }}>
                    <Typeahead
                      id="myTypeahead"
                      labelKey="name"
                      multiple
                      options={options}
                      placeholder="Type to search..."
                      onChange={(selected) => setSelectedOptions(selected)}
                      selected={selectedOptions}
                      style={{ width: '80%', maxHeight: '200px' }}
                    />
                    <Button variant="contained" color="primary" type="submit" sx={{ width: '20%', marginTop: 2 }}>
                      Search
                    </Button>
                  </div> */}

                  {/* </form> */}
                  {/* </CardContent>
                  </Card> */}

                  {match_final_Data_allprint_array?.map((item, index) => {
                    return (
                      <div className="py-1" key={index}>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card mb-2 ">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                      <a href={item.profilepic} target="blank">
                                        <img className="w-100" src={item.profilepic} alt="k" />
                                      </a>
                                      {/* <button>Test</button> */}
                                    </div>
                                    <div className="col-md-6">
                                      <h4 class="card-title">{maskUsernamefull(item.firstName)}<span>{" "}</span>{item.lastName}</h4>
                                      <div class="d-flex justify-content-between">
                                        <div>Online 1h ago</div>
                                        <div>You & Her</div>
                                        <div>Astro</div>
                                      </div>
                                      <div class="border-top my-2"></div>
                                      <div class="d-flex justify-content-between" style={{ fontSize: 12 }}>
                                        <div class="d-flex flex-column">
                                          <div class="d-flex">
                                            <span>27 yrs</span>
                                            <span>{item.height}</span>
                                            <span>{item.star}</span>
                                          </div>
                                          <div>{item.mothertongue}</div>
                                          <div><span>{item.caste}</span><span>, </span><span>{item.subcaste}</span></div>
                                        </div>
                                        <div class="d-flex flex-column">
                                          <div>{item.maritalstatus}</div>
                                          <div><span>{item.residingcity}</span><span>, </span><span>{item.residingstate}</span></div>
                                          <div>{item.employment}</div>
                                        </div>
                                      </div>
                                      <div className="my-1 text-md" title="I graduated in Management from a renowned institution. Growing up, I was a disciplined and focused." >
                                        I graduated in Management from a renowned institution. Growing up....<span title="I graduated in Management from a renowned institution. Growing up, I was a disciplined and focused." className="text-info" style={{ cursor: "pointer" }} onClick={() => handleReadMore(item)}>Read more</span>
                                      </div>
                                      {/* <h5 className="card-title my-2">Why I'm the Best Practise.</h5>
                                      <p className="card-text text-dark">Some quick example text to build on the"</p> */}
                                      {/* <button type="button" class="btn btn-info w-100" onClick={() => handleTestYourself(item)}>Do You want to know Test Report?</button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>


                // <div>
                //   {match_final_Data_allprint_array.map((item, index) => {
                //     return (
                //       <div class="container-fluid" key={index}>
                //         <div class="row">
                //           <div class="col-12 mt-3">
                //             <div class="card">
                //               <div class="card-horizontal" style={{ display: 'flex', flexGrow: 1, flexShrink: 1, flexBasis: 'auto' }}>
                //                 <div class="img-square-wrapper" style={{ width: '280px', height: '280px' }}>
                //                   <img style={{ width: '100%', height: '100%' }} class="" src={item.profilepic} alt="Card image cap" />
                //                 </div>
                //                 <div class="card-body">
                //                   <div className="">
                //                     <h4 class="card-title">{item.name}</h4>
                //                     <div>
                //                       <div className="d-flex justify-content-between">
                //                         <div>Online 1h ago</div>
                //                         <div>You & Her</div>
                //                         <div>Astro</div>
                //                       </div>
                //                       <div className="border-top my-3"></div>
                //                       <div>
                //                         <div className="d-flex justify-content-between">
                //                           <div className="d-flex flex-direction-column">
                //                             <div className="d-flex">
                //                               <span>27 yrs</span>
                //                               <span>{item.height}</span>
                //                               <span>{item.star}</span>
                //                             </div>
                //                             <div>
                //                               {item.mothertongue}
                //                             </div>
                //                             <div>
                //                               {item.caste},{item.subcaste}
                //                             </div>
                //                           </div>
                //                           <div className="d-flex flex-direction-column">
                //                             <div>{item.maritalstatus}</div>
                //                             <div>{item.residingcity},{item.residingstate}</div>
                //                             <div>{item.employment}</div>
                //                           </div>
                //                         </div>
                //                       </div>

                //                       <div>
                //                         I graduated in Management from a renowned institution. Growing up, I was a disciplined and focused s...
                //                       </div>
                //                     </div>
                //                   </div>
                //                   <div>
                //                     Chek Button
                //                   </div>


                //                 </div>
                //               </div>
                //               <div class="card-footer">
                //                 <small class="text-muted">Last updated 3 mins ago</small>
                //               </div>
                //             </div>

                //           </div>
                //         </div>
                //       </div>
                //     )

                //   })}

                // </div>

              }



            </div>

            {/* tab-2 */}
            <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
              Test Yourself
            </div>


            {/* tab-3 */}
            <div class="tab-pane" id="simple-tabpanel-3" role="tabpanel" aria-labelledby="simple-tab-3">


              <div className="card">
                <div className="card-body">
                  <ul className="list-group">
                    {requestData.map((item, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        {item.RequestFor}
                        {/* <div>Request Send by {UserName}</div> */}
                        <div>Request Send by {item?.requesteduname}</div>
                        {item.btStatus == false ?
                          <button onClick={() => handleOpenRequest(item)} type="button" className="btn btn-primary rounded-pill">Accept</button>
                          :
                          <button type="button" className="btn btn-primary rounded-pill">Accepted</button>

                        }

                      </li>
                    ))}
                  </ul>
                </div>
              </div>




            </div>



            {/* tab-4 */}
            <div class="tab-pane" id="simple-tabpanel-4" role="tabpanel" aria-labelledby="simple-tab-4">
              <div className="card">
                <div className="card-body">
                  <ul className="list-group">

                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Satisfaction Test

                      <button
                        onClick={() => handleViewTest("satisfactiontest")}
                        type="button" className="btn btn-primary rounded-pill">View</button>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Personality Test

                      <button
                        onClick={() => handleViewTest("personalitytest")}
                        type="button" className="btn btn-primary rounded-pill">View</button>
                    </li>


                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                      Sixteen Personality Factor
                      <button
                        onClick={() => handleViewTest("sixteenpersonalityfactor")}
                        type="button" className="btn btn-primary rounded-pill">View</button>
                    </li> */}


                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Fifty Questions Test

                      <button
                        onClick={() => handleViewTest("fiftyquestions")}
                        type="button" className="btn btn-primary rounded-pill">View</button>
                    </li>


                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Hundred Question Test

                      <button
                        onClick={() => handleViewTest("hundredquestiontest")}
                        type="button" className="btn btn-primary rounded-pill">View</button>
                    </li>

                  </ul>
                </div>
              </div>
            </div>



          </div>

        </Grid>
      </Grid>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style1, width: 400 }}>
          <h2 id="parent-modal-title">Test List</h2>

          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="custom-links">
                  <div className='link-like-div' onClick={() => handleTest('psy')}>1. Psychological Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('blood')}>2. Blood grouping Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('thal')}>3. Thalassemia Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('std')}>4. Tests for sexually transmitted Diseases</div>
                  <div className='link-like-div' onClick={() => handleTest('infer')}>5. Infertility Screening</div>
                  <div className='link-like-div' onClick={() => handleTest('chr')}>6. Chronic disorders</div>
                </div>
              </div>
            </div>
          </div>


        </Box>
      </Modal> */}


      <Modal className="modal-text-black" centered show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Test List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-links">
                  <div className='link-like-div' onClick={() => handleTest('psy')}>1. Psychological Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('blood')}>2. Blood grouping Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('thal')}>3. Thalassemia Tests</div>
                  <div className='link-like-div' onClick={() => handleTest('std')}>4. Tests for sexually transmitted Diseases</div>
                  <div className='link-like-div' onClick={() => handleTest('infer')}>5. Infertility Screening</div>
                  <div className='link-like-div' onClick={() => handleTest('chr')}>6. Chronic disorders</div>
                  <div className='link-like-div' onClick={() => handleTest('hor')}>7. Horoscope Test</div>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




      {/* <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style1, width: 200 }}>
          <h2 id="child-modal-title">Test Result</h2>
          <p id="child-modal-description">
            Your form has submitted successfully
          </p>
          <button
            onClick={handleClose1}
            type="button"

            style={{
              backgroundColor: '#4caf50',
              color: 'white',
              padding: '10px 15px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >Download your Result</button>
        </Box>
      </Modal> */}



      <Modal centered show={open1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Test Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your form has submitted successfully</p>
          <Button
            variant="success"
            onClick={handleClose1}
            style={{ borderRadius: '5px' }}
          >
            Download your Result
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>






      {/* <Modal
        open={Psychologicalmodel}
        onClose={handlePsychologicalmodelclose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style1, width: 200 }}>
          <h2 id="child-modal-title">Psychological Tests</h2>
          <div>
            It will show in know (more icon) Why is it important: In a country where arranged
            marriages are the norm, would you know, just by looking at a person that he/she has a mental
            illness? Or that he/she suffers from depression, schizophrenia or even a personality disorder? The
            answer is quite clearly no.
            So, here is what you need to do, get yourself and your partner tested. While, testing for mental
            illness includes checking for conditions like schizophrenia, depression, mood disorders, mania, etc.
            the tests should also include behavioral and personality disorders. There is a whole array of things
            that could put a dent in your marriage. Conditions like obsessive compulsive personality disorder,
            narcissistic personality disorders, partner relationship problems, anger management issues, bipolar
            disorder and many more, could be a part of your significant other s life. Remember, these conditions
            are not compartmentalized, you will end up bearing the brunt of his/her condition. So get tested. A
            simple psychometric test, which ideally takes a day will tell you about your mental health status.
            While this is no reason to leave and run ( in the case of a positive diagnosis), the treating
            psychologist will definitely tell you about how you need to manage the person and the marriage.
            Marriage counsellors are trained to help people who are planning to get married. They guide them
            on a variety of subjects like sexual compatibility and intimacy, emotional intimacy, different
            adjustments that have to be made, interacting and living with in-laws, joint or nuclear families,
            communication patterns and misinterpretations, etc.
            What it would entail: This test is called a psychometric test and usually takes a day to do. To do the
            analysis, a clinical psychologist will administer tests like multiple choice questions, picture analysis
            etc. that you have to answer. It is simple and your identity will be kept under wraps if you desire. In
            the end, getting married should be a happy occasion, but it is important to remember that it is a
            lifelong commitment and knowing what you are getting into, health wise is the best way to succeed.
            The test is compulsory for the candidate here
            Test yourself, down load the report to know yourself.
            1. Satisfaction with life (Each will be leads to new window with description
            of individual test )
            2. Personality Test (Each will be leads to new window with description of
            individual test )
            3. The Sixteen Personality Factor (Each will be leads to new window with
            description of individual test )
            4. 50 Questions You Must Ask Before Getting Married (It will be generate a
            test report fill up by candidate . )
            5. 100 Questions You Must Ask Before Getting Married(It will be generate a
            test report fill up by candidate . )
            6.
            All the test report available in the profile. The test report will
            be download by the candidate itself. On demand and
            approved by the other candidate, it will be download by
            others
          </div>
          <button
            onClick={handlePsychologicalmodelclose}
            type="button"
            style={{
              backgroundColor: '#4caf50',
              color: 'white',
              padding: '10px 15px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >Close</button>
        </Box>
      </Modal> */}




      <Modal centered show={Psychologicalmodel} onHide={handlePsychologicalmodelclose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Psychological Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              It will show in know (more icon) Why is it important: In a country where arranged
              marriages are the norm, would you know, just by looking at a person that he/she has a mental
              illness? Or that he/she suffers from depression, schizophrenia or even a personality disorder? The
              answer is quite clearly no.
              So, here is what you need to do, get yourself and your partner tested. While, testing for mental
              illness includes checking for conditions like schizophrenia, depression, mood disorders, mania, etc.
              the tests should also include behavioral and personality disorders. There is a whole array of things
              that could put a dent in your marriage. Conditions like obsessive compulsive personality disorder,
              narcissistic personality disorders, partner relationship problems, anger management issues, bipolar
              disorder and many more, could be a part of your significant other s life. Remember, these conditions
              are not compartmentalized, you will end up bearing the brunt of his/her condition. So get tested. A
              simple psychometric test, which ideally takes a day will tell you about your mental health status.
              While this is no reason to leave and run ( in the case of a positive diagnosis), the treating
              psychologist will definitely tell you about how you need to manage the person and the marriage.
              Marriage counsellors are trained to help people who are planning to get married. They guide them
              on a variety of subjects like sexual compatibility and intimacy, emotional intimacy, different
              adjustments that have to be made, interacting and living with in-laws, joint or nuclear families,
              communication patterns and misinterpretations, etc.
              What it would entail: This test is called a psychometric test and usually takes a day to do. To do the
              analysis, a clinical psychologist will administer tests like multiple choice questions, picture analysis
              etc. that you have to answer. It is simple and your identity will be kept under wraps if you desire. In
              the end, getting married should be a happy occasion, but it is important to remember that it is a
              lifelong commitment and knowing what you are getting into, health wise is the best way to succeed.
              The test is compulsory for the candidate here
              Test yourself, down load the report to know yourself.
              1. Satisfaction with life (Each will be leads to new window with description
              of individual test )
              2. Personality Test (Each will be leads to new window with description of
              individual test )
              3. The Sixteen Personality Factor (Each will be leads to new window with
              description of individual test )
              4. 50 Questions You Must Ask Before Getting Married (It will be generate a
              test report fill up by candidate . )
              5. 100 Questions You Must Ask Before Getting Married(It will be generate a
              test report fill up by candidate . )
              6.
              All the test report available in the profile. The test report will
              be download by the candidate itself. On demand and
              approved by the other candidate, it will be download by
              others
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handlePsychologicalmodelclose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




      {/* <Modal
        open={openRequest}
        onClose={handleCloseRequest}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style1, width: 200 }}>
          <h2 id="modal-title">Confirmation</h2>
          <p id="modal-description">Do you want to accept this request?</p>
          <Button variant="contained" onClick={handleAcceptRequest} style={{ marginRight: 8 }}>
            Submit
          </Button>
          <Button variant="contained" onClick={handleCloseRequest}>
            Cancel
          </Button>
        </Box>
      </Modal> */}



      <Modal centered show={openRequest} onHide={handleCloseRequest}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to accept this request?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleAcceptRequest} style={{ marginRight: 8 }}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseRequest}>
            No
          </Button>
        </Modal.Footer>
      </Modal>





      {isTest == true ?

        <Modal centered size="xl" show={isTestModalOpen} onHide={handleisTestModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{testData?.testName}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="thank-you-pop">
              <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
              <p>Answer Report: {testData?.testName} result is {testData.testResult} towards life.</p>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleisTestModalClose}>
              OK
            </Button>
            <Button variant="primary" onClick={handleisTestModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        :
        null
      }



      {isTesthunfif == true ?

        <Modal centered size="lg" show={isTestModalOpen} onHide={handleisTestModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{testData?.testName}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-1">
            <div className="modal-body-content">
              <p>Timer: {testData.timer}</p>
              <p>Timestamp: {testData.timestamp}</p>


              <h6>Questions & Answers:</h6>
              {testData.questionsanswers.map((qa, index) => (
                <div key={index}>
                  <p><strong>Question {qa.id}: </strong>{qa.question}</p>
                  <p><strong>Selected Answer: </strong>{qa.selectedAnswer}</p>
                  <p><strong>Options: </strong>
                    {/* {qa.options.join(", ")} */}
                  </p>
                  <ul>
                    {qa.options.map((option, i) => (
                      <li key={i}>{i + 1}. {option}</li>
                    ))}
                  </ul>
                </div>
              ))
              }
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleisTestModalClose}>
              OK
            </Button>
            <Button variant="primary" onClick={handleisTestModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        :
        null
      }



    </div >
  );
}

export default Profile;