import React from 'react'
import './TestYourself.css'
import { useLocation } from "react-router-dom";





const TestYourself = () => {


    const location = useLocation();
    const state = location?.state
    const ITEM = location?.state?.state?.ITEM

    console.log('====================================');
    console.log(ITEM);
    console.log(location?.state);
    console.log('====================================');
    return (



        <div class="container">
            <div class="main-body">


                <nav aria-label="breadcrumb" class="main-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)">User</a></li>
                        <li class="breadcrumb-item active" aria-current="page">User Profile</li>
                    </ol>
                </nav>


                <div class="row gutters-sm">
                    <div class="col-md-4 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center text-center">
                                    <img src={ITEM?.profilepic} alt="Admin" class="rounded-circle" width="150" />
                                    <div class="mt-3">
                                        <h4>{ITEM?.name}</h4>

                                        <p class="text-secondary mb-1">{ITEM?.education}</p>
                                        <p class="text-secondary mb-1">{ITEM?.employment}, {ITEM?.companyname}</p>
                                        <p class="text-muted font-size-sm">{ITEM?.residingcity}, {ITEM?.residingcountry}</p>
                                        <button class="btn btn-primary">Follow</button>
                                        <button class="btn btn-outline-primary">Message</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-3">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Website</h6>
                                    <span class="text-secondary">https://bootdey.com</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Github</h6>
                                    <span class="text-secondary">bootdey</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
                                    <span class="text-secondary">@bootdey</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram mr-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
                                    <span class="text-secondary">bootdey</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
                                    <span class="text-secondary">bootdey</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Full Name</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {ITEM?.name}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Email</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {ITEM?.email}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Phone</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        (239) 816-9029
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Mobile</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        (320) 380-4539
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h6 class="mb-0">Address</h6>
                                    </div>
                                    <div class="col-sm-9 text-secondary">
                                        {ITEM?.birthcity}, {ITEM?.birthstate},   {ITEM?.birthcountry}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-12">
                                        <a class="btn btn-info " target="__blank" href="https://www.bootdey.com/snippets/view/profile-edit-data-and-skills">Edit</a>
                                    </div>
                                </div>
                            </div>
                        </div>



















                        <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css" />
                        <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
                        <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                        {/* <!------ Include the above in your HEAD tag ----------> */}

                        <div class="container">
                            <h2>TRACK INFO</h2>
                            <div class="row">

                                <div class="col-md-12 col-lg-12">
                                    <div id="tracking-pre"></div>
                                    <div id="tracking">
                                        <div class="text-center tracking-status-intransit">
                                            <p class="tracking-status text-tight">in transit</p>
                                        </div>
                                        <div class="tracking-list">
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Aug 10, 2018<span>05:01 PM</span></div>
                                                <div class="tracking-content">DESTROYEDPER SHIPPER INSTRUCTION<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Aug 10, 2018<span>11:19 AM</span></div>
                                                <div class="tracking-content">SHIPMENT DELAYSHIPPER INSTRUCTION TO DESTROY<span>SHENZHEN, CHINA, PEOPLE'S REPUBLIC</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 27, 2018<span>04:08 PM</span></div>
                                                <div class="tracking-content">DELIVERY ADVICERequest Instruction from ORIGIN<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 20, 2018<span>05:25 PM</span></div>
                                                <div class="tracking-content">Delivery InfoCLOSED-OFFICE/HOUSE CLOSED<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-outfordelivery">
                                                    <svg class="svg-inline--fa fa-shipping-fast fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="shipping-fast" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-shipping-fast"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 20, 2018<span>08:58 AM</span></div>
                                                <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 19, 2018<span>05:42 PM</span></div>
                                                <div class="tracking-content">Delivery InfoUNABLE TO ACCESS<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-outfordelivery">
                                                    <svg class="svg-inline--fa fa-shipping-fast fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="shipping-fast" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-shipping-fast"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 19, 2018<span>07:36 AM</span></div>
                                                <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 17, 2018<span>10:54 AM</span></div>
                                                <div class="tracking-content">Delivery InfoCLOSED-OFFICE/HOUSE CLOSED<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-outfordelivery">
                                                    <svg class="svg-inline--fa fa-shipping-fast fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="shipping-fast" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-shipping-fast"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 17, 2018<span>08:08 AM</span></div>
                                                <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 16, 2018<span>12:30 PM</span></div>
                                                <div class="tracking-content">SHIPMENT DELAYCONSIGNEE NOT AVAILABLE<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 16, 2018<span>12:06 PM</span></div>
                                                <div class="tracking-content">Delivery InfoCLOSED-OFFICE/HOUSE CLOSED<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-outfordelivery">
                                                    <svg class="svg-inline--fa fa-shipping-fast fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="shipping-fast" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-shipping-fast"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 16, 2018<span>08:32 AM</span></div>
                                                <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 14, 2018<span>01:57 PM</span></div>
                                                <div class="tracking-content">Delivery InfoMISSED DELIVERY<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-outfordelivery">
                                                    <svg class="svg-inline--fa fa-shipping-fast fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="shipping-fast" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-shipping-fast"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 14, 2018<span>08:41 AM</span></div>
                                                <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 11, 2018<span>05:22 PM</span></div>
                                                <div class="tracking-content">Shipment designated to .<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 11, 2018<span>10:32 AM</span></div>
                                                <div class="tracking-content">Shipment arrived at KUALA LUMPUR (LOGISTICS HUB), MALAYSIA station.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 10, 2018<span>02:30 PM</span></div>
                                                <div class="tracking-content">Custom cleared and arrived at KUALA LUMPUR (LOGISTICS HUB), MALAYSIA station.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 10, 2018<span>07:30 AM</span></div>
                                                <div class="tracking-content">Shipment arrived at airport.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 10, 2018<span>03:59 AM</span></div>
                                                <div class="tracking-content">Shipment departed to MALAYSIA.<span>HONG KONG, HONGKONG</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 09, 2018<span>04:03 PM</span></div>
                                                <div class="tracking-content">Shipment designated to MALAYSIA.<span>SHENZHEN, CHINA, PEOPLE'S REPUBLIC</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 09, 2018<span>11:04 AM</span></div>
                                                <div class="tracking-content">Pickup shipment checked in at SHENZHEN.<span>SHENZHEN, CHINA, PEOPLE'S REPUBLIC</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-intransit">
                                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-circle"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 09, 2018<span>10:09 AM</span></div>
                                                <div class="tracking-content">Shipment info registered at SHENZHEN.<span>SHENZHEN, CHINA, PEOPLE'S REPUBLIC</span></div>
                                            </div>
                                            <div class="tracking-item">
                                                <div class="tracking-icon status-inforeceived">
                                                    <svg class="svg-inline--fa fa-clipboard-list fa-w-12" aria-hidden="true" data-prefix="fas" data-icon="clipboard-list" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                                                        <path fill="currentColor" d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"></path>
                                                    </svg>
                                                    {/* <!-- <i class="fas fa-clipboard-list"></i> --> */}
                                                </div>
                                                <div class="tracking-date">Jul 06, 2018<span>02:02 PM</span></div>
                                                <div class="tracking-content">Shipment designated to MALAYSIA.<span>HONG KONG, HONGKONG</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        {/* <div class="row gutters-sm">
                            <div class="col-sm-6 mb-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                                        <small>Web Design</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Website Markup</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>One Page</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Mobile Template</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '55%' }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Backend API</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 mb-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                                        <small>Web Design</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Website Markup</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>One Page</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Mobile Template</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '55%' }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <small>Backend API</small>
                                        <div class="progress mb-3" style={{ height: 5 }}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                    </div>
                </div>

            </div>
        </div>




        // <div class="container-xl px-4 mt-4">
        //     <nav class="nav nav-borders">
        //         <a class="nav-link active ms-0" href="https://www.bootdey.com/snippets/view/bs5-edit-profile-account-details" target="__blank">Profile</a>
        //         <a class="nav-link" href="https://www.bootdey.com/snippets/view/bs5-profile-billing-page" target="__blank">Billing</a>
        //         <a class="nav-link" href="https://www.bootdey.com/snippets/view/bs5-profile-security-page" target="__blank">Security</a>
        //         <a class="nav-link" href="https://www.bootdey.com/snippets/view/bs5-edit-notifications-page" target="__blank">Notifications</a>
        //     </nav>
        //     <hr class="mt-0 mb-4" />
        //     <div class="row">
        //         <div class="col-xl-4">
        //             <div class="card mb-4 mb-xl-0">
        //                 <div class="card-header">Profile Picture</div>
        //                 <div class="card-body text-center">
        //                     <img class="img-account-profile rounded-circle mb-2" src="http://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
        //                     <div class="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
        //                     <button class="btn btn-primary" type="button">Upload new image</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div class="col-xl-8">
        //             <div class="card mb-4">
        //                 <div class="card-header">Account Details</div>
        //                 <div class="card-body">
        //                     <form>
        //                         <div class="mb-3">
        //                             <label class="small mb-1" for="inputUsername">Username (how your name will appear to other users on the site)</label>
        //                             <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="username" />
        //                         </div>
        //                         <div class="row gx-3 mb-3">
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputFirstName">First name</label>
        //                                 <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" value="Valerie" />
        //                             </div>
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputLastName">Last name</label>
        //                                 <input class="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value="Luna" />
        //                             </div>
        //                         </div>
        //                         <div class="row gx-3 mb-3">
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputOrgName">Organization name</label>
        //                                 <input class="form-control" id="inputOrgName" type="text" placeholder="Enter your organization name" value="Start Bootstrap" />
        //                             </div>
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputLocation">Location</label>
        //                                 <input class="form-control" id="inputLocation" type="text" placeholder="Enter your location" value="San Francisco, CA" />
        //                             </div>
        //                         </div>
        //                         <div class="mb-3">
        //                             <label class="small mb-1" for="inputEmailAddress">Email address</label>
        //                             <input class="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="name@example.com" />
        //                         </div>
        //                         <div class="row gx-3 mb-3">
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputPhone">Phone number</label>
        //                                 <input class="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number" value="555-123-4567" />
        //                             </div>
        //                             <div class="col-md-6">
        //                                 <label class="small mb-1" for="inputBirthday">Birthday</label>
        //                                 <input class="form-control" id="inputBirthday" type="text" name="birthday" placeholder="Enter your birthday" value="06/10/1988" />
        //                             </div>
        //                         </div>
        //                         <button class="btn btn-primary" type="button">Save changes</button>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        // <div>
        //     <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />
        //     <div class="container bootstrap snippets bootdeys">
        //         <div class="row">
        //             <div class="col-lg-3 col-md-3 col-sm-4">
        //                 <div class="panel rounded shadow">
        //                     <div class="panel-body">
        //                         <div class="inner-all">
        //                             <ul class="list-unstyled">
        //                                 <li class="text-center">
        //                                     <img data-no-retina="" class="img-circle img-responsive img-bordered-primary" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="John Doe" />
        //                                 </li>
        //                                 <li class="text-center">
        //                                     <h4 class="text-capitalize">John Doe</h4>
        //                                     <p class="text-muted text-capitalize">web designer</p>
        //                                 </li>
        //                                 <li>
        //                                     <a href="" class="btn btn-success text-center btn-block">PRO Account</a>
        //                                 </li>
        //                                 <li><br /></li>
        //                                 <li>
        //                                     <div class="btn-group-vertical btn-block">
        //                                         <a href="" class="btn btn-default"><i class="fa fa-cog pull-right"></i>Edit Account</a>
        //                                         <a href="" class="btn btn-default"><i class="fa fa-sign-out pull-right"></i>Logout</a>
        //                                     </div>
        //                                 </li>
        //                             </ul>
        //                         </div>
        //                     </div>
        //                 </div>

        //                 <div class="panel panel-theme rounded shadow">
        //                     <div class="panel-heading">
        //                         <div class="pull-left">
        //                             <h3 class="panel-title">Contact</h3>
        //                         </div>
        //                         <div class="pull-right">
        //                             <a href="#" class="btn btn-sm btn-success"><i class="fa fa-facebook"></i></a>
        //                             <a href="#" class="btn btn-sm btn-success"><i class="fa fa-twitter"></i></a>
        //                             <a href="#" class="btn btn-sm btn-success"><i class="fa fa-google-plus"></i></a>
        //                         </div>
        //                         <div class="clearfix"></div>
        //                     </div>
        //                     <div class="panel-body no-padding rounded">
        //                         <ul class="list-group no-margin">
        //                             <li class="list-group-item"><i class="fa fa-envelope mr-5"></i> support@bootdey.com</li>
        //                             <li class="list-group-item"><i class="fa fa-globe mr-5"></i> www.bootdey.com</li>
        //                             <li class="list-group-item"><i class="fa fa-phone mr-5"></i> +6281 903 xxx xxx</li>
        //                         </ul>
        //                     </div>
        //                 </div>

        //             </div>
        //             <div class="col-lg-9 col-md-9 col-sm-8">

        //                 <div class="profile-cover">
        //                     <div class="cover rounded shadow no-overflow">
        //                         <div class="inner-cover">
        //                             {/* <!-- Start offcanvas btn group menu: This menu will take position at the top of profile cover (mobile only). --> */}
        //                             <div class="btn-group cover-menu-mobile hidden-lg hidden-md">
        //                                 <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-toggle="dropdown">
        //                                     <i class="fa fa-bars"></i>
        //                                 </button>
        //                                 <ul class="dropdown-menu pull-right no-border" role="menu">
        //                                     <li class="active"><a href="#"><i class="fa fa-fw fa-clock-o"></i> <span>Timeline</span></a></li>
        //                                     <li><a href="#"><i class="fa fa-fw fa-user"></i> <span>About</span></a></li>
        //                                     <li><a href="#"><i class="fa fa-fw fa-photo"></i> <span>Photos</span> <small>(98)</small></a></li>
        //                                     <li><a href="#"><i class="fa fa-fw fa-users"></i><span> Friends </span><small>(23)</small></a></li>
        //                                     <li><a href="#"><i class="fa fa-fw fa-envelope"></i> <span>Messages</span> <small>(7 new)</small></a></li>
        //                                 </ul>
        //                             </div>
        //                             <img src="https://bootdey.com/img/Content/flores-amarillas-wallpaper.jpeg" class="img-responsive full-width" alt="cover" style={{ maxHeight: 200 }} />
        //                         </div>
        //                         <ul class="list-unstyled no-padding hidden-sm hidden-xs cover-menu">
        //                             <li class="active"><a href="#"><i class="fa fa-fw fa-clock-o"></i> <span>Timeline</span></a></li>
        //                             <li><a href="#"><i class="fa fa-fw fa-user"></i> <span>About</span></a></li>
        //                             <li><a href="#"><i class="fa fa-fw fa-photo"></i> <span>Photos</span> <small>(98)</small></a></li>
        //                             <li><a href="#"><i class="fa fa-fw fa-users"></i><span> Friends </span><small>(23)</small></a></li>

        //                         </ul>
        //                     </div>
        //                 </div>
        //                 <div class="divider"></div>
        //                 <div class="panel rounded shadow">
        //                     <form action="...">
        //                         <textarea class="form-control input-lg no-border" rows="2" placeholder="What are you doing?..."></textarea>
        //                     </form>
        //                     <div class="panel-footer">
        //                         <button class="btn btn-success pull-right mt-5">POST</button>
        //                         <ul class="nav nav-pills">
        //                             <li><a href="#"><i class="fa fa-user"></i></a></li>
        //                             <li><a href="#"><i class="fa fa-map-marker"></i></a></li>
        //                             <li><a href="#"><i class="fa fa-camera"></i></a></li>
        //                             <li><a href="#"><i class="fa fa-smile-o"></i></a></li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //                 <div class="row">
        //                     <div class="col-md-6">
        //                         <div class="panel panel-success rounded shadow">
        //                             <div class="panel-heading no-border">
        //                                 <div class="pull-left half">
        //                                     <div class="media">
        //                                         <div class="media-object pull-left">
        //                                             <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="..." class="img-circle img-post" />
        //                                         </div>
        //                                         <div class="media-body">
        //                                             <a href="#" class="media-heading block mb-0 h4 text-white">John Doe</a>
        //                                             <span class="text-white h6">on 8th June, 2014</span>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div class="pull-right">
        //                                     <a href="#" class="text-white h4"><i class="fa fa-heart"></i> 15.5K</a>
        //                                 </div>
        //                                 <div class="clearfix"></div>
        //                             </div>
        //                             <div class="panel-body no-padding">
        //                                 <img src="https://www.bootdey.com/image/340x210/" alt="..." class="img-responsive full-width" />
        //                                 <div class="inner-all block">
        //                                     view all <a href="#">7 comments</a>
        //                                 </div>
        //                                 <div class="line no-margin"></div>
        //                                 <div class="media inner-all no-margin">
        //                                     <div class="pull-left">
        //                                         <img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="..." class="img-post2" />
        //                                     </div>
        //                                     <div class="media-body">
        //                                         <a href="#" class="h4">John Doe</a>
        //                                         <small class="block text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. </small>
        //                                         <em class="text-xs text-muted">Posted on <span class="text-danger">Dec 08, 2014</span></em>
        //                                     </div>
        //                                 </div>
        //                                 <div class="line no-margin"></div>
        //                                 <div class="media inner-all no-margin">
        //                                     <div class="pull-left">
        //                                         <img src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="..." class="img-post2" />
        //                                     </div>
        //                                     <div class="media-body">
        //                                         <a href="#" class="h4">John Doe</a>
        //                                         <small class="block text-muted">Quaerat, impedit minus non commodi facere doloribus nemo ea voluptate nesciunt deleniti.</small>
        //                                         <em class="text-xs text-muted">Posted on <span class="text-danger">Dec 08, 2014</span></em>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div class="panel-footer">
        //                                 <form action="#" class="form-horizontal">
        //                                     <div class="form-group has-feedback no-margin">
        //                                         <input class="form-control" type="text" placeholder="Your comment here..." />
        //                                         <button type="submit" class="btn btn-theme fa fa-search form-control-feedback"></button>
        //                                     </div>
        //                                 </form>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div class="col-md-6">
        //                         <div class="panel panel-success rounded shadow">
        //                             <div class="panel-heading no-border">
        //                                 <div class="pull-left half">
        //                                     <div class="media">
        //                                         <div class="media-object pull-left">
        //                                             <img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="..." class="img-circle img-post" />
        //                                         </div>
        //                                         <div class="media-body">
        //                                             <a href="#" class="media-heading block mb-0 h4 text-white">John Doe</a>
        //                                             <span class="text-white h6">on 8th June, 2014</span>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div class="pull-right">
        //                                     <a href="#" class="text-white h4"><i class="fa fa-heart"></i> 15.5K</a>
        //                                 </div>
        //                                 <div class="clearfix"></div>
        //                             </div>
        //                             <div class="panel-body no-padding">
        //                                 <div class="inner-all block">
        //                                     <h4>Upload on my album :D</h4>
        //                                     <p>
        //                                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, iste omnis fugiat porro consequuntur ratione iure error reprehenderit cum est ab similique magnam molestias aperiam voluptatibus quia aliquid! Sed, minima.
        //                                     </p>
        //                                     <blockquote class="mb-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, delectus!</blockquote>

        //                                     <img data-no-retina="" src="https://www.bootdey.com/image/340x210/" alt="..." width="100" />
        //                                     <img data-no-retina="" src="https://www.bootdey.com/image/340x210/" alt="..." width="100" />
        //                                     <img data-no-retina="" src="https://www.bootdey.com/image/340x210/" alt="..." width="100" />
        //                                 </div>
        //                                 <div class="inner-all bg-success">
        //                                     view all <a href="#">7 comments</a>
        //                                 </div>
        //                             </div>
        //                             <div class="panel-footer no-padding no-border">
        //                                 <div class="media inner-all no-margin">
        //                                     <div class="pull-left">
        //                                         <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="..." class="img-post2" />
        //                                     </div>
        //                                     <div class="media-body">
        //                                         <a href="#" class="h4">John Doe</a>
        //                                         <small class="block text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. </small>
        //                                         <em class="text-xs text-muted">Posted on <span class="text-danger">Dec 08, 2014</span></em>
        //                                     </div>
        //                                 </div>
        //                                 <div class="line no-margin"></div>
        //                                 <div class="media inner-all no-margin">
        //                                     <div class="pull-left">
        //                                         <img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="..." class="img-post2" />
        //                                     </div>
        //                                     <div class="media-body">
        //                                         <a href="#" class="h4">John Doe</a>
        //                                         <small class="block text-muted">Quaerat, impedit minus non commodi facere doloribus nemo ea voluptate nesciunt deleniti.</small>
        //                                         <em class="text-xs text-muted">Posted on <span class="text-danger">Dec 08, 2014</span></em>
        //                                     </div>
        //                                 </div>
        //                                 <div class="line no-margin"></div>
        //                                 <form action="#" class="form-horizontal inner-all">
        //                                     <div class="form-group has-feedback no-margin">
        //                                         <input class="form-control" type="text" placeholder="Your comment here..." />
        //                                         <button type="submit" class="btn btn-theme fa fa-search form-control-feedback"></button>
        //                                     </div>
        //                                 </form>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default TestYourself
