// App.js

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import "./styles.css";
import { initialPosts } from "./initialPosts";
import PostDetails from "./Pages/PostDetails/PostDetails";
import NewPost from "./Pages/NewPost/NewPost";
import Homes from "./Pages/Home/Homes";
import {
  ThemeProvider,
  CssBaseline,
  createTheme,
  Backdrop,
  makeStyles,
  CircularProgress,
  Paper
} from "@material-ui/core";
import { Switch as DarkLightButtton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { BrowserRouter as Router, Redirect, Switch, Route, Link } from "react-router-dom";
import Mui from "./components/unwanted/Test";

import Login from "./components/Login";
import Signup from "./components/Signup";
import Tried from "./components/unwanted/DelLater";
import 'remixicon/fonts/remixicon.css'
// import Signupstep1 from "./components/unwanted/Signupstep1";
// import Signupstep2 from "./components/unwanted/Signupstep2";
// import Signupstep3 from "./components/unwanted/Signupstep3";
// import Signupstep4 from "./components/unwanted/Signupstep4";
// import Location from "./components/unwanted/Googleautocomp";
import UploadPhotos from "./components/UploadPhotos";
// import Toofanicoder from "./components/unwanted/Toofanicoder";
import SignupN from "./components/Signupnew";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  handleDarkMode,
  login,
  logout,
  selectUser
} from "./features/userSlice";
import { useEffect } from "react";
import { auth, db } from "./app/firebase";
import Header from "./components/Header";
import Profiles from "./components/Profiles";
// import Profile from "./components/unwanted/Profile";
import ProfileN from "./components/ProfileN";
import Requests from "./components/Requests";
import Shortlisted from "./components/Shortlisted";
import Shortlistedme from "./components/Shortlisteme";
import Recentlyviewed from "./components/Recentlyviewed";
import EditProfile from "./components/EditProfile/EditProfile";
import ProfileSettings from "./components/ProfileSettings";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import MobileBottomnav from "./components/MobileBottomnav";
import NavBar from "./components/navbar";
import FirstNav from "./components/FirstNav";
import UpperNav from "./components/UpperNav";
import Carousel from 'react-responsive-carousel';
import one from './assets/1.jpeg';
import two from './assets/2.jpeg';
import three from './assets/3.jpeg';
import four from './assets/4.jpeg';
import five from './assets/5.jpeg';
import six from './assets/6.jpeg';
import Routes from "./Route";
import ViewProfileDetails from "./components/ViewProfileDetails";
import Preferencepartner from "./components/EditProfile/Preferencepartner";
import TestYourself from "./components/TestYourself/TestYourself";
import MoreProfileDetails from "./components/MoreProfileDetails/MoreProfileDetails";
import PsychologicalTests from "./components/Tests/PsychologicalTests";
import Chronicdisorders from "./components/Tests/Chronicdisorders";
import InfertilityScreening from "./components/Tests/InfertilityScreening";
import BloodgroupingTests from "./components/Tests/BloodgroupingTests";
import SexuallytransmittedDiseases from "./components/Tests/SexuallytransmittedDiseases";
import ThalassemiaTests from "./components/Tests/ThalassemiaTests";
import QuestionAnswers from "./components/TestYourself/QuestionAnswers";
import Homepagepackage from "./components/homepagepackage/homepagepackage";
import Adminsignin from "./components/adminpanel/adminsignin";
import Adminsignup from "./components/adminpanel/adminsignup";
import PayUPaymentPage from "./components/PaymentGateway/PayUPaymentPage";
import PayUMoneyform from "./components/PaymentGateway/PayUMoneyform";
import MiniDrawer from "./components/adminmenu/MiniDrawer";
import Satisfactiontest from "./components/TestYourself/satisfactiontest";
import Personalitytest from "./components/TestYourself/personalitytest";
import Sixteenpersonalityfactor from "./components/TestYourself/sixteenpersonalityfactor";
import Fiftyquestions from "./components/TestYourself/fiftyquestions";
import Hundredquestiontest from "./components/TestYourself/hundredquestiontest";
import InnerPackage from "./components/InnerPackage/InnerPackage";
import FaqHeader from "./components/faq/faqheader";
import FAQ from "./components/faq/FAQ";
import FaqFile from "./components/faq/faqfile";
import DisplayAllPosts from "./components/blogpage/DisplayAllPosts";
import CreateNewPost from "./components/blogpage/CreateNewPost";
import ModifyPost from "./components/blogpage/ModifyPost";
import Post from "./components/blogpage/Post";
import Message from "./components/messaging/message";
import Aboutus from "./components/Aboutus";
import { Privacypolicy } from "./components/Privacypolicy";


import ChatBox from "./components/messaging/chatbox";
import HoroscopeTest from "./components/Tests/HoroscopeTest";
import Termsconditions from "./components/Termsconditions";
import Cookiepolicy from "./components/Cookiepolicy";
import Refundpolicy from "./components/Refundpolicy";
import Contactus from "./components/Contactus";
import Socialforum from "./components/Socialforum";
import Community from "./components/Community";
import Service from "./components/Service";
import Vision from "./components/Vision";
import Successstories from "./components/Successstories";
import Weddingevent from "./components/Weddingevent";
import Testimonials from "./components/Testimonials";
import Blogs from "./Blogs";
import Footer from "./components/Footer";

//import Termsconditions from "./components/Termsconditions.JS";
//import Termsconditions from "./components/Termsconditions.JS";
// import Homepageadmin from "./components/adminmenu/homepageadmin";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function App() {

  const [posts, setPosts] = useState(initialPosts);
  const history = useHistory();

  function onDelete(deleteId) {
    let searchBar = document.getElementById("searchInput");
    if (searchBar.innerText.trim() !== "") {
      let text = searchBar.innerText;
      searchBar.innerText = text;
    }

    for (let i = initialPosts.length - 1; i >= 0; --i) {
      if (initialPosts[i].id === deleteId) {
        initialPosts.splice(i, 1);
        break;
      }
    }
    history.push("/Homes");
  }


  const HomePage = () => (
    <div>
      {/* Your /home page content */}
      <h1>Welcome to the /home page!</h1>
    </div>
  );
  const [darkMode, setDarkMode] = useState("dark");
  // const darkmodeselector = useSelector(darkmode);
  const darkmodeselector = useSelector(darkmode);
  const onClickItem = () => {

  }
  const onChange = () => {

  }
  const onClickThumb = () => {

  }
  console.log(darkmodeselector);
  const theme = createTheme({
    palette: {
      // primary: { light: "blue[300]", main: "blue[500]", dark: "blue[700]" },

      //secondary: "green",
      type: darkmodeselector,
      // primary: {
      //     light: "#80cbc4",
      //     main: "#009688",
      //     dark: "#004d40",
      //     contrastText: "#fff",
      // },
      // primary: {
      //     light: "#7cbdc9",
      //     main: "#40817a",
      //     dark: "#003b32",
      //     contrastText: "#fff",
      // },
      // ,
      primary: {
        light: "#ff6e40",
        main: "#1877F2",
        dark: "#0091ea",
        contrastText: "#fff"

      },
      secondary: {
        light: "#ff6e40",
        main: "#1877F2",
        dark: "#0091ea",
        contrastText: "#fff"
      },
      text: {}
    }
  });

  // const handleDarkMode = () => {
  //     setDarkMode(!darkMode);
  // };
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    // auth.onAuthStateChanged((userAuth) => {
    //   setOpen(true);
    //   if (userAuth) {

    //     console.log(userAuth.email);
    //     var docRef = db.collection("users").doc(userAuth.email);
    //     let userData;
    //     docRef
    //       .get()
    //       .then((doc) => {
    //         console.log(open);
    //         if (doc.exists) {
    //           userData = doc.data();
    //           console.log(userData);
    //         } else {
    //           console.log("else");
    //         }
    //       })
    //       .then(() => {
    //         dispatch(
    //           login({
    //             email: userAuth.email,
    //             uid: userAuth.uid,
    //             displayName: userAuth.displayName,
    //             profileUrl: userAuth.photoURL,
    //             userData: userData
    //           })
    //         );
    //         setOpen(false);
    //       });
    //   } else {
    //     dispatch(logout());
    //     setOpen(false);
    //   }
    // });
  }, []);
  return (
    <div>
      <Routes />
      <Router>
        <ThemeProvider
          theme={theme}
        >
          <CssBaseline>
            <div>
              <FirstNav />
              <UpperNav />
              <div>
                <NavBar />
              </div>

            </div>

            {/* <Header /> */}


            <div className="matrimony__container">
              {/* <h1>Matrimony</h1>
                        <DarkLightButtton
                            className="mat__darklighttoggle"
                            onChange={handleDarkMode}
                            value={darkMode}
                        /> */}
              {/* <Carousel autoPlay={true}> */}
              {/* <div>
                    <img src={one} />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={two} />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src={three} />
                    <p className="legend">Legend 3</p>
                </div>
                <div>
                  <img src={four} />
                    <p className="legend">Legend 4</p>
                </div>
                <div>
                    <img src={five} />
                    <p className="legend">Legend 5</p>
                </div>
                <div>
                    <img src={six} />
                    <p className="legend">Legend 6</p>
                </div> */}
              {/* </Carousel> */}

              {!user ? (
                <>
                  {open ? (
                    <>
                      {/* {alert("inside if")} */}
                      <Backdrop className={classes.backdrop} open={open}>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </>
                  ) : (
                    <>
                      {/* {setOpen(false)} */}
                      <Switch>
                        <Route path="/signup">
                          <SignupN />
                        </Route>
                        <Route path="/ForgotPassword">
                          <ForgotPassword />
                        </Route>
                        <Route path="/">
                          <Login />
                        </Route>
                      </Switch>
                      {/* <Login /> */}
                    </>
                  )}
                </>
              ) : (
                <Switch>
                  {/* <Route path="/signup">
                                <Signup />
                            </Route>
                            <Route path="/date">
                                <Mui />
                            </Route>
                            <Route path="/step1">
                                <Signupstep1 />
                            </Route>
                            <Route path="/step2">
                                <Signupstep2 />
                            </Route>
                            <Route path="/step3">
                                <Signupstep3 />
                            </Route>
                            <Route path="/step4">
                                <Signupstep4 />
                            </Route> */}
                  <Route path="/Updateprofile">
                    <UploadPhotos />
                  </Route>
                  <Route path="/ChangePassword">
                    <ChangePassword />
                  </Route>
                  <Route path="/signup">
                    <SignupN />
                  </Route>
                  {/* <Route path="/Location">
                                <Location />
                            </Route>
                            <Route path="/Toofani">
                                <Toofanicoder />
                            </Route> */}
                  {/* <Route path="/SignupN">
                                    <SignupN />
                                </Route> */}
                  <Route path="/Login">
                    <Login />
                  </Route>
                  <Route path="/ForgotPassword">
                    <ForgotPassword />
                  </Route>
                  {/* <Route path="/ViewProfileDetails">
                  <ViewProfileDetails />
                </Route> */}
                  {/* <Route path="/ViewProfileDetails" component={ViewProfileDetails} /> */}
                  {/* <PrivateRoute path="/">
                  <ViewProfileDetails />
                </PrivateRoute> */}
                  {/* <Route path="/ProfileN/:id">
                    <ProfileN />
                  </Route> */}
                  <Route path="/ProfileN">
                    <ProfileN />
                  </Route>
                  <Route path="/Profile">
                    <ProfileN />
                  </Route>
                  <Route path="/Requests">
                    <Requests />
                  </Route>
                  <Route path="/Shortlisted">
                    <Shortlisted />
                  </Route>
                  <Route path="/Shortlistedme">
                    <Shortlistedme />
                  </Route>
                  <Route path="/Recentlyviewed">
                    <Recentlyviewed />
                  </Route>
                  <Route path="/EditProfile">
                    <EditProfile />
                  </Route>
                  <Route path="/ProfileSettings">
                    <ProfileSettings />
                  </Route>
                  <Route path="/ProfileN">
                    <Profiles />
                  </Route>

                  <Route path="/Homepagepackage">
                    <Homepagepackage />
                  </Route>

                  <Route path="/InnerPackage">
                    <InnerPackage />
                  </Route>

                  <Route path="/PartPref">
                    <Preferencepartner />
                  </Route>
                  <Route path="/testyourself">
                    <TestYourself />
                  </Route>
                  <Route path="/moreprofiledetails">
                    <MoreProfileDetails />
                  </Route>


                  <Route path="/psychologicaltests">
                    <PsychologicalTests />
                  </Route>
                  <Route path="/Chronicdisorders">
                    <Chronicdisorders />
                  </Route>
                  <Route path="/InfertilityScreening">
                    <InfertilityScreening />
                  </Route>
                  <Route path="/BloodgroupingTests">
                    <BloodgroupingTests />
                  </Route>
                  <Route path="/SexuallytransmittedDiseases">
                    <SexuallytransmittedDiseases />
                  </Route>
                  <Route path="/ThalassemiaTests">
                    <ThalassemiaTests />
                  </Route>
                  <Route path="/HoroscopeTest">
                    <HoroscopeTest />
                  </Route>


                  <Route path="/QuestionAnswers">
                    <QuestionAnswers />
                  </Route>


                  <Route path="/Satisfactiontest">
                    <Satisfactiontest />
                  </Route>

                  <Route path="/Personalitytest">
                    <Personalitytest />
                  </Route>

                  <Route path="/Sixteenpersonalityfactor">
                    <Sixteenpersonalityfactor />
                  </Route>

                  <Route path="/Fiftyquestions">
                    <Fiftyquestions />
                  </Route>

                  <Route path="/Hundredquestiontest">
                    <Hundredquestiontest />
                  </Route>








                  <Route path="/PayUMoneyform">
                    <PayUMoneyform />
                  </Route>
                  <Route path="/PayUPaymentPage">
                    <PayUPaymentPage />
                  </Route>

                  {/* admin routes */}

                  <Route path="/Adminsignin">
                    <Adminsignin />
                  </Route>

                  <Route path="/Adminsignup">
                    <Adminsignup />
                  </Route>

                  <Route path="/MiniDrawer">
                    <MiniDrawer />
                  </Route>

                  {/* <Route path="/MiniDrawer/Homepageadmin">
                    <Homepageadmin />
                  </Route> */}

                  {/* admin routes */}
                  {/* Redirect from root to /home */}
                  <Route exact path="/" render={() => <Redirect to="/login" />} />
                  <Route path="/Adminsignup">
                    <Adminsignup />
                  </Route>
                  <Route path="/login">
                    <HomePage />
                  </Route>



                  <Route path="/FaqHeader">
                    <FaqHeader />
                  </Route>


                  <Route path="/FAQ">
                    <FAQ />
                  </Route>

                  <Route path="/Vision">
                    <Vision />
                  </Route>

                  <Route path="/Blogs">
                    <Blogs />
                  </Route>
                  <Route
                    exact
                    path="/Homes"
                    render={(props) => (
                      <Homes posts={posts} setPosts={setPosts} handleDelete={onDelete} />
                    )}
                  />
                  <Route path="/post/:id" component={PostDetails} />
                  <Route path="/newpost" component={NewPost} />



                  <Route path="/Successstories">
                    <Successstories />
                  </Route>

                  <Route path="/Weddingevent">
                    <Weddingevent />
                  </Route>

                  <Route path="/Testimonials">
                    <Testimonials />
                  </Route>

                  <Route path="/FaqFile">
                    <FaqFile />
                  </Route>

                  <Route path="/Aboutus">
                    <Aboutus />
                  </Route>

                  <Route path="/Privacypolicy">
                    <Privacypolicy />
                  </Route>

                  <Route path="/Termsconditions">
                    < Termsconditions />
                  </Route>

                  <Route path="/Cookiepolicy">
                    < Cookiepolicy />
                  </Route>

                  <Route path="/Refundpolicy">
                    < Refundpolicy />
                  </Route>

                  <Route path="/Service">
                    <Service />
                  </Route>

                  <Route path="/Contactus">
                    < Contactus />
                  </Route>

                  <Route path="/Socialforum">
                    < Socialforum />
                  </Route>

                  <Route path="/Community">
                    <Community />
                  </Route>

                  <Route path="/DisplayAllPosts">
                    <DisplayAllPosts />
                  </Route>

                  <Route path="/CreateNewPost">
                    <CreateNewPost />
                  </Route>

                  <Route path="/ModifyPost">
                    <ModifyPost />
                  </Route>

                  <Route path="/Post">
                    <Post />
                  </Route>

                  <Route path="/Message">
                    <Message />
                  </Route>

                  <Route path="/ChatBox">
                    <ChatBox />
                  </Route>

                  {/* <PrivateRoute path="/">
                    <Profiles />
                  </PrivateRoute> */}
                </Switch>
              )}
            </div>

            <Footer />
            <MobileBottomnav />
          </CssBaseline>
        </ThemeProvider>
      </Router>
    </div>
  );
}
export default App;