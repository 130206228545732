import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import payUImage from '../image/PayU.svg.png';
import PayUPaymentPage from './PayUPaymentPage';
import { useLocation } from 'react-router-dom';
import * as environment from '../../coreservices/environment'
import * as crypto from 'crypto';
const PayUMoneyform = () => {

    const location = useLocation();

    console.log('====================================');
    console.log("location", location);
    console.log("location?.state?.state?.mobile", location?.state?.state?.mobile);

    console.log("location?.state?.state?.fromPage", location?.state?.state?.fromPage);

    console.log("location?.state?.state?.PackageData", location?.state?.state?.PackageData);

    console.log('====================================');

    const [test_of, set_test_of] = useState(location?.state?.state?.test_of_title ? location?.state?.state?.test_of_title : "")
    const [mob_no, setmob_no] = useState(location?.state?.state?.mobile)
    const [fromPage, setfromPage] = useState(location?.state?.state?.fromPage)

    const [PackageData, setPackageData] = useState(location?.state?.state?.PackageData)

    const [nuserId, setnuserId] = useState("")

    useEffect(() => {
        if (localStorage.getItem('userId') != "") {
            console.log(localStorage.getItem('userId'));
            let userId = localStorage.getItem('userId');
            setnuserId(userId);
        }
    }, [])

    const [form, setForm] = useState({
        name: location?.state?.state?.name ? location?.state?.state?.name : "",
        email: location?.state?.state?.email ? location?.state?.state?.email : "",
        amount: location?.state?.state?.amount ? location?.state?.state?.amount : 0
    });


    // useEffect(() => { 
    //    if(location?.state?.state?.test_of_title){
    //     set_test_of(location?.state?.state?.test_of_title)
    //    } 
    // }, [])


    const [toggle, setToggle] = useState(1);
    const [hash, setHash] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [ptrue, setptrue] = useState(false);

    const handleChange = (e) => {
        if (e.target.name === 'amount') {
            setForm({ ...form, [e.target.name]: parseFloat(e.target.value) })
        } else {
            setForm({ ...form, [e.target.name]: e.target.value })
        }
    }


    useEffect(() => {
        generateTransactionID()
    }, [])

    function generateTransactionID() {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000000);
        const merchantPrefix = 'T';
        const transactionID = `${merchantPrefix}${timestamp}${randomNum}`;
        setTransactionId(transactionID);
        return transactionID
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        getHash();
        setToggle(2);
    }
    const handleprint = (e) => {
        setptrue(true)
    }
    const getHash = async () => {
        console.log('====================================');
        console.log("form", form);
        console.log('====================================', transactionId);



        const data = {
            key: "JkHCpE",
            salt: "H70fsg9b5PMttHgTV5SpM3VgWXv2elBI",
            txnid: transactionId,
            amount: form?.amount,
            productinfo: PackageData.packageName,
            firstname: form?.name,
            email: form?.email,
            udf1: PackageData.packagePrice,
            udf2: PackageData.packageMobileAvailable,
            udf3: PackageData.packageTYSAvailable,
            udf4: PackageData.packageValidity,
            udf5: nuserId
        };

        const cryp = crypto.createHash('sha512');
        const string = data.key + '|' + data.txnid + '|' + data.amount + '|' + data.productinfo + '|' + data.firstname + '|' + data.email + '|' + data.udf1 + '|' + data.udf2 + '|' + data.udf3 + '|' + data.udf4 + '|' + data.udf5 + '||||||' + data.salt
        // const string = `${data.key}|${data.txnid}|${data.amount}|${data.productinfo}|${data.firstname}|${data.email}|${data.udf1}|${data.udf2}|${data.udf3}|${data.udf4}|${data.udf5}||||||${data.salt}`;
        cryp.update(string);
        const hash = cryp.digest('hex');

        console.log("transactionId____", transactionId);
        console.log('hash____', hash);
        setHash(hash)
        setTransactionId(transactionId)





        // axios.post('http://localhost:5001/api/payu/hash', { ...form, transactionId: transactionId })
        // await axios.post(`${environment.apiUrl}/Paymentgateway/hash`, { ...form, transactionId: transactionId })
        //     .then(res => {
        //         console.log('====================================', res?.data?.transactionId);
        //         console.log("res_api", res);
        //         console.log('====================================', res?.data?.hash);
        //         setHash(res?.data?.hash)
        //         setTransactionId(res?.data?.transactionId)
        //     })
        //     .catch(error => {
        //         console.error(error);
        //         // window.location.href = res.data
        //     })

        // try {
        //     const response = await fetch(`${environment.apiUrl}/Paymentgateway/hash`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ ...form, transactionId: transactionId })
        //     });

        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }

        //     const res = await response.json();

        //     console.log('====================================', res?.transactionId);
        //     console.log("res_api", res);
        //     console.log('====================================', res?.hash);

        //     setHash(res?.hash);
        //     setTransactionId(res?.transactionId);
        // } catch (error) {
        //     console.error(error);
        //     // window.location.href = res.data
        // }
    }





    return (
        <>
            {toggle === 1 && <div className='main-2 container gap-5'>
                <div className='h-100 d-flex flex-column py-5 justify-content-between'>
                    <img width={350} src="https://files.readme.io/fa8a25c-white-logo.png" alt="" />
                    <h1 className='mt-4 fw-bold card'>Payment Gateway Integration</h1>
                </div>
                <button className="" onClick={() => handleprint()}>print</button>
                <form onSubmit={handleSubmit} className='h-100 d-flex flex-column justify-content-center form'>

                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input value={form?.name} required type="text" name='name' className="form-control" onChange={handleChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input value={form?.email} required type="text" name='email' className="form-control" onChange={handleChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Number</label>
                        <input value={mob_no ? mob_no : ""} required type="text" name='number' className="form-control" onChange={handleChange} />
                    </div>

                    <div class="mb-3">
                        <label className="form-label">Amount</label>
                        <input value={form?.amount} required type="number" name='amount' className="form-control" onChange={handleChange} />
                    </div>

                    <div class="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label">Check me out</label>
                    </div>
                    {ptrue == true &&
                        <div>
                            <p>{hash}</p>
                            <p>{transactionId}</p>
                        </div>
                    }


                    <button type="submit" className="">Check Details</button>
                </form>
            </div>}
            {
                toggle === 2 && <PayUPaymentPage setToggle={setToggle} form={form} hash={hash} transactionId={transactionId} test_of={test_of} fromPage={fromPage} mob_no={mob_no} nuserId={nuserId} PackageData={PackageData} />
            }
        </>
    )

}

export default PayUMoneyform
