import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const Contactus = () => {

  const classes = useStyles();

  return (
    <div className={classes.main_d}>
      <div className={classes.in_d}>
        <h1>Main Office</h1>
        <h4>Address:</h4>
        <p>Hauz Khas Fort Rd, Hauz Khas, New Delhi, Delhi 110016</p>
        <h4>Email:</h4>
        <p>info@indianmatchmaking.co.in</p>
        <h4>WhatsApp:</h4>
        <p>+91 8280946968</p>
      </div>

    </div>
  )
}
const useStyles = makeStyles((theme) => ({
  main_d: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    background: 'thistle',
  },
  in_d: {
    padding: '20px',
    background: 'white',
    borderRadius: '50px',
  }
}));
export default Contactus