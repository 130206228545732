import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button } from '@material-ui/core';
import { auth } from "../app/firebase";
import { useHistory } from "react-router-dom";
import { logout } from "../features/userSlice";
import { useDispatch } from "react-redux";

const SocialLinks = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [istoken, setisToken] = useState(false);
    console.log(localStorage.getItem('authToken'));

    useEffect(() => {
        if (localStorage.getItem('authToken') != null) {
            setisToken(true);
        } else {
            setisToken(false);
        }
    }, [localStorage.getItem('authToken')])

    const handlelogout = () => {
        // auth.signOut().then(() => {
        localStorage.clear();
        history.push("/login");
        window.location.reload();
        //   dispatch(logout());
        // });
    };
    return (
        <div>
            {/* Facebook */}
            <IconButton style={{ cursor: 'pointer' }} href="https://www.facebook.com/your-page" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
            </IconButton>

            {/* Instagram */}
            <IconButton href="https://www.instagram.com/your-profile" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
            </IconButton>
            {/* Twitter */}
            <IconButton href="https://www.twitter.com/your-profile" target="_blank" rel="noopener noreferrer">
                <TwitterIcon />
            </IconButton>

            {/* Gmail */}
            <IconButton href="mailto:your-email@gmail.com">
                <MailOutlineIcon />
            </IconButton>
            {istoken === true ?
                <Button onClick={handlelogout}>Logout</Button>
                :
                null
            }

        </div>
    );
};

export default SocialLinks;
