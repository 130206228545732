import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { db } from "../app/firebase";
import "./Profiles";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { fetchAsyncProfiles } from "../features/profilesSlice";
import { getAllProfiles } from "../features/profilesSlice.js";
import globalUseStyles from "./globalstyles";
import { shortlistNew, removeshortlist } from "../utils/Shortlist";
import { RequestPhoto } from "../utils/RequestPhoto";

import { useLocation } from 'react-router-dom';
import * as environment from '../coreservices/environment'


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  education: {
    wordBreak: "break-word",
    display: "flex"
  },
  infoiconedu: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    marginLeft: "3px"
  },
  avatar: {
    backgroundColor: red[500]
  },
  viewButton: {
    marginTop: "15px"
  },
  cardfooter: {

    marginTop: "auto",
    paddingTop: "0",
    padding: "0 16px 8px",
    "& button": {
      margin: "0 5px",
      flexGrow: "1",
      flexBasis: "0"
    }
  },
  mat__dummyprofilepic: {
    paddingBottom: "56.2%",
    position: "relative"
  },
  mat__dummyprofilepicholder: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  mat__dummyprofileimg: {
    width: "100%",
    height: "100%",
    opacity: "0.3"
  },
  mat__requestpic: {
    position: "absolute",
    top: "50%",
    left: "50%",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    transform: "translate(-50%,-50%)"

  },
  sendshortbuttons: {
    "& .MuiButton-label": {
      flexDirection: "column"
    },
    fontSize: "0.75rem"
  }
}));

export default function Profiles() {


  const location = useLocation();

  console.log('====================================');
  console.log("location", location);
  console.log('====================================');
  // const emailID = location.state.state.email

  const emailID = "sonu@gmail.com"
  console.log('====================================');
  console.log("emailID", emailID);
  console.log('====================================');

  useEffect(() => {
    if (emailID != '') {
      getUserByEmail()
    }

  }, [emailID])

  const getUserByEmail = async () => {

    try {
      const response = await fetch(`${environment.apiUrl}/signupprofiles/email/${emailID}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetchedData = await response.json();
      console.log('====================================');
      console.log("fetchedData", fetchedData);
      console.log('====================================');

      // setnuserId(fetchedData.userId)
      // setloggedUsersData(fetchedData);
      // setpic1(doc.data()?.pic1 ? doc.data()?.pic1 : "");
      // setpic2(doc.data()?.pic2 ? doc.data()?.pic2 : "");
      // setprofilePic(doc.data()?.profilepic);



      console.log("btoadata", btoa(emailID));
      console.log("emailID", emailID);



      // window.location.href = `http://localhost:3000/ProfileN/${btoa(emailID)}?emailid=${emailID}`;


      history.push(`/ProfileN/${btoa(emailID)}`, { email: emailID });



      // history.push(`/ProfileN/${btoa(emailID)}`, { state: { email: emailID } });
      // gotoProfile()
    } catch (error) {
      console.error(error.message);
    }

  }


  // const [ProfileDetailsObject, setProfileDetailsObject] = useState({})
  const profilesThunk = useSelector(getAllProfiles);
  // console.log("start");
  // console.log("btoa", profilesThunk);
  // console.log("btoadata", profilesThunk[0]);
  // console.log("end");


  const history = useHistory();
  const gotoProfile = () => {
    if (profilesThunk.length != 0) {

      console.log("btoadata", btoa(emailID));
      console.log("emailID", emailID);

      history.push(`/ProfileN/${btoa(emailID)}`, { state: { email: emailID } });
    }
  };

  // useEffect(() => {
  //   if (location) {
  //     console.log('====================================');
  //     console.log("locationhh", location);
  //     console.log('====================================');
  //     setTimeout(() => {

  //       setLoaded(true);
  //       history.push("/ProfileN", { params: { profilesThunk }, email: emailID });
  //     }, 2000);
  //   }

  // }, []);





  // useEffect(() => {
  //   // if (profilesThunk.length != 0) {
  //   //   console.log("btoadata", btoa(profilesThunk[0].data.email));
  //   //   setProfileDetailsObject(profilesThunk[0])
  //   //   window.location.href = `http://localhost:3000/ProfileN/${btoa(profilesThunk[0].data.email)}`;
  //   // }

  // }, [profilesThunk])



  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (location) {
  //     console.log('====================================');
  //     console.log("locationhh", location);
  //     console.log('====================================');
  //     setTimeout(() => {

  //       setLoaded(true);
  //       history.push("/ProfileN", { params: { profilesThunk }, email: emailID });
  //     }, 2000);
  //   }

  // }, []);


  const globalClasses = globalUseStyles();

  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const userDetails = useSelector(selectUser);

  const [user, setUser] = useState(userDetails);
  const [profiles, setProfiles] = useState([]);
  const [loggedinprofile, setloggedinprofile] = useState([]);

  useEffect(() => {
    setUser(userDetails);
    setloggedinprofile(userDetails);
  }, [userDetails, user]);

  console.log(
    "loggedinprofilee2" +
    "length" +
    Object.keys(loggedinprofile).length +
    JSON.stringify(loggedinprofile)
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getAge = (dateString) => {

    var today = new Date();

    var birthDate;
    dateString.seconds
      ? (birthDate = new Date(
        dateString.seconds * 1000 + dateString.nanoseconds / 1000000
      ))
      : (birthDate = new Date(dateString));
    birthDate = new Date(
      dateString.seconds * 1000 + dateString.nanoseconds / 1000000
    );

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  let genderSearch = "";
  genderSearch = user?.userData?.gender === "male" ? "female" : "male";

  const dispatch = useDispatch();
  useEffect(() => {

    if (Object.keys(loggedinprofile).length > 1) {
      console.log("dispatch(fetchAsyncProfiles(genderSearch))", dispatch(fetchAsyncProfiles(genderSearch)))
      dispatch(fetchAsyncProfiles(genderSearch))
    }
    else {
      console.log("dispatch(fetchAsyncProfiles(genderSearch))", dispatch(fetchAsyncProfiles(genderSearch)))
    }
  }, [dispatch, genderSearch, loggedinprofile]);




  const userlogged = firebase.auth().currentUser;
  const loggedinuserEmail = userlogged?.email;
  var userRef = db.collection("users").doc(loggedinuserEmail);
  const [openSnackbar, setopenSnackbar] = useState(false);
  const [snackbarmsg, setsnackbarmsg] = useState();
  const [snackbartype, setsnackbartype] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSnackbar(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  useEffect(() => {
    const usersRef = db
      .collection("users")
      .doc(loggedinuserEmail?.toLowerCase());
    usersRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        usersRef.onSnapshot((doc) => {
          setloggedinprofile(doc.data());
        });
      } else {

      }
    });
  }, [loggedinuserEmail]);



  const sendInterest = (intersetedProfile) => {
    console.log("interesstttttttt strttttt");
    console.log(intersetedProfile);
    console.log("interesstttttttt enddddddd");

    userRef.set(
      {
        interestssent: firebase.firestore.FieldValue.arrayUnion(
          intersetedProfile.data.email
        )
      },
      { merge: true }
    );


    db.collection("users")
      .doc(loggedinuserEmail)
      .collection("interestssent")
      .doc(intersetedProfile?.data.email)
      .set({
        email: intersetedProfile?.data?.email,
        name: intersetedProfile?.data?.name,
        education: intersetedProfile?.data?.education,
        star: intersetedProfile?.data?.birthstar,
        raashi: intersetedProfile?.data?.raashi,
        gothra: intersetedProfile?.data?.gothra,
        dob: intersetedProfile?.data?.birthdate,
        dp: intersetedProfile?.data?.profilepic,
        accepted: false,
        read: false
      });

    db.collection("users")
      .doc(intersetedProfile?.data?.email)
      .set(
        {
          interestsreceived: firebase.firestore.FieldValue.arrayUnion(
            loggedinuserEmail
          )
        },
        { merge: true }
      );

    db.collection("users")
      .doc(intersetedProfile?.data?.email)
      .collection("interestsreceived")
      .doc(loggedinuserEmail)
      .set({
        email: loggedinprofile.email,
        name: loggedinprofile.name,
        education: loggedinprofile.education,
        star: loggedinprofile.birthstar,
        raashi: loggedinprofile.raashi,
        gothra: loggedinprofile.gothra,
        dob: loggedinprofile.birthdate,
        dp: loggedinprofile.profilepic,
        accepted: false,
        read: false
      })
      .then(() => {
        setsnackbarmsg(
          `Successfully sent interest to ${intersetedProfile?.data?.name}`
        );
        setsnackbartype("success");
        setopenSnackbar(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const [interestSent, setinterestSent] = useState();
  const [interestReceived, setinterestReceived] = useState();


  async function nshortlist([shortlist]) {
    let shortlistReturn = await shortlistNew(
      loggedinuserEmail,
      shortlist.data.email,
      shortlist.data,
      loggedinuserEmail,
      loggedinprofile
    );
    const tried = await shortlistReturn;
    if (tried === "Success") {
      setsnackbarmsg("Successfully added to shortlist");
      setsnackbartype("success");
      setopenSnackbar(true);
    }
  }

  async function removeShortlistt(email, loggedinuserEmail) {
    let removeShortlistreturn = await removeshortlist(email, loggedinuserEmail);
    const removedPromise = await removeShortlistreturn;
    if (removedPromise === "Success") {
      setsnackbarmsg(" Removed from shortlist");
      setsnackbartype("error");
      setopenSnackbar(true);
    }
  }











  const requestPhoto = (intersetedProfile) => {
    RequestPhoto(
      intersetedProfile,
      loggedinprofile,
      loggedinuserEmail,
      setsnackbarmsg,
      setsnackbartype,
      setopenSnackbar
    );

  };
  return (
    <React.Fragment>

    </React.Fragment>

  );
}
