import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import SocialLinks from './SocialLinks';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const FirstNav = () => {
    // const history = useHistory();
    const classes = useStyles();

    const style1 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    // function submitForm() {
    //     // Get form data
    //     const form = document.getElementById('matrimonyForm');
    //     const formData = new FormData(form);

    //     // Display user responses (you can send this data to a server instead)
    //     formData.forEach((value, key) => {
    //         console.log(`${key}: ${value}`);
    //     });
    // }
    // const socialLinksStyle = {
    //     display: 'flex',
    //     justifyContent: 'space-around',
    //     marginTop: '20px',
    // };

    // const linkStyle = {
    //     color: '#333',
    //     fontSize: '24px',
    //     textDecoration: 'none',
    // };

    // const hoverStyle = {
    //     color: '#007bff',
    // };

    return (
        <div className={classes.tophead}>
            <SocialLinks />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({


    tophead: {
        padding: '4px',
        backgroundColor: '#a36178',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        curser: 'pointer',
        display: 'flex',
        gap: '23rem',
        backgroundColor:'#861f41',

    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        // "&:hover": {
        //     backgroundColor: alpha(theme.palette.common.white, 0.25),
        // },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    login: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    moreouter: {
        display: "flex"
    },
    more: {
        marginLeft: "12px",
        paddingRight: "12px",
        color: "#fff"
    },
    sublinks: {
        textDecoration: "none",
        color: "inherit"
    },
    sublinksProfile: {
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        "& svg": {
            marginRight: "5px"
        }
    },
    headericons: {
        width: "63px"
    },


}));

export default FirstNav
