import Swal from 'sweetalert2';

export const showSuccessNotification = (title, timer = 1500) => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: title,
    showConfirmButton: false,
    timer: timer
  });
};
