import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, storage } from "../../app/firebase";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";



import {
    makeStyles,
    IconButton,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import * as environment from '../../coreservices/environment'

const useStyles = makeStyles((theme) => ({
    updatebutton: {
        marginTop: "20px",
        display: "flex",
        marginLeft: "auto",
    },
}));


const HoroscopeTest = (props) => {


    useEffect(() => {
        getTestImages()
    }, [localStorage.getItem('userId')])

    const location = useLocation();

    const loggedUsersData_psy = location?.state?.state?.loggedUsersData
    const loggedinUser_psy = location?.state?.state?.loggedinUser


    console.log('====================================');
    console.log("loggedUsersData_psy", loggedUsersData_psy);
    console.log("loggedinUser_psy", loggedinUser_psy);
    console.log('====================================');


    const handleSubmit = async () => {

        let data = {
            userId: localStorage.getItem('userId'),
            path: horoscopepath
        }


        try {
            const response = await fetch(`${environment.apiUrl}/horoscopedetail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            console.log("responseData", responseData);
            alert('Record Submitted Successfully.')
            getTestImages()
        } catch (error) {
            console.error('Error during POST request:', error);
        }

    };


    const [isLength, setisLength] = useState(false)
    const getTestImages = async () => {
        try {
            const response = await fetch(`${environment.apiUrl}/horoscopedetail/${localStorage.getItem('userId')}`); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const fetched_img_Data = await response.json();
            if (fetched_img_Data) {
                setHoroscopepath(fetched_img_Data?.path);
                setisLength(true)
            }
            console.log('=============fetched_img_Data=======================', fetched_img_Data);
        } catch (error) {
            console.error(error.message);
        }
    }













    const userDetails = useSelector(selectUser);
    console.log(userDetails);
    const [user, setUser] = useState(userDetails);

    const classes = useStyles();
    const filepickerRef = useRef(null);

    const [horoscopepath, setHoroscopepath] = useState("");
    const addImage = (e, imageType) => {
        const reader = new FileReader();
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
        reader.onload = (readerEvent) => {
            // console.log(readerEvent.target.result);
            if (readerEvent.target.result) {
                const uploadTaskPic = storage
                    .ref(`profiepics/${user.userData.email + imageType}`)
                    .putString(readerEvent.target.result, "data_url");
                // removeImage1();
                uploadTaskPic.on(
                    "state_change",
                    null,
                    (error) => console.log(error),

                    () => {
                        // var progress =
                        //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log("Upload is " + progress + "% done");
                        storage
                            .ref("profiepics")
                            .child(user.userData.email + "horoscope")
                            .getDownloadURL()
                            .then((url) => {
                                // firebase.auth().currentUser.updateProfile({
                                //     photoURL: url,
                                // });
                                setHoroscopepath(url);
                                console.log(url);
                                db.collection("users")
                                    .doc(user.userData.email)
                                    .set(
                                        {
                                            horoscopeurl: url,
                                        },
                                        { merge: true }
                                    );
                            });
                    }
                );
            }
        };
    };

    return (
        <div className="cen-div">

            <div className="center-div">
                <div className="centered-div-1">

                    <div>
                        <h2 style={{ textAlign: 'center' }}>Horoscope Test</h2>

                        {isLength == false ?
                            <div class="mb-3">

                                <div
                                    className={classes.mat__profilepicupload}
                                    onClick={() => filepickerRef.current.click()}
                                >
                                    <IconButton
                                        color="primary"
                                        title="Upload Horoscope"
                                        className={classes.mat__horoscopeupload}
                                    >
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => addImage(e, "horoscope")}
                                            ref={filepickerRef}
                                            className={classes.mat__horoscopeupload}
                                        />
                                        <PhotoCamera />
                                        Upload Horoscope Test File
                                    </IconButton>
                                </div>
                                <div>{horoscopepath}</div>
                                {/* <div>Bloodgroup Test File</div> */}

                                {horoscopepath != "" ?
                                    <div>
                                        <a href={horoscopepath} target="_blank"
                                            rel="noopener noreferrer" download="image.jpg">Download Horoscope Test File</a>
                                    </div>
                                    :
                                    null
                                }

                                {/* <label for="formFileReadonly" class="form-label">Readonly file input example</label> */}
                                {/* <input class="form-control" type="file" id="formFileReadonly" readonly onChange={handleFileChange} /> */}
                            </div>
                            :
                            <div className="mb-3">
                                {horoscopepath != "" ?
                                    <div>
                                        <a href={horoscopepath} target="_blank"
                                            rel="noopener noreferrer" download="image.jpg">Download Horoscope Test File</a>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }


                        {isLength == false &&
                            (
                                <div>
                                    <button onClick={handleSubmit} type="button" class="btn btn-primary">Submit</button>
                                </div>
                            )

                        }
                    </div>


                </div>


            </div>

        </div>


    )
}

export default HoroscopeTest
