import React from "react";
import "./blogpage.css";

const Post = ({ id, title, content, editPost, deletePost }) => {
    return (
        <>
            <div className="card card-width bg-dark">
                <section key={id}>
                    <h3>{title}</h3>
                    <hr className="new1"></hr>
                    <p>{content}</p>
                    <span title="edit post" onClick={() => editPost(id)}>
                        <i class="ri-edit-box-line"></i>
                    </span>
                    <span title="delete post" onClick={() => deletePost(id)}>
                        <i class="ri-delete-bin-7-fill"></i>
                    </span>
                </section>
            </div>
        </>
    );
};

export default Post;
