import React from 'react'

const Cookiepolicy = () => {
  return (
    <div className='p-4'>
      <div>
        Cookie Policy
        <p>
          Indianmatchmaking.co.in aims to offer its members with products and services, which are
          trustworthy, making it simpler for them to build some of the meaningful connections. Our company
          is here and believes in 100% privacy. We will provide you with ample and clear-cut information on
          the current data practices that we follow. So, we have kept the technical and legal jargon completely
          to the minimum.
          This policy is all about cookies, the types available on the device you will be using while visiting this
          site and how our team uses them. This policy will not work on any of your personal information.
          Want to learn more about the ways we process your on info? For that, check out our privacy policy.
        </p>

      </div>
      <div>
        WHAT COOKIES MEAN:
        <p>
          Cookies are defined as small files, which are sent to-from the web browsers via memory of your
          device. Cookie might have name of domain, from where the cookie might have been originated, the
          lifetime of the cookie (i.e when it will expire), and it will generate one random unique or similar
          identifier. A Cookie might have some information about the device you are using, like browsing
          history, user settings and some activities conducted while using our services.
        </p>

      </div>
      <div>
        DIFFERENT TYPES AND FORMS OF COOKIES AS USED:
        <p>
          There are first and third party cookies. The first party ones are placed on the device, directly from
          our part. For ex, these first party cookies are used to adapt to language preferences of the browser
          or to better understand your usage of the website. On the other hand, we have third party cookies,
          placed on devices by service providers and our partners. The third party cookies are mainly used for
          measuring user numbers on our website or just to enable you to share content with others across
          multiple social media platforms.
          We have Persistent and Session cookies, for you. Session cookies till you close the browser. It is also
          used for many reasons, such as to learn about user session and also helping you to use the website
          in more efficient manner. The Persistent ones will have longer lifespan and wont delete whenever
          closing browser. Such cookies are mostly used for helping you to sign right in and for analytical
          purposes.
        </p>

      </div>
      <div>
        OTHER WEB BEACONS AND TRACKING TECHNOLOGIES AS USED:
        <p>
          Web beacons (called pixels or gifs), tracking URLs or SDKs are mainly used for same purposes. Web
          beacons are mainly tiny graphic files having unique identifiers to recognize someone who has visited
          our services we offer or has opened any email that we sent them. On the other hand, there are
          tracking URLs, noted mostly as customized generated links that help us understand the flow of traffic
          that our website gets. SDKs, on the other hand, are smaller code pieces added in apps, working more
          like web beacons and cookies.
          For simplicity we consider these technologies as “cookies” in the cookie policy.
        </p>

      </div>
      <div>
        REASONS TO USE COOKIES:
        <p>
          Just like any other online services, cookies are used for securing, and providing the services we offer.
          It will include remembering preferences, recognizing user while visiting website after first time and
          personalizing and tailoring ads matching up to your needs. For getting hands on these services, we
          might link info from other cookies with personal info about you.
          Whenever you use our website or apps, there might be some or other cookies presented to your
          device.
        </p>

      </div>
      <div>
        ESSENTIAL WEB COOKIES
        <p>
          These cookies are mainly used for providing you with the services available through our website and
          to use some of it’s features like accessing secured areas.
          ANALYTICS COOKIES:
          These cookies are widely used to understand how our website is used, analyze marketing campaigns
          and improve our website for you.
        </p>

      </div>
      <div>
        COOKIES FOR ADVERTISEMENTS
        <p>
          These cookies are used for creating ad messages quite relevant to you. They perform certain
          functions, like preventing same ad from re-appearing at a continuous rate and even to ensure that
          the important advertisements are displayed. These cookies are designed to help select
          advertisements, depending on the level of interest and even measuring the number of ads which are
          up on display. The ads will be marked on their performance level like how many people might have
          clicked on the ad to get some info out of it.
        </p>

      </div>
      <div>
        COOKIES FOR SOCIAL NETWORKING
        <p>
          These cookies help you share the pages you want and the content along with it, which you might
          find rather interesting on our website. You can do that by using the help of third party based social
          networking channels and some of the other online platforms. These cookies can be used for
          advertising purposes too.
        </p>

      </div>
      <div>
        HOW TO CONTROL COOKIES?
        <p>
          Cookies can be managed in several ways. Please note that the changes you make to cookie
          preferences may make browsing our website a less satisfying experience. In some instances, you
          may be able to use all parts of the website.
        </p>

      </div>
      <div>
        DEVICES CONTROL AND BROWSER:
        <p>
          Some browsers offer you with settings to control or even reject cookies or might alert you whenever
          any cookie is placed on your device. These cookie management processes are a bit different for
          every internet browser. Make sure to find the steps in the selected browser help menu.
          You can also reset device identifiers by just switching from your mobile settings. This process is a bit
          different for every device and you can find the steps in settings menu for more details.
        </p>

      </div>
      <div>
        INTERNET RELATED AD TOOLS:
        <p>
          You can opt out of seeing interest based advertising from participating through Interactive Digital
          Advertising Alliance, Appchoices (apps only) or Digital Advertising Alliance.
          Opting out will not mean that you won’t see the ads. It means you won’t get personalized ads from
          companies, participating in such programs. If you delete cookies, you have to opt out again.
        </p>

      </div>
      <div>
        SOCIAL COOKIES:
        <p>
          To share content on social media, some website features might use social media based plugins.
          Based on the account settings, we will receive info automatically from social media platforms while
          using corresponding button on site. You can refer to the respective social media’s privacy and cookie
          policies for more details.
        </p>

      </div>
      <div>
        COOKIES FROM GOOGLE:
        <p>
          We use Google Analytics, which is a Google services which uses cookies and other data collecting
          technologies to collect information use of the website and services in order to share usage trends.
          Visit www.google.com/settings/ads to opt out of Google Analytics or you can download this browser
          extension to opt-out at https://tools.google.com/dlpage/gaoptout.
        </p>

      </div>
      <div>
        WAYS TO CONTACT US:
        <p>
          Do you have any queries related to Cookie Policy? If so, then you can always reach us her
          info@indianmatchmaking.co.in
        </p>

      </div>
    </div>
  )
}

export default Cookiepolicy