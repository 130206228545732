import React from 'react'
import logoimage from '../assets/logomatchmaking.png';
import { makeStyles } from "@material-ui/core/styles";
import bgimage from '../assets/bgimage3.jpg';

const UpperNav = () => {

    const classes = useStyles();



    const headerStyle = {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // Add other background properties as needed
    };


    return (
        <div className={classes.headermain}>

            <div className={classes.container}>
                <div style={headerStyle} className={classes.headerflex}>

                    <div >
                        <figure className="logo">
                            {" "}
                            <a className="logo-wrap" href="https://www.indianmatchmaking.co.in/">
                                <img className={classes.logoImage}
                                    // style={{ width: '400px', height: '80px' }}
                                    src={logoimage}
                                    alt="matchmaking Matrimony"
                                />
                            </a>
                        </figure>
                    </div>

                    <div className={classes.detailsim}>
                        <div className={classes.utility}>

                            <a className={classes.phone} href="tel:+91-8280946968">
                                {" "}
                                <i className="fa fa-phone" aria-hidden="true" />
                                +91-8280946968
                            </a>
                            {/* <a className={classes.phone} href="tel:+91-8280946968">
                                <i className="fa fa-mobile" aria-hidden="true" /> +91-8280946968
                            </a> */}


                            <a className={classes.phone} href="mailto:contact@wedgatematrimony.com">
                                <i className="fa fa-envelope" aria-hidden="true" />{" "}
                                info@indianmatchmaking.co.in
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    phone: {
        color: 'white'
    },
    headermain: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container: {
        width: '100%'

    },
    headerflex: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        gap: 40,

        // [theme.breakpoints.down('sm')]: {
        //     flexDirection: 'column',
        //     gap: '20px',
        // },
        [theme.breakpoints.down(700)]: {
            flexDirection: 'column',
            gap: '0',
        },
    },
    detailsim: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    utility: {
        gap: '30px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '10px',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '5px',
        },
        [theme.breakpoints.down(700)]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '10px',
            marginBottom: '5px',
        },

    },
    tophead: {
        padding: '1rem',
        backgroundColor: 'blueviolet'
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        // "&:hover": {
        //     backgroundColor: alpha(theme.palette.common.white, 0.25),
        // },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    login: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    moreouter: {
        display: "flex"
    },
    more: {
        marginLeft: "12px",
        paddingRight: "12px",
        color: "#fff"
    },
    sublinks: {
        textDecoration: "none",
        color: "inherit"
    },
    sublinksProfile: {
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        "& svg": {
            marginRight: "5px"
        }
    },
    headericons: {
        width: "63px"
    },


    logoImage: {
        width: '400px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '300px',
            height: '60px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '200px',
            height: '40px',
        },
    },

    // phone: {
    //     color: 'white',
    //     textDecoration: 'none',
    //     display: 'flex',
    //     alignItems: 'center',
    //     gap: '5px',
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '0.8rem',
    //     },
    // },
    // headermain: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     padding: '1rem',
    //     [theme.breakpoints.down('sm')]: {
    //         padding: '0.5rem',
    //     },
    // },
    // container: {
    //     width: '100%',
    // },
    // headerflex: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     gap: '40px',
    //     [theme.breakpoints.down('sm')]: {
    //         flexDirection: 'column',
    //         gap: '20px',
    //     },
    // },
    // logo: {
    //     margin: 0,
    // },
    // logoWrap: {
    //     textDecoration: 'none',
    // },
    // logoImage: {
    //     width: '400px',
    //     height: '80px',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '300px',
    //         height: '60px',
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         width: '200px',
    //         height: '40px',
    //     },
    // },
    // detailsim: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '100%',
    //     },
    // },
    // utility: {
    //     display: 'flex',
    //     gap: '30px',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     [theme.breakpoints.down('sm')]: {
    //         flexDirection: 'column',
    //         gap: '10px',
    //     },
    // },
}));



export default UpperNav


