export const maskUsername = (username) => {
    const startingPart = username.slice(0, -2);
    const maskedPart = '**';
    return startingPart + maskedPart;
};

export const maskUsernamefull = (username) => {
    if (typeof username === 'string') {
        const maskedUsername = username.replace(/[a-zA-Z]/g, '*');
        return maskedUsername;
    }
}

export const maskEmail = (email) => {
    if (/^.+@.+\..+$/.test(email)) {
        const maskedUsername = '*'.repeat(5) + email.slice(5);
        return maskedUsername;
    }

    // Return the original email if it doesn't match the expected pattern
    return email;


}

export const maskMobileNumber = (mobileNumber) => {
    const maskedDigitsmob = mobileNumber.slice(0, -5).replace(/\d/g, '*');
    let mob = maskedDigitsmob + mobileNumber.slice(5);
    // setmobileNo(mob)
    return mob
};