import React from "react";
import { useHistory } from "react-router-dom";
import "./post-styles.css";

export default function Post({ id, url, title, date, onDelete }) {
  const history = useHistory();

  function handleClick() {
    history.push(`/post/${id}`);
  }

  function handleDelete() {
    onDelete(id);
  }

  return (
    <li id="postAndButton">
      <div id="postPreview" onClick={handleClick}>
        <img src={url} alt="thumbnail" />
        <h2>{title}</h2>
        <em>{date}</em>
      </div>
      <span id="deleteBtn" onClick={handleDelete}>
        <img
          src="https://icons-for-free.com/iconfiles/png/512/close+closecancelsquare+cross+delete+remove+square+icon-1320185734508070818.png"
          alt="Delete post"
        />
      </span>
    </li>
  );
}
