import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, storage } from "../../app/firebase";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";



import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import * as environment from '../../coreservices/environment'

const useStyles = makeStyles((theme) => ({
  updatebutton: {
    marginTop: "20px",
    display: "flex",
    marginLeft: "auto",
  },
}));
const InfertilityScreening = () => {


  useEffect(() => {
    getTestImages()
  }, [localStorage.getItem('userId')])

  const location = useLocation();

  const loggedUsersData_psy = location?.state?.state?.loggedUsersData
  const loggedinUser_psy = location?.state?.state?.loggedinUser

  console.log('====================================');
  console.log("loggedUsersData_psy", loggedUsersData_psy);
  console.log("loggedinUser_psy", loggedinUser_psy);
  console.log('====================================');


  const handleSubmit = async () => {

    let data = {
      userId: localStorage.getItem('userId'),
      path: horoscopepath
    }


    try {
      const response = await fetch(`${environment.apiUrl}/infertilityscreening`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data as needed
      const responseData = await response.json();
      console.log("responseData", responseData);
      alert('Record Submitted Successfully.')
      getTestImages()
    } catch (error) {
      console.error('Error during POST request:', error);
    }

  };


  const [isLength, setisLength] = useState(false)
  const getTestImages = async () => {
    try {
      const response = await fetch(`${environment.apiUrl}/infertilityscreening/${localStorage.getItem('userId')}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetched_img_Data = await response.json();
      if (fetched_img_Data) {
        setHoroscopepath(fetched_img_Data?.path);
        setisLength(true)
      }
      console.log('=============fetched_img_Data=======================', fetched_img_Data);
    } catch (error) {
      console.error(error.message);
    }
  }








  const userDetails = useSelector(selectUser);
  console.log(userDetails);
  const [user, setUser] = useState(userDetails);

  const classes = useStyles();
  const filepickerRef = useRef(null);

  const [horoscopepath, setHoroscopepath] = useState("");
  const addImage = (e, imageType) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      // console.log(readerEvent.target.result);
      if (readerEvent.target.result) {
        const uploadTaskPic = storage
          .ref(`profiepics/${user.userData.email + imageType}`)
          .putString(readerEvent.target.result, "data_url");
        // removeImage1();
        uploadTaskPic.on(
          "state_change",
          null,
          (error) => console.log(error),

          () => {
            // var progress =
            //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            storage
              .ref("profiepics")
              .child(user.userData.email + "horoscope")
              .getDownloadURL()
              .then((url) => {
                // firebase.auth().currentUser.updateProfile({
                //     photoURL: url,
                // });
                setHoroscopepath(url);
                console.log(url);
                db.collection("users")
                  .doc(user.userData.email)
                  .set(
                    {
                      horoscopeurl: url,
                    },
                    { merge: true }
                  );
              });
          }
        );
      }
    };
  };

  return (
    <div className="cen-div">

      <div className="center-div">
        <div className="centered-div-1">

          <div>
            <h2 style={{ textAlign: 'center' }}>InfertilityScreening Tests</h2>
            <span className='text-info fs-4 fw-bold'>Why is it important: </span>
            Why is it important: Some people believe that
            doing this test can cause more harm than good, but it is always better to be aware than to be
            shocked or disappointed after marriage. Since sexual intimacy is the cornerstone of a good
            marriage, it is important to know if you both can have a normal sex life and even procreate if
            you want. Women with irregular cycles should get their blood tested for their reproductive
            hormones, FSH (follicular stimulating hormones), LH (leutenising hormone), prolactin, TSH
            (thyroid stimulating hormone) and AMH to find out why they are not ovulating. This is called
            anovulation. The commonest reason for this in young women is PCOD (polycystic ovarian
            disease). Also younger men if they notice that their testes are not present in their scrotum,
            should get his semen tested, to make sure his sperm count is normal.
            'In the case of older women, they should get their blood tested for their AMH levels (Anti-M
            llerian Hormone), to check for their ovarian reserves. This way, they ll know how much time
            they have left of their biological clock,' he adds.
            What it would entail: For women, these tests might take a day to do. It will include a blood
            test and in cases where you have to be tested for PCOS, you will have to undergo an
            ultrasound. In the case of men all they have to provide is a semen sample. (Read: It is
            possible to figure out the cause for infertility in about a month )


            {isLength == false ?
              <div class="mb-3">

                <div
                  className={classes.mat__profilepicupload}
                  onClick={() => filepickerRef.current.click()}
                >
                  <IconButton
                    color="primary"
                    title="Upload Horoscope"
                    className={classes.mat__horoscopeupload}
                  >
                    <input
                      type="file"
                      hidden
                      onChange={(e) => addImage(e, "horoscope")}
                      ref={filepickerRef}
                      className={classes.mat__horoscopeupload}
                    />
                    <PhotoCamera />
                    Upload InfertilityScreening Test File
                  </IconButton>
                </div>
                <div>{horoscopepath}</div>

                {horoscopepath != "" ?
                  <div>
                    <a href={horoscopepath} target="_blank"
                      rel="noopener noreferrer" download="image.jpg">Download InfertilityScreening Test File</a>
                  </div>
                  :
                  null
                }


              </div>
              :
              <div className="mb-3">
                {horoscopepath != "" ?
                  <div>
                    <a href={horoscopepath} target="_blank"
                      rel="noopener noreferrer" download="image.jpg">Download InfertilityScreening Test File</a>
                  </div>
                  :
                  null
                }
              </div>
            }



            {isLength == false &&
              (
                <div>
                  <button onClick={handleSubmit} type="button" class="btn btn-primary">Submit</button>
                </div>
              )

            }
          </div>


        </div>





      </div>

    </div>
  )
}

export default InfertilityScreening
