// import React, { useEffect, useState } from 'react'
// import './QuestionAnswer.css';
// import { Modal } from 'react-bootstrap';
// import { useHistory } from "react-router-dom";

// const Fiftyquestions = () => {
//     const [showModal, setShowModal] = useState(false);
//     const history = useHistory();
//     const handleRedirectProfile = () => {
//         history.push('/psychologicaltests');
//     }
//     const handleShowModal = () => {
//         setShowModal(true);
//         handleStopTimer();
//     }
//     const handleCloseModal = () => setShowModal(false);
//     const ThankYouModal = ({ showModal, handleClose }) => {
//         return (
//             <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
//                 <Modal.Header closeButton>
//                     <Modal.Title>Thank You!</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="thank-you-pop">
//                         <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
//                         <h1>Thank You!</h1>
//                         <p>Your submission is received and saved in your profile</p>
//                         <div className='d-flex justify-content-center align-items-center'>

//                             <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
//                                 Ok</button>
//                         </div>
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         );
//     };
//     const [seconds, setSeconds] = useState(0);
//     const [isRunning, setIsRunning] = useState(true);
//     const [recordedTime, setRecordedTime] = useState(null);
//     const handleStopTimer = () => {
//         setIsRunning(false);
//         setRecordedTime(seconds);
//     };
//     const Timer = () => {

//         useEffect(() => {
//             let intervalId;

//             if (isRunning) {
//                 intervalId = setInterval(() => {
//                     setSeconds((prevSeconds) => prevSeconds + 1);
//                 }, 1000);
//             }

//             // Cleanup function to clear the interval when the component unmounts or timer is stopped
//             return () => clearInterval(intervalId);
//         }, [isRunning]);

//         const minutes = Math.floor(seconds / 60);
//         const remainingSeconds = seconds % 60;

//         return (
//             <div>
//                 <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
//                 {recordedTime !== null && (
//                     <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
//                 )}
//                 {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
//             </div>
//         );
//     };


//     const [questions, setQuestions] = useState([
//         {
//             id: 1,
//             question: 'How do you usually handle stress?',
//             options: ['XYZXYZ', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 2,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         {
//             id: 3,
//             question: 'How do you usually handle stress?',
//             options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 4,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         {
//             id: 5,
//             question: 'How do you usually handle stress?',
//             options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 6,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         // Add more questions as needed
//     ]);

//     const handleAnswerSelect = (questionId, selectedAnswer, question) => {
//         console.log("quest id", questionId)
//         console.log("sel ans", selectedAnswer)
//         console.log("ques", question)
//         setQuestions((prevQuestions) =>
//             prevQuestions.map((question) =>
//                 question.id === questionId ? { ...question, selectedAnswer } : question
//             )
//         );
//     };


//     const renderQuestions = () => {
//         return questions.map((question) => (
//             <div key={question.id} className="question-container">
//                 <h3>{`Question ${question.id}: ${question.question}`}</h3>
//                 <ul>
//                     {question.options.map((option, index) => (
//                         <li key={index}>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name={`question_${question.id}`}
//                                     value={option}
//                                     checked={question.selectedAnswer === option}
//                                     onChange={() => handleAnswerSelect(question.id, option, question.question)}
//                                 />
//                                 {option}
//                             </label>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         ));
//     };


//     return (
//         <div className="cen-div">

//             <div className="center-div">
//                 <div className="centered-div-1">

//                     <div>

//                         <div>
//                             <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
//                             <h2 style={{ textAlign: 'center' }}>Questions</h2>
//                         </div>




//                         <div className="psychological-test-container">
//                             <h1>Psychological Test</h1>
//                             {renderQuestions()}
//                             <div className='d-flex justify-content-center align-items-center'>

//                                 <button onClick={handleShowModal} type="button" class="btn btn-success">
//                                     Submit</button>
//                             </div>
//                         </div>

//                     </div>


//                 </div>

//             </div>


//             <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


//         </div>
//     )
// }

// export default Fiftyquestions



import React, { useEffect, useState } from 'react'
import './QuestionAnswer.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import * as environment from '../../coreservices/environment'

const Fiftyquestions = () => {

  const searchParams = new URLSearchParams(window?.location?.search);
  const status = searchParams?.get('status');


  // Your logic for handling failure status
  console.log('Status is', status);


  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const handleRedirectProfile = () => {
    history.push('/psychologicaltests');
  }
  const handleShowModal = async () => {
    setShowModal(true);
    handleStopTimer();

    console.log('====================================');
    console.log("seconds_log", seconds)
    console.log("localStorage.getItem_userId_log", localStorage.getItem("userId"));
    console.log("questionss_log", questions);
    console.log('====================================');



    let CreateSatisfactiontestDto = {
      userId: localStorage.getItem("userId"),
      FiftyquestionId: '0',
      questions: "",
      answers: "",
      // questionsanswers: questions.map(question => { questionId: question.id; question: question.question; answer: question.selectedAnswer }),
      questionsanswers: questions,

      timer: seconds,
      timestamp: new Date(),
      testName: "fifty_questions",
    }

    // let Questionsanswers = {
    //     questionId: questions.id,
    //     question: questions.question,
    //     answer: questions.selectedAnswer,
    // }
    console.log('====================================');
    console.log("CreateSatisfactiontestDto", CreateSatisfactiontestDto);
    console.log('====================================');

    try {
      const response = await fetch(`${environment.apiUrl}/fiftyquestion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(CreateSatisfactiontestDto),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data as needed
      const responseData = await response.json();
      console.log("responseData", responseData);
    } catch (error) {
      console.error('Error during POST request:', error);
    }


  }
  const handleCloseModal = () => setShowModal(false);
  const ThankYouModal = ({ showModal, handleClose }) => {
    return (
      <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="thank-you-pop">
            <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
            <h1>Thank You!</h1>
            <p>Your submission is received and saved in your profile</p>
            <div className='d-flex justify-content-center align-items-center'>

              <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
                Ok</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [recordedTime, setRecordedTime] = useState(null);
  const handleStopTimer = () => {
    setIsRunning(false);
    setRecordedTime(seconds);
  };
  const Timer = () => {

    useEffect(() => {
      let intervalId;

      if (isRunning) {
        intervalId = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);
      }

      // Cleanup function to clear the interval when the component unmounts or timer is stopped
      return () => clearInterval(intervalId);
    }, [isRunning]);

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
      <div>
        <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
        {recordedTime !== null && (
          <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
        )}
        {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
        <div style={{ textAlign: 'left' }}>Falling in love may come naturally, but sustaining that love requires dedication and self-awareness/
          प्यार मेंपड़ना स्वाभाविक रूप सेहो सकता है, लेवकन उस प्यार को बनाए रखनेकेवलए समपपण और आत्मजागरूकता की आिश्यकता होती है।
          Marriage is a significant milestone in life, signifying the depth of love and commitment between
          partners. Embarking on life's journey together with your best friend forever is an extraordinary
          adventure/ वििाह जीिन मेंएक महत्वपूणपमील का पत्थर है, जो भागीदारोोंके बीच प्यार और प्रवतबद्धता की
          गहराई को दर्ापता है। अपनेसबसेअच्छेदोस्त के साथ हमेर्ा केवलए जीिन की यात्रा पर वनकलना एक
          असाधारण साहवसक कायपहै।
          Now is the time for you and your fiancé to delve into these essential questions before your wedding
          day. Understanding each other's perspectives on these matters will establish realistic expectations
          and equip you for your shared future. Take turns asking these open-ended questions and prioritize
          listening to your partner's feelings, viewpoints, and aspirations/
          अब आपकेऔर आपके मोंगेतर केवलए अपनी र्ादी केवदन सेपहलेइन आिश्यक प्रश्ोोंपर विचार करनेका
          समय आ गया है। इन मामलोोंपर एक-दू सरेके दृविकोण को समझनेसेयथाथपिादी अपेक्षाएों स्थावपत होोंगी और
          आप अपनेसाझा भविष्य केवलए तैयार होोंगे। इन खुलेप्रश्ोोंको बारी-बारी सेपूछेंऔर अपनेसाथी की भािनाओों,
          दृविकोण और आकाोंक्षाओोंको सुननेको प्राथवमकता दें।
          We’ve chosen 10 topics that all couples need to focus on. हमने10 विषय चुनेहैंवजन पर सभी जोड़ोोंको
          ध्यान कें वित करनेकी आिश्यकता है।
          "You can skip any questions that you find embarrassing or prefer not to answer. Please
          answer this question and save it in your profile. Then, ask your fiancé to answer these
          questions and send the request to view their answers through this site only."
          "आप वकसी भी सिाल को छोड़ सकतेहैंवजसेआप लाज़मी मानतेहैंया उसेजिाब देनेकी पसोंद नहीोंकरते।
          कृ पया इस सिाल का उत्तर देंऔर अपनी प्रोफाइल मेंइसेसहेजें। विर, अपनेप्रेमी/प्रेवमका सेइन सिालोोंका
          उत्तर देनेकेवलए कहेंऔर उनके उत्तरोोंको देखनेका अनुरोध के िल इस साइट के माध्यम सेभेजें।"
        </div>

      </div>
    );
  };


  const [questions, setQuestions] = useState([

    {
      "id": "1",
      "question": "What beliefs do you have about yourself that resulted from your childhood (अपनेबारेमें आपकी क्या मान्यताएँहैंजो बचपन सेउत्पन्न हुई हैं)?",
      "options": [
        "I am confident because my parents always encouraged me to pursue my interests and supported my endeavors. (मैं आश्वस्त हों क्योंकि मेरे माता-पिता ने हमेशा मुझे मेरी रुचियों को आगे बढ़ाने केलिए प्रोत्साहित किया और मेरे प्रयासों का समर्थन किया।)",
        "My worth is tied to my academic performance. (मेरी योग्यता मेरे शैक्षिक प्रदर्शन से जुड़ी है।)",
        "I should always prioritize family over my own needs. (मुझे हमेशा अपनी ज़रूरतों से ज़्यादा परिवार को प्राथमिकता देनी चाहिए।)",
        "Expressing emotions is a sign of vulnerability. (भावनाओं को व्यक्त करना असुरक्षा का प्रतीक है।)",
        "Success is defined by societal standards, not personal fulfillment. (सफलता सामाजिक मानकों से परिभाषित होती है, व्यक्तिगत पूर्णता से नहीं।)",
        "I must conform to traditional gender roles and expectations. (मुझे पारंपरिक लैंगिक भूमिकाओं और अपेक्षाओं के अनुसार चलना चाहिए।)",
        "None of the above / उपरोक्त विकल्पों में से कोई नहीं",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "2",
      "question": "If you could change anything about the way you were raised, what would it be and why? (अगर आप अपने पालने-पोषण के तरीके में कुछ बदल सकते हैं, तो वह क्या होगा और क्यों?)",
      "options": [
        "I would have liked more freedom to explore my interests and passions. (मुझे अपने रुचियों और जुनून को खोजने के लिए अधिक स्वतंत्रता चाहिए थी।)",
        "My parents should have encouraged open communication to discuss feelings and opinions. (मेरे माता-पिता को भावनाओं और रायों को चर्चा करने के लिए खुली या साफ़ डिलील सोंचार करना चाहिए था।)",
        "I wish my upbringing had focused more on personal growth rather than just academic success. (मुझे अपने पालने-पोषण में व्यक्तिगत विकास पर ज्यादा ध्यान दिया जाता, शैक्षिक सफलता से ज्यादा।)",
        "My family's attitude towards expressing emotions should have been more accepting and supportive. (मेरे परिवार को भावनाओं को व्यक्त करने की दृष्टि को अधिक स्वीकारशील और सहायक होना चाहिए था।)",
        "It would have been better if my upbringing had been less focused on societal expectations and more on individual happiness. (अगर मेरे पालने-पोषण में समाजी उम्मीदों पर कम ध्यान दिया गया और अधिक व्यक्तिगत खुशी पर ध्यान दिया गया होता।)",
        "None of the above / उपरोक्त विकल्पों में से कोई नहीं",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "3",
      "question": "Were you allowed to express your emotions as a kid? Why or why not? (क्या आप बचपन में अपनेभािनाओोंको व्यक्त करनेकी अनुमवत थी? क्यों या क्यों नहीं?)",
      "options": [
        "No, because showing emotions was seen as a sign of weakness in my family. (नहीं, क्योंवक अपनी भावनाओं को दिखाना मेरे परिवार में कमजोरी की वनर्ानी के रूप में देखा गया था।)",
        "Yes, but only certain emotions were deemed acceptable, like happiness or excitement. (हाों, लेवकन के िल कु छ भािनाएँ स्वीकायपमानी गईों, जैसेखुर्ी या उत्साह।)",
        "No, because discussing emotions was considered taboo in our household. (नहीं, क्योंवक हमारे घर में भावनाओं पर चर्चा करना तबू माना गया था।)",
        "Yes, my family encouraged open expression of emotions as a way to foster communication and understanding. (हाों, मेरे परिवार ने सोंिाद और समझ को बढािा देनेके रूप में भावनाओं के खुले व्यक्त करनेको प्रोत्सावहत वकया।)",
        "No, because there was a cultural expectation to maintain a stoic demeanor and not burden others with emotional issues. (नहीं, क्योंवक सोंिेदनात्मक मुद्दों सेदू सरोंको अवधकार करनेकी सािस्कृ वतक अपेक्षा थी और सोंजात रहना था।)",
        "None of the above / उपरोक्त विकल्पों में से कोई नहीं",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "4",
      "question": "What are your family's views on race, ethnicity, and politics? (आपके परिवार की रास्त्रीयता, जाति, और राजनीति पर क्या विचार हैं?)",
      "options": [
        "My family believes in embracing diversity and respecting people of all races and ethnicities. (मेरा परिवार विविधता को गले लगाने और सभी जातियों और नस्लों के लोगों का सम्मान करने में विश्वास रखता है।)",
        "We avoid discussing politics to prevent disagreements within the family. (हम परिवार में असहमति से बचने के लिए राजनीति पर चर्चा करने से बचते हैं।)",
        "My family holds traditional views on race and ethnicity, preferring to stick to our own community. (मेरे परिवार को जाति और नस्ल पर पारंपरिक दृष्टिकोण है, हमारे खुद की समुदाय में अटके रहने का पसंद करते हैं।)",
        "Politics is a sensitive topic in our household, so we try to steer clear of it during discussions. (हमारे घर में राजनीति एक संवेदनशील विषय है, इसलिए हम चर्चाओं के दौरान इससे दूर रहने का प्रयास करते हैं।)",
        "We believe in equality and inclusivity, regardless of race, ethnicity, or political affiliation. (हम जाति, नस्ल, या राजनीतिक संबंध के बिना समानता और समावेशितता में विश्वास रखते हैं।)",
        "None of the above / उपरोक्त विकल्पों में से कोई नहीं",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "5",
      "question": "How compatible are we in our money styles and how will we handle finances once married?",
      "options": [
        "We have similar money management styles and plan to combine our finances seamlessly.",
        "Our money styles are somewhat different, but we are open to discussing and finding common ground.",
        "We have completely different money management styles, but we are willing to compromise and adapt for the sake of our relationship.",
        "We haven't discussed our money styles yet, but we understand the importance of financial planning and are committed to figuring it out together.",
        "We haven't discussed it yet and plan to figure it out as we go along in our marriage.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "6",
      "question": "Are you willing to relocate for either of our jobs and if so, to where?",
      "options": [
        "Yes, I am willing to relocate wherever necessary for our career growth.",
        "I prefer not to relocate, but I am open to discussing it based on the circumstances.",
        "No, I am not willing to relocate for job purposes.",
        "I am open to relocating to certain cities or areas if it benefits both of our careers.",
        "I am undecided about relocation at the moment and need more discussion before making a decision.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "7",
      "question": "How much a month can each of us spend without any rules and without having to ‘ask permission’?",
      "options": [
        "₹10,000",
        "₹20,000",
        "₹30,000",
        "₹50,000",
        "₹100,000",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "8",
      "question": "What will happen if one of us loses a job or is laid off? What would be your plan of action?",
      "options": [
        "Utilize emergency savings until a new job is found.",
        "Explore unemployment benefits and seek new job opportunities actively.",
        "Cut down on non-essential expenses and create a strict budget until stable employment is secured.",
        "Consider alternative income sources like freelancing or part-time work.",
        "Seek guidance from career counselors or network with professionals in the industry for job leads.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "9",
      "question": "How would we navigate a drastic career change once we’re married?",
      "options": [
        "Support each other emotionally and financially during the transition period.",
        "Seek guidance from mentors or career counselors to explore new opportunities.",
        "Communicate openly about aspirations, concerns, and potential challenges.",
        "Consider the impact on family dynamics and make decisions collaboratively.",
        "Be flexible and adaptable to change, embracing new opportunities and challenges together.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "10",
      "question": "Have you ever run into trouble with debt? Do you have any debt and if so how much? Tell me about it.",
      "options": [
        "Yes, I have faced challenges with debt in the past, and I currently have debt.",
        "No, I have not encountered any significant debt issues so far.",
        "I have had some debt in the past, but I have managed to clear it off.",
        "I prefer not to disclose my financial situation regarding debt.",
        "I am currently managing some debt, but it's under control and being paid off gradually.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "11",
      "question": "Which one of us will pay the bills? Or will we share that responsibility?",
      "options": [
        "I will take responsibility for paying the bills.",
        "My partner will handle bill payments.",
        "We will share the responsibility of paying bills equally.",
        "We haven't decided yet, but we will discuss and come to a mutual agreement.",
        "It will depend on our respective incomes and financial situations.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "12",
      "question": "If we have differences regarding our finances, how will we plan on resolving them?",
      "options": [
        "Discuss our financial goals and priorities and find a compromise.",
        "Seek advice from a financial advisor or counselor to mediate our differences.",
        "Set a regular time to review our finances together and make necessary adjustments.",
        "Allocate specific responsibilities for managing different aspects of our finances.",
        "Take turns making financial decisions and respect each other's opinions.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "13",
      "question": "What are ways that you would like us to financially invest in our marriage? (Ex: date nights, vacations, seminars, relationship books, etc.)",
      "options": [
        "Plan a memorable honeymoon trip to our dream destination.",
        "Invest in experiences like cooking classes, dance lessons, or adventure sports together.",
        "Save for a down payment on our dream home or future investments.",
        "Consider investing in joint hobbies or activities that strengthen our bond.",
        "Save for future goals like starting a family, further education, or retirement planning.",
        "Hosting a grand wedding reception for family and friends.",
        "Planning an extravagant honeymoon to a dream destination.",
        "Investing in a luxurious wedding venue and décor.",
        "Organizing traditional ceremonies with elaborate rituals and celebrations.",
        "Saving up for future milestones like buying a house or starting a family.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "14",
      "question": "When conflict arises, do you tend to want to fight or avoid it? Why do you think that is?",
      "options": [
        "I prefer to confront the conflict and resolve it through discussion.",
        "I tend to avoid conflict as much as possible to maintain peace and harmony.",
        "I usually confront conflict initially but try to resolve it peacefully later.",
        "I tend to avoid conflict initially but may address it later if necessary.",
        "It depends on the situation and the severity of the conflict.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "15",
      "question": "How and when will we resolve differences in our marriage?",
      "options": [
        "We will set aside time for open and honest communication to address any differences as they arise.",
        "We will seek counseling or mediation to resolve conflicts in a healthy and constructive manner.",
        "Differences will be addressed immediately through mutual understanding and compromise.",
        "We will establish ground rules for conflict resolution and commit to following them.",
        "We will prioritize understanding each other's perspectives and finding common ground.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "16",
      "question": "Do you feel comfortable seeking professional counseling if needed? Why or why not?",
      "options": [
        "Yes, I believe professional counseling can provide valuable insights and support to resolve issues.",
        "No, I feel uncomfortable discussing personal matters with a stranger.",
        "Yes, seeking professional help shows maturity and a willingness to address challenges constructively.",
        "No, I prefer to handle problems on my own without involving a third party.",
        "Yes, I understand the benefits of professional counseling in improving mental health and relationships.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "17",
      "question": "Do you feel like we have a good understanding of how and when we should bring up issues in our relationship? Why or why not?",
      "options": [
        "Yes, we have clear communication guidelines and know when to address issues calmly.",
        "No, there are misunderstandings about when and how to discuss relationship issues.",
        "Yes, we have developed a healthy pattern of communication that allows us to address issues effectively.",
        "No, we struggle with knowing when it's appropriate to discuss relationship issues.",
        "Yes, we have established mutual respect and understanding, making it easier to address concerns.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "18",
      "question": "How can we make sure that divorce is NEVER an option for us?",
      "options": [
        "By prioritizing open communication and actively resolving conflicts.",
        "By nurturing mutual respect, trust, and understanding in our relationship.",
        "By seeking professional help or counseling when facing challenges in our marriage.",
        "By committing to continuous growth and improvement as individuals and as a couple.",
        "By understanding that challenges are a natural part of any relationship and working through them together.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "19",
      "question": "What things make you angry? What do you do when you’re angry? What are ways you process your anger in a healthy way?",
      "options": [
        "Unfair treatment or injustice makes me angry; I usually confront the issue calmly and assertively.",
        "Disrespect or dishonesty triggers my anger; I take time to cool down and express my feelings constructively.",
        "When things don't go as planned or there's a lack of control, I feel angry; I engage in physical activities or deep breathing to calm myself.",
        "Betrayal or deception makes me furious; I seek solitude to reflect on my emotions and find constructive solutions.",
        "I get angry when I feel misunderstood or unheard; I express myself through journaling or talking to a trusted friend.",
        "None of the above",
        "Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "20",
      "question": "What is the best way for me to communicate difficult feelings about you so that you are not offended? आप तकलीिदेह भािनाओोंको मुझसेकै सेसोंिावदत करना चावहए तावक आप को कोई आपवत्त ना हो?",
      "options": [
        "Choose a calm and private setting to discuss the issue. / समस्या पर चचापकरनेकेवलए एक र्ाोंत और वनजी क्तस्थवत का चयन करें।",
        "Use 'I' statements to express feelings without blaming or accusing. / आरोप न लगाकर भािनाओोंको व्यक्त करनेकेवलए 'मैं' कथनोंका प्रयोग करें।",
        "Listen actively to each other without interrupting or getting defensive. / एक-दू सरेको वबना बावधत वकए या सोंज्ञानात्मक रूप सेसुनें।",
        "Offer constructive suggestions for resolving the issue together. / समस्या को समाधान के वलए सोंयुक्त युक्तक्तयाँप्रस्तावित करें।",
        "Express empathy and understanding towards each other's perspectives. / एक-दू सरेके दृविकोण के प्रवत सहानुभूवत और समझ व्यक्त करें।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "21",
      "question": "What are some unhealthy habits that we have when we communicate? (Ex: Name calling, blaming, fixing, minimizing feelings, making judgmental statements, eye rolling, shutting down.) हमारेसोंचार करतेसमय कु छ अअस्वस्थ आदतेंक्या हैं? (उदाहरण: नाम बुलाना, आरोप लगाना, सुधार करना, भािनाओोंको कम करना, वनणापयक बयान करना, आँख मारना, बोंद कर देना।)",
      "options": [
        "Name calling / नाम बुलाना",
        "Blaming / आरोप लगाना",
        "Fixing / सुधार करना",
        "Minimizing feelings / भािनाओोंको कम करना",
        "Making judgmental statements / वनणापयक बयान करना",
        "Eye rolling / आँख मारना",
        "Shutting down / बोंद कर देना",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "22",
      "question": "Do you feel like I try my best to understand your views, feelings, and opinions? क्या आपको लगता हैवक मैंअपनी सबसेअच्छी कोवर्र् करता हँआपकेविचारोों, भािनाओोंऔर राय को समझनेकी? क्योंया क्योंनहीों?",
      "options": [
        "Yes, you always make an effort to understand and empathize with me. / हाों, आप हमेर्ा मेरेविचारोोंऔर भािनाओोंको समझनेऔर सहानुभूवत करनेकी कोवर्र् करतेहैं।",
        "No, I often feel like you don't make enough effort to understand me. / नहीों, अक्सर मुझे लगता हैवक आप मुझेसमझनेकेवलए पयापप्त प्रयास नहीोंकरतेहैं।",
        "Sometimes, but there are moments when you seem more focused on your own perspective. / कभी-कभी, लेवकन कु छ पल ऐसेहोतेहैंजब आप अपनेव्यक्तक्तगत दृविकोण पर अवधक ध्यान देनेलगतेहैं।",
        "I believe you try your best, but there's always room for improvement in understanding each other. / मुझेविश्वास हैवक आप अपनी सबसेअच्छी कोवर्र् करतेहैं, लेवकन हम एक-दू सरे को समझनेमेंसुधार केवलए हमेर्ा जगह होती है।",
        "It depends on the situation and the topic of discussion. / यह पररक्तस्थवत और चचापकेविषय पर वनभपर करता है।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "23",
      "question": "What is one thing that I can work on to become a better listener? मैंएक बेहतर सुननेिाला बननेकेलए क्या एक चीज पर काम कर सकता हँ?",
      "options": [
        "Practice active listening by paraphrasing what the speaker said. / बोलनेिालेनेक्या कहा है, उसको पुन: र्ब्दाथपकरके सविय रूप सेसुननेका अभ्यास करें।",
        "Avoid interrupting and allow the speaker to finish their thoughts before responding. / बाधा न बनाएों और जिाब देनेसेपहलेिक्ता को अपनेविचारोंको समाप्त करनेकी अनुमवत दें।",
        "Maintain eye contact and show nonverbal cues of engagement, such as nodding. / आँख सोंपकप बनाए रखेंऔर एों गेजमेंट के अिधारणात्मक सोंके त वदखाएों, जैसेवक हाों करना।",
        "Be patient and avoid forming judgments or jumping to conclusions prematurely. / धैयप रखेंऔर विचारोोंका वनमापण या जल्दी सेवनष्कषों पर पहुोंचनेसेबचें।",
        "Show empathy by acknowledging the speaker's feelings and validating their experiences. / िक्ता की भािनाओोंको स्वीकार करतेहुए और उनके अनुभिोोंको मान्यता वदखाते हुए सहानुभूवत वदखाएों ।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "24",
      "question": "What are five things we have in common? हममेंसेकौन सी पाोंच चीजेंवमलती हैं?",
      "options": [
        "We both enjoy traveling and exploring new places. / हम दोनोंयात्रा का आनोंद लेतेहैंऔर नए स्थानोंकी खोज करनेका आनोंद लेतेहैं।",
        "We share a love for outdoor activities like hiking and camping. / हम दोनोंहाइकिंग और कैंपिंग जैसी बाहरी गतिविधियोंकेवलए प्यार करतेहैं।",
        "Both of us enjoy watching movies and binge-watching TV shows together. / हम दोनों फिल्मेंदेखनेका आनंद लेतेहैंऔर टीवी शोजकी भारी मात्रामेंदेखनेकेआनंद मेंहैं।",
        "We have a shared passion for food and enjoy trying out new cuisines. / हमारेपास खानेके प्रति एक साझा जुनून हैऔर हम नए व्यंजन कोआनजानेकेआनंद मेंहैं।",
        "We both value spending quality time with family and friends. / हम दोनोंपरिवार और दोस्तोंकेसाथ गुणवत्ता का समय बितानेकेमहत्व कोमानतेहैं।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "25",
      "question": "In what ways has our relationship changed you? हमारेसंबंधोंनेआपको किस प्रकार बदला वदया है?",
      "options": [
        "I have become more patient and understanding. / मैंनेअवधक सोंयमी और समझदार बन गया हँ।",
        "Our relationship has made me more empathetic towards others. / हमारेसंबंधोंनेमुझे दू सरोंके प्रवत अवधक सहानुभूतपरिपणा बना वदया है।",
        "I have learned to compromise and prioritize our relationship. / मैंनेसमझौता करना और हमारेसंबंध को प्राथमिकता देना सीखा है।",
        "Our relationship has helped me become more open-minded and accepting of differences. / हमारेसंबंधोंनेमुझेअवधक खुले-मन केऔर विविभतताओंको स्वीकार करनेिाले बना वदया है।",
        "I have become more responsible and committed towards our shared goals. / मैंनेहमारे संयुक्त लक्ष्योंकेप्रति अधिक जिम्मेदार और प्रतिबद्ध बन गया हँ।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "26",
      "question": "What can I do that provides the greatest comfort and encouragement for you when you are hurt, fearful, anxious, or worried? जब आप चोट लगेहों, भयभीत, वचोंवतत या परेर्ान होों, तब मैंक्या कर सकता हँजो आपको सबसेअवधक आराम और प्रोत्साहन प्रदान करता है?",
      "options": [
        "Offer words of reassurance and listen attentively to your concerns. / आपकेवचोंताओोंको सुनकर आत्मविश्वास की र्ब्दोोंकी पेर्कर् करेंऔर सोंिेदनर्ीलता सेसुनें।",
        "Provide physical affection like hugs or holding hands. / आवलोंगन या हाथ पकड़नेजैसी र्ारीररक स्पर्पकी पेर्कर् करें।",
        "Help distract me with activities or hobbies we enjoy together. / हम दोनोंवमलकर आनंद लेने िालेकायों या र्ाकोोंसेमुझेविचवलत करनेमेंमदद करें।",
        "Simply be there for me, offering your presence and support without judgment. / बस मेरेवलए िहाँहोों, अपनी मौजूदगी और समथपन प्रदान करें, वबना वनणपय वकए।",
        "Encourage me to express my feelings and emotions freely. / मुझेअपनेभावनाओंऔर भावनाओंको स्वतोंत्रता सेव्यक्त करनेकेवलए प्रोत्सावहत करें।",
        "None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "27",
      "question": "How would you rank all the priorities in your life: work, family, spouse, friends, hobbies, etc.? Does your ranking reflect the amount of time you spend on each? आप अपनेजीिन मेंसभी प्राथ्मकताओोंको कैसेरैंक करेंगे: काम, पररिार, पवत/पत्नी, दोस्त, र्ौक, आवद? क्या आपका रैंवकों ग प्रत्येक पररयोजना पर खचपवकए गए समय को दर्ापता है?",
      "options": [
        "I. Family, spouse, work, friends, hobbies / पररिार, पवत/पत्नी, काम, दोस्त, र्ौक",
        "II. Work, family, spouse, friends, hobbies / काम, पररिार, पवत/पत्नी, दोस्त, र्ौक",
        "III. Spouse, family, work, friends, hobbies / पवत/पत्नी, पररिार, काम, दोस्त, र्ौक",
        "IV. Friends, family, work, spouse, hobbies / दोस्त, पररिार, काम, पवत/पत्नी, र्ौक",
        "V. Hobbies, friends, family, work, spouse / र्ौक, दोस्त, पररिार, काम, पवत/पत्नी",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "28",
      "question": "What boundaries can we make that will protect us from spending too much time on our phones? हम वकन सीमाओंको बना सकतेहैंजो हमेंअपनेिोन पर बहुत अवधक समय वबतानेसे बचाएों गे?",
      "options": [
        "I. Setting specific time limits for phone usage each day. / प्रवतवदन िोन उपयोग केवलए विवर्ि समय सीमाएँवनधापररत करना।",
        "II. Implementing phone-free zones or times during family activities. / पररिार की गवतविवधयोोंके दौरान िोन-मुक्त क्षेत्रोोंया समयोोंका अमल करना।",
        "III. Keeping phones out of the bedroom to promote better sleep hygiene. / बेहतर नीोंद स्वास्थ्यको बढािा देनेकेवलए बे रूम सेिोन बाहर रखना।",
        "IV. Designating certain times for phone use and sticking to them strictly. / िोन उपयोग केवलए वनवित समयोोंको वनधापररत करना और सख्ती सेउनका पालन करना।",
        "V. Encouraging offline activities and hobbies to reduce dependence on phones. / िोनोोंपर आधाररतता को कम करनेकेवलए ऑफलाइन गवतविवधयोंऔर र्ौकोोंको प्रोत्सावहत करना।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "29",
      "question": "What does self-care look like to you? How well do you implement self-care into your life? आपकेवलए सेल्फ-के यर का अथपक्या है? आप अपनेजीिन मेंसेल्फ-के यर को वकतनी अच्छेसेलागू करतेहैं?",
      "options": [
        "I. Taking time for relaxation and mindfulness practices like meditation or yoga. / ध्यान या योग जैसी ध्यान और क्तस्थवतमन की अभ्यास केवलए समय वनकालना।",
        "II. Prioritizing physical health through regular exercise and nutritious eating habits. / वनयवमत व्यायाम और पौविक खानेकी आदतोोंके माध्यम सेर्ारीररक स्वास्थ्यको प्राथवमकता देना।",
        "III. Engaging in activities that bring joy and fulfillment, such as hobbies or spending time with loved ones. / खुर्ी और आत्मसोंतोष लानेिाली गवतविवधयोोंमेंर्ावमल होना, जैसेवक र्ौक या वप्रयजनोंके साथ समय वबताना।",
        "IV. Setting boundaries and saying no when necessary to avoid burnout and overwhelm. / बनपआउट और अत्यवधक भार को टालनेकेवलए आिश्यक होनेपर सीमाओोंको वनधापररत करना और नहीोंकहना।",
        "V. Practicing self-compassion and forgiveness, accepting oneself with all flaws and imperfections. / आत्म-दया और क्षमा का अभ्यास करना, सभी दोषोोंऔर अविश्वासोंके साथ अपनेको स्वीकार करना।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "30",
      "question": "What are healthy boundaries we need to put into play on having friends of the opposite sex? हमेंविपरीत वलोंग के दोस्तोंको लेकर खेलनेकेवलए क्या स्वस्थ सीमाएँहमेंलागूकरनी चावहए?",
      "options": [
        "I. Communicating openly and transparently with our partner about our friendships. / हमारे साथी को हमारी वमत्रता के बारेमेंस्पि और पारदर्ी रूप सेसोंचार करना।",
        "II. Setting clear boundaries on physical and emotional intimacy with friends of the opposite sex. / विपरीत वलोंग के दोस्तोंके साथ र्ारीररक और भािनात्मक सोंबोंध मेंस्पि सीमाएँ वनधापररत करना।",
        "III. Avoiding spending excessive one-on-one time with opposite-sex friends. / विपरीत वलोंग के दोस्तोंके साथ अत्यवधक एक-से-एक समय वबतानेसेबचना।",
        "IV. Prioritizing the needs and feelings of our partner over those of opposite-sex friends. / विपरीत वलोंग के दोस्तोंके नी टस और भािनाओोंके बजाय हमारेसाथी की जरूरतोंऔर भािनाओों को प्राथवमकता देना।",
        "V. Being transparent about interactions and conversations with opposite-sex friends. / विपरीत वलोंग के दोस्तोंके साथ इोंटरैक्शन और िातापओोंके बारेमेंपारदर्ी होना।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "31",
      "question": "What topics do we deal with as a couple that we will not discuss with our parents and family members? हम जो विषय जोड़तेहैंजो हम हमारेमाता-वपता और पररिार के सदस्योोंसेनहीोंचचापकरेंगे, िेक्या हैं?",
      "options": [
        "I. Intimate details about our relationship and sex life. / हमारेररश्तेऔर सेक्स जीिन के बारेमेंआोंतररक वििरण।",
        "II. Financial matters and budgeting decisions. / वित्तीय मामलेऔर बजट वनणपय।",
        "III. Conflicts or disagreements we may have with each other. / हमारी एक-दू सरेके साथ वििाद या असहमवत।",
        "IV. Plans or discussions about starting a family or having children. / पररिार बनानेया बच्ों को जन्म देनेके बारेमेंयोजनाएँया चचापएँ ।",
        "V. Personal struggles or mental health issues we may be facing. / हम सामना कर सकतेहैं व्यक्तक्तगत सोंघषपया मानवसक स्वास्थ्यसमस्याओोंके साथ।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "32",
      "question": "Do you think we are spiritually compatible? Explain.",
      "options": [
        "I. Yes, we share similar beliefs and values that align with our spiritual practices.",
        "II. No, we have different spiritual perspectives and practices that may not align well.",
        "III. It's hard to say, as spirituality is a personal journey and can be interpreted differently by each individual.",
        "IV. We haven't discussed spirituality much, so it's difficult to determine our compatibility in this aspect.",
        "V. Our spiritual compatibility may evolve over time as we explore and understand each other's beliefs and practices better.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "33",
      "question": "What are your thoughts about prayer and meditation?",
      "options": [
        "I. I find prayer and meditation to be essential for spiritual growth and inner peace.",
        "II. I believe in the power of prayer but haven't explored meditation much.",
        "III. I prefer meditation over prayer as a means of relaxation and self-reflection.",
        "IV. Both prayer and meditation are important for connecting with oneself and the divine.",
        "V. I haven't really thought much about prayer and meditation.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "34",
      "question": "How important is spirituality to you?",
      "options": [
        "I. It is the cornerstone of my life, guiding my decisions and providing me with inner strength.",
        "II. Spirituality plays a significant role in my life, helping me find meaning and purpose.",
        "III. It is somewhat important to me, but I don't prioritize it over other aspects of my life.",
        "IV. I haven't really thought much about spirituality.",
        "V. It's not important to me at all; I focus on practical matters and tangible outcomes.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "35",
      "question": "How important is spirituality to you? Are you an atheist or theist?",
      "options": [
        "I. Spirituality is very important to me, and I am a theist.",
        "II. Spirituality is somewhat important to me, but I am undecided about my beliefs.",
        "III. Spirituality is not important to me, and I am an atheist.",
        "IV. I haven't thought much about spirituality, and I am still exploring my beliefs.",
        "V. Spirituality is important to me, but I don't associate with any particular religious beliefs.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "36",
      "question": "What should we do if we end up having mismatched sex drives at some point in our marriage?",
      "options": [
        "I. Communicate openly and honestly with your partner about your needs and desires.",
        "II. Seek professional help or counseling to address underlying issues causing the mismatch.",
        "III. Experiment with different techniques and activities to enhance intimacy and pleasure for both partners.",
        "IV. Be patient and understanding, recognizing that fluctuations in sex drive are normal and can be worked through together.",
        "V. Explore alternative forms of physical and emotional intimacy that can fulfill both partners' needs.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "37",
      "question": "What are ways we can make sure our sexual intimacy stays a priority once we’re married?",
      "options": [
        "I. Schedule regular date nights to keep the romance alive.",
        "II. Communicate openly about desires, fantasies, and concerns.",
        "III. Prioritize physical affection and touch outside of sexual encounters.",
        "IV. Explore new techniques and positions to keep things exciting.",
        "V. Make time for regular check-ins to discuss satisfaction and address any issues.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "38",
      "question": "Do you consider watching pornography cheating? Why or why not?",
      "options": [
        "I. Yes, because it can create unrealistic expectations and emotional distance in a relationship.",
        "II. No, as long as it doesn't interfere with the emotional or physical intimacy in the relationship.",
        "III. Yes, because it can lead to secrecy and betrayal of trust in a relationship.",
        "IV. No, as long as both partners are comfortable with it and it doesn't replace physical intimacy.",
        "V. It depends on the boundaries and agreements set by the partners in their relationship.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "39",
      "question": "When one of us is not in the mood for sex, what should be communicated so neither of us feels rejected?",
      "options": [
        "I. Express understanding and reassurance that it's okay and doesn't reflect on the relationship.",
        "II. Discuss other ways to feel connected and intimate without engaging in sexual activity.",
        "III. Reiterate love and affection for each other regardless of the current situation.",
        "IV. Share openly about personal feelings and reasons for not being in the mood.",
        "V. Create a safe space for honest communication without fear of judgment or rejection.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "40",
      "question": "Are you comfortable discussing our sexual likes and dislikes? Why or why not?",
      "options": [
        "I. Yes, because open communication strengthens our relationship and enhances intimacy.",
        "II. No, because I feel uncomfortable discussing such personal matters.",
        "III. Yes, because it helps us understand each other's preferences and strengthen our sexual connection.",
        "IV. No, because I fear it might lead to conflict or embarrassment.",
        "V. Yes, but only if there's mutual trust and respect in our relationship.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "41",
      "question": "Who taught you about sex? Was it helpful or a hindrance?",
      "options": [
        "I. My parents provided guidance and information about sex education.",
        "II. I learned from friends or peers through informal conversations.",
        "III. School or educational institutions provided sex education classes.",
        "IV. I learned through books, online resources, or media.",
        "V. I didn't receive any formal education about sex and learned through personal experiences.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "42",
      "question": "How many sexual partners have you had in the past? Do you feel like these experiences will hinder or help us in our sex life together?",
      "options": [
        "I. I prefer not to disclose my past sexual partners as it's a private matter.",
        "II. I've had a few sexual partners in the past, but I believe our experiences together will enhance our sex life.",
        "III. My past sexual experiences have taught me valuable lessons that can benefit our sex life.",
        "IV. I've had multiple sexual partners in the past, but I believe our commitment to each other will strengthen our bond and sex life.",
        "V. I've had no sexual partners before, so I'm eager to explore and learn together with you.",
        "VI. None of the above",
        "VII. Other"
      ],
      "selectedAnswer": null
    },
    {
      "id": "43",
      "question": "What did your past relationships teach you about love, trust, and commitment? आपकेवपछलेसोंबोंधोोंनेआपको प्यार, विश्वास और प्रवतबद्धता के बारेमेंक्या वसखाया?",
      "options": [
        "I. My past relationships taught me the importance of communication and honesty in building trust. / मेरेवपछलेसोंबोंधोोंनेमुझेविश्वास बनानेमेंसोंचार और ईमानदारी के महत्व को वसखाया।",
        "II. I learned that love requires effort, compromise, and understanding from both partners. / मैंनेसीखा वक प्यार केवलए दोनोोंसावथयोोंसेप्रयास, समझौता, और समझ की आिश्यकता होती है।",
        "III. Trust is fragile and needs to be earned through consistent actions and reliability. / विश्वास नाजुक हैऔर वनयवमत वियाओोंऔर विश्वसनीयता केमाध्यम सेकमाया जाना चावहए।",
        "IV. My past relationships taught me the importance of commitment and staying true to promises. / मेरेवपछलेसोंबोंधोोंनेमुझेप्रवतबद्धता और िादोोंके प्रवत सच्ाई रहनेकेमहत्व को वसखाया।",
        "V. Love is about acceptance, forgiveness, and growing together through challenges. / प्यार स्वीकृ वत, क्षमा, और चुनौवतयोोंके माध्यम सेसाथ मेंबढनेके बारेमेंहै।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "44",
      "question": "Were any of your past relationships physically or emotionally abusive? Explain. क्या आपके वपछलेसोंबोंधोोंमेंवकसी भी र्ारीररक या भािनात्मक वहोंसात्मकता थी? व्याख्या करें।",
      "options": [
        "I. Yes, I experienced physical abuse in one or more of my past relationships, which left me traumatized. / हाों, मैंनेअपनेवपछलेसोंबोंधोोंमेंएक या एक सेअवधक सोंबोंधोोंमेंर्ारीररक वहोंसा का अनुभि वकया, वजससेमैंआत्मा वनजपन हो गया।",
        "II. No, none of my past relationships involved physical or emotional abuse. / नहीों, मेरे वपछलेसोंबोंधोोंमेंवकसी भी र्ारीररक या भािनात्मक वहोंसा का सक्तम्मवलत नहीोंथा।",
        "III. Yes, I faced emotional abuse in one or more of my past relationships, which affected my mental well-being. / हाों, मैंनेअपनेवपछलेसोंबोंधोोंमेंएक या एक सेअवधक सोंबोंधोोंमें भािनात्मक वहोंसा का सामना वकया, वजससेमेरेमानवसक स्वास्थ्य प्रभावित हुआ।",
        "IV. No, I haven't experienced any form of abuse in my past relationships. / नहीों, मुझेअपनेवपछलेसोंबोंधोोंमेंवकसी भी प्रकार की वहोंसा का अनुभि नहीोंहुआ है।",
        "V. I prefer not to discuss the details of past abuse in my relationships. / मैंअपनेसोंबोंधोोंमें वपछली वहोंसा के वििरणोोंपर चचापकरना पसोंद नहीोंकरता हों।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "45",
      "question": "Were there any red flags you saw in past relationships that you ignored? Why do you think that was? क्या आपनेवपछलेसोंबोंधोोंमेंकोई लाल झों ेदेखेवजन्हेंआपनेनजरअोंदाज वकया? आपको क्योों लगता हैवक ऐसा हुआ?",
      "options": [
        "I. Yes, I noticed certain warning signs but chose to overlook them because I was deeply invested in the relationship. / हाों, मैंनेकु छ चेतािनी सोंके तोोंको ध्यान मेंरखा लेवकन मैंने उन्हेंनजरअोंदाज वकया क्योंवक मैंसोंबोंध मेंगहराई सेवलप्त था।",
        "II. No, I was vigilant about red flags and addressed them promptly to avoid potential problems. / नहीों, मैंलाल झों ोोंके प्रवत सतकप था और सोंभावित समस्याओोंसेबचनेकेवलए उन्हेंतुरोंत सोंबोवधत वकया।",
        "III. Yes, I ignored red flags because I believed I could change the person or situation with time. / हाों, मैंनेलाल झों ेको नजरअोंदाज वकया क्योंवक मुझेलगा वक मैंसमय के साथ व्यक्तक्त या क्तस्थवत को बदल सकता हँ।",
        "IV. No, I didn't notice any red flags in my past relationships. / नहीों, मुझेअपनेवपछलेसोंबोंधोों मेंकोई लाल झों ेनहीोंवदखे।",
        "V. I saw red flags but dismissed them because I feared being alone or starting over. / मैंनेलाल झों ेदेखेलेवकन मैंनेउन्हेंनकारा क्योंवक मैंअके लेपन से रता था या विर सेर्ुरू करनेसे।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "46",
      "question": "What were some of the mistakes you made in past relationships with your boyfriend/girlfriend? कु छ ऐसी गलवतयाँक्या थीोंजो आपनेवपछलेसोंबोंधोोंमेंअपनेबॉयफ्रें /गलपफ्रें के साथ की थीों?",
      "options": [
        "I. I didn't communicate my needs and feelings effectively, leading to misunderstandings. / मैंने अपनी आिश्यकताओोंऔर भािनाओोंको प्रभािी ढोंग सेसोंचाररत नहीोंवकया, वजससेगलतिहवमयाँहुईों।",
        "II. I was too possessive or jealous, which strained the relationship. / मैंबहुत अवधक स्वावमत्वी या ईष्यापपूणपथा, वजससेसोंबोंध मेंतनाि आया।",
        "III. I didn't give enough space or freedom to my partner, suffocating the relationship. / मैंनेअपने साथी को पयापप्त जगह या स्वतोंत्रता नहीोंदी, वजससेसोंबोंध मेंदबाि बढ गया।",
        "IV. I was dishonest or unfaithful, breaking the trust in the relationship. / मैंबेईमान या अविश्वासी था, वजससेसोंबोंध मेंविश्वास टू ट गया।",
        "V. I didn't prioritize the relationship and neglected my partner's needs, causing resentment. / मैंनेसोंबोंध को प्राथवमकता नहीोंदी और अपनेसाथी की आिश्यकताओोंको उपेवक्षत वकया, वजससे नाराजगी हुई।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "47",
      "question": "One day in our future, would you want children? And if so, how many? वकसी वदन हमारेभविष्य में, क्या आप बच्ोंकी इच्छा रखेंगे? और यवद हाों, तो वकतने?",
      "options": [
        "I. Yes, I want children, and I would like to have one child. / हाों, मुझेबच्ेचावहए, और मैंएक बच्ा पैदा करना चाहँगा।",
        "II. Yes, I want children, and I would like to have two children. / हाों, मुझे बच्ेचावहए, और मैंदो बच्ेपैदा करना चाहोंगी।",
        "III. No, I don't want children in the future. / नहीों, मैंभविष्य मेंबच्ोंकी इच्छा नहीोंरखता हँ।",
        "IV. I'm undecided about having children in the future. / मैंभविष्य मेंबच्ोंको लेकर अवनणपवयत हँ।",
        "V. Yes, I want children, but I haven't decided on the number yet. / हाों, मुझेबच्ेचावहए, लेवकन मैंने अभी तक सोंख्या पर वनणपय नहीोंवकया है।",
        "VI. I'm open to having children if it's meant to be, but I haven't thought about the number. / यवद यह होना चावहए, तो मैंबच्ोंको लेकर खुला हँ, लेवकन मैंनेसोंख्या पर विचार नहीोंवकया है।",
        "VII. None of the above / उपरोक्त वकसी भी विकल्प को नहीों",
        "VIII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "48",
      "question": "If we are unable to get pregnant naturally, would you be open to fertility treatments such as IVF, artificial insemination, and surrogacy? Explain. यवद हम प्राकृ वतक रूप सेगभपधारण नहींकर सकते, तो क्या आप IVF, कृ वत्रम प्रसि, और प्रतीत प्रेषण जैसेप्रजनन उपचारोंकेवलए खुलेहोंगे? स्पिकरें।",
      "options": [
        "I. Yes, I am open to exploring fertility treatments if necessary to start a family. / हाों, मैंपररिार र्ुरू करनेके वलए आिश्यकता पड़नेपर प्रजनन उपचारोोंकी जाोंच करनेके वलए खुला हँ।",
        "II. No, I would prefer to explore other options or consider adoption instead. / नहीं, मैं अन्य विकल्पोोंकी जाोंच करना पसोंद करूँ गा या बजाय इसके गोद लेना की विचार करूँ गा।",
        "III. I am unsure about fertility treatments and would need more information before making a decision. / मैं प्रजनन उपचारोंके बारेमें अवनवित हों और वनणपय लेनेसेपहले अवधक जानकारी की आिश्यकता होगी।",
        "IV. It would depend on various factors such as cost, success rates, and ethical considerations. / यह लागत, सिलता दर, और नैवतक सम्मवतयोोंजैसेविवभन्न कारकोोंपर वनभपर करेगा।",
        "V. I haven't thought about fertility treatments yet and would need time to consider all options. / मैंनेअभी तक प्रजनन उपचारोंके बारेमें विचार नहींवकया हैऔर सभी विकल्पोों को विचार करनेका समय चावहए।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीं",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "49",
      "question": "Once we have kids, is it important to live near family? Why or why not? जब हमारेपास बच्े होोंगे, क्या पररिार के पास रहना महत्वपूणपहै? क्योोंया क्योंनहीं?",
      "options": [
        "I. Yes, living near family provides a support system and helps in childcare. / हाों, पररिार के पास रहनेसेसहारा वमलता हैऔर बच्ोंकी देखभाल मेंमदद वमलती है।",
        "II. No, it's not necessary as we can create our own support network wherever we live. / नहीं, यह आिश्यक नहींहैक्योंवक हम जहाोंभी रहतेहैं, हम अपनी खुद की सहायता नेटिकप बना सकतेहैं।",
        "III. It depends on the relationship with our family and our individual circumstances. / यह हमारेपररिार के साथ सोंबोंध और हमारी व्यक्तक्तगत पररक्तस्थवतयोोंपर वनभपर करेगा।",
        "IV. Living near family can be beneficial for cultural and traditional reasons. / पररिार के पास रहना साोंस्कृ वतक और पारोंपररक कारणोोंकेवलए िायदेमोंद हो सकता है।",
        "V. It's a personal choice and varies from family to family. / यह व्यक्तक्तगत चुनौती हैऔर पररिार सेपररिार अलग होता है।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीं",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    },
    {
      "id": "50",
      "question": "Do you anticipate raising our children the same way you were raised, completely differently from the way you were raised, or a mixture of both? आपका आर्ा हैवक आप हमारेबच्ोोंको िैसे ही पालन-पोषण करेंगेजैसा वक आपको पाला-पोषा गया था, पूरी तरह सेवभन्नता सेया दोनोंका वमश्रण?",
      "options": [
        "I. I anticipate raising our children the same way I was raised, following similar traditions and values. / मैंआर्ा करता हों वक हमारेबच्ोोंको मैंिैसेही पालन-पोषण करूों गा जैसेमुझेपाला-पोषा गया था, समान परोंपराओोंऔर मूल्योोंका पालन करतेहुए।",
        "II. I plan to raise our children completely differently, incorporating new approaches and modern parenting techniques. / मैंहमारेबच्ोोंको पूरी तरह सेवभन्नता सेपालन करनेकी योजना करता हों, नए दृविकोण और आधुवनक पेरेंवटोंग तकनीकोंको र्ावमल करतेहुए।",
        "III. I envision a mixture of both, taking the best from my upbringing while also adapting to new circumstances and knowledge. / मैंदोनोोंका वमश्रण देखता हँ, अपनेपालन के सिपश्रेष्ठ सेलेकर नए पररक्तस्थवतयोोंऔर ज्ञान के अनुसार समायोवजत होता हँ।",
        "IV. My approach to parenting will depend on the individual needs and personalities of our children. / मेरा पेरेंवटोंग के प्रवत दृविकोण हमारेबच्ोोंकी व्यक्तक्तगत आिश्यकताओोंऔर व्यक्तक्तत्व पर वनभपर करेगा।",
        "V. I haven't thought about it yet and would need to discuss it further with you. / मैंनेइस पर अभी तक विचार नहींवकया हैऔर मुझेआपके साथ और चचापकरनी होगी।",
        "VI. None of the above / उपरोक्त वकसी भी विकल्प को नहीं",
        "VII. Other / अन्य"
      ],
      "selectedAnswer": null
    }
    // Add more questions as needed
  ]);

  const handleAnswerSelect = (questionId, selectedAnswer, question) => {
    console.log("quest id log", questionId)
    console.log("sel ans log", selectedAnswer)
    console.log("ques log", question)
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId ? { ...question, selectedAnswer } : question
      )
    );
  };


  const renderQuestions = () => {
    return questions.map((question) => (
      <div key={question.id} className="question-container">
        <h3>{`Question ${question.id}: ${question.question}`}</h3>
        <ul>
          {question.options.map((option, index) => (
            <li key={index}>
              <label>
                <input
                  type="radio"
                  name={`question_${question.id}`}
                  value={option}
                  checked={question.selectedAnswer === option}
                  onChange={() => handleAnswerSelect(question.id, option, question.question)}
                />
                {option}
              </label>
            </li>
          ))}
        </ul>
      </div>
    ));
  };


  return (
    <div className="cen-div">

      <div className="center-div">
        <div className="centered-div-1">

          <div>

            <div>
              <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
              <h2 style={{ textAlign: 'center' }}>Questions</h2>
            </div>




            <div className="psychological-test-container">
              <h4>1. Part-i: Childhood & Family (Four Questions )/ भाग-ए: बचपन और पररिार (चार प्रश्)</h4>
              {renderQuestions()}
              <div className='d-flex justify-content-center align-items-center'>
                <button onClick={handleShowModal} type="button" class="btn btn-success">
                  Submit</button>
              </div>
            </div>

          </div>


        </div>

      </div>


      <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


    </div>
  )
}

export default Fiftyquestions


