import React, { useEffect, useState } from 'react'
import './QuestionAnswer.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import * as environment from '../../coreservices/environment'

const Personalitytest = () => {

    const searchParams = new URLSearchParams(window?.location?.search);
    const status = searchParams?.get('status');


    // Your logic for handling failure status
    console.log('Status is', status);



    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const handleRedirectProfile = () => {
        history.push('/psychologicaltests');
    }
    const handleShowModal = async () => {
        calculateTotalMarks()
        getAnswerReport()
        setShowModal(true);
        handleStopTimer();

        console.log('====================================');
        console.log("seconds_log", seconds)
        console.log("localStorage.getItem_userId_log", localStorage.getItem("userId"));
        console.log("questionss_log", questions);
        console.log('====================================');





        let CreatePersonalitytestDto = {
            userId: localStorage.getItem("userId"),
            PersonalitytestId: '0',
            questions: "",
            answers: "",
            // questionsanswers: questions.map(question => { questionId: question.id; question: question.question; answer: question.selectedAnswer }),
            questionsanswers: questions,

            timer: seconds,
            timestamp: new Date(),
            testName: "personality_test",
            testResult: getAnswerReport(),
            marks: calculateTotalMarks()
        }

        // let Questionsanswers = {
        //     questionId: questions.id,
        //     question: questions.question,
        //     answer: questions.selectedAnswer,
        // }
        console.log('====================================');
        console.log("CreatePersonalitytestDto", CreatePersonalitytestDto);
        console.log('====================================');

        try {
            const response = await fetch(`${environment.apiUrl}/personalitytest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(CreatePersonalitytestDto),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            console.log("responseData", responseData);
        } catch (error) {
            console.error('Error during POST request:', error);
        }


    }
    const handleCloseModal = () => setShowModal(false);
    const ThankYouModal = ({ showModal, handleClose }) => {
        return (
            <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="thank-you-pop">
                        <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
                        <h1>Thank You!</h1>
                        <p>Answer Report: Your Personality test result is {getAnswerReport()} towards life.</p>
                        <p>Your submission is received and saved in your profile</p>
                        <div className='d-flex justify-content-center align-items-center'>

                            <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
                                Ok</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [recordedTime, setRecordedTime] = useState(null);
    const handleStopTimer = () => {
        setIsRunning(false);
        setRecordedTime(seconds);
    };
    const Timer = () => {

        useEffect(() => {
            let intervalId;

            if (isRunning) {
                intervalId = setInterval(() => {
                    setSeconds((prevSeconds) => prevSeconds + 1);
                }, 1000);
            }

            // Cleanup function to clear the interval when the component unmounts or timer is stopped
            return () => clearInterval(intervalId);
        }, [isRunning]);

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return (
            <div>
                <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
                {recordedTime !== null && (
                    <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
                )}
                {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
                <div style={{ textAlign: 'left' }}>
                    This is a personality test, it will help you understand why you act the way that you do and how your
                    personality is structured.
                    It can be helpful in identifying leisure activities that align with personality, identifying personality
                    types that might be influencing behaviour, & monitoring changes in personality type.
                    It will measured the following five types of trait : Extroversion, Agreeableness, Conscientiousness,
                    Neuroticism, and Openness to Experience.
                </div>
            </div>
        );
    };



    const [questions, setQuestions] = useState([
        {
            id: '1',
            question: '. I am the life of the party ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '2',
            question: ' I feel little concern for others ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '3',
            question: 'I am always prepared ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '4',
            question: ' I get stressed out easily ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '5',
            question: 'I have a rich vocabulary ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '6',
            question: 'I do not talk a lot ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '7',
            question: 'I am interested in people ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '8',
            question: 'I leave my belongings around ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '9',
            question: 'I am relaxed most of the time ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '10',
            question: 'I have difficulty understanding abstract ideas ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '11',
            question: 'I feel comfortable around people ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '12',
            question: 'I insult people ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '13',
            question: 'I pay attention to details ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '14',
            question: 'I worry about things ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '15',
            question: 'I have a vivid imagination ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },

        {
            id: '16',
            question: 'I keep in the background ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '17',
            question: 'I sympathize with others feelings ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '18',
            question: 'I make a mess of things ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '19',
            question: 'I seldom feel blue ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '20',
            question: 'I am not interested in abstract ideas ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '21',
            question: 'I start conversations ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '22',
            question: 'I am not interested in other peoples problems ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '23',
            question: 'I get chores done right away ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '24',
            question: 'I am easily disturbed ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '25',
            question: 'I have excellent ideas ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '26',
            question: 'I have little to say ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '27',
            question: 'I have a soft heart ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '28',
            question: 'I often forget to put things back in their proper place ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '29',
            question: 'I get upset easily ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '30',
            question: 'I do not have a good imagination ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '31',
            question: 'I talk to a lot of different people at parties ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '32',
            question: 'I am not really interested in others ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '33',
            question: 'I like order ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '34',
            question: 'I change my mood a lot ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '35',
            question: 'I am quick to understand things ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '36',
            question: 'I do not like to draw attention to myself ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '37',
            question: 'I take time out for others ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '38',
            question: 'I shirk my duties ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '39',
            question: 'I have frequent mood swings ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '40',
            question: 'I use difficult words ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '41',
            question: 'I do not mind being the center of attention ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '42',
            question: 'I feel others\' emotions ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '43',
            question: 'I follow a schedule ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '44',
            question: 'I get irritated easily ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '45',
            question: 'I spend time reflecting on things ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '46',
            question: 'I am quiet around strangers ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '47',
            question: 'I make people feel at ease ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '48',
            question: 'I am exacting in my work ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '49',
            question: 'I often feel blue ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        },
        {
            id: '50',
            question: 'I am full of ideas ?',
            options: [
                { label: 'Agree', value: 5 },
                { label: 'Slightly Agree', value: 4 },
                { label: 'Neutral', value: 3 },
                { label: 'Slightly Disagree', value: 2 },
                { label: 'Disagree', value: 1 }
            ],
            selectedOption: null,
        }

        // Add more questions as needed
    ]);




    // const handleAnswerSelect = (questionId, selectedAnswer, question) => {
    //     console.log("quest id log", questionId)
    //     console.log("sel ans log", selectedAnswer)
    //     console.log("ques log", question)
    //     setQuestions((prevQuestions) =>
    //         prevQuestions.map((question) =>
    //             question.id === questionId ? { ...question, selectedAnswer } : question
    //         )
    //     );
    // };
    const handleAnswerSelect = (questionId, selectedValue) => {
        setQuestions(prevQuestions =>
            prevQuestions.map(question =>
                question.id === questionId
                    ? { ...question, selectedOption: selectedValue }
                    : question
            )
        );
    };


    const calculateTotalMarks = () => {
        let totalMarks = 0;
        questions.forEach(question => {
            totalMarks += question.selectedOption;
        });

        return totalMarks;
    };

    const getAnswerReport = () => {

        const totalMarks = calculateTotalMarks();
        if (totalMarks <= 250 && totalMarks >= 210) {
            return 'Openness to Experience (O)';
        } else if (totalMarks == 200) {
            return 'Neuroticism (N)';
        } else if (totalMarks <= 190 && totalMarks >= 150) {
            return 'Conscientiousness (C)';
        } else if (totalMarks <= 140 && totalMarks >= 100) {
            return 'Agreeableness (A)';
        } else {
            return 'Extroversion (E)';
        }
    };





    // const renderQuestions = () => {
    //     return questions.map((question) => (
    //         <div key={question.id} className="question-container">
    //             <h3>{`Question ${question.id}: ${question.question}`}</h3>
    //             <ul>
    //                 {question.options.map((option, index) => (
    //                     <li key={index}>
    //                         <label>
    //                             <input
    //                                 type="radio"
    //                                 name={`question_${question.id}`}
    //                                 value={option.value}
    //                                 checked={question.selectedAnswer === option.value}
    //                                 onChange={() => handleAnswerSelect(question.id, option.value)}
    //                             />
    //                             {option}
    //                         </label>
    //                     </li>
    //                 ))}
    //             </ul>
    //         </div>
    //     ));
    // };


    return (
        <div className="cen-div">

            <div className="center-div">
                <div className="centered-div-1">

                    <div>

                        <div>
                            <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
                            <h2 style={{ textAlign: 'center' }}>Questions</h2>
                        </div>




                        <div className="psychological-test-container">
                            <h1>Personality Test</h1>
                            {/* {renderQuestions()} */}

                            {questions.map(question => (
                                <div key={question.id}>
                                    <h3 style={{ color: 'black' }}>{`Question ${question.id}: ${question.question}`}</h3>
                                    {question.options.map(option => (
                                        <label key={option.label}>
                                            <input
                                                type="radio"
                                                name={`question_${question.id}`}
                                                value={option.value}
                                                checked={question.selectedOption === option.value}
                                                onChange={() => handleAnswerSelect(question.id, option.value)}
                                            />
                                            {option.label}
                                        </label>
                                    ))}
                                </div>
                            ))}

                            {/* {questions.map(question => (
                                    <div key={question.id}>
                                        <h3 style={{ color: 'black' }}>{`Question ${question.id}: ${question.question}`}</h3>
                                        {question.options.map(option => (
                                            <label key={option.label}>
                                                <input
                                                    type="radio"
                                                    name={`question_${question.id}`}
                                                    value={option.value}
                                                    checked={question.selectedAnswer === option.value}
                                                    onChange={() => handleAnswerSelect(question.id, option.value)}
                                                />
                                                {option.label}
                                            </label>
                                        ))}
                                    </div>
                                ))}

                            {questions.map((question) => (
                                    <div key={question.id} className="question-container">
                                        <h3>{`Question ${question.id}: ${question.question}`}</h3>
                                        <ul>
                                            {question.options.map((option, index) => (
                                                <li key={index}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`question_${question.id}`}
                                                            value={option.value}
                                                            checked={question.selectedAnswer === option.value}
                                                            onChange={() => handleAnswerSelect(question.id, option.value)}
                                                        />
                                                        {option}
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                            ))} */}
                            <div className='d-flex justify-content-center align-items-center'>

                                <button onClick={handleShowModal} type="button" class="btn btn-success">
                                    Submit</button>
                            </div>
                        </div>

                    </div>


                </div>

            </div>


            <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


        </div>
    )
}

export default Personalitytest


