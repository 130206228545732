// import React, { useEffect, useState } from 'react'
// import './QuestionAnswer.css';
// import { Modal } from 'react-bootstrap';
// import { useHistory } from "react-router-dom";

// const Hundredquestiontest = () => {
//     const [showModal, setShowModal] = useState(false);
//     const history = useHistory();
//     const handleRedirectProfile = () => {
//         history.push('/psychologicaltests');
//     }
//     const handleShowModal = () => {
//         setShowModal(true);
//         handleStopTimer();
//     }
//     const handleCloseModal = () => setShowModal(false);
//     const ThankYouModal = ({ showModal, handleClose }) => {
//         return (
//             <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
//                 <Modal.Header closeButton>
//                     <Modal.Title>Thank You!</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="thank-you-pop">
//                         <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
//                         <h1>Thank You!</h1>
//                         <p>Your submission is received and saved in your profile</p>
//                         <div className='d-flex justify-content-center align-items-center'>

//                             <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
//                                 Ok</button>
//                         </div>
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         );
//     };
//     const [seconds, setSeconds] = useState(0);
//     const [isRunning, setIsRunning] = useState(true);
//     const [recordedTime, setRecordedTime] = useState(null);
//     const handleStopTimer = () => {
//         setIsRunning(false);
//         setRecordedTime(seconds);
//     };
//     const Timer = () => {

//         useEffect(() => {
//             let intervalId;

//             if (isRunning) {
//                 intervalId = setInterval(() => {
//                     setSeconds((prevSeconds) => prevSeconds + 1);
//                 }, 1000);
//             }

//             // Cleanup function to clear the interval when the component unmounts or timer is stopped
//             return () => clearInterval(intervalId);
//         }, [isRunning]);

//         const minutes = Math.floor(seconds / 60);
//         const remainingSeconds = seconds % 60;

//         return (
//             <div>
//                 <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
//                 {recordedTime !== null && (
//                     <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
//                 )}
//                 {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
//             </div>
//         );
//     };


//     const [questions, setQuestions] = useState([
//         {
//             id: 1,
//             question: 'How do you usually handle stress?',
//             options: ['XYZXYZ', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 2,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         {
//             id: 3,
//             question: 'How do you usually handle stress?',
//             options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 4,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         {
//             id: 5,
//             question: 'How do you usually handle stress?',
//             options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
//             selectedAnswer: null,
//         },
//         {
//             id: 6,
//             question: 'In social situations, you are...',
//             options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
//             selectedAnswer: null,
//         },
//         // Add more questions as needed
//     ]);

//     const handleAnswerSelect = (questionId, selectedAnswer, question) => {
//         console.log("quest id", questionId)
//         console.log("sel ans", selectedAnswer)
//         console.log("ques", question)
//         setQuestions((prevQuestions) =>
//             prevQuestions.map((question) =>
//                 question.id === questionId ? { ...question, selectedAnswer } : question
//             )
//         );
//     };


//     const renderQuestions = () => {
//         return questions.map((question) => (
//             <div key={question.id} className="question-container">
//                 <h3>{`Question ${question.id}: ${question.question}`}</h3>
//                 <ul>
//                     {question.options.map((option, index) => (
//                         <li key={index}>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name={`question_${question.id}`}
//                                     value={option}
//                                     checked={question.selectedAnswer === option}
//                                     onChange={() => handleAnswerSelect(question.id, option, question.question)}
//                                 />
//                                 {option}
//                             </label>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         ));
//     };


//     return (
//         <div className="cen-div">

//             <div className="center-div">
//                 <div className="centered-div-1">

//                     <div>

//                         <div>
//                             <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
//                             <h2 style={{ textAlign: 'center' }}>Questions</h2>
//                         </div>




//                         <div className="psychological-test-container">
//                             <h1>Psychological Test</h1>
//                             {renderQuestions()}
//                             <div className='d-flex justify-content-center align-items-center'>

//                                 <button onClick={handleShowModal} type="button" class="btn btn-success">
//                                     Submit</button>
//                             </div>
//                         </div>

//                     </div>


//                 </div>

//             </div>


//             <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


//         </div>
//     )
// }

// export default Hundredquestiontest



import React, { useEffect, useState } from 'react'
import './QuestionAnswer.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import * as environment from '../../coreservices/environment'

const Hundredquestiontest = () => {

    const searchParams = new URLSearchParams(window?.location?.search);
    const status = searchParams?.get('status');


    // Your logic for handling failure status
    console.log('Status is', status);



    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const handleRedirectProfile = () => {
        history.push('/psychologicaltests');
    }
    const handleShowModal = async () => {
        setShowModal(true);
        handleStopTimer();

        console.log('====================================');
        console.log("seconds_log", seconds)
        console.log("localStorage.getItem_userId_log", localStorage.getItem("userId"));
        console.log("questionss_log", questions);
        console.log('====================================');



        let CreateSatisfactiontestDto = {
            userId: localStorage.getItem("userId"),
            HundredquestiontestId: '0',
            questions: "",
            answers: "",
            // questionsanswers: questions.map(question => { questionId: question.id; question: question.question; answer: question.selectedAnswer }),
            questionsanswers: questions,

            timer: seconds,
            timestamp: new Date(),
            testName: "hundred_questions",
        }

        // let Questionsanswers = {
        //     questionId: questions.id,
        //     question: questions.question,
        //     answer: questions.selectedAnswer,
        // }
        console.log('====================================');
        console.log("CreateSatisfactiontestDto", CreateSatisfactiontestDto);
        console.log('====================================');

        try {
            const response = await fetch(`${environment.apiUrl}/hundredquestiontest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(CreateSatisfactiontestDto),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            console.log("responseData", responseData);
        } catch (error) {
            console.error('Error during POST request:', error);
        }


    }
    const handleCloseModal = () => setShowModal(false);
    const ThankYouModal = ({ showModal, handleClose }) => {
        return (
            <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="thank-you-pop">
                        <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
                        <h1>Thank You!</h1>
                        <p>Your submission is received and saved in your profile</p>
                        <div className='d-flex justify-content-center align-items-center'>

                            <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
                                Ok</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [recordedTime, setRecordedTime] = useState(null);
    const handleStopTimer = () => {
        setIsRunning(false);
        setRecordedTime(seconds);
    };
    const Timer = () => {

        useEffect(() => {
            let intervalId;

            if (isRunning) {
                intervalId = setInterval(() => {
                    setSeconds((prevSeconds) => prevSeconds + 1);
                }, 1000);
            }

            // Cleanup function to clear the interval when the component unmounts or timer is stopped
            return () => clearInterval(intervalId);
        }, [isRunning]);

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return (
            <div>
                <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
                {recordedTime !== null && (
                    <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
                )}
                {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
                <div style={{ textAlign: 'left' }}>
                    Falling in love may come naturally, but sustaining that love requires dedication and self-awareness/
                    प्यार मेंपड़ना स्वाभाविक रूप सेहो सकता है, लेवकन उस प्यार को बनाए रखनेकेवलए समपपण और आत्मजागरूकता की आिश्यकता होती है।
                    .
                    Marriage is a significant milestone in life, signifying the depth of love and commitment between
                    partners. Embarking on life's journey together with your best friend forever is an extraordinary
                    adventure/ वििाह जीिन मेंएक महत्वपूणपमील का पत्थर है, जो भागीदारोोंके बीच प्यार और प्रवतबद्धता की
                    गहराई को दर्ापता है। अपनेसबसेअच्छेदोस्त के साथ हमेर्ा केवलए जीिन की यात्रा पर वनकलना एक
                    असाधारण साहवसक कायपहै।
                    Now is the time for you and your fiancé to delve into these essential questions before your wedding
                    day. Understanding each other's perspectives on these matters will establish realistic expectations
                    and equip you for your shared future. Take turns asking these open-ended questions and prioritize
                    listening to your partner's feelings, viewpoints, and aspirations/
                    अब आपकेऔर आपके मोंगेतर केवलए अपनी र्ादी केवदन सेपहलेइन आिश्यक प्रश्ोोंपर विचार करनेका
                    समय आ गया है। इन मामलोोंपर एक-दू सरेके दृविकोण को समझनेसेयथाथपिादी अपेक्षाएों स्थावपत होोंगी और
                    आप अपनेसाझा भविष्य केवलए तैयार होोंगे। इन खुलेप्रश्ोोंको बारी-बारी सेपूछेंऔर अपनेसाथी की भािनाओों,
                    दृविकोण और आकाोंक्षाओोंको सुननेको प्राथवमकता दें।
                    You can now collect another set of 100 more detailed questions and answers for the opposing
                    partners and yourself, if you are unable to finding all the answers in the preceding 50 numerical
                    questions and answers. अगर आप पूिपित 50 सोंख्यात्मक प्रश्ोोंऔर उत्तरोोंमेंसभी उत्तर नहीोंवमल रहेहैं, तो
                    आप अब दू सरेसोंगी पार्पनसपऔर आपकेवलए और 100 और विस्तृत प्रश्ोोंऔर उत्तरोोंका सोंग्रह कर सकतेहैं।
                    We’ve chosen 10 topics that all couples need to focus on. हमने10 विषय चुनेहैंवजन पर सभी जोड़ोोंको
                    ध्यान कें वित करनेकी आिश्यकता है।
                    "You can skip any questions that you find embarrassing or prefer not to answer. Please
                    answer this question and save it in your profile. Then, ask your fiancé to answer these
                    questions and send the request to view their answers through this site only."
                    "आप वकसी भी सिाल को छोड़ सकतेहैंवजसेआप लाज़मी मानतेहैंया उसेजिाब देनेकी पसोंद नहीोंकरते।
                    कृ पया इस सिाल का उत्तर देंऔर अपनी प्रोफाइल मेंइसेसहेजें। विर, अपनेप्रेमी/प्रेवमका सेइन सिालोोंका
                    उत्तर देनेकेवलए कहेंऔर उनके उत्तरोोंको देखनेका अनुरोध के िल इस साइर् के माध्यम सेभेजें।"
                </div>

            </div>
        );
    };


    const [questions, setQuestions] = useState([
        {
            id: '1',
            question: 'Have there been times when you were uncomfortable with the way I behaved with the opposite sex?',
            options: [
                'Yes, there have been a few instances.',
                'No, I\'ve always been comfortable with your behavior.',
                'Sometimes, but it was understandable.',
                'Rarely, but it did happen.',
                'I\'d prefer not to answer this question.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '2',
            question: 'If so, when and what did I do?',
            options: [
                'Yes, it happened a while ago and you were overly friendly with someone.',
                'No, there hasn\'t been any specific incident.',
                'Yes, during a party when you were too attentive to someone else.',
                'Maybe, but I can\'t recall the exact instance.',
                'No, I haven\'t noticed anything of that sort.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '3',
            question: 'What do I do now or what could I do in the future that would make you mistrust me?',
            options: [
                'If you start hiding things from me.',
                'If you become overly secretive about your activities.',
                'If you constantly lie to me.',
                'If you betray my trust by cheating on me.',
                'If you ignore my feelings and needs repeatedly.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '4',
            question: 'Would you be comfortable transferring all your money into my bank account?',
            options: [
                'Yes, I trust you completely.',
                'No, I prefer to keep my finances separate.',
                'Maybe, but I would need some time to think about it.',
                'Yes, if we discuss and plan it together.',
                'No, I\'m uncomfortable with that idea.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '5',
            question: 'Who comes first, your spouse or your children?',
            options: [
                'My spouse comes first, always.',
                'My children come first, their needs are my priority.',
                'It depends on the situation, sometimes my spouse comes first, and sometimes my children.',
                'Both are equally important to me, I can\'t prioritize one over the other.',
                'My spouse and children are equally important, but my spouse comes first in terms of emotional support.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '6',
            question: 'Is trust automatic until something occurs that takes it away, or does it evolve over time?',
            options: [
                'Trust is automatic until something happens that breaks it.',
                'Trust evolves over time based on actions and experiences.',
                'Trust is earned gradually through consistent behavior.',
                'Trust is a combination of both, but it\'s mostly built over time.',
                'Trust depends on both automatic initial trust and evolving trust over time.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '7',
            question: 'Do you trust me with money?',
            options: [
                'Yes, I trust you completely with money.',
                'No, I prefer to handle my finances independently.',
                'I trust you, but I would prefer if we discuss financial matters together.',
                'Yes, to a certain extent, but I would like transparency in financial matters.',
                'I trust you, but I would feel more comfortable if we have a joint account.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },
        {
            id: '8',
            question: 'Is it permissible for us to open each other\'s mail?',
            options: [
                'No, it\'s a violation of privacy.',
                'Yes, we have nothing to hide from each other.',
                'No, it\'s against the law to open someone else\'s mail without permission.',
                'Yes, as long as it\'s for a valid reason and with mutual agreement.',
                'No, it\'s a breach of trust and respect for each other\'s boundaries.',
                'None of the above.',
                'Other (please specify)',
            ],
            selectedAnswer: null,
        },

        {
            "id": "9",
            "question": "Whenever we have difficult feelings about each other, should…",
            "options": [
                "Remain silent and address the issue later.",
                "Say something as soon as the difficult feelings arise.",
                "Wait a certain amount of time before raising the issue.",
                "Discuss the issue calmly and openly when both are ready.",
                "Seek the help of a mediator or counselor to resolve the issue.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "10",
            "question": "If so, what?",
            "options": [
                "Yes, it's better to address the issue calmly and openly.",
                "Yes, seeking the help of a mediator or counselor can provide a neutral perspective.",
                "No, it's best to resolve issues between ourselves without involving third parties.",
                "No, sometimes it's better to take some time to reflect on the issue before discussing it.",
                "Yes, it's important to address the issue as soon as possible to prevent it from escalating.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "11",
            "question": "If you always say you are going to do something but never do it, what is the most effective way to bring this problem to your attention?",
            "options": [
                "Have an open and honest conversation about how your actions affect me.",
                "Set clear and specific expectations for what needs to be done and the consequences of not following through.",
                "Seek help from a third party or counselor to mediate and address the issue effectively.",
                "Take a break from the situation and come back to it when emotions have cooled down to discuss calmly.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "12",
            "question": "What did you admire about the way your mother and father treated each other?",
            "options": [
                "I admired how they always showed respect and kindness towards each other.",
                "I admired their ability to communicate openly and resolve conflicts peacefully.",
                "Their mutual support and teamwork were admirable.",
                "I admired how they prioritized each other's happiness and well-being.",
                "Their love and commitment towards each other were inspiring.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "13",
            "question": "What is the best way for me to communicate difficult feelings about you so that you are not offended?",
            "options": [
                "Express your feelings calmly and respectfully, focusing on 'I' statements rather than blaming.",
                "Choose an appropriate time and place to discuss your feelings privately.",
                "Use 'I feel' statements to express your emotions without attacking or accusing.",
                "Listen actively to my perspective without interrupting, and then share your feelings calmly.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "14",
            "question": "Who should know about the arguments we have?",
            "options": [
                "Only the two of us, it's private between partners.",
                "Close friends and family members who can offer support and guidance.",
                "Only a trusted third party if we need mediation or assistance in resolving conflicts.",
                "No one, arguments should remain between us unless it's necessary to involve someone else.",
                "It depends on the severity of the argument and the need for outside perspective or intervention.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "15",
            "question": "What makes you not want to talk to me?",
            "options": [
                "When I feel criticized or judged instead of understood.",
                "When I sense anger or hostility in the conversation.",
                "When I feel overwhelmed or stressed and need space to process my thoughts.",
                "When I sense a lack of empathy or emotional support.",
                "When I feel like my opinions or feelings are dismissed or invalidated.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "16",
            "question": "Do you feel you could communicate with me under any circumstance and about any subject?",
            "options": [
                "Yes, I feel comfortable discussing anything with you regardless of the situation.",
                "Yes, I believe we can talk about any subject openly and honestly, no matter what.",
                "No, there are certain situations where I might find it difficult to communicate openly.",
                "No, there are certain topics I may feel uncomfortable discussing depending on the circumstances.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },

        {
            "id": "17",
            "question": "How are we different?",
            "options": [
                "We have different communication styles and preferences.",
                "Our personalities and interests vary significantly.",
                "We come from different backgrounds and cultures.",
                "Our perspectives and life experiences shape us differently.",
                "We have contrasting ways of handling emotions and conflicts.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "18",
            "question": "Could this be a source of future conflict?",
            "options": [
                "Yes, it's possible if we don't address our differences effectively.",
                "No, as long as we communicate openly and respect each other's differences.",
                "Yes, if we don't find a way to compromise and understand each other's perspectives.",
                "No, differences can be enriching if we learn from them and grow together.",
                "Yes, unless we find common ground and establish mutual respect for our differences.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "19",
            "question": "Do our differences complement each other?",
            "options": [
                "Yes, our differences balance and enhance our relationship.",
                "No, our differences sometimes create conflicts instead of complementing each other.",
                "Yes, because they provide different perspectives and enrich our experiences.",
                "No, our differences often lead to misunderstandings and disagreements.",
                "Yes, because they challenge us to grow and learn from each other.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "20",
            "question": "Do you anticipate maintaining your single lifestyle after we are married? That is, will you spend just as much time with your friends, family, and work colleagues? Why or why not?",
            "options": [
                "Yes, I value my independence and plan to maintain a balance between my personal life and relationships.",
                "No, I anticipate spending more time with my spouse and prioritizing our relationship over other commitments.",
                "Yes, I believe in maintaining my individual interests and social circles even after marriage.",
                "No, I anticipate adjusting my lifestyle to accommodate the needs and expectations of married life.",
                "It depends on various factors, including the dynamics of our relationship and career demands.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },
        {
            "id": "21",
            "question": "How did your family resolve conflicts when you were growing up?",
            "options": [
                "They openly discussed the issues and reached a consensus through dialogue.",
                "Conflicts were often resolved through compromise and finding common ground.",
                "They sought intervention from elders or a third party to mediate and resolve conflicts.",
                "Conflicts were often avoided or suppressed rather than directly addressed.",
                "They followed a hierarchical structure where the decision of the elder or authority figure was final.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": null
        },

        {
            "id": "22",
            "question": "Do you approve or disapprove of that method?/ प्रश्न: आप उस लवलि क स्वीकृ ि करिेहैंया नहीं?",
            "options": [
                "I approve of that method as it promotes open communication and understanding.",
                "I disapprove of that method because it may not effectively address underlying issues.",
                "I have mixed feelings about that method; it may work in some situations but not in others.",
                "I neither approve nor disapprove of that method; it depends on the specific circumstances.",
                "I need more information to form an opinion about that method.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I have mixed feelings about that method; it may work in some situations but not in others."
        },
        {
            "id": "23",
            "question": "What will you change or not change to resolve conflicts in your future family?/प्रश्न: आप अपनेिलवष्य के पररवार मेंलववाद ंक सुलझानेके ललए क्या बदलेंगेया नहींबदलेंगे?",
            "options": [
                "I will improve my communication skills and actively listen to understand others' perspectives.",
                "I will learn to compromise and find solutions that satisfy both parties.",
                "I will work on controlling my temper and reacting calmly in tense situations.",
                "I will prioritize finding common ground and resolving conflicts peacefully.",
                "I will encourage open dialogue and create a supportive environment for resolving conflicts.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I will prioritize finding common ground and resolving conflicts peacefully."
        },
        {
            "id": "24",
            "question": "Is there anything about marriage that frightens you?/क्या शादी के बारेमेंआपक कु छ ऐसी बािेंडरा देिी हैं?",
            "options": [
                "Yes, the idea of lifelong commitment and responsibility can be intimidating.",
                "No, I am excited and ready to embrace the challenges and joys of marriage.",
                "Yes, the fear of losing personal freedom and independence in marriage concerns me.",
                "No, I have confidence in our relationship and believe we can overcome any challenges together.",
                "Yes, the fear of marital conflicts and misunderstandings worries me.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "No, I have confidence in our relationship and believe we can overcome any challenges together."
        },
        {
            "id": "25",
            "question": "Would you prefer to live in the village, town, city, the countryside, or by the beach? Why?/क्या आप गाँव, शहर, नगर, गाँव के पास, या समुद्र िट पर रहना पसंद करेंगे? क्य ं?",
            "options": [
                "I would prefer to live in a village for its peaceful and close-knit community.",
                "I would prefer to live in a town for its balance of urban amenities and natural surroundings.",
                "I would prefer to live in a city for its career opportunities, cultural diversity, and vibrant lifestyle.",
                "I would prefer to live in the countryside for its scenic beauty and tranquility.",
                "I would prefer to live by the beach for its calming atmosphere and recreational activities.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I would prefer to live in a city for its career opportunities, cultural diversity, and vibrant lifestyle."
        },
        {
            "id": "26",
            "question": "If I wanted to move away from our families for work, would you support me?/ अगर मैंकाम के ललए हमारेपररवार ोंसेदू र जाना चाहिा/चाहिी था, ि क्या आप मुझे समथान करेंगे?",
            "options": [
                "Yes, I would support your career aspirations and decisions even if it means being away from our families.",
                "No, I believe our families are important, and I would prefer to find a solution that keeps us closer to them.",
                "It would depend on the circumstances and the impact of your absence on our families.",
                "Yes, I understand the importance of career opportunities and would encourage you to pursue them even if it means relocating.",
                "No, I would find it difficult to be away from our families and would prefer if we can find work opportunities closer to them.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "It would depend on the circumstances and the impact of your absence on our families."
        },
        {
            "id": "27",
            "question": "How would it affect you if I travel on my own frequently to (1) visit family, (2) earn income, (3) pursue a hobby, or (4) deal with stress?/ अगर मैंअके लेयात्रिकत होता हूं यह ाजके (1)परिवार के ललए, (2)आय कमाने, (3)अपना शोखा यांएक ानके, (4)सािव का सामना करने के ललए, तोइसे आपप पे कैसे प्रभावीत होता है?",
            "options": [
                "I would miss you but understand the reasons for your travel and support your endeavors.",
                "I would feel lonely and disconnected without you but would manage by staying busy with my own activities.",
                "I would feel anxious about your safety and well-being but trust that you can take care of yourself.",
                "I would appreciate the time apart as it allows us to pursue our individual interests and hobbies.",
                "I would worry about the strain it puts on our relationship and prefer if we can find ways to travel together.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I would miss you but understand the reasons for your travel and support your endeavors."
        },
        {
            "id": "28",
            "question": "What should we do if one of us is offered a job opportunity that requires relocation to a different city or country?/अगर हममेंसेकोईभीएक व्यक्ति एक नये शहर या देश में स्थानांत रनिकतके ललएट का मौका पािा, तो हमेंक्या करना चाहि?एगे?",
            "options": [
                "We should discuss the pros and cons together and make a joint decision that benefits both of us.",
                "We should prioritize the career opportunity and be willing to make the necessary adjustments for the relocation.",
                "We should consider the impact on our relationship and explore alternatives such as commuting or temporary relocation.",
                "We should consult with our families and seek their advice before making a decision.",
                "We should evaluate the long-term implications on our goals and aspirations before committing to the relocation.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "We should discuss the pros and cons together and make a joint decision that benefits both of us."
        },
        {
            "id": "29",
            "question": "How would you handle disagreements or conflicts between our parents or families?/ आप हमारेमाता-पिता या पररवार ंके बीचगलववा यालवववादकेसमाेलने का तरीका कैसे रखेंगें?",
            "options": [
                "I would act as a mediator and facilitate open communication to resolve the conflicts peacefully.",
                "I would stay neutral and avoid getting involved in their disagreements unless necessary.",
                "I would discuss the issues with both parties separately and try to find common ground without taking sides.",
                "I would encourage them to address their concerns directly and work towards understanding each other's perspectives.",
                "I would seek professional help or counseling if the conflicts escalate and impact our family dynamics.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I would encourage them to address their concerns directly and work towards understanding each other's perspectives."
        },

        {
            "id": "30",
            "question": "How will you support my hobbies?/आप मेरेशौक ंका समथान कैसेकरेंगे?",
            "options": [
                "I will actively participate in your hobbies and encourage you to pursue them passionately.",
                "I will provide you with the necessary resources and time to indulge in your hobbies.",
                "I will encourage you to explore new hobbies and support you in trying new activities.",
                "I will respect your hobbies and give you space and time to enjoy them without interference.",
                "I will support your hobbies by actively participating in related events or activities with you.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I will actively participate in your hobbies and encourage you to pursue them passionately."
        },
        {
            "id": "31",
            "question": "How do you feel about having our parents come to live with us if the need arises?/यद ावश्यकिा हो, हमारेमािा-लपिा हमारेसाथ रहनेआिेहैं, आपका क्या लवचार है?",
            "options": [
                "I believe it would be a great opportunity to strengthen family bonds.",
                "I'm open to the idea, as long as we can ensure everyone's comfort and privacy.",
                "It would be a fulfilling experience to care for our parents in their golden years.",
                "Let's discuss the practicalities and ensure we have the necessary arrangements in place.",
                "It would be a decision we make together, considering everyone's needs and preferences.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "It would be a fulfilling experience to care for our parents in their golden years."
        },
        {
            "id": "32",
            "question": "Is there anything you would regret not being able to do or accomplish if you married me?/क्या आपक कु छ ऐसी चीज सेपछिावा ह गा लजसेआप करनेया पूरा करनेमेंसमथानहींह िे अगर आप मुझसेशादी करिे?",
            "options": [
                "I don't foresee any regrets, as long as we support and encourage each other in our goals.",
                "As long as we communicate openly and work together, I don't think there would be any regrets.",
                "I believe our partnership would be fulfilling, and any regrets would be addressed together.",
                "Regrets are a natural part of life, but I trust that we'll navigate any challenges together.",
                "I'm confident that marrying you would only enhance my life, without any regrets.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I'm confident that marrying you would only enhance my life, without any regrets."
        },
        {
            "id": "33",
            "question": "How will we schedule holidays with our families?/हम अपनेपररवार ंके साथ छु लिय ंकी कै सेअनुसूलचि करेंगे?",
            "options": [
                "We can alternate between spending holidays with each of our families, ensuring fairness.",
                "Let's sit down together and create a yearly holiday schedule that accommodates both our families.",
                "We could prioritize major holidays with one family and smaller ones with the other, rotating each year.",
                "We'll discuss and prioritize based on the importance of each holiday to our respective families.",
                "Flexibility will be key; we can decide based on family needs and commitments each year.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "We'll discuss and prioritize based on the importance of each holiday to our respective families."
        },

        {
            "id": "34",
            "question": "What justifies going into debt?/कजामेंजानेक क्या जायज बनािा है?",
            "options": [
                "Investing in education or skill development that enhances future earning potential.",
                "Taking on debt for essential medical treatments or emergencies.",
                "Starting or expanding a business that has strong potential for long-term profitability.",
                "Making an investment in a property or asset that appreciates over time.",
                "Consolidating high-interest debt into a lower-interest loan for better financial management.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "Investing in education or skill development that enhances future earning potential."
        },
        {
            "id": "35",
            "question": "What are all your current personal debts?/आपके विामान मेंसिी व्यक्तिगि कजा क्या हैं?",
            "options": [
                "I do not have any personal debts at the moment.",
                "I have debts from educational loans for my studies.",
                "I owe money on a personal loan taken for medical expenses.",
                "I have credit card debt due to some unforeseen expenses.",
                "I have borrowed money for home renovation purposes.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I do not have any personal debts at the moment."
        },
        {
            "id": "36",
            "question": "Do you feel stress when facing financial problems?/क्या आपक लविीय समस्याओं का सामना करिेसमय िनाव महसूस ह िा है?",
            "options": [
                "Yes, always.",
                "Sometimes, depending on the severity of the problem.",
                "No, I manage stress well.",
                "Not usually, but occasionally.",
                "I'm not sure.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "Yes, always."
        },
        {
            "id": "37",
            "question": "How do you deal with that stress?/आप उस िनाव का सामना कै सेकरिेहैं?",
            "options": [
                "I practice relaxation techniques like meditation or yoga.",
                "I talk to someone I trust about my problems.",
                "I engage in physical activities like exercise or sports.",
                "I try to focus on finding solutions to the problem.",
                "I take a break or engage in hobbies to distract myself.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I practice relaxation techniques like meditation or yoga."
        },
        {
            "id": "38",
            "question": "How often do you use credit cards, and what do you buy with them?/आप िे लडट काडालकिनी बार उपय ग करिेहैं, और उनके साथ क्या खरीदिेहैं?",
            "options": [
                "Frequently, for daily expenses like groceries and bills.",
                "Occasionally, for big purchases like electronics or vacations.",
                "Rarely, only for emergencies.",
                "Never, I prefer using cash or debit cards.",
                "Always, for all types of purchases.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "Frequently, for daily expenses like groceries and bills."
        },

        {
            "id": "39",
            "question": "How should we prepare for a financial emergency?/लविीय आपािकाल के ललए हमें कै सेिैयारी करनी चालहए?",
            "options": [
                "We should build an emergency fund by saving a portion of our income regularly.",
                "It's important to have insurance coverage for health, home, and other valuables.",
                "We should create a budget and stick to it to manage our finances efficiently.",
                "Having a diverse investment portfolio can provide stability during financial crises.",
                "Regularly reviewing and updating our financial plan can help us adapt to changing circumstances.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "We should build an emergency fund by saving a portion of our income regularly."
        },
        {
            "id": "40",
            "question": "Do you feel that lack of money is a good reason not to have children?/क्या आपक लगिा हैलक पैसेकी कमी बच्च ंक नहींपालनेका एक अच्छा कारण है?",
            "options": [
                "Yes, financial stability is important for providing a good life for children.",
                "No, love and care are more important than money when it comes to raising children.",
                "Financial challenges can be overcome with proper planning and support.",
                "It depends on the individual's ability to manage finances and provide for children.",
                "Every family's situation is different; financial considerations are just one aspect.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "Yes, financial stability is important for providing a good life for children."
        },
        {
            "id": "41",
            "question": "When our child is born, will he or she go to daycare or will one of us stay home to take care of the child? Who will it be?/ जब हमारा बच्चा पैदा ह गा, क्या वह डेके यर मेंजाएगा या हममेंसेक ई र्र पर रहेगा बच्चेकी देखिाल के ललए? यह कौन ह गा?",
            "options": [
                "We haven't decided yet, but we'll discuss and come to a mutual agreement.",
                "We might consider a combination of daycare and one of us staying home, depending on our work schedules.",
                "One of us will stay home to take care of the child, while the other continues working.",
                "We'll explore childcare options and make a decision based on what's best for our child and our family.",
                "It will depend on various factors such as our financial situation, personal preferences, and childcare availability.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "We haven't decided yet, but we'll discuss and come to a mutual agreement."
        },
        {
            "id": "42",
            "question": "Will we have a budget?/क्या हमारा एक बजट ह गा?",
            "options": [
                "Yes, having a budget is essential for managing our finances effectively.",
                "No, we prefer to manage our finances without a strict budget.",
                "We haven't discussed it yet, but we might consider having a budget in the future.",
                "Yes, a budget will help us track our expenses and prioritize our financial goals.",
                "It depends on our financial situation and our approach to money management.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "Yes, having a budget is essential for managing our finances effectively."
        },
        {
            "id": "43",
            "question": "Who will pay the bills?/लबल कौन िरेगा?",
            "options": [
                "We will split the bills evenly between us.",
                "I will take responsibility for paying the bills.",
                "We will take turns paying the bills each month.",
                "We will pay the bills from a joint account.",
                "We will each pay specific bills based on our individual expenses.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "We will split the bills evenly between us."
        },

        {
            "id": "44",
            "question": "How do you feel about helping me pay my debts?/मेरेकजाक िुगिान मेंमेरी सहायिा के बारेमेंआपका कै सा लवचार है?",
            "options": [
                "I am willing to help you pay off your debts to ease your financial burden.",
                "We are partners, and I believe in supporting each other in times of need, including financial challenges.",
                "Your debts are our responsibility, and I am committed to helping you overcome them.",
                "We will work together to create a plan to pay off your debts systematically.",
                "I understand the importance of financial stability, and I am here to support you in achieving it.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I am willing to help you pay off your debts to ease your financial burden."
        },
        {
            "id": "45",
            "question": "What are your feelings about saving money?/ पैसेबचानेके बारेमेंआपका क्या लवचार है?",
            "options": [
                "I believe in saving money for a secure future and financial stability.",
                "Saving money gives me a sense of financial security and peace of mind.",
                "I prioritize saving money to achieve my financial goals and fulfill my aspirations.",
                "Saving money is essential for building a strong financial foundation and facing unexpected expenses.",
                "I believe in saving money as a habit to ensure financial independence and future security.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I believe in saving money for a secure future and financial stability."
        },
        {
            "id": "46",
            "question": "Do you prefer separate bank accounts or assets in different names? Why?/ क्या आप अलग-अलग बैंक खािेया लवलिन्न नाम ंमेंसंपलिय ंक पसंद करिेहैं? क्य ं?",
            "options": [
                "I prefer separate bank accounts for independence and financial autonomy.",
                "Assets in different names provide clarity and ownership rights in case of any legal issues.",
                "Separate bank accounts allow better management of personal expenses and savings.",
                "Assets in different names can provide diversification and protection in case of financial risks.",
                "We may consider a combination based on our financial goals and legal advice.",
                "None of the above.",
                "Other (please specify)"
            ],
            "selectedAnswer": "I prefer separate bank accounts for independence and financial autonomy."
        },
        {
            "id": "47",
            "question": "Which childhood experiences influence your behavior and attitude the most? / बचपन के कौन से अनुभव आपके व्यवहार और दृष्टिकोण को सबसे अधिक प्रभावित करते हैं?",
            "options": [
                "Family dynamics and relationships shape behavior significantly. / परिवार के विवाद और संबंध व्यवहार को बड़े प्रभाव से प्रभावित करते हैं।",
                "Peer interactions during childhood play a crucial role. / बचपन में साथी अभिव्यक्तियों का महत्वपूर्ण योगदान होता है।",
                "School environment and experiences with teachers impact attitude formation. / स्कूल के माहौल और वर्क्षकों के साथ अनुभव में अभिवृत्ति के प्रभाव को देखा जाता है।",
                "Cultural influences and traditions leave a lasting imprint on behavior. / सांस्कृतिक प्रभाव और परंपराएँ व्यवहार पर स्थायी छाप छोड़ती हैं।",
                "Personal experiences of success or failure shape individual outlook. / सफलता या असफलता के व्यक्तिगत अनुभव व्यक्तिगत दृष्टिकोण को आकार देते हैं।",
                "None of the above. / ऊपर से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "48",
            "question": "Could any feelings of affection and romance be revived if you met a previous boyfriend/girlfriend even though you feel strongly committed to me? / यदि आप मेरे प्रति दृढ़ता से प्रतिबद्ध महसूस करते हैं तो भी यदि आप किसी पूर्व प्रेमी/प्रेमिका से मिलें तो क्या स्नेह और रोमांस की भावनाएँ पुनर्जीवित हो सकती हैं?",
            "options": [
                "Past relationships may evoke memories, but commitment to you remains firm. / पिछले संबंध यादों को जगाते हैं, लेकिन आपके प्रति प्रतिबद्धता दृढ़ रहती है।",
                "Meeting an ex may trigger nostalgia, but my love for you is unwavering. / पूर्व प्रेमी/प्रेमिका से मिलना विचारों को जगा सकता है, लेकिन मेरा आपके प्रति प्यार अडिग है।",
                "Past emotions may resurface briefly, but my heart belongs to you completely. / पिछले भावनाएँ अल्पकालिक रूप में पुनः उभर सकती हैं, लेकिन मेरा दिल पूरी तरह आपके पास है।",
                "Reconnecting with an ex might stir old feelings, yet my loyalty is to you. / पूर्व प्रेमी/प्रेमिका से फिर से जुड़ना पुराने भावनाओं को उत्तेजित कर सकता है, लेकिन मेरी वफादारी आपके प्रति है।",
                "While past flames may flicker, my heart burns only for you. / पिछले रिश्तों की चिनगारियाँ हो सकती हैं, लेकिन मेरा दिल के लिए आपके लिए जलता है।",
                "None of the above. / ऊपर से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "49",
            "question": "Is there anything in your past I should be aware of? / क्या आपके अतीत में कुछ ऐसा है जिसके बारे में मुझे अवगत होना चाहिए?",
            "options": [
                "There may be aspects of my past that I'm willing to share if you're interested. / मेरे अतीत में विवादित मामले हो सकते हैं जिन्हें मैं साझा करने को तैयार हूँ अगर आप चाहें।",
                "I believe in open communication and honesty, so feel free to ask me anything. / मैं खुली बातचीत और ईमानदारी में विश्वास करता हूँ, इसलिए कुछ भी पूछने में खुले रहें।",
                "My past has shaped me, but I'm committed to our present and future together. / मेरा अतीत मुझे आकार दिया है, लेकिन मैं हमारे वर्तमान और भविष्य को एक साथ रखने के प्रति प्रतिबद्ध हूँ।",
                "There might be experiences I've had that could influence our relationship, but let's discuss openly. / मेरे पास ऐसे अनुभव हो सकते हैं जो हमारे संबंध पर प्रभाव डाल सकते हैं, लेकिन चलो खुले तौर पर बातचीत करें।",
                "I've made mistakes in the past, but I'm committed to learning and growing with you. / मैंने अपने अतीत में गलतियाँ की हैं, लेकिन मैं आपके साथ सीखने और बढ़ने के लिए प्रतिबद्ध हूँ।",
                "None of the above. / ऊपर से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "50",
            "question": "What did you dislike the most about your previous partners? / आपको अपने पिछले साझेदारों में सबसे अधिक क्या नापसंद था?",
            "options": [
                "Lack of communication and understanding was a major issue in previous relationships. / पिछले संबंधों में बातचीत और समझने की कमी एक मुख्य समस्या थी।",
                "Trust issues and dishonesty were deal-breakers in past partnerships. / पिछले साझेदारी में विश्वास संबंधी समस्याएँ और बेईमानी अविश्वास की स्थिति थी।",
                "Emotional unavailability and lack of empathy were challenging aspects. / भावनात्मक अनुपलब्धता और सहानुभूति की कमी चुनौतीपूर्ण पहलू थे।",
                "Differences in values and life goals caused conflicts in previous relationships. / मूल्यों और जीवन के लक्ष्यों में अंतर पिछले संबंधों में विवाद उत्पन्न करते थे।",
                "Incompatibility in personality traits led to dissatisfaction in past partnerships. / व्यक्तित्व गुणों में असंगति पिछले साझेदारियों में असंतोष लाई।",
                "None of the above. / कोई भी उपरोक्त विशेषताओं में से कोई नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "51",
            "question": "If your past boyfriends/girlfriends listed your most negative characteristics, what would they be? / यदि आपके पिछले बॉयफ्रेंड/गर्लफ्रेंड ने आपकी सबसे नकारात्मक विशेषताएँ सूचीबद्ध की हों, तो वे क्या होंगी?",
            "options": [
                "Always late to everything. / यह हमेशा सब कुछ के लिए देर से पहुँचता है।",
                "Can be too stubborn sometimes. / कभी-कभी यह बहुत जिद्दी हो सकता है।",
                "Doesn't listen carefully. / यह ध्यान से सुनता नहीं है।",
                "Too messy and disorganized. / बहुत ही अस्तव्यस्त और असंगठित होता है।",
                "Always forgets important dates. / हमेशा महत्वपूर्ण तिथियों को भूल जाता है।",
                "None of the above. / कोई भी उपरोक्त विशेषताओं में से कोई नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "52",
            "question": "Do you keep letters and memorabilia from past relationships? Why or why not? / क्या आप पिछले रिश्तों के पत्र और यादगार चीज़ें रखते हैं? क्यों या क्यों नहीं?",
            "options": [
                "Yes, they hold sentimental value for me. / हाँ, उनमें संवेदनशील मूल्य है।",
                "No, I prefer to move on and leave the past behind. / नहीं, मैं आगे बढ़ने और भूतकाल को पीछे छोड़ने की पसंद करता हूँ।",
                "Yes, as a reminder of lessons learned. / हाँ, सीखे गए सबक को याद रखने के लिए।",
                "No, it's better to let go of the past completely. / नहीं, बेहतर है कि पूरी तरह से भूतकाल को छोड़ दिया जाए।",
                "Sometimes, depending on the significance of the item. / कभी-कभी, वस्त्र के महत्व पर निर्भर करता है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "53",
            "question": "Are you comfortable continuing this relationship if there are things in my past that I am not willing to share with you? / यदि मेरे अतीत में कुछ ऐसी बातें हैं जिन्हें मैं आपके साथ साझा नहीं करना चाहता तो क्या आप इस रिश्ते को जारी रखने में सहज हैं?",
            "options": [
                "Yes, I trust you and respect your privacy. / हाँ, मैं आप पर भरोसा करता हूँ और आपकी गोपनीयता का सम्मान करता हूँ।",
                "No, honesty and transparency are important to me. / नहीं, मेरे लिए ईमानदारी और पारदर्शिता महत्वपूर्ण हैं।",
                "Maybe, it depends on the nature of those things. / शायद, यह उन चीजों के स्वभाव पर निर्भर करेगा।",
                "Yes, everyone has a right to privacy. / हाँ, हर किसी को गोपनीयता का अधिकार है।",
                "No, it might create doubts and insecurities in the relationship. / नहीं, यह संबंध में संदेह और असुरक्षा पैदा कर सकता है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "54",
            "question": "Have you ever been involved in any criminal activities? What were they? / क्या आप कभी किसी आपराधिक गतिविधियों में शामिल रहे हैं? वे क्या थे?",
            "options": [
                "No, I have never been involved in any criminal activities. / नहीं, मैं ने कभी भी किसी भी आपराधिक गतिविधि में शामिल नहीं हुआ है।",
                "Yes, but it was a minor offense and I learned from it. / हाँ, लेकिन यह एक लघु अपराध था और मैंने उससे सीखा।",
                "No, and I believe in living a lawful life. / नहीं, और मैं कानूनी जीवन जीने में विश्वास रखता हूँ।",
                "Yes, but I was wrongly accused and later acquitted. / हाँ, लेकिन मुझ पर गलत आरोप लगाया गया था और बाद में बरी किया गया।",
                "No, criminal activities go against my principles and values. / नहीं, आपराधिक गतिविधियाँ मेरे सिद्धांतों और मूल्यों के खिलाफ हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "55",
            "question": "Did your mother or father abuse each other or you in any way- sexually, emotionally, or physically? / क्या आपके माता या पिता ने एक-दूसरे या आपके साथ किसी भी तरह से दुर्व्यवहार किया- यौन, भावनात्मक या शारीरिक?",
            "options": [
                "No, my parents have never abused each other or me in any way. / नहीं, मेरे माता-पिता कभी भी एक-दूसरे या मुझे किसी भी तरह का शारीरिक, भावनात्मक या यौन उत्पीड़न नहीं किया है।",
                "Yes, there have been instances of emotional abuse in my family. / हाँ, मेरे परिवार में भावनात्मक उत्पीड़न के कुछ घटनाओं का अनुभव हुआ है।",
                "No, my parents have always treated each other and me with respect. / नहीं, मेरे माता-पिता हमेशा एक-दूसरे और मुझे सम्मान से व्यवहार किया है।",
                "Yes, there have been instances of physical abuse in my family. / हाँ, मेरे परिवार में शारीरिक उत्पीड़न की कुछ घटनाएँ हुई हैं।",
                "No, my family has a healthy and supportive environment. / नहीं, मेरे परिवार में एक स्वस्थ और सहायक वातावरण है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "56",
            "question": "Have you ever been able to overcome a bad habit? What was it? / क्या आप कभी किसी बुरी आदत पर काबू पा सके हैं? यह क्या था?",
            "options": [
                "Yes, I overcame smoking. / हाँ, मैं धूम्रपान को छोड़ दिया।",
                "No, I still struggle with the same bad habits. / नहीं, मैं अब भी उन्हीं बुरी आदतों से लड़ता हूँ।",
                "Yes, I stopped biting my nails. / हाँ, मैंने अपने नाखूनों को चबाना छोड़ दिया।",
                "No, I haven't been able to overcome any bad habits yet. / नहीं, मैंने अब तक कोई बुरी आदत को नहीं जीता है।",
                "Yes, I stopped procrastinating. / हाँ, मैंने टालमटोल करना बंद कर दिया।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "57",
            "question": "Have you ever been violent in past relationships? / क्या आप पिछले रिश्तों में कभी हिंसक रहे हैं?",
            "options": [
                "No, I have never been violent in any past relationships. / नहीं, मैं ने कभी भी किसी पूर्व संबंध में हिंसक नहीं रहा हूँ।",
                "Yes, but I have learned from those experiences and have changed. / हाँ, लेकिन मैंने उन अनुभवों से सीखा है और बदल गया हूँ।",
                "No, violence has no place in my relationships. / नहीं, हिंसा मेरे संबंधों में कोई स्थान नहीं है।",
                "Yes, but it was due to unresolved anger and I have sought help to address it. / हाँ, लेकिन यह अनसुलझे गुस्से के कारण था और मैंने इसे समाधान करने के लिए मदद मांगी।",
                "No, I believe in resolving conflicts through communication and understanding. / नहीं, मैं संवाद और समझदारी के माध्यम से विवादों को सुलझाने में विश्वास करता हूँ।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "58",
            "question": "If we eliminated physical attraction from our relationship, what would be left? / यदि हम अपने रिश्ते से शारीरिक आकर्षण खत्म कर दें, तो क्या बचेगा?",
            "options": [
                "Deep emotional connection and understanding. / गहरा भावनात्मक संबंध और समझ।",
                "Mutual respect and admiration. / साथी आदर और प्रशंसा।",
                "Shared interests and values. / संयुक्त हितों और मूल्यों।",
                "Trust and loyalty. / विश्वास और वफादारी।",
                "Support and companionship. / सहारा और संगति।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "59",
            "question": "What is the best way for me to show that I love you? / मेरे लिए यह दिखाने का सबसे अच्छा तरीका क्या है कि मैं तुमसे प्यार करता हूँ?",
            "options": [
                "Strong emotional bond and connection. / गहरा भावनात्मक बंधन और संबंध।",
                "Genuine friendship and companionship. / सच्ची मित्रता और संगति।",
                "Shared goals and aspirations. / संयुक्त लक्ष्यों और आकांक्षाओं।",
                "Trust, respect, and understanding. / विश्वास, सम्मान, और समझ।",
                "Deep intellectual connection and compatibility. / गहरा बौद्धिक संबंध और संगति।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "60",
            "question": "If I put on weight, will it affect our sexual relationship? How? / अगर मेरा वजन बढ़ जाए तो क्या इसका असर हमारे यौन संबंधों पर पड़ेगा? कैसे?",
            "options": [
                "No, physical appearance doesn't affect our emotional connection. / नहीं, शारीरिक रूप भावनात्मक संबंध पर प्रभाव नहीं डालता।",
                "Yes, it might affect our intimacy temporarily. / हाँ, यह अस्थायी रूप से हमारी अंतरंगता पर प्रभाव डाल सकता है।",
                "No, our bond goes beyond physical appearance. / नहीं, हमारा संबंध शारीरिक रूप से आगे बढ़ता है।",
                "Yes, it might lead to self-esteem issues affecting our sexual relationship. / हाँ, यह आत्मसम्मान समस्याओं का कारण बन सकता है जो हमारे यौन संबंध पर प्रभाव डाल सकता है।",
                "No, our connection is based on love and understanding, not physical appearance. / नहीं, हमारा संबंध प्रेम और समझ पर आधारित है, न कि शारीरिक रूप पर।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "61",
            "question": "Is it important for you to know that I'm a virgin? / क्या आपके लिए यह जानना ज़रूरी है कि मैं वर्जिन हूँ?",
            "options": [
                "Yes, it matters to me because of cultural or personal beliefs. / हाँ, यह मेरे लिए महत्वपूर्ण है क्योंकि सांस्कृतिक या व्यक्तिगत विश्वास के कारण।",
                "No, it doesn't matter to me, our connection is based on other factors. / नहीं, मेरे लिए यह महत्वपूर्ण नहीं है, हमारा संबंध अन्य कारकों पर आधारित है।",
                "Yes, it's important for me to know for intimacy reasons. / हाँ, यह मेरे लिए अंतरंगता के कारण जानने के लिए महत्वपूर्ण है।",
                "No, it's your personal matter and doesn't affect our relationship. / नहीं, यह आपका व्यक्तिगत मामला है और हमारे संबंध को प्रभावित नहीं करता है।",
                "Yes, it's a matter of importance to me for various reasons. / हाँ, यह मेरे लिए विभिन्न कारणों से महत्वपूर्ण है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "62",
            "question": "Why or why not? / क्यों या क्यों नहीं?",
            "options": [
                "Yes, it might impact our cultural or religious beliefs. / हाँ, यह हमारे सांस्कृतिक या धार्मिक विश्वासों पर प्रभाव डाल सकता है।",
                "No, your past doesn't define our present or future together. / नहीं, आपका अतीत हमारे वर्तमान या भविष्य को निर्धारित नहीं करता।",
                "Yes, it's important for me to understand your experiences and expectations. / हाँ, मेरे लिए यह आपके अनुभवों और अपेक्षाओं को समझने के लिए महत्वपूर्ण है।",
                "No, it's your personal matter and doesn't affect our relationship. / नहीं, यह आपका व्यक्तिगत मामला है और हमारे संबंध को प्रभावित नहीं करता है।",
                "Yes, it could impact our sexual compatibility and communication. / हाँ, यह हमारे यौन अनुकूलता और संचार पर प्रभाव डाल सकता है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "63",
            "question": "What do I do that causes you to question my love? / मैं ऐसा क्या करूँ जिससे तुम्हें मेरे प्यार पर सवाल उठाना पड़े?",
            "options": [
                "When you don't express affection or appreciation. / जब आप प्यार या सराहना का इज़हार नहीं करते।",
                "When you prioritize other things over spending time with me. / जब आप मेरे साथ समय बिताने को किसी और चीज़ की प्राथमिकता देते हैं।",
                "When you don't listen to me or consider my feelings. / जब आप मेरी बात नहीं सुनते या मेरे भावनाओं का ध्यान नहीं देते।",
                "When you're not transparent or honest about your actions. / जब आप अपने कार्यों के बारे में स्पष्ट या ईमानदार नहीं होते।",
                "When you show more affection towards others than me. / जब आप अन्य लोगों के प्रति मेरी तुलना में अधिक प्यार दिखाते हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "64",
            "question": "What turns you off sexually? / क्या चीज़ आपको यौन रूप से उत्तेजित करती है?",
            "options": [
                "Lack of emotional connection. / भावनात्मक संबंध की कमी।",
                "Poor hygiene. / खराब सफाई।",
                "Lack of communication or openness. / संचार या खुलापन की कमी।",
                "Feeling pressured or rushed. / दबाव या जल्दबाजी का अहसास।",
                "Lack of effort or enthusiasm. / प्रयास या उत्साह की कमी।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "65",
            "question": "How would our relationship be affected if for medical reasons we could not have children? / यदि चिकित्सा कारणों से हमारे बच्चे नहीं हो सके तो हमारे रिश्ते पर क्या प्रभाव पड़ेगा?",
            "options": [
                "We would explore other options like adoption or surrogacy. / हम गोद लेने या सरोगेसी जैसे अन्य विकल्पों का अध्ययन करेंगे।",
                "It would strengthen our bond as we face challenges together. / यह हमारे बंधन को मजबूत करेगा क्योंकि हम साथ मिलकर चुनौतियों का सामना करेंगे।",
                "It might cause emotional distress initially, but we would find ways to cope. / शुरुआत में यह भावनात्मक तनाव का कारण बन सकता है, लेकिन हम इसका सामना करने के तरीके खोजेंगे।",
                "We would focus on building a fulfilling life together without children. / हम बच्चों के बिना एक पूर्णत: संतोषजनक जीवन का निर्माण करने पर ध्यान केंद्रित करेंगे।",
                "Our relationship might undergo challenges, but our love and commitment would prevail. / हमारे संबंधों को चुनौतियों का सामना करना पड़ सकता है, लेकिन हमारा प्रेम और प्रतिबद्धता प्रभावी रहेगा।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "66",
            "question": "Do you think being in love means: (1) Never having to say you're sorry, (2) Always having to say you're sorry, (3) Knowing when to say you're sorry, or (4) Being the first to say I'm sorry? / क्या आपको लगता है कि प्यार में होने का मतलब है: (1) कभी यह न कहना कि आपको खेद है, (2) हमेशा यह कहना कि आपको खेद है, (3) यह जानना कि कब आपको खेद है, या (4) आई एम सॉरी कहने वाले पहले व्यक्ति बनें?",
            "options": [
                "Love means knowing when to say sorry. / प्यार का मतलब है कब माफी मांगनी है यह जानना।",
                "Love is about being the first to apologize. / प्यार में पहले माफी मांगना होता है।",
                "Love is understanding when apologies are needed. / प्यार में समझना होता है कि कब माफी की जरूरत है।",
                "Love involves expressing sorry whenever necessary. / प्यार में जब भी जरूरत हो, माफी व्यक्त करना होता है।",
                "Love means never hesitating to say sorry when needed. / प्यार का मतलब है जब जरूरत हो, तो माफी कहने में हिचकिचाहट नहीं होना।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "67",
            "question": "If we are unable to have children, should we adopt? / हम बच्चे नहीं पैदा कर सकते, तो क्या हमें गोद लेना चाहिए?",
            "options": [
                "Yes, adoption can be a beautiful way to expand our family. / हाँ, गोद लेना हमारे परिवार को विस्तार करने का एक सुंदर तरीका हो सकता है।",
                "No, it's a personal decision and we should consider all options. / नहीं, यह एक व्यक्तिगत निर्णय है और हमें सभी विकल्पों का विचार करना चाहिए।",
                "Yes, it would bring joy to a child in need of a loving home. / हाँ, यह एक प्यार भरे घर की आवश्यकता में एक बच्चे को खुशी लाएगा।",
                "No, we should focus on our relationship without children. / नहीं, हमें बच्चों के बिना हमारे संबंधों पर ध्यान केंद्रित करना चाहिए।",
                "Yes, it's a chance to make a positive impact on a child's life. / हाँ, यह एक बच्चे के जीवन पर सकारात्मक प्रभाव डालने का मौका है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "68",
            "question": "Do you anticipate raising our children (1) the same way you were raised (2) completely differently from the way you were raised (3) a mixture of both? / क्या आपको लगता है कि हमें हमारे बच्चों को (1) उसी तरह से पालना चाहिए जिस तरह से आपको पाला गया था (2) पूरी तरह से अलग तरीके से पालना चाहिए (3) दोनों का मिश्रण?",
            "options": [
                "Yes, I envision raising our children similar to how I was raised. / हाँ, मैं हमारे बच्चों को जैसे ही पालन-पोषण करने की कल्पना करता हूँ जैसे मुझे पाला-पोषा गया था।",
                "No, I plan to raise our children in a completely different manner from my upbringing. / नहीं, मैं हमारे बच्चों को पूरी तरह से अलग तरीके से पालन करने की योजना बनाता हूँ जिसमे मुझे पाला-पोषा गया था।",
                "Yes, I see a mixture of both my upbringing and new ideas in raising our children. / हाँ, मैं हमारे बच्चों को पालन करने में अपने पालन-पोषण और नए विचारों का मिश्रण देखता हूँ।",
                "No, I would like to explore a different approach tailored to our family's needs. / नहीं, मैं हमारे परिवार की आवश्यकताओं के अनुकूल एक विभिन्न दृष्टिकोण का अन्वेषण करना चाहता हूँ।",
                "Yes, but with some modifications to suit our circumstances. / हाँ, लेकिन हमारे परिस्थितियों के अनुकूल कुछ संशोधन के साथ।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "69",
            "question": "How long would you like to wait before having children? / आप बच्चों को पैदा करने से पहले कितना समय इंतजार करना चाहेंगे?",
            "options": [
                "Immediately after marriage. / शादी के तुरंत बाद।",
                "One to two years after marriage. / शादी के एक से दो साल बाद।",
                "Three to five years after marriage. / शादी के तीन से पांच साल बाद।",
                "More than five years after marriage. / शादी के पांच साल से अधिक बाद।",
                "I am unsure about having children. / मुझे बच्चों के बारे में अनिश्चितता है।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "70",
            "question": "Other than formal schooling, what types of education will our children get and how will they receive them? / शैक्षिक शिक्षा के अलावा, हमारे बच्चों को किस प्रकार की शिक्षा मिलेगी और वे इसे कैसे प्राप्त करेंगे?",
            "options": [
                "They will receive practical life skills education through activities like cooking, gardening, and DIY projects. / वे खाना पकाना, बागवानी, और घर के परियोजनाओं जैसी गतिविधियों के माध्यम से व्यावहारिक जीवन कौशल शिक्षा प्राप्त करेंगे।",
                "Cultural and moral education will be imparted through storytelling, visits to religious places, and discussions on ethics. / सांस्कृतिक और नैतिक शिक्षा को कहानियों, धार्मिक स्थलों की यात्राओं और नैतिकता पर चर्चाओं के माध्यम से प्रदान की जाएगी।",
                "They will learn about environmental conservation through outdoor activities like camping and nature walks. / वे कैम्पिंग और प्रकृति सैरगाह जैसी बाहरी गतिविधियों के माध्यम से पर्यावरण संरक्षण के बारे में सीखेंगे।",
                "Emotional intelligence will be fostered through open communication, expression of feelings, and mindfulness practices. / खुली संवाद, भावनाओं की अभिव्यक्ति, और संवेदनशीलता के अभ्यास के माध्यम से भावनात्मक बुद्धिमत्ता को बढ़ावा दिया जाएगा।",
                "They will be encouraged to explore arts and creativity through activities like painting, music, and drama. / उन्हें पेंटिंग, संगीत, और नाटक जैसी गतिविधियों के माध्यम से कला और रचनात्मकता का अन्वेषण करने के लिए प्रोत्साहित किया जाएगा।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "71",
            "question": "When we have children, who will change the diapers, heat the bottles, prepare the meals, do the housework, bathe the child, get up in the middle of the night when a child is crying, take the child to the doctor, buy clothing, and dress the child? / जब हमारे पास बच्चे होंगे, तो डायपर बदलेगा, बोतलें गर्म करेगा, भोजन तैयार करेगा, घर के काम करेगा, बच्चे को नहलाएगा, रात के बीच में जब बच्चा रोएगा, तो उठेगा, बच्चे को डॉक्टर के पास ले जाएगा, कपड़े खरीदेगा, और बच्चे को कपड़े पहनाएगा?",
            "options": [
                "We will share the responsibilities equally, taking turns for each task. / हम जिम्मेदारियों को बराबरी रूप से बांटेंगे, हर कार्य के लिए बारी-बारी से।",
                "It will depend on our schedules and availability at the time. / यह हमारी अनुसूचियों और समय पर उपलब्धता पर निर्भर करेगा।",
                "We will hire help or seek assistance from family members as needed. / हम आवश्यकता अनुसार मदद करेंगे या परिवार के सदस्यों से सहायता लेंगे।",
                "We will divide tasks based on our strengths and preferences. / हम कार्यों को हमारी ताकतों और पसंदों के आधार पर बांटेंगे।",
                "We will hire a nanny or babysitter to assist with childcare duties. / हम चाइल्डकेयर कर्तव्यों में सहायता के लिए नैनी या बेबीसिटर को रखेंगे।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "72",
            "question": "What types of discipline would you implement to correct a child's or a teenager's behavior? / बच्चे या किशोर के व्यवहार को सुधारने के लिए आप किस प्रकार के शिक्षाप्रद उपाय अपनाएंगे?",
            "options": [
                "Gentle guidance and redirection towards positive behavior. / सौम्य मार्गदर्शन और सकारात्मक व्यवहार की ओर पुनः निर्देश करना।",
                "Consistent rules and routines to establish structure. / संरचना स्थापित करने के लिए सुसंगत नियम और दिनचर्याएं।",
                "Encouraging self-reflection and responsibility for actions. / आत्मचिंतन और क्रियाओं के लिए जिम्मेदारी को प्रोत्साहित करना।",
                "Natural consequences to teach lessons. / सिखाने के लिए प्राकृतिक परिणाम।",
                "Positive reinforcement and rewards for good behavior. / अच्छे व्यवहार के लिए सकारात्मक पुनरावृत्ति और पुरस्कार।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "73",
            "question": "Were these practices you experienced or are they new ones you have developed on your own? / क्या ये अनुभव आपके अपने थे या ये नए उपाय हैं जिन्हें आपने खुद विकसित किया है?",
            "options": [
                "These practices were based on my own experiences growing up. / ये अनुभव मेरे बचपन के अनुभवों पर आधारित थे।",
                "I learned some from my upbringing and adapted others over time. / मैंने कुछ अपने पारिवारिक अनुभवों से सीखा और कुछ को समय के साथ अनुकूलित किया।",
                "These are new practices I have developed based on research and learning. / ये नए प्रयोग हैं जिन्हें मैंने अनुसंधान और सीखने के आधार पर विकसित किया है।",
                "I observed these methods from others and incorporated them into my approach. / मैंने इन विधियों को दूसरों से देखा और उन्हें अपने दृष्टिकोण में शामिल किया।",
                "These are a combination of traditional practices and modern approaches. / ये पारंपरिक अभ्यासों और आधुनिक दृष्टिकोणों का संयोजन हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "74",
            "question": "If I had bad breath or body odor or wear dirty clothes, will you tell me? / अगर मेरी मुंह से बदबू हो या शरीर की बदबू हो या मैं गंदे कपड़े पहने हो, तो क्या आप मुझे बताएंगे?",
            "options": [
                "Yes, I believe honesty is important in our relationship. / हां, मुझे लगता है कि हमारे संबंध में ईमानदारी महत्वपूर्ण है।",
                "No, I might feel uncomfortable bringing it up. / नहीं, मुझे यह उठाने में असहजता महसूस हो सकती है।",
                "Yes, because I care about your hygiene and health. / हां, क्योंकि मुझे आपकी स्वच्छता और स्वास्थ्य की चिंता है।",
                "No, I might try to drop hints subtly. / नहीं, मैं सूक्ष्मता से संकेत देने का प्रयास कर सकता हूँ।",
                "Yes, but I'll be sensitive in how I approach the topic. / हां, लेकिन मैं विषय को कैसे उठाता हूँ, उसमें संवेदनशील होऊंगा।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "75",
            "question": "Should I tell you? Why or why not? / क्या मुझे आपको बताना चाहिए? क्यों या क्यों नहीं?",
            "options": [
                "Yes, because it's important for our overall health and well-being. / हां, क्योंकि हमारे समग्र स्वास्थ्य और कल्याण के लिए यह महत्वपूर्ण है।",
                "No, it might hurt your feelings and cause unnecessary embarrassment. / नहीं, यह आपके भावनाओं को ठेस पहुंचा सकता है और अनावश्यक शर्मिंदगी का कारण बन सकता है।",
                "Yes, because open communication strengthens our bond. / हां, क्योंकि खुली बातचीत हमारे संबंध को मजबूत बनाती है।",
                "No, it's not necessary unless it becomes a persistent issue. / नहीं, यह जब तक यह एक दृढ़ समस्या नहीं बन जाता, तब तक आवश्यक नहीं है।",
                "Yes, because honesty and transparency are important in our relationship. / हां, क्योंकि हमारे संबंध में ईमानदारी और पारदर्शिता महत्वपूर्ण हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "76",
            "question": "How should we do it? / हमें इसे कैसे करना चाहिए?",
            "options": [
                "We should have a private conversation in a gentle and respectful manner. / हमें सौम्य और सम्मानपूर्ण तरीके से एक निजी बातचीत करनी चाहिए।",
                "We can bring it up casually during a relaxed moment. / हम इसे आराम से समय में एक साधारण चरण में उठा सकते हैं।",
                "We might write a note or message if we feel uncomfortable discussing it face-to-face. / अगर हमें इस बारे में चेहरे से चेहरे बातचीत करने में असहजता महसूस होती है, तो हम एक नोट या संदेश लिख सकते हैं।",
                "We should choose a time when we are both calm and relaxed to address the issue. / हमें समस्त चिंता और संवेदनशीलता की स्थिति में एक समय चुनना चाहिए ताकि हम इस मुद्दे पर विचार कर सकें।",
                "We could use humor to lighten the mood and make it easier to discuss. / हम मिजाज को हल्का करने और चर्चा को सुविधाजनक बनाने के लिए हास्य का प्रयोग कर सकते हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "77",
            "question": "What is nagging to you? / आपके लिए नाग करना क्या है?",
            "options": [
                "Constantly reminding or complaining about the same thing repeatedly. / बार-बार एक ही चीज को याद दिलाना या उसी चीज पर शिकायत करना।",
                "Criticizing or finding fault in everything I do. / मैं जो भी करता हूँ, उसमें दोष ढूंढना या आलोचना करना।",
                "Constantly asking for things to be done immediately without considering my schedule or workload. / मेरी अनुसूची या काम के बारे में ध्यान नहीं देते हुए तुरंत काम कराने की बार-बार मांग करना।",
                "Repeatedly bringing up past mistakes or failures. / बार-बार पिछली गलतियों या असफलताओं का जिक्र करना।",
                "Not giving me space or time to relax and unwind. / मुझे आराम करने और विश्राम करने का समय न देना।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "78",
            "question": "Do I nag? / क्या मैं आपको नाग करता हूँ?",
            "options": [
                "Sometimes, but it's usually because I care about you and want things done. / कभी-कभी, लेकिन यह आमतौर पर इसलिये होता है क्योंकि मुझे आपसे परवाह है और मुझे चीजें होनी चाहिए।",
                "No, you communicate your concerns clearly without nagging. / नहीं, आप चिंताओं को स्पष्ट रूप से बताते हैं और परेशान नहीं करते।",
                "Sometimes, but it's unintentional and you're open to feedback. / कभी-कभी, लेकिन यह अनजाने में होता है और आप प्रतिक्रिया के लिये खुले हैं।",
                "Yes, and it can be overwhelming at times. / हां, और कभी-कभी यह थोड़ा अत्यधिक हो सकता है।",
                "No, you rarely if ever nag about anything. / नहीं, आप किसी भी चीज के बारे में शायद ही कभी शिकायत करते हैं।",
                "None of the above. / उपरोक्त में से कोई भी नहीं।",
                "Other: Write your own answer. / अन्य: अपना उत्तर लिखें।"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "79",
            "question": "How does it make you feel?",
            "options": [
                "It can make me feel frustrated or annoyed.",
                "Sometimes I feel hurt or upset because it feels like you don't trust me to get things done.",
                "It can be overwhelming and make me feel like I'm not doing enough.",
                "Sometimes I feel guilty if I realize you're right and I haven't been doing something I should have.",
                "I might feel motivated to improve if it's something I hadn't noticed before.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "80",
            "question": "Do you approve without reservation of the way I dress?",
            "options": [
                "Yes, I love your sense of style and how you dress.",
                "Most of the time, but there are occasional preferences I have.",
                "No, there are certain things I wish you would change or improve.",
                "Sometimes, it depends on the occasion or situation.",
                "I have some reservations, but overall I appreciate your style.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "81",
            "question": "What does my family do that annoys you?",
            "options": [
                "They interfere too much in our personal matters.",
                "They are overly critical or judgmental.",
                "They don't respect our boundaries or privacy.",
                "They are too traditional or conservative in their views.",
                "They compare us to others or make unfair comparisons.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "82",
            "question": "Would it bother you if I made body noises all the time, like passing gas or burping?",
            "options": [
                "Yes, it would bother me, as I prefer more discretion in public settings.",
                "No, it wouldn't bother me, as it's natural and everyone does it.",
                "It would bother me occasionally, especially in certain situations.",
                "It depends on how frequent and loud it is.",
                "Initially, it might bother me, but I could get used to it over time.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "83",
            "question": "Is there anything you do in your line of work that I would disapprove of or that would hurt me?",
            "options": [
                "No, everything I do aligns with our values and won't harm our relationship.",
                "Yes, there might be aspects of my work that could upset you, but I'm open to discussing them.",
                "I don't think so, but if there are any concerns, I'm willing to address them.",
                "There might be occasional conflicts of interest, but I ensure they won't affect us negatively.",
                "I don't foresee any issues, but if there are, I promise to communicate openly with you.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "84",
            "question": "Do you believe that you should stick with a marriage if you are unhappy all the time?",
            "options": [
                "Yes, marriage is a commitment and should be worked on even during tough times.",
                "No, if you're constantly unhappy, it's better to seek happiness elsewhere.",
                "It depends on the situation and efforts to resolve issues within the marriage.",
                "Yes, sometimes unhappiness is temporary and can be overcome with patience and effort.",
                "No, it's important to prioritize your own mental and emotional well-being.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "85",
            "question": "When do you need space away from me?",
            "options": [
                "When I'm feeling overwhelmed or stressed and need time to recharge.",
                "Sometimes, when I want to pursue my own hobbies or interests.",
                "When I need time for self-reflection and introspection.",
                "During times of conflict or disagreement, to cool off and gather my thoughts.",
                "When I need to focus on work or personal projects without distractions.",
                "None of the above."
            ],
            "selectedAnswer": ""
        },
        {
            "id": "86",
            "question": "How would you rank all the priorities in your life: work, school, family, spouse, friends, hobbies, and church? Does your ranking reflect the amount of time you spend on each?",
            "options": [
                "My ranking would be family, spouse, work, friends, hobbies, school, and religion, reflecting the time I spend on each.",
                "Family, spouse, and friends are my top priorities, followed by work, hobbies, school, and religion, which align with my time allocation.",
                "Work and family come first for me, followed by spouse, friends, hobbies, school, and religion, reflecting my time distribution.",
                "Family, spouse, and religion hold the highest importance for me, followed by work, friends, hobbies, and school, aligning with my time commitments.",
                "Every aspect of my life is important, but I prioritize family, work, and friends, then spouse, hobbies, school, and religion, which reflect my time allocation.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "87",
            "question": "Are you closer to your mother or father? Why?",
            "options": [
                "I am closer to my mother because she has been my primary caregiver and emotional support throughout my life.",
                "I am closer to my father because he has been my mentor and role model, guiding me in various aspects of life.",
                "I am equally close to both my parents as they have contributed equally to my upbringing and well-being.",
                "I am closer to my mother because I find it easier to share my emotions and feelings with her.",
                "I am closer to my father because we share similar interests and hobbies, which have strengthened our bond.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "88",
            "question": "Do you prefer a set daily work schedule or flexible work activities and timetables?",
            "options": [
                "I prefer a set daily work schedule for better organization and productivity.",
                "I prefer flexible work activities and timetables as they allow me to manage my time according to my needs.",
                "I prefer a mix of both, where I have a basic structure but also the flexibility to adapt to changes.",
                "I prefer a set daily work schedule for maintaining consistency and routine in my tasks.",
                "I prefer flexible work activities and timetables to accommodate unexpected changes and personal commitments.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "90",
            "question": "What do you fear?",
            "options": [
                "I fear failure and not living up to my potential.",
                "I fear losing loved ones and being alone.",
                "I fear uncertainty and not knowing what the future holds.",
                "I fear rejection and not being accepted for who I am.",
                "I fear losing my sense of purpose and direction in life.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "91",
            "question": "What influence, if any, do you believe my family should have on our relationship?",
            "options": [
                "I believe your family should have a supportive and positive influence on our relationship, fostering harmony and understanding.",
                "I believe your family's influence should be limited to offering guidance and support when needed, respecting our independence as a couple.",
                "Your family's influence should be balanced, neither dominating nor interfering excessively in our relationship.",
                "I believe your family's influence should be minimal, allowing us to navigate our relationship independently.",
                "Your family's influence should be supportive and respectful of our decisions, enhancing our relationship rather than causing conflicts.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "92",
            "question": "Do you believe that our parents should know our financial condition, whether good or bad, just because they want to? How far should this go?",
            "options": [
                "Yes, our parents should know about our financial condition because they care about our well-being and can offer valuable advice.",
                "Our parents have a right to know about our financial condition, but the extent of information shared should depend on the level of trust and communication in our relationship.",
                "Our parents should know about our financial condition, but the details shared should be limited to maintain privacy and independence.",
                "Our parents should be informed about our financial condition if they are directly affected by it, but otherwise, it's not necessary to disclose everything.",
                "Our parents' knowledge about our financial condition should be based on mutual trust and respect, and we should share relevant information accordingly.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "93",
            "question": "What are your views on pornography?/ पोनोग्रािी पर आपके क्या विचार हैं?",
            "options": [
                "I believe pornography can have negative effects on relationships and individuals, promoting unrealistic expectations and objectification. / मुझे लगता हैवक पोनोग्रािी सोंबोंधोंऔर व्यस्क्तयोोंपर नकारात्मक प्रभाि डाल सकती है, अिास्तविक अपेक्षाओोंऔर िस्त्रालोंकरण को प्रोत्सावहत करती है।",
                "I believe pornography is a personal choice, but it's important to be aware of its potential impact on mental health and relationships. / मुझेलगता हैवक पोनोग्रािी एक व्यस्क्तगत चुनाि है, लेवकन इसके मानवसक स्वास्थ्य और सोंबोंधोों पर पोर्ेंवर्यल प्रभाि के बारेमेंजागरूक रहना जरूरी है।",
                "I am against pornography as it often portrays unhealthy and unrealistic representations of sex and relationships. / मैंपोनोग्रािी के स्खलाि हूँक्योंवक यह अक्सर अस्वस्थ और अिास्तविक सेक्स और सोंबोंधोोंकी प्रवतष्ठाओोंको वदखाती है।",
                "I believe in moderation and responsible consumption of pornography, understanding its potential impacts on individuals and relationships. / मुझे सामान्यता और वजम्मेदार रूप सेपोनोग्रािी का उपभोग करनेका विश्वास है, वजसमेंइसके व्यस्क्तयोोंऔर सोंबोंधोोंपर पोर्ेंवर्यल प्रभािोोंको समझा जाता है।",
                "I believe pornography should be regulated and restricted to protect vulnerable individuals and promote healthy attitudes towards sex and relationships. / मुझेलगता हैवक पोनोग्रािी को वनयोंवत्रत और प्रवतबोंवधत वकया जाना चावहए तावक असमथपव्यस्क्तयोोंकी सुरक्षा हो सके और सेक्स और सोंबोंधोोंके प्रवत स्वस्थ दृविकोण को प्रोत्सावहत वकया जा सके ।",
                "None of the above: [User can select this option if none of the provided answers fit their response.]",
                "Other: [User can provide their own response]"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "94",
            "question": "How would you react if our son or daughter told us they were gay?/ यवद हमारा बेर्ा या बेर्ी हमेंबताए वक िेसमलैंवगक हैंतो आपकी क्या प्रवतविया होगी?",
            "options": [
                "I. I would love and support them unconditionally, their sexual orientation doesn't change how much I care for them. / मैंउन्हेंवनरपेक्षता सेप्यार और समथपन करूों गा, उनकी लैंवगक सोंिेदना मुझेउनसेप्यार करनेकी मात्रा मेंकोई पररितपन नहीोंकरती।",
                "II. I would listen to them empathetically and reassure them that they are loved and accepted just as they are. / मैंउनको सहानुभूवत सेसुनूोंगा और उन्हें यह पुनः पुवि करूों गा वक िेउसी तरह सेप्यार वकए और स्वीकार वकए जातेहैं।",
                "III. I would educate myself more about LGBTQ+ issues and provide a safe and supportive environment for our child. / मैंस्वयों को LGBTQ+ मुद्दोोंके बारेमें अवधक वर्वक्षत करूों गा और हमारेबच्चेके वलए एक सुरवक्षत और समथपक िातािरण प्रदान करूों गा।",
                "IV. I would stand up for our child and defend their right to love who they choose, regardless of societal norms. / मैंहमारेबच्चेके वलए उठ खड़ा होऊों गा और उनके चुनेहुए वकसी सेभी प्यार करनेके अवधकार की रक्षा करूों गा, सामावजक मानकोोंके बािजूद।",
                "V. I would seek guidance from LGBTQ+ support groups and professionals to ensure we provide the best support for our child. / मैंLGBTQ+ समथपन समूहोोंऔर पेर्ेिरोोंसेमागपदर्पन लेंगा तावक हम हमारेबच्चेके वलए सिोत्तम समथपन प्रदान कर सकें ।",
                "VI. None of the above: [User can select this option if none of the provided answers fit their response.]",
                "VII. Other: [User can provide their own response]"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "95",
            "question": "Do you harbor any racial prejudice?/ क्या आप वकसी नस्लीय पूिापग्रह सेग्रस्त हैं?",
            "options": [
                "I. I strive to treat everyone equally and fairly, regardless of race or ethnicity. / मैंसभी को न्यायसोंगतता सेऔर बराबरी सेव्यिहार करनेका प्रयास करता हूँ, जैसा वक उनकी जावत या नस्ल हो।",
                "II. I am committed to challenging any racial prejudice within myself and actively working to overcome it. / मैंअपनेअोंदर वकसी भी जावतगत पूिापग्रह को चुनौती देनेके वलए प्रवतबद्ध हूँऔर इसेपररस्स्थवतगत रूप सेपररभावषत करनेके वलए काम कर रहा हूँ।",
                "III. I believe in celebrating diversity and embracing people from all racial backgrounds with respect and acceptance. / मैंविविधता का मानता हूँऔर सम्मान और स्वीकृ वत के साथ सभी जावतगत पृष्ठभूवमयोोंसेलोगोंका स्वागत करता हूँ।",
                "IV. I am conscious of the social injustices faced by marginalized communities and strive to be an ally in fighting against racism. / मैंसामावजक अन्यायोोंको ध्वस्त करनेिाली समुदायोोंद्वारा वकए जानेिालेअन्यायोोंके बारेमेंजागरूक हूँऔर जावतिाद के स्खलाि लड़नेमेंएक सहयोगी बननेका प्रयास करता हूँ।",
                "V. I believe that racial prejudice has no place in our society and actively work to challenge discriminatory attitudes and behaviors. / मुझेयह मान्यता हैवक हमारेसमाज मेंजावतगत पूिापग्रह का कोई स्थान नहीोंहैऔर भेदभािपूणप धारणाओोंऔर व्यिहारोोंको चुनौती देनेके वलए सविय रूप सेकाम करता हूँ।",
                "VI. None of the above: [User can select this option if none of the provided answers fit their response.]",
                "VII. Other: [User can provide their own response]"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "96",
            "question": "How do you feel about having guns in our home?/ हमारेघर मेंबोंदू केंहोनेपर आपको कै सा लगता है?",
            "options": [
                "I. I am comfortable with having guns in our home for self-defense and protection. / मैंअपनेघर मेंबोंदू कोोंके होनेपर स्वणपरक्षा और सुरक्षा के वलए सोंतुि हूँ।",
                "II. I am not comfortable with guns in our home due to safety concerns and potential risks. / मुझेसुरक्षा के वचोंता और सोंभावित खतरोोंके कारण हमारेघर में बोंदू कोोंके साथ आराम नहीोंहै।",
                "III. I believe in strict gun control measures and prefer not to have guns in our home. / मैंसख्त बोंदू क वनयोंत्रण उपायोोंमेंविश्वास करता हूँऔर हमारेघर में बोंदू कोोंका होना पसोंद नहीोंकरता।",
                "IV. I am open to having guns in our home but only after proper training and safety precautions. / मैंअपनेघर मेंबोंदू कोोंके होनेके वलए खुलेमन सेहूँलेवकन सही प्रवर्क्षण और सुरक्षा सािधावनयोोंके बाद।",
                "V. I am indifferent towards having guns in our home and leave the decision to my partner. / मैंअपनेघर मेंबोंदू कोोंके होनेके प्रवत उदासीन हूँऔर इस िै सलेको अपनेसाथी को सौोंपता हूँ।",
                "VI. None of the above: [User can select this option if none of the provided answers fit their response.]",
                "VII. Other: [User can provide their own response]"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "97",
            "question": "Is there anyone close to you who feels we should not get married? Why?/ क्या आपके करीब कोई हैवजसेलगता हैवक हमेंर्ादी नहीोंकरनी चावहए? क्योों?",
            "options": [
                "I. Yes, there is someone close who feels we should not get married because they doubt our compatibility. / हाों, कु छ नजदीकी लोग हैंजो मानतेहैंवक हमें वििाह नहीोंकरना चावहए क्योंवक उन्हेंहमारी अनुकू लता पर सोंदेह है।",
                "II. No, there is no one close to me who feels we should not get married. / नहीों, मेरेपास ऐसा कोई नजदीकी व्यस्क्त नहीोंहैजो मानेवक हमें वििाह नहीोंकरना चावहए।",
                "III. Yes, there is someone close who feels we should not get married due to financial concerns. / हाों, कुछ नजदीकी लोग हैंजो मानतेहैंवक हमें वििाह नहीों करना चावहए क्योंवक उन्हेंआवथपक वचोंताओोंका सामना करना हो रहा है।",
                "IV. Yes, there is someone close who feels we should not get married because of cultural or religious differences. / हाों, कुछ नजदीकी लोग हैंजो मानतेहैंवक हमें वििाह नहीों करना चावहए क्योंवक सांस्कृतक या धार्मक विभाव में अंतर है।",
                "V. Yes, there is someone close who feels we should not get married for personal reasons that they haven't shared. / हाों, कुछ नजदीकी लोग हैंजो मानतेहैंवक हमें वििाह नहीों करना चावहए क्योंवक व्यक्तगत कारणोंके लिए जोवे उन्होोंने साझाकिया है।",
                "VI. None of the above: [User can select this option if none of the provided answers fit their response.]",
                "VII. Other: [User can provide their own response]"
            ],
            "selectedAnswer": ""
        },

        {
            "id": "98",
            "question": "What health problems do you have?",
            "options": [
                "I have asthma, which requires occasional medication and monitoring.",
                "I suffer from hypertension and take regular medication to manage it.",
                "I have a history of allergies, particularly to pollen and dust, which can cause respiratory issues.",
                "I am diabetic and need to regulate my diet and take insulin regularly.",
                "I have a back problem that occasionally requires physiotherapy and pain management.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "99",
            "question": "Have you ever had any psychological problems?",
            "options": [
                "I have struggled with anxiety in the past, but I have sought therapy and coping mechanisms to manage it.",
                "I have experienced depression in the past, but with therapy and support, I have been able to overcome it.",
                "I have faced trauma in my life, which has affected my mental health, but I am working through it with therapy.",
                "I have struggled with obsessive-compulsive disorder (OCD) in the past, but therapy has helped me manage it better.",
                "I have never experienced any psychological problems.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "100",
            "question": "When you are in a bad mood, how should I deal with it?",
            "options": [
                "Give me space and time to cool down, then we can talk about what's bothering me.",
                "Offer me a comforting gesture like a hug or a kind word to let me know you're there for me.",
                "Listen to me without judgment and try to understand my perspective without getting defensive.",
                "Engage me in a distracting or enjoyable activity to help shift my focus from the negative mood.",
                "Respect my need for solitude and give me space to process my emotions on my own.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "101",
            "question": "Do you like pets? Should we get one?",
            "options": [
                "Yes, I love pets and think having one would bring joy and companionship to our lives.",
                "No, I am not a fan of pets and prefer to keep our home pet-free.",
                "Yes, I like pets, but I'm concerned about the responsibility and care they require.",
                "I am indifferent towards pets and don't have a strong preference either way.",
                "I have allergies or other issues that make having pets difficult for me.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        },
        {
            "id": "102",
            "question": "What pet should we get and who will take care of it?",
            "options": [
                "We should get a dog because they are loyal companions, and we can share the responsibility of taking care of it.",
                "Let's consider getting a cat as they are independent and low-maintenance pets, and we can both take care of it.",
                "We could get a fish tank because it's a low-maintenance option and adds tranquility to our home, and we can share the responsibility.",
                "Let's consider getting a bird because they are beautiful and can be a source of joy, and we can take turns caring for it.",
                "We should not get a pet at the moment as we may not have the time or resources to properly care for it.",
                "None of the above.",
                "Other"
            ],
            "selectedAnswer": ""
        }
        // Add more questions as needed
    ]);

    const handleAnswerSelect = (questionId, selectedAnswer, question) => {
        console.log("quest id log", questionId)
        console.log("sel ans log", selectedAnswer)
        console.log("ques log", question)
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) =>
                question.id === questionId ? { ...question, selectedAnswer } : question
            )
        );
    };


    const renderQuestions = () => {
        return questions.map((question) => (
            <div key={question.id} className="question-container">
                <h3>{`Question ${question.id}: ${question.question}`}</h3>
                <ul>
                    {question.options.map((option, index) => (
                        <li key={index}>
                            <label>
                                <input
                                    type="radio"
                                    name={`question_${question.id}`}
                                    value={option}
                                    checked={question.selectedAnswer === option}
                                    onChange={() => handleAnswerSelect(question.id, option, question.question)}
                                />
                                {option}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };


    return (
        <div className="cen-div">

            <div className="center-div">
                <div className="centered-div-1">

                    <div>

                        <div>
                            <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
                            <h2 style={{ textAlign: 'center' }}>Questions</h2>
                        </div>
                        <div className="psychological-test-container">
                            <h4>Category-I: Trust (Eight Questions )/ श्रेणी-I: विश्वास (आठ
                                प्रश्)।</h4>
                            {renderQuestions()}
                            <div className='d-flex justify-content-center align-items-center'>

                                <button onClick={handleShowModal} type="button" class="btn btn-success">
                                    Submit</button>
                            </div>
                        </div>

                    </div>


                </div>

            </div>


            <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


        </div>
    )
}

export default Hundredquestiontest


