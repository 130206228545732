import React from "react";
import WatermarkedImage from "./WatermarkedImage";

const SampleApp = () => {
  const imageUrl ="https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg"
  const watermarkText = "INDIANMATCHMAKING.CO.IN";

  return (
    <div>
      <WatermarkedImage imageUrl={imageUrl} watermarkText={watermarkText} />
    </div>
  );
};

export default SampleApp;
