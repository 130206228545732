// import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { makeStyles } from "@material-ui/core/styles";
import './navbars.css'
import { useHistory } from "react-router-dom";

import React, { useState } from 'react';
import {
    // AppBar, Toolbar, Typography, Box, Button, IconButton,
    Drawer, List, ListItem, ListItemText
} from '@mui/material';

const drawerWidth = 240;
const navItems = ['Home', 'Service', 'Packages', 'Social Forum', 'Community', 'Blog', 'Contact Us'];



// import MenuIcon from '@mui/icons-material/Menu';
// import { useHistory } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ElevationScroll(props) {
    const { children, window } = props;

    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        position: trigger ? 'fixed' : 'relative',
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,

};



export default function NavBar(props) {



    const [mobileOpen, setMobileOpen] = useState(false);
    const history = useHistory();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuClick = (path) => {
        history.push(path);
        setMobileOpen(false);
    };

    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //         <List>
    //             {['Home', 'Service', 'Packages', 'Test Yourself', 'Social Forum', 'Community', 'Contact Us', 'FAQ'].map((text) => (
    //                 <ListItem button key={text} onClick={() => handleMenuClick(text.toLowerCase().replace(' ', ''))}>
    //                     <ListItemText primary={text} />
    //                 </ListItem>
    //             ))}
    //         </List>
    //     </Box>
    // );

    const handleOpenHomePage = () => {

        console.log(localStorage.getItem('authToken'));
        console.log(JSON.parse(localStorage.getItem('user_data')));

        let user_data = JSON.parse(localStorage.getItem('user_data'))
        if (localStorage.getItem('authToken') == null) {
            history.push('login')
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Use 'auto' for instant scroll
            });
        } else {
            history.push(`/ProfileN/${btoa(user_data?.email)}`, { state: { email: user_data?.email } });
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Use 'auto' for instant scroll
            });
        }
    }

    const [openTest, setOpenTest] = React.useState(false);
    const handleOpenTest = () => {

        console.log(localStorage.getItem('authToken'));
        console.log(JSON.parse(localStorage.getItem('user_data')));
        let user_data = JSON.parse(localStorage.getItem('user_data'))
        if (localStorage.getItem('authToken') == null) {
            setOpenTest(true);
        } else {
            setOpen(true);
        }



    }


    const handleCloseTest = () => setOpenTest(false);


    const handleOpenPackages = () => {

        console.log(localStorage.getItem('authToken'));
        console.log(JSON.parse(localStorage.getItem('user_data')));
        let user_data = JSON.parse(localStorage.getItem('user_data'))
        if (localStorage.getItem('authToken') == null) {
            history.push('/Homepagepackage');
        } else {
            history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "Login" } });
        }
    }




    const handleSignUpTest = () => {
        setOpenTest(false);
        // history.push('/signup');
        history.push('/');
    }

    const handleOpenService = () => {
        history.push('/Service');
    }

    const handleOpenFAQ = () => {
        history.push('/FaqFile');
    }


    const handleOpenContactus = () => {
        history.push('/Contactus');
    }

    const handleOpenSocialforum = () => {
        history.push('/Socialforum');
    }

    const handleOpenCommunity = () => {
        history.push('/Community');
    }

    // const [mobileOpen, setMobileOpen] = React.useState(false);
    // const handleDrawerToggle = () => {
    //     setMobileOpen((prevState) => !prevState);
    // };
    const pages = ['Products', 'Pricing', 'Blog'];
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const [Psychologicalmodel, setPsychologicalmodel] = React.useState(false)


    const handleTest = (val) => {
        console.log(localStorage.getItem('packagePaymentStatus'));
        let packagePaymentStatus = localStorage.getItem('packagePaymentStatus')

        console.log(JSON.parse(localStorage.getItem('user_data')));
        let user_data = JSON.parse(localStorage.getItem('user_data'))

        setOpen(false);
        if (val == 'psy') {
            history.push('/psychologicaltests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
        }


        // BloodgroupingTests
        else if (val == 'blood') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "BloodgroupingTests" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/BloodgroupingTests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }


            // console.log(JSON.parse(localStorage.getItem('user_data')));
            // let user_data = JSON.parse(localStorage.getItem('user_data'))
            // if (localStorage.getItem('authToken') == null) {
            //     history.push('/Homepagepackage');
            // } else {
            //     history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "Login" } });
            // }


        } else if (val == 'thal') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "ThalassemiaTests" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/ThalassemiaTests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }


        } else if (val == 'std') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "SexuallytransmittedDiseases" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/SexuallytransmittedDiseases', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }


        } else if (val == 'infer') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "InfertilityScreening" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/InfertilityScreening', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }


        } else if (val == 'chr') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "Chronicdisorders" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Chronicdisorders', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }


        } else if (val == 'hor') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", fromPage: "HoroscopeTest" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/HoroscopeTest', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
            }



        }

    }


    // const handleTest = (val) => {
    //     setOpen(false);
    //     // if (val == 'psy') {
    //     //     history.push('/psychologicaltests');
    //     // } else if (val == 'blood') {
    //     //     history.push('/BloodgroupingTests');
    //     // } else if (val == 'thal') {
    //     //     history.push('/ThalassemiaTests');
    //     // } else if (val == 'std') {
    //     //     history.push('/SexuallytransmittedDiseases');
    //     // } else if (val == 'infer') {
    //     //     history.push('/InfertilityScreening');
    //     // } else if (val == 'chr') {
    //     //     history.push('/Chronicdisorders');
    //     // }

    //     console.log(JSON.parse(localStorage.getItem('user_data')));

    //     let user_data = JSON.parse(localStorage.getItem('user_data'))

    //     if (val == 'psy') {
    //         history.push('/psychologicaltests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'blood') {
    //         history.push('/BloodgroupingTests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'thal') {
    //         history.push('/ThalassemiaTests', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'std') {
    //         history.push('/SexuallytransmittedDiseases', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'infer') {
    //         history.push('/InfertilityScreening', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'chr') {
    //         history.push('/Chronicdisorders', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     } else if (val == 'hor') {
    //         history.push('/HoroscopeTest', { state: { loggedUsersData: user_data, loggedinUser: user_data } });
    //     }

    // }


    const handleClick = () => {
        setOpen(true);
        // Navigate to the '/target' route when the button is clicked
        // history.push('/test  yourself');
        console.log("click")
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
        setOpen(false)
    };




    const handlePsychologicalmodelclose = () => {
        setPsychologicalmodel(true);
    };

    const handlePsychologicalmodelopen = () => {
        setPsychologicalmodel(false);
    };


    const drawer = (
        <Box
            onClick={handleDrawerToggle}
            sx={{ textAlign: 'center', padding: '1rem' }}
        >
            <Box
                className="ButtonBox"
                sx={{
                    gap: '2rem',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {localStorage.getItem('authToken') != '' && localStorage.getItem('authToken') != null ?
                    <Button className="Button" sx={{ color: '#fff' }} onClick={handleOpenHomePage}>
                        Back
                    </Button>
                    :
                    null
                }
                <Button className="Button" sx={{ color: '#000' }} onClick={handleOpenHomePage}>
                    Home
                </Button>
                {/* <Button className="Button" onClick={handleOpenService} sx={{ color: '#000' }}>
                    Service
                </Button> */}
                <Button className="Button" sx={{ color: '#000' }} onClick={handleOpenPackages}>
                    Packages
                </Button>
                <Button className="Button" onClick={handleOpenTest} sx={{ color: '#000' }}>
                    Test Yourself
                </Button>
                {/* <Button className="Button" onClick={handleOpenSocialforum} sx={{ color: '#000' }}>
                    Social Forum
                </Button>
                <Button className="Button" onClick={handleOpenCommunity} sx={{ color: '#000' }}>
                    Community
                </Button> */}
                <Button className="Button" onClick={handleOpenContactus} sx={{ color: '#000' }}>
                    Contact Us
                </Button>
                <Button className="Button" onClick={handleOpenFAQ} sx={{ color: '#000' }}>
                    FAQ
                </Button>
            </Box>
        </Box>
    );
    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //       <List>
    //         <ListItem button onClick={() => handleMenuClick('/login')}>
    //           <ListItemText primary="Home" />
    //         </ListItem>
    //         <ListItem button onClick={handleOpenService}>
    //           <ListItemText primary="Service" />
    //         </ListItem>
    //         <ListItem button onClick={handleMenuClick('/packages')}>
    //           <ListItemText primary="Packages" />
    //         </ListItem>
    //         <ListItem button onClick={handleMenuClick('/test')}>
    //           <ListItemText primary="Test Yourself" />
    //         </ListItem>
    //         <ListItem button onClick={handleOpenSocialforum}>
    //           <ListItemText primary="Social Forum" />
    //         </ListItem>
    //         <ListItem button onClick={handleOpenCommunity}>
    //           <ListItemText primary="Community" />
    //         </ListItem>
    //         <ListItem button onClick={handleOpenContactus}>
    //           <ListItemText primary="Contact Us" />
    //         </ListItem>
    //         <ListItem button onClick={handleOpenFAQ}>
    //           <ListItemText primary="FAQ" />
    //         </ListItem>
    //       </List>
    //     </Box>
    //   );

    // const history = useHistory();
    const style1 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };





    return (
        <React.Fragment>


            {/* <CssBaseline /> */}

            <ElevationScroll {...props}>
                <AppBar className="AppBar">

                    <Toolbar className="Toolbar" sx={{ width: '100%', backgroundColor: '#861f41' }}>


                        <Typography className="Typography" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Box className="ButtonBox" sx={{ gap: '2rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                {localStorage.getItem('authToken') != '' && localStorage.getItem('authToken') != null ?
                                    <Button className="Button" sx={{ color: '#fff' }} onClick={handleOpenHomePage}>
                                        Back
                                    </Button>
                                    :
                                    null
                                }


                                <Button className="Button" sx={{ color: '#fff' }} onClick={handleOpenHomePage}>
                                    Home
                                </Button>
                                {/* <Button className="Button" onClick={handleOpenService} sx={{ color: '#fff' }}>
                                    Service
                                </Button> */}
                                <Button className="Button" sx={{ color: '#fff' }} onClick={handleOpenPackages}>
                                    Packages
                                </Button>
                                <Button className="Button" onClick={handleOpenTest} sx={{ color: '#fff' }}>
                                    Test Yourself
                                </Button>
                                {/* <Button className="Button" onClick={handleOpenSocialforum} sx={{ color: '#fff' }}>
                                    Social Forum
                                </Button>
                                <Button className="Button" onClick={handleOpenCommunity} sx={{ color: '#fff' }}>
                                    Community
                                </Button> */}
                                <Button className="Button" onClick={handleOpenContactus} sx={{ color: '#fff' }}>
                                    Contact Us
                                </Button>
                                <Button className="Button" onClick={handleOpenFAQ} sx={{ color: '#fff' }}>
                                    FAQ
                                </Button>

                            </Box>
                        </Typography>



                        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                            }}
                        >
                            {drawer}
                        </Drawer>

                    </Toolbar>
                </AppBar>
            </ElevationScroll>





            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style1, width: 400 }}>
                    <h2 id="parent-modal-title">Test List</h2>

                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="custom-links">
                                    <div className='link-like-div' onClick={() => handleTest('psy')}>1. Psychological Tests</div>
                                    <div className='link-like-div' onClick={() => handleTest('blood')}>2. Blood grouping Tests</div>
                                    <div className='link-like-div' onClick={() => handleTest('thal')}>3. Thalassemia Tests</div>
                                    <div className='link-like-div' onClick={() => handleTest('std')}>4. Tests for sexually transmitted Diseases</div>
                                    <div className='link-like-div' onClick={() => handleTest('infer')}>5. Infertility Screening</div>
                                    <div className='link-like-div' onClick={() => handleTest('chr')}>6. Chronic disorders</div>
                                    <div className='link-like-div' onClick={() => handleTest('hor')}>7. Horoscope Test</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <form style={{
                        maxWidth: '400px',
                        margin: '0 auto',
                        padding: '20px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <label>1. What is Your Marital Staus?</label>
                            <label style={{ marginBottom: '10px' }}>
                                <input type="radio" name="relocate" value="yes" defaultChecked /> Single
                            </label>
                            <label style={{ marginBottom: '10px' }}>
                                <input type="radio" name="relocate" value="no" /> Married
                            </label>
                            <label style={{ marginBottom: '10px' }}>
                                <input type="radio" name="relocate" value="no" /> Divorced
                            </label>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <label>2. Are you open to an inter-caste marriage?</label>
                            <label style={{ marginBottom: '10px' }}>
                                <input type="radio" name="interCaste" value="yes" defaultChecked /> Yes
                            </label>
                            <label style={{ marginBottom: '10px' }}>
                                <input type="radio" name="interCaste" value="no" /> No
                            </label>
                        </div>

                    </form> */}


                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <button
                            onClick={handleClose}
                            type="button"

                            style={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                padding: '10px 15px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleOpen1}
                            type="button"

                            style={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                padding: '10px 15px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Submit
                        </button>

                    </div> */}
                </Box>
            </Modal>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style1, width: 200 }}>
                    <h2 id="child-modal-title">Test Result</h2>
                    <p id="child-modal-description">
                        Your form has submitted successfully
                    </p>
                    <button
                        onClick={handleClose1}
                        type="button"

                        style={{
                            backgroundColor: '#4caf50',
                            color: 'white',
                            padding: '10px 15px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >Download your Result</button>
                </Box>
            </Modal>





            <Modal
                open={Psychologicalmodel}
                onClose={handlePsychologicalmodelclose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style1, width: 200 }}>
                    <h2 id="child-modal-title">Psychological Tests</h2>
                    <div>

                        It will show in know (more icon) Why is it important: In a country where arranged
                        marriages are the norm, would you know, just by looking at a person that he/she has a mental
                        illness? Or that he/she suffers from depression, schizophrenia or even a personality disorder? The
                        answer is quite clearly no.
                        So, here is what you need to do, get yourself and your partner tested. While, testing for mental
                        illness includes checking for conditions like schizophrenia, depression, mood disorders, mania, etc.
                        the tests should also include behavioral and personality disorders. There is a whole array of things
                        that could put a dent in your marriage. Conditions like obsessive compulsive personality disorder,
                        narcissistic personality disorders, partner relationship problems, anger management issues, bipolar
                        disorder and many more, could be a part of your significant other s life. Remember, these conditions
                        are not compartmentalized, you will end up bearing the brunt of his/her condition. So get tested. A
                        simple psychometric test, which ideally takes a day will tell you about your mental health status.
                        While this is no reason to leave and run ( in the case of a positive diagnosis), the treating
                        psychologist will definitely tell you about how you need to manage the person and the marriage.
                        Marriage counsellors are trained to help people who are planning to get married. They guide them
                        on a variety of subjects like sexual compatibility and intimacy, emotional intimacy, different
                        adjustments that have to be made, interacting and living with in-laws, joint or nuclear families,
                        communication patterns and misinterpretations, etc.
                        What it would entail: This test is called a psychometric test and usually takes a day to do. To do the
                        analysis, a clinical psychologist will administer tests like multiple choice questions, picture analysis
                        etc. that you have to answer. It is simple and your identity will be kept under wraps if you desire. In
                        the end, getting married should be a happy occasion, but it is important to remember that it is a
                        lifelong commitment and knowing what you are getting into, health wise is the best way to succeed.
                        The test is compulsory for the candidate here
                        Test yourself, down load the report to know yourself.
                        1. Satisfaction with life (Each will be leads to new window with description
                        of individual test )
                        2. Personality Test (Each will be leads to new window with description of
                        individual test )
                        3. The Sixteen Personality Factor (Each will be leads to new window with
                        description of individual test )
                        4. 50 Questions You Must Ask Before Getting Married (It will be generate a
                        test report fill up by candidate . )
                        5. 100 Questions You Must Ask Before Getting Married(It will be generate a
                        test report fill up by candidate . )
                        6.
                        All the test report available in the profile. The test report will
                        be download by the candidate itself. On demand and
                        approved by the other candidate, it will be download by
                        others

                    </div>
                    <button
                        onClick={handlePsychologicalmodelclose}
                        type="button"

                        style={{
                            backgroundColor: '#4caf50',
                            color: 'white',
                            padding: '10px 15px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >Close</button>
                </Box>
            </Modal>



            <Modal className="modal-text-black"
                open={openTest}
                onClose={handleCloseTest}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Sign Up / Login Required
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Before giving tests, you have to create your profile.
                        Please click sign up to continue.</Typography>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={handleCloseTest}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={handleSignUpTest}>
                            Sign Up / Login
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </React.Fragment>
    );
}


const useStyles = makeStyles((theme) => ({


    // tophead: {
    //     padding: '4px',
    //     backgroundColor: '#a36178',
    //     display: 'flex',
    //     justifyContent: 'flex-end',
    //     alignItems: 'center',
    //     curser: 'pointer',
    //     display: 'flex',
    //     gap: '23rem',


    // },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "block",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        // "&:hover": {
        //     backgroundColor: alpha(theme.palette.common.white, 0.25),
        // },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    login: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    moreouter: {
        display: "flex"
    },
    more: {
        marginLeft: "12px",
        paddingRight: "12px",
        color: "#861f41"
    },
    sublinks: {
        textDecoration: "none",
        color: "inherit"
    },
    sublinksProfile: {
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        "& svg": {
            marginRight: "5px"
        }
    },
    headericons: {
        width: "63px"
    },


}));
