import React from "react";
import "./faq.css";

const FaqHeader = () => {
    return (
        <div className="faq-header">
            <h1>FAQ Page</h1>
        </div>
    );
};

export default FaqHeader;