import React, { Component, useEffect, useState } from 'react'
import '../MoreProfileDetails/MoreProfileDetails.css'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import RazorpayPaymentForm from '../paymentmethod/RazorpayPaymentForm'

import { Form, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { useLocation } from "react-router-dom";
const PsychologicalTests = () => {

    const searchParams = new URLSearchParams(window?.location?.search);
    const req_body_status = searchParams?.get('status');


    // Your logic for handling failure status
    console.log('Status is', req_body_status);

    // Your logic for handling failure status
    console.log('req_body_status is', req_body_status);

    useEffect(() => {
        if (req_body_status != "" && req_body_status != null) {
            if (req_body_status == "success") {
                alert('Success')
            } else if (req_body_status == "failure") {
                alert('Failure')
            }
        }

    }, [req_body_status])


    const location = useLocation();

    const loggedUsersData_psy = location?.state?.state?.loggedUsersData
    const loggedinUser_psy = location?.state?.state?.loggedinUser

    const [paymentData, setpaymentData] = useState(loggedUsersData_psy);


    console.log('====================================');
    console.log("loggedUsersData_psy", loggedUsersData_psy);
    console.log("loggedinUser_psy", loggedinUser_psy);
    console.log('====================================');
    function handlePayments() {
        alert("Purchase Successful!")
    }

    const [EligibilityModal, setEligibilityModal] = useState(false);
    const history = useHistory();


    const handleClosePaymentModal = () => {
        setEligibilityModal(false);
    };

    const handleEligible = () => {
        // Add your payment logic here
        console.log('Eligible confirmed!');

        // Redirect to the QuestionAnswers page after payment confirmation
        //NOTE:-Redirect all test here by condition ques ans
        history.push('/QuestionAnswers');
    };


    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [showPaymentPopup1, setShowPaymentPopup1] = useState(false);

    const [test_of_title, settest_of_title] = useState("")

    const handlePaymentConfirmation = (test_of) => {
        setShowPaymentPopup(true);
        settest_of_title(test_of)
    };



    const handleClosePaymentPopup = () => {
        setShowPaymentPopup(false);
    };

    const handleClosePaymentPopup1 = () => {
        setShowPaymentPopup1(false);
    };


    const handlePayment = () => {
        // Add your payment logic here
        // history.push('/PayUMoneyform', { state: { test_of_title: test_of_title, name: paymentData?.name, mobile: paymentData?.phone, email: paymentData?.email, amount: "10" } });
        console.log(localStorage.getItem('packagePaymentStatus'));
        let packagePaymentStatus = localStorage.getItem('packagePaymentStatus')

        console.log(JSON.parse(localStorage.getItem('user_data')));
        let user_data = JSON.parse(localStorage.getItem('user_data'))


        if (test_of_title == 'satisfaction_test') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", test_of_title: "satisfaction_test" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Satisfactiontest');
            }
        }

        if (test_of_title == 'personality_test') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", test_of_title: "personality_test" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Personalitytest');
            }
        }

        if (test_of_title == 'Sixteen_personality_factor') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", test_of_title: "Sixteen_personality_factor" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Sixteenpersonalityfactor');
            }
        }

        if (test_of_title == 'fifty_questions') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", test_of_title: "fifty_questions" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Fiftyquestions');
            }
        }

        if (test_of_title == 'hundred_questions') {
            if (packagePaymentStatus == 'NotPaid') {
                history.push('/InnerPackage', { state: { userId: user_data?.userId, name: user_data?.firstName + " " + user_data?.lastName, mobile: user_data?.phone, email: user_data?.email, amount: "1", test_of_title: "hundred_questions" } });
            } else if (packagePaymentStatus == 'Paid') {
                history.push('/Hundredquestiontest');
            }
        }




        // history.push('/PayUMoneyform', { state: { test_of_title: test_of_title, name: paymentData?.name, mobile: paymentData?.phone, email: paymentData?.email, amount: "10" } });

        // console.log('paymentData?.name!', paymentData?.name);
        // setShowPaymentPopup(false);
        // setShowPaymentPopup1(true);
    };


    const handleSubmit = () => {
        // Add your payment logic here
        console.log('Payment handleSubmit!');

        setShowPaymentPopup1(false);
        setEligibilityModal(true)
    };


    return (
        <div className="cen-div">

            <div className="center-div">
                <div className="centered-div-1">

                    <div>
                        <h2 style={{ textAlign: 'center' }}>PSYCOLOGICAL TEST</h2>

                        <span className='text-info fs-4 fw-bold'>Why is it important: </span> In a country where arranged
                        marriages are the norm, would you know, just by looking at a person that he/she has a mental
                        illness? Or that he/she suffers from depression, schizophrenia or even a personality disorder? The
                        answer is quite clearly no. <br />
                        So, here is what you need to do, get yourself and your partner tested. While, testing for mental
                        illness includes checking for conditions like schizophrenia, depression, mood disorders, mania, etc.
                        the tests should also include behavioral and personality disorders. There is a whole array of things
                        that could put a dent in your marriage. Conditions like obsessive compulsive personality disorder,
                        narcissistic personality disorders, partner relationship problems, anger management issues, bipolar
                        disorder and many more, could be a part of your significant other s life. Remember, these conditions
                        are not compartmentalized, you will end up bearing the brunt of his/her condition. So get tested. A
                        simple psychometric test, which ideally takes a day will tell you about your mental health status.
                        While this is no reason to leave and run ( in the case of a positive diagnosis), the treating
                        psychologist will definitely tell you about how you need to manage the person and the marriage.
                        Marriage counsellors are trained to help people who are planning to get married. They guide them
                        on a variety of subjects like sexual compatibility and intimacy, emotional intimacy, different
                        adjustments that have to be made, interacting and living with in-laws, joint or nuclear families,
                        communication patterns and misinterpretations, etc. <br />
                        What it would entail: This test is called a psychometric test and usually takes a day to do. To do the
                        analysis, a clinical psychologist will administer tests like multiple choice questions, picture analysis
                        etc. that you have to answer. It is simple and your identity will be kept under wraps if you desire. In
                        the end, getting married should be a happy occasion, but it is important to remember that it is a
                        lifelong commitment and knowing what you are getting into, health wise is the best way to succeed.
                        The test is compulsory for the candidate here <br />

                    </div>



                    {/* <Button variant="primary" onClick={handlePaymentConfirmation}>
                        Open Payment Confirmation
                    </Button>

                    <PaymentConfirmationPopup
                        show={showPaymentPopup}
                        handleClose={handleClosePaymentPopup}
                        handlePayment={handlePayment}
                    />

                    <Link to="#" onClick={handlePaymentConfirmation}>
                        Open Payment Confirmation
                    </Link> */}



                    <text className='fs-4 fw-bold'>Test yourself, down load the report to know yourself.</text>

                    <br />
                    <Link
                        to="#"
                        // to='/BloodgroupingTests' 
                        onClick={() => handlePaymentConfirmation("satisfaction_test")}
                    >
                        1. Satisfaction with life (Each will be leads to new window with description
                        of individual test )
                    </Link>

                    <br />
                    <Link
                        to="#"
                        // to='/BloodgroupingTests'
                        onClick={() => handlePaymentConfirmation("personality_test")}
                    >
                        2. Personality Test (Each will be leads to new window with description of
                        individual test )
                    </Link>

                    <br />
                    {/* <Link
                        to="#"
                        // to='/BloodgroupingTests'
                        onClick={() => handlePaymentConfirmation("Sixteen_personality_factor")}
                    >
                        3. The Sixteen Personality Factor (Each will be leads to new window with
                        description of individual test )
                    </Link> */}

                    <br />
                    <Link
                        to="#"
                        // to='/BloodgroupingTests'
                        onClick={() => handlePaymentConfirmation("fifty_questions")}
                    >
                        4. 50 Questions You Must Ask Before Getting Married (It will be generate a
                        test report fill up by candidate . )
                    </Link>

                    <br />
                    <Link
                        to="#"
                        // to='/BloodgroupingTests'
                        onClick={() => handlePaymentConfirmation("hundred_questions")}
                    >
                        5. 100 Questions You Must Ask Before Getting Married(It will be generate a
                        test report fill up by candidate.)
                    </Link>

                    <br />



                    {/* <html>
                        <body>
                            <form action='https://test.payu.in/_payment' method='post'>
                                <input type="hidden" name="key" value="JP***g" />
                                <input type="hidden" name="txnid" value="t6svtqtjRdl4ws" />
                                <input type="hidden" name="productinfo" value="iPhone" />
                                <input type="hidden" name="amount" value="10" />
                                <input type="hidden" name="email" value="test@gmail.com" />
                                <input type="hidden" name="firstname" value="Ashish" />
                                <input type="hidden" name="lastname" value="Kumar" />
                                <input type="hidden" name="surl" value="https://apiplayground-response.herokuapp.com/" />
                                <input type="hidden" name="furl" value="https://apiplayground-response.herokuapp.com/" />
                                <input type="hidden" name="phone" value="9988776655" />
                                <input type="hidden" name="hash" value="eabec285da28fd0e3054d41a4d24fe9f7599c9d0b66646f7a9984303fd6124044b6206daf831e9a8bda28a6200d318293a13d6c193109b60bd4b4f8b09c90972" />
                                <input type="submit" value="submit" /> </form>
                        </body>
                    </html> */}



                    {/* <div> <a href='https://test.payumoney.com/url/6Iur9uhoSCYZ' > Pay Now </a> </div> */}
                    {/* <button onClick={() => handlePayments()}>Pay</button> */}
                    {/* <RazorpayPaymentForm /> */}
                </div>

            </div>






















            <PaymentConfirmationPopup
                show={showPaymentPopup}
                handleClose={handleClosePaymentPopup}
                handlePayment={handlePayment}
            />



            <PaymentModal
                show={showPaymentPopup1}
                handleClose={handleClosePaymentPopup1}
                handleSubmit={handleSubmit}
            />



            <EligibilityConfirmationPopup
                show={EligibilityModal}
                handleClose={handleClosePaymentModal}
                handleEligibilityConfirmation={handleEligible}
            />


        </div>
    )
}

// PaymentConfirmationPopup.js


const PaymentConfirmationPopup = ({ show, handleClose, handlePayment }) => {
    return (
        <Modal className="modal-text-black" centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to proceed with the test?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handlePayment}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};



const PaymentForm = ({ handlePayment, handleClose }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCVV] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // You can add your payment processing logic here
        // For simplicity, just calling the handlePayment function
        handlePayment();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="cardNumber">
                <Form.Label>Card Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter card number"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="expiryDate">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="MM/YY"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="cvv">
                <Form.Label>CVV</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter CVV"
                    value={cvv}
                    onChange={(e) => setCVV(e.target.value)}
                    required
                />
            </Form.Group>

            <div className='mt-4 d-flex justify-content-between gap-4'>
                <Button className='mr-2' variant="primary" type="submit">
                    Submit Payment
                </Button>

                <Button className='ml-2' variant="secondary" onClick={handleClose} >
                    Cancel
                </Button>
            </div>

        </Form>
    );
};



const PaymentModal = ({ show, handleClose, handleSubmit }) => {
    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Payment Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PaymentForm handlePayment={handleSubmit}
                    handleClose={handleClose} />
            </Modal.Body>
        </Modal>
    );
};


const EligibilityConfirmationPopup = ({ show, handleEligibleCancel, handleEligibilityConfirmation }) => {
    return (
        <Modal centered show={show} onHide={handleEligibleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Eligibility Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You are eligible to asking 100 Questions ,which Must Ask Before Getting Married.

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEligibilityConfirmation}>
                    OK
                </Button>
                <Button variant="primary" onClick={handleEligibleCancel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default PsychologicalTests