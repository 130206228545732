import React, { useState } from "react";
import "./faq.css";

import FaqHeader from "./faqheader";
import FAQ from "./FAQ";

export default function FaqFile() {
    const [faqs, setFaqs] = useState([
        {
            question: "How to register?",
            answer:
                `Login to https://indianmatchmaking.co.in/
                You can choose to register either as a free member or as a paid member.
                `,
            open: true

        },
        {
            question: "a. Free registration",
            answer: `Provide name, age, gender, e-mail ID.
            Click on Register link and you will have to enter on a couple of pages the details of the person 
            intending to get married.
            After you submit your information, your profile will be automatically created and you shall be 
            given a 'Matrimony ID' generated for future references and login purposes.`,
            open: false
        },
        {
            question:
                "b. Paid registration",
            answer: `Provide name, age, gender, e-mail ID.
            Click on Register link and you will have to enter details of the person intending to get married.
            Choose package for the required period.
            Click on Join Now link and you will find a page on which you could enter the details of the person 
            intending to get married.
            You shall have a 'Matrimony ID' generated for further references and login purposes.`,
            open: false
        },
        {
            question: "I. What is profile validation?",
            answer:
                `All the profiles are validated before inclusion in the database. Express Validation of profile will take 
            just 1 Hour Turn Around Time. Our support team meticulously validates each and every profile 
            carefully on specific criterion before it is added to our database. E-mail will be sent to you once 
            the profile is validated and added.`,
            open: false
        },
        {
            question: "II. On what criteria is a profile validated?",
            answer:
                `Almost every word counts. We categorically ignore profiles that are submitted by underage 
            persons, and profiles that contain abusive content. We reject profiles that contain invalid data 
            such as added for fun/looking for friendship/not related to marriage. We try and maintain the 
            highest quality in the database we serve to ensure only members who are serious about 
            marriage/relationships make use of the service.`,
            open: false
        },
        {
            question: "III. Single registration ",
            answer:
                `a. Can I register on behalf of someone else?
            Yes, you can register on behalf of someone else.
            b. Can I register for my sister/brother and myself with the same Matrimony ID?
            A Matrimony ID identifies each person uniquely. Please register separate profiles for you and your 
            sibling for individual partner searches.
            c. Can I register for my friend, relative or sibling?
            Yes! In fact we have a large number of profiles being registered by parents, siblings, relatives, etc.
            d. Can I register profiles for my sibling and myself with the same e-mail ID?
            No, you can’t! a maximum of one profile (one male and one female) can have the same e-mail ID.
            `,
            open: false
        },
        {
            question: "IV. Profile description - what to enter?",
            answer:
                `a. What should I enter in the profile description field?
            Your profile description is where you reveal yourself - your personality, your likes and dislikes, your 
            educational and family background, career and personal interests, and expectations about your 
            partner. Try to give a clear self-description and a complete picture of what you expect. A good 
            description will always fetch instant responses. Look at the sample profiles below, which are clear, 
            brief and have all the important details in a well-organised manner.
            I am a friendly, fun-loving person. I have a pleasing personality. I hold a bachelors degree in Dental 
            Surgery. I am working as an associate doctor in a private clinic for the past 3 years in Chennai. My 
            hobbies are listening to music (any kind of music, whichever is soothing) and travelling.
            We have an open-minded, religious family. Father is a retired Asst. Engineer from U.P state 
            government and mother is a simple, loving and caring housewife. I have two brothers, both elder 
            and married. The first is an Engineer in Indian Air Force and the second is employed in a reputed 
            glass factory.
            My ideal partner will be someone who is intelligent, caring, broad-minded and has a good sense of 
            humour. I believe that love, trust, understanding and commitment are the four pillars of any 
            relation and this is what I am looking for in my partner.
            b. Can I view sample profiles?
            Yes`,
            open: false
        },
        {
            question: "V. What all information from my profile will be displayed to other members?",
            answer:
                `Free members can view only some of your details. They cannot view your mobile number, 
            horoscope, etc. Paid members can view all your details if you approved it .It will under your 
            control. `,
            open: false
        },
        {
            question: "VI. Can I use the same e-mail ID for multiple registrations?",
            answer:
                `No. You can create a new mail id for 2nd profiles for different persons.`,
            open: false
        },
        {
            question: "VII. What is Mobile Number Verification?",
            answer:
                `Indianmatchmaking is the Indian's only site to display 100% verified mobile numbers. So, unless 
            you verify your mobile number, you cannot use our service. The verification process is done free of 
            cost. Only paid members can view your mobile number and you will also get to know the 
            members who have viewed your mobile number.`,
            open: false
        },
        {
            question: "VIII. How to verify your mobile number?",
            answer:
                `Login to your account. In the My Home page, click on Edit link in the top navigation bar. In the Edit 
            page, under Contact Details, click on Verify link to verify your mobile number.
            Steps to verify your mobile number:
            Call 0-***-***-**** from the number you have provided on our site
            Press 2 for phone verification
            You will be asked to enter your 6 digit IVR PIN
            Enter your PIN and the mobile verification process is complete.`,
            open: false
        },
        {
            question: "IX. How to hide mobile number?",
            answer:
                `Login to your account. In the My Home page, click on Edit Profile section. Under Edit Contact 
            Details, click on edit mobile number link. In the mobile verification page, click on Hide Mobile 
            Number link to hide your mobile number.
            `,
            open: false
        },
        {
            question: "X. Who viewed my mobile number?",
            answer:
                `Login to your account. In the My Home page, under Views, click on the link "members who viewed 
            my mobile number."`,
            open: false
        },
        {
            question: "2. SEARCH PROFILES",
            answer:
                `I. What is Quick Search?
            II. What is Regular Search?
            III. What is Advanced Search?
            IV. Search by Member ID
            V. Search by Members Online
            VI. Keywords Search
            VII. Can I save my Search criteria so that I don't have to set it every time?
            VIII. Search results
            IX. What is partner preference?
            X. How do I view a particular profile?
            XI. I get the error "No matching records found" while searching for the profiles. Why 
            is that so?
            XII. Short-list profiles
            XIII. Block Profiles`,
            open: false
        },
        {
            question: "3. PROFILE CREATION / MODIFICATION",
            answer:
                `I. How do I create a complete profile?
            II. Photos
            III. Horoscopes
            IV. Trust Badge
            V. AstroMatch
            VI. Activation/deactivation
            VII. Modify Profile
            VIII. What are the DO's and DON'Ts while creating a matrimonial profile?`,
            open: false
        },
        {
            question: "4. WAYS TO CONTACT",
            answer:
                `I. Express Interest
            II. Personalised Messages
            III. Chat
            IV. Call / Send SMS`,
            open: false
        },
        {
            question: "5. MEMBERSHIPS",
            answer:
                `I. What is Indianmatchmaking Personalised Matchmaking services?
            II. What is the difference between free membership & paid 
            (Gold/Diamond/Platinum/Assisted) membership?
            III. What are the packages available for your paid services?
            IV. What are the different modes of payment?
            V. I'm not able to make the online payment with my card even though it's a valid 
            one. Why?
            VI. Is online payment secure?
            VII. What is easy payment or doorstep payment?
            VIII. I have made an easy payment request. How long will it take for my request to 
            be processed?
            IX. How do I make the payment through post/courier?
            X. Payments sent to India
            XI. How long will it take to upgrade my profile if I have made the payment by 
            Cheque/DD through post or courier?
            XII. Pay at banks
            XIII. Payment Associates`,
            open: false
        },
        {
            question: "6. LOGIN DETAILS",
            answer:
                `I. How do I log in?
            II. I'm already logged in, but I get a message 'You must login prior to using the 
            matrimonial services'. What should I do?
            III. What is Matrimony ID?
            IV. I forgot my Matrimony ID/Password. What should I do?
            V. How do I change my Password?
            VI. Why is the message Invalid Matrimony ID/E-mail ID or Incorrect Password 
            being displayed when I try to login?
            VII. Can I choose my own Matrimony ID?
            VIII. My Home`,
            open: false
        },
        {
            question: "7. MISCELLANEOUS",
            answer:
                `I. Can I contact the Referee?
            II. How do I contact customer support?
            III. What is 24 x 7 customer care?
            IV. Do you provide any dating/friendship services?
            V. How do I add my success story?
            VI. What is sample description?
            VII. What is "profiles I'm looking for"?
            VIII. What is "profiles looking for me"?
            IX. What is Mutual Match?
            X. What is Daily MatchWatch Archive?
            XI. What is Safety Tips link?
            XII. What is Auto Renewal?
            XIII. How to turn off the auto renewal?
            XIV. What is Money back guarantee?
            XV. When is it possible to give you a 100% refund?
            XVI. Profile Highlighter
            XVII. AstroMatch
            XVIII. Psychological test 
            XIX. Personality Match`,
            open: false
        },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },{
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },{
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },{
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },{
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },
        // {
        //     question:"",
        //     answer:
        //     ``,
        //     open: false
        // },

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div className="">
            <FaqHeader />
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
            </div>
        </div>
    );
}
