import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SaveIcon from "@material-ui/icons/Save";
import { showSuccessNotification } from '../SweetAlert';
import { useHistory } from 'react-router-dom';
import * as environment from '../../coreservices/environment'

const useStyles = makeStyles((theme) => ({

    container: {
        display: 'flex',
        width: '100%',
        height: 'auto',
    },

    leftcolumn: {
        height: '100%',
    },
    rightcolumn: {
        height: '100%',
    },
    centercolumn: {
        height: 'auto',
    },

    leftcolumn: {
        width: '20%',
        backgroundcolor: '#f2f2f2',
    },
    rightcolumn: {
        width: '20%',
        backgroundcolor: '#f2f2f2',
    },

    centercolumn: {
        width: '60%',
        backgroundColor: '#e0e0e0',
    },


    /* Normal state styles */
    // ToggleButton: {
    //     backgroundColor: '#ffffff'; /* Set your default background color */
    //     color: '#000000', /* Set your default text color */
    //   }

    /* Active state styles */
    //   ToggleButton.Mui-selected: {
    //     backgroundColor: '#333333', /* Set your dark background color */
    //     color: '#ffffff', /* Set your light text color */
    //   }

}));

const Preferencepartner = () => {
    const history = useHistory();
    const [mongoid, setmongoid] = useState('')
    const [partnerPreferrenceId, setPartnerPreferrnceId] = useState('');
    const [userId, setUserId] = useState('')
    const u_id = localStorage.getItem("userId")
    const nuserId_1 = localStorage.getItem("nUserId")
    const [maritalstatus, setMaritalStatus] = React.useState('');
    const [fromage, setFromAge] = useState('18');
    const [toage, setToAge] = useState('18');
    const [fromheight, setFromHeight] = useState('18');
    const [toheight, setToHeight] = useState('18');
    const [motherTongue, setMotherTongue] = useState('');
    const [caste, setCaste] = useState('');
    const [subCaste, setSubCaste] = useState('');
    const [star, setStar] = useState('');
    const [education, setEducation] = useState('');
    const [employedIn, setEmployedIn] = useState('');
    const [annualIncome, setAnnualIncome] = useState('');
    const [countryLivingIn, setCountryLivingIn] = useState('');
    const [foodHabit, setFoodHabit] = useState('');
    const [smokingHabit, setSmokingHabit] = useState('');
    const [drinkingHabit, setDrikingHabit] = useState('');
    const [partnerpreferencedata, setPartnerpreferencedata] = useState([])

    // const storedData = localStorage.getItem('myData');
    const storedData = localStorage.getItem('userId');
    const storedemail = localStorage.getItem('email');

    // if (storedData !== null) {
    // const parsedData = JSON.parse(storedData);
    // setUserId(parsedData);
    // } else {
    //     console.log('Item not found in localStorage');
    // }
    useEffect(() => {
        if (storedData != '' && storedData != null) {

            getData();
        }

    }, [storedData]);
    // }, []);
    function getData() {
        // Define the URL for fetching data
        // const fetchDataUrl = `${environment.apiUrl}/partnerpreferrence/${JSON.parse(storedData)}`;
        const fetchDataUrl = `${environment.apiUrl}/partnerpreferrence/findpref/${storedData}`;

        fetch(fetchDataUrl)
            .then(response => {

                if (response) {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                }

            })
            .then(data => {
                if (data) {
                    console.log('Data:1', data);
                    setmongoid(data._id);
                    setPartnerpreferencedata(data);
                    setPartnerPreferrnceId(data.partnerPreferrenceId);
                    setUserId(data.userId);
                    setMaritalStatus(data.maritalStatus);
                    setFromAge(data.fromAge);
                    setToAge(data.toAge);
                    setFromHeight(data.fromHeight);
                    setToHeight(data.toHeight);
                    setMotherTongue(data.motherTongue);
                    setCaste(data.caste);
                    setSubCaste(data.subCaste);
                    setStar(data.star);
                    setEducation(data.education);
                    setEmployedIn(data.employedIn);
                    setAnnualIncome(data.annualIncome);
                    setCountryLivingIn(data.countryLivingIn);
                    setFoodHabit(data.foodHabits);
                    setSmokingHabit(data.smokingHabits);
                    setDrikingHabit(data.drinkingHabits);
                }
                else {
                    console.log('Data:2', data);
                }
                // Handle the successful response


            })
            .catch(error => {
                // Handle errors
                console.error('Error:', error);
            });

    }
    const postData = () => {

        // Define the URL for updating data
        const updateDataUrl = `${environment.apiUrl}/partnerPreferrence`;

        // Prepare the data to be sent in the request
        const updatedData = {
            // id: partnerPreferrenceId,
            userId: storedData,
            maritalStatus: maritalstatus,
            fromAge: fromage,
            toAge: toage,
            fromHeight: fromheight,
            toHeight: toheight,
            motherTongue: motherTongue,
            caste: caste,
            subCaste: subCaste,
            star: star,
            education: education,
            employedIn: employedIn,
            annualIncome: annualIncome,
            countryLivingIn: countryLivingIn,
            foodHabits: foodHabit,
            smokingHabits: smokingHabit,
            drinkingHabits: drinkingHabit,
            FinalData: [],
            message: ""
        };
        console.log("updateData", updatedData)
        // Make a PUT request to update data
        fetch(updateDataUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Data posted successfully:', data);
                if (data) {
                    showSuccessNotification("Your work has been saved");
                }

                const confirmation = window.confirm("Record added Successfully");
                if (confirmation) {
                    history.push(`/ProfileN/${btoa(storedemail)}`, { state: { email: storedemail, matchingData: data } });
                }

            })

            .catch(error => {
                console.error('Error updating data:', error);
            });
    }
    const updateData = () => {

        // Define the URL for updating data
        const updateDataUrl = `${environment.apiUrl}/partnerPreferrence/updateBy_id/${partnerPreferrenceId}`;

        // Prepare the data to be sent in the request
        const updatedData = {
            id: mongoid,
            partnerPreferrenceId: partnerPreferrenceId,
            userId: storedData,
            maritalStatus: maritalstatus,
            fromAge: fromage,
            toAge: toage,
            fromHeight: fromheight,
            toHeight: toheight,
            motherTongue: motherTongue,
            caste: caste,
            subCaste: subCaste,
            star: star,
            education: education,
            employedIn: employedIn,
            annualIncome: annualIncome,
            countryLivingIn: countryLivingIn,
            foodHabits: foodHabit,
            smokingHabits: smokingHabit,
            drinkingHabits: drinkingHabit,
            FinalData: [],
            message: ""

        };
        console.log("updateData", updatedData)
        // Make a PUT request to update data
        fetch(updateDataUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Data updated successfully:', data);
                if (data) {
                    showSuccessNotification("Your work has been updated");

                    // window.alert("Record updated Successfully!");

                    // Ask for confirmation and call function on 'OK'
                    const confirmation = window.confirm("Record updated Successfully");
                    if (confirmation) {
                        history.push(`/ProfileN/${btoa(storedemail)}`, { state: { email: storedemail, matchingData: data } });
                    }


                }
            })

            .catch(error => {
                console.error('Error updating data:', error);
            });

    };

    // Event handlers
    const handleMaritalStatusChange = (event) => setMaritalStatus(event.target.value);
    const handleFromAgeChange = (event) => setFromAge(event.target.value);
    const handleToAgeChange = (event) => setToAge(event.target.value);
    const handleFromHeightChange = (event) => setFromHeight(event.target.value);
    const handleToHeightChange = (event) => setToHeight(event.target.value);
    const handleMotherTongueChange = (event) => setMotherTongue(event.target.value);
    const handleCasteChange = (event) => setCaste(event.target.value);
    const handleSubCasteChange = (event) => setSubCaste(event.target.value);
    const handleStarChange = (event) => setStar(event.target.value);
    const handleEducationChange = (event) => setEducation(event.target.value);
    const handleEmployedInChange = (event) => setEmployedIn(event.target.value);
    const handleAnnualIncomeChange = (event) => setAnnualIncome(event.target.value);
    const handleCountryLivingInChange = (event) => setCountryLivingIn(event.target.value);
    const handleFoodHabitChange = (event) => setFoodHabit(event.target.value);
    const handleSmokingHabitChange = (event) => setSmokingHabit(event.target.value);
    const handleDrinkingHabitChange = (event) => setDrikingHabit(event.target.value);

    // const handleMaritalStatusChange = (event, newAlignment) => {
    //     setMaritalStatus(newAlignment);
    //     console.log("newAlignment", newAlignment)
    //     console.log("event", event)
    // };
    const classes = useStyles();
    return (
        <div>
            <div className={classes.container}>
                <div className={classes.leftcolumn}>
                    <div>Left</div>
                </div>
                <div className={classes.centercolumn}>
                    <div className='p-4 m-2'>
                        {/* <div data-toggle="buttons" className='btn-group btn-group-toggle d-flex g-3 mb-3 w-100 justify-content-start'>
                            <label class="btn btn-outline-info m-2 active">
                                <input type="checkbox" name="options" id="option1" autocomplete="off" checked/> Active
                            </label>
                            <label class="btn btn-outline-info m-2 active">
                                <input type="checkbox" name="options" id="option1" autocomplete="off" checked/> Active
                            </label>
                            <label class="btn btn-outline-info m-2 active">
                                <input type="checkbox" name="options" id="option1" autocomplete="off" checked/> Active
                            </label>
                        </div> */}
                        <label>Marital Status</label>
                        <ToggleButtonGroup
                            value={maritalstatus}
                            exclusive
                            onChange={handleMaritalStatusChange}
                            aria-label="text alignment"
                            className='d-flex g-3 flex-wrap'
                        >
                            <ToggleButton className='m-2 rounded-pill border border-info' value="unmarried" aria-label="left aligned">
                                UNMARRIED
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="widow_widower" aria-label="centered">
                                WIDOW/WIDOWER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="divorced" aria-label="right aligned">
                                DIVORCED
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {/* <ToggleGroupCommon unmarried={unmarried} widow_widower={widow_widower} divorced={divorced} maritalstatus={maritalstatus}/> */}
                        <div className='row g-3 mb-3'>
                            <label>Age</label>
                            <div className='col-xl-4 col-sm-12 col-lg-12'>
                                <select
                                    id="fromAgeSelect"
                                    className="form-select"
                                    value={fromage}
                                    onChange={handleFromAgeChange}
                                >
                                    <option selected>select</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                    <option value="53">53</option>
                                    <option value="54">54</option>
                                    <option value="55">55</option>
                                    <option value="56">56</option>
                                    <option value="57">57</option>
                                    <option value="58">58</option>
                                    <option value="59">59</option>
                                    <option value="60">60</option>
                                    <option value="61">61</option>
                                    <option value="62">62</option>
                                    <option value="63">63</option>
                                    <option value="64">64</option>
                                    <option value="65_and_above">65 and above</option>
                                </select>
                            </div>
                            <div className='col-xl-1 col-sm-12 col-lg-12'>To</div>
                            <div className='col-xl-4 col-sm-12 col-lg-12'>
                                <select
                                    id="toAgeSelect"
                                    className="form-select"
                                    value={toage}
                                    onChange={handleToAgeChange}
                                >
                                    <option selected>select</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                    <option value="53">53</option>
                                    <option value="54">54</option>
                                    <option value="55">55</option>
                                    <option value="56">56</option>
                                    <option value="57">57</option>
                                    <option value="58">58</option>
                                    <option value="59">59</option>
                                    <option value="60">60</option>
                                    <option value="61">61</option>
                                    <option value="62">62</option>
                                    <option value="63">63</option>
                                    <option value="64">64</option>
                                    <option value="65_and_above">65 and above</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Height</label>
                            <div className='col-xl-4 col-sm-12 col-lg-12'>
                                <select
                                    id="fromHeightSelect"
                                    className="form-select"
                                    value={fromheight}
                                    onChange={handleFromHeightChange}
                                >
                                    <option selected>Select</option>
                                    <option value="four_ft_122">4ft/122cm</option>
                                    <option value="four_ft_1in_124">4ft 1in/124cm</option>
                                    <option value="four_ft_2in_127">4ft 2in/127cm</option>
                                    <option value="four_ft_3in_130">4ft 3in/130cm</option>
                                    <option value="four_ft_4in_132">4ft 4in/132cm</option>
                                    <option value="four_ft_5in_135">4ft 5in/135cm</option>
                                    <option value="four_ft_6in_137">4ft 6in/137cm</option>
                                    <option value="four_ft_7in_140">4ft 7in/140cm</option>
                                    <option value="four_ft_8in_142">4ft 8in/142cm</option>
                                    <option value="four_ft_9in_145">4ft 9in/145cm</option>
                                    <option value="four_ft_10in_147">4ft 10in/147cm</option>
                                    <option value="four_ft_11in_150">4ft 11in/150cm</option>
                                    <option value="five_ft_152">5ft/152cm</option>
                                    <option value="five_ft_1in_155">5ft 1in/155cm</option>
                                    <option value="five_ft_2in_157">5ft 2in/157cm</option>
                                    <option value="five_ft_3in_160">5ft 3in/160cm</option>
                                    <option value="five_ft_4in_163">5ft 4in/163cm</option>
                                    <option value="five_ft_5in_165">5ft 5in/165cm</option>
                                    <option value="five_ft_6in_168">5ft 6in/168cm</option>
                                    <option value="five_ft_7in_170">5ft 7in/170cm</option>
                                    <option value="five_ft_8in_173">5ft 8in/173cm</option>
                                    <option value="five_ft_9in_175">5ft 9in/175cm</option>
                                    <option value="five_ft_10in_178">5ft 10in/178cm</option>
                                    <option value="five_ft_11in_180">5ft 11in/180cm</option>
                                    <option value="six_ft_183">6ft/183cm</option>
                                    <option value="six_ft_1in_185">6ft 1in/185cm</option>
                                    <option value="six_ft_2in_188">6ft 2in/188cm</option>
                                    <option value="six_ft_3in_191">6ft 3in/191cm</option>
                                    <option value="six_ft_4in_193">6ft 4in/193cm</option>
                                    <option value="six_ft_5in_196">6ft 5in/196cm</option>
                                    <option value="six_ft_6in_198">6ft 6in/198cm</option>
                                    <option value="six_ft_7in_201">6ft 7in/201cm</option>
                                    <option value="six_ft_8in_203">6ft 8in/203cm</option>
                                    <option value="six_ft_9in_206">6ft 9in/206cm</option>
                                    <option value="six_ft_10in_208">6ft 10in/208cm</option>
                                    <option value="six_ft_11in_211">6ft 11in/211cm</option>
                                    <option value="seven_ft_213">7ft/213cm</option>
                                    <option value="seven_ft_1in_216">7ft 1in/216cm</option>
                                    <option value="seven_ft_2in_218">7ft 2in/218cm</option>
                                </select>
                            </div>
                            <div className='col-xl-1 col-sm-12 col-lg-12'>To</div>
                            <div className='col-xl-4 col-sm-12 col-lg-12'>
                                <select
                                    id="toHeightSelect"
                                    className="form-select"
                                    value={toheight}
                                    onChange={handleToHeightChange}
                                >
                                    <option selected>Select</option>
                                    <option value="four_ft_122">4ft/122cm</option>
                                    <option value="four_ft_1in_124">4ft 1in/124cm</option>
                                    <option value="four_ft_2in_127">4ft 2in/127cm</option>
                                    <option value="four_ft_3in_130">4ft 3in/130cm</option>
                                    <option value="four_ft_4in_132">4ft 4in/132cm</option>
                                    <option value="four_ft_5in_135">4ft 5in/135cm</option>
                                    <option value="four_ft_6in_137">4ft 6in/137cm</option>
                                    <option value="four_ft_7in_140">4ft 7in/140cm</option>
                                    <option value="four_ft_8in_142">4ft 8in/142cm</option>
                                    <option value="four_ft_9in_145">4ft 9in/145cm</option>
                                    <option value="four_ft_10in_147">4ft 10in/147cm</option>
                                    <option value="four_ft_11in_150">4ft 11in/150cm</option>
                                    <option value="five_ft_152">5ft/152cm</option>
                                    <option value="five_ft_1in_155">5ft 1in/155cm</option>
                                    <option value="five_ft_2in_157">5ft 2in/157cm</option>
                                    <option value="five_ft_3in_160">5ft 3in/160cm</option>
                                    <option value="five_ft_4in_163">5ft 4in/163cm</option>
                                    <option value="five_ft_5in_165">5ft 5in/165cm</option>
                                    <option value="five_ft_6in_168">5ft 6in/168cm</option>
                                    <option value="five_ft_7in_170">5ft 7in/170cm</option>
                                    <option value="five_ft_8in_173">5ft 8in/173cm</option>
                                    <option value="five_ft_9in_175">5ft 9in/175cm</option>
                                    <option value="five_ft_10in_178">5ft 10in/178cm</option>
                                    <option value="five_ft_11in_180">5ft 11in/180cm</option>
                                    <option value="six_ft_183">6ft/183cm</option>
                                    <option value="six_ft_1in_185">6ft 1in/185cm</option>
                                    <option value="six_ft_2in_188">6ft 2in/188cm</option>
                                    <option value="six_ft_3in_191">6ft 3in/191cm</option>
                                    <option value="six_ft_4in_193">6ft 4in/193cm</option>
                                    <option value="six_ft_5in_196">6ft 5in/196cm</option>
                                    <option value="six_ft_6in_198">6ft 6in/198cm</option>
                                    <option value="six_ft_7in_201">6ft 7in/201cm</option>
                                    <option value="six_ft_8in_203">6ft 8in/203cm</option>
                                    <option value="six_ft_9in_206">6ft 9in/206cm</option>
                                    <option value="six_ft_10in_208">6ft 10in/208cm</option>
                                    <option value="six_ft_11in_211">6ft 11in/211cm</option>
                                    <option value="seven_ft_213">7ft/213cm</option>
                                    <option value="seven_ft_1in_216">7ft 1in/216cm</option>
                                    <option value="seven_ft_2in_218">7ft 2in/218cm</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Mother Tongue</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="motherTongueSelect"
                                    className="form-select"
                                    value={motherTongue}
                                    onChange={handleMotherTongueChange}
                                >
                                    <option selected>Select</option>
                                    <option value="hindi">Hindi</option>
                                    <option value="english">English</option>
                                    <option value="telugu">Telugu</option>
                                    <option value="bengali">Bengali</option>
                                    <option value="marathi">Marathi</option>
                                    <option value="tamil">Tamil</option>
                                    <option value="urdu">Urdu</option>
                                    <option value="kannada">Kannada</option>
                                    <option value="odia">Odia</option>
                                    <option value="punjabi">Punjabi</option>
                                    <option value="malayalam">Malayalam</option>
                                    <option value="gujarati">Gujarati</option>
                                    <option value="assamese">Assamese</option>
                                    <option value="maithili">Maithili</option>
                                    <option value="santali">Santali</option>
                                    <option value="kashmiri">Kashmiri</option>
                                    <option value="nepali">Nepali</option>
                                    <option value="konkani">Konkani</option>
                                    <option value="sindhi">Sindhi</option>
                                    <option value="dogri">Dogri</option>
                                    <option value="manipuri">Manipuri</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Caste</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="casteSelect"
                                    className="form-select"
                                    value={caste}
                                    onChange={handleCasteChange}
                                >
                                    <option selected>Select</option>
                                    <option value="brahmin">Brahmin</option>
                                    <option value="kshatriya">Kshatriya</option>
                                    <option value="vaishya">Vaishya</option>
                                    <option value="shudra">Shudra</option>
                                    <option value="dalit">Dalit</option>
                                    <option value="jat">Jat</option>
                                    <option value="yadav">Yadav</option>
                                    <option value="scheduled_caste">Scheduled Caste</option>
                                    <option value="scheduled_tribe">Scheduled Tribe</option>
                                    <option value="other_backward_classes">Other Backward Classes (OBC)</option>
                                    <option value="Dont_Beleive_In_Caste_System">Don't Beleive In Caste</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Sub Caste</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="subCasteSelect"
                                    className="form-select"
                                    value={subCaste}
                                    onChange={handleSubCasteChange}
                                >
                                    <option selected>Sub Caste</option>
                                    <option value="iyer">Iyer</option>
                                    <option value="iyengar">Iyengar</option>
                                    <option value="rajput">Rajput</option>
                                    <option value="thakur">Thakur</option>
                                    <option value="agarwal">Agarwal</option>
                                    <option value="bania">Bania</option>
                                    <option value="jatav">Jatav</option>
                                    <option value="kurmi">Kurmi</option>
                                    <option value="mahar">Mahar</option>
                                    <option value="paraiyar">Paraiyar</option>
                                    <option value="other_subcaste">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Star</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="starSelect"
                                    className="form-select"
                                    value={star}
                                    onChange={handleStarChange}
                                >
                                    <option selected>Select</option>
                                    <option value="ashwini">Ashwini</option>
                                    <option value="bharani">Bharani</option>
                                    <option value="kritika">Kritika</option>
                                    <option value="rohini">Rohini</option>
                                    <option value="mrigashira">Mrigashira</option>
                                    <option value="ardra">Ardra</option>
                                    <option value="punarvasu">Punarvasu</option>
                                    <option value="pushya">Pushya</option>
                                    <option value="ashlesha">Ashlesha</option>
                                    <option value="magha">Magha</option>
                                    <option value="purva_phalguni">Purva Phalguni</option>
                                    <option value="uttara_phalguni">Uttara Phalguni</option>
                                    <option value="hasta">Hasta</option>
                                    <option value="chitra">Chitra</option>
                                    <option value="swati">Swati</option>
                                    <option value="vishakha">Vishakha</option>
                                    <option value="anuradha">Anuradha</option>
                                    <option value="jyeshtha">Jyeshtha</option>
                                    <option value="mula">Mula</option>
                                    <option value="purva_ashadha">Purva Ashadha</option>
                                    <option value="uttara_ashadha">Uttara Ashadha</option>
                                    <option value="shravana">Shravana</option>
                                    <option value="dhanishta">Dhanishta</option>
                                    <option value="shatabhisha">Shatabhisha</option>
                                    <option value="purva_bhadrapada">Purva Bhadrapada</option>
                                    <option value="uttara_bhadrapada">Uttara Bhadrapada</option>
                                    <option value="revati">Revati</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Education</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="educationSelect"
                                    className="form-select"
                                    value={education}
                                    onChange={handleEducationChange}
                                >
                                    <option selected>Education</option>
                                    <option value="bachelor_of_arts">Bachelor of Arts (BA)</option>
                                    <option value="bachelor_of_science">Bachelor of Science (BSc)</option>
                                    <option value="bachelor_of_commerce">Bachelor of Commerce (BCom)</option>
                                    <option value="bachelor_of_engineering">Bachelor of Engineering (BE)</option>
                                    <option value="bachelor_of_technology">Bachelor of Technology (BTech)</option>
                                    <option value="bachelor_of_business_administration">Bachelor of Business Administration (BBA)</option>
                                    <option value="bachelor_of_fine_arts">Bachelor of Fine Arts (BFA)</option>
                                    <option value="bachelor_of_laws">Bachelor of Laws (LLB)</option>
                                    <option value="bachelor_of_architecture">Bachelor of Architecture (BArch)</option>
                                    <option value="master_of_arts">Master of Arts (MA)</option>
                                    <option value="master_of_science">Master of Science (MSc)</option>
                                    <option value="master_of_commerce">Master of Commerce (MCom)</option>
                                    <option value="master_of_technology">Master of Technology (MTech)</option>
                                    <option value="master_of_business_administration">Master of Business Administration (MBA)</option>
                                    <option value="master_of_fine_arts">Master of Fine Arts (MFA)</option>
                                    <option value="master_of_laws">Master of Laws (LLM)</option>
                                    <option value="master_of_architecture">Master of Architecture (MArch)</option>
                                    <option value="chartered_accountancy">Chartered Accountancy (CA)</option>
                                    <option value="company_secretary">Company Secretary (CS)</option>
                                    <option value="cost_and_management_accountancy">Cost and Management Accountancy (CMA)</option>
                                    <option value="law">Law (LLB)</option>
                                    <option value="medicine">Medicine (MBBS)</option>
                                    <option value="pharmacy">Pharmacy (BPharm, MPharm)</option>
                                    <option value="dentistry">Dentistry (BDS, MDS)</option>
                                    <option value="diploma">Diploma</option>
                                    <option value="certificate_in_digital_marketing">Certificate in Digital Marketing</option>
                                    <option value="vocational_training_course">Vocational Training Course</option>
                                    <option value="diploma_in_culinary_arts">Diploma in Culinary Arts</option>
                                    <option value="certificate_in_web_design">Certificate in Web Design</option>
                                    <option value="diploma_in_interior_design">Diploma in Interior Design</option>
                                    <option value="other_education">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Employed In</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="employedInSelect"
                                    className="form-select"
                                    value={employedIn}
                                    onChange={handleEmployedInChange}
                                >
                                    <option selected>Select</option>
                                    <option value="government">Government</option>
                                    <option value="it_sector">IT Sector</option>
                                    <option value="private">Private</option>
                                    <option value="business">Business</option>
                                    <option value="other_employedin">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Annual Income</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="annualIncomeSelect"
                                    className="form-select"
                                    value={annualIncome}
                                    onChange={handleAnnualIncomeChange}
                                >
                                    <option selected>Select</option>
                                    <option value="less_than_200000">Less than ₹2,00,000</option>
                                    <option value="200000_to_399999">₹2,00,000 - ₹3,99,999</option>
                                    <option value="400000_to_599999">₹4,00,000 - ₹5,99,999</option>
                                    <option value="600000_to_799999">₹6,00,000 - ₹7,99,999</option>
                                    <option value="800000_to_999999">₹8,00,000 - ₹9,99,999</option>
                                    <option value="1000000_to_1499999">₹10,00,000 - ₹14,99,999</option>
                                    <option value="1500000_and_above">₹15,00,000 and above</option>
                                    <option value="prefer_not_to_disclose">Prefer not to disclose</option>
                                </select>
                            </div>
                        </div>
                        <div className='row g-3 mb-3'>
                            <label>Country Living In</label>
                            <div className='col-xl-10 col-sm-12 col-lg-12'>
                                <select
                                    id="countryLivingInSelect"
                                    className="form-select"
                                    value={countryLivingIn}
                                    onChange={handleCountryLivingInChange}
                                >
                                    <option selected>Country Living In</option>
                                    <option value="india">India</option>
                                    <option value="united_states">United States</option>
                                    <option value="canada">Canada</option>
                                    <option value="united_kingdom">United Kingdom</option>
                                    <option value="australia">Australia</option>
                                    <option value="germany">Germany</option>
                                    <option value="france">France</option>
                                    <option value="japan">Japan</option>
                                    <option value="china">China</option>
                                    <option value="brazil">Brazil</option>
                                    <option value="south_africa">South Africa</option>
                                    <option value="russia">Russia</option>
                                    <option value="italy">Italy</option>
                                    <option value="spain">Spain</option>
                                    <option value="mexico">Mexico</option>
                                    <option value="netherlands">Netherlands</option>
                                    <option value="switzerland">Switzerland</option>
                                    <option value="sweden">Sweden</option>
                                    <option value="singapore">Singapore</option>
                                    <option value="south_korea">South Korea</option>
                                    <option value="other_country">Other</option>
                                </select>
                            </div>
                        </div>
                        <div>Food Habbits</div>
                        <ToggleButtonGroup
                            className='flex-wrap'
                            value={foodHabit}
                            exclusive
                            onChange={handleFoodHabitChange}
                            aria-label="text alignment"
                        >
                            <ToggleButton className='m-2 rounded-pill border border-info' value="vgnn" aria-label="left aligned">
                                VEGETARIAN
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="nvgn" aria-label="centered">
                                NON-VEGETARIAN
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="egn" aria-label="right aligned">
                                EGGETARIAN
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="vgn" aria-label="right aligned">
                                VEGGAN
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="dtm" aria-label="right aligned">
                                DOESN'T MATTER
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <div>Smoking Habbits</div>
                        <ToggleButtonGroup
                            className='flex-wrap'
                            value={smokingHabit}
                            exclusive
                            onChange={handleSmokingHabitChange}
                            aria-label="text alignment"
                        >
                            <ToggleButton className='m-2 rounded-pill border border-info' value="ns" aria-label="left aligned">
                                NON SMOKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="ls" aria-label="centered">
                                LIGHT SMOKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="sm" aria-label="right aligned">
                                SMOKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="dmt" aria-label="right aligned">
                                DOESN'T MATTER
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <div>Drinking Habbits</div>
                        <ToggleButtonGroup
                            className='flex-wrap'
                            value={drinkingHabit}
                            exclusive
                            onChange={handleDrinkingHabitChange}
                            aria-label="text alignment"
                        >
                            <ToggleButton className='m-2 rounded-pill border border-info' value="nd" aria-label="centered">
                                NON-DRINKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="ld" aria-label="right aligned">
                                LIGHT-DRINKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="dd" aria-label="right aligned">
                                DRINKER
                            </ToggleButton>
                            <ToggleButton className='m-2 rounded-pill border border-info' value="dm" aria-label="right aligned">
                                DOESN'T MATTER
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    {partnerpreferencedata.length == 0 ?
                        <div className='d-flex justify-content-end w-100 p-2 mb-2 cursor-pointer rounded'>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-primary">
                                    <SaveIcon />
                                </button>
                                <button onClick={postData} type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                        :
                        <div className='d-flex justify-content-end w-100 p-2 mb-2 cursor-pointer rounded'>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-primary">
                                    <SaveIcon />
                                </button>
                                <button onClick={updateData} type="button" class="btn btn-primary">Update</button>
                            </div>
                        </div>
                    }

                </div>
                <div className={classes.rightcolumn}>
                    <div>right</div>
                </div>

            </div>
            {/* <div class="container">
                <div class="row align-items-start">
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                </div>
                <div class="row align-items-end">
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                    <div class="col">
                        One of three columns
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Preferencepartner
