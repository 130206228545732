import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, storage } from "../../app/firebase";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";



import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import * as environment from '../../coreservices/environment'


const useStyles = makeStyles((theme) => ({
  updatebutton: {
    marginTop: "20px",
    display: "flex",
    marginLeft: "auto",
  },
}));


const BloodgroupingTests = (props) => {


  useEffect(() => {
    getTestImages()
  }, [localStorage.getItem('userId')])

  const location = useLocation();

  const loggedUsersData_psy = location?.state?.state?.loggedUsersData
  const loggedinUser_psy = location?.state?.state?.loggedinUser


  console.log('====================================');
  console.log("loggedUsersData_psy", loggedUsersData_psy);
  console.log("loggedinUser_psy", loggedinUser_psy);
  console.log('====================================');


  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };



  const handleSubmit = async () => {

    let data = {
      userId: localStorage.getItem('userId'),
      path: horoscopepath
    }


    try {
      const response = await fetch(`${environment.apiUrl}/bloodgroupingtest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data as needed
      const responseData = await response.json();
      console.log("responseData", responseData);
      alert('Record Submitted Successfully.')
      getTestImages()
    } catch (error) {
      console.error('Error during POST request:', error);
    }

  };


  const [isLength, setisLength] = useState(false)
  const getTestImages = async () => {
    try {
      const response = await fetch(`${environment.apiUrl}/bloodgroupingtest/${localStorage.getItem('userId')}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const fetched_img_Data = await response.json();
      console.log(fetched_img_Data.length);
      console.log(fetched_img_Data);
      if (fetched_img_Data) {
        setHoroscopepath(fetched_img_Data?.path);
        setisLength(true)
      }
      console.log('=============fetched_img_Data=======================', fetched_img_Data);
    } catch (error) {
      console.error(error.message);
    }
  }













  const userDetails = useSelector(selectUser);
  console.log(userDetails);
  const [user, setUser] = useState(userDetails);

  const classes = useStyles();
  const filepickerRef = useRef(null);

  const [horoscopepath, setHoroscopepath] = useState("");
  const addImage = (e, imageType) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      // console.log(readerEvent.target.result);
      if (readerEvent.target.result) {
        console.log(user);
        console.log(loggedUsersData_psy);
        console.log(loggedinUser_psy);
        const uploadTaskPic = storage
          .ref(`profiepics/${user.userData.email + imageType}`)
          .putString(readerEvent.target.result, "data_url");
        // removeImage1();
        uploadTaskPic.on(
          "state_change",
          null,
          (error) => console.log(error),

          () => {
            // var progress =
            //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Upload is " + progress + "% done");
            storage
              .ref("profiepics")
              .child(user.userData.email + "horoscope")
              .getDownloadURL()
              .then((url) => {
                // firebase.auth().currentUser.updateProfile({
                //     photoURL: url,
                // });
                setHoroscopepath(url);
                console.log(url);
                db.collection("users")
                  .doc(user.userData.email)
                  .set(
                    {
                      horoscopeurl: url,
                    },
                    { merge: true }
                  );
              });
          }
        );
      }
    };
  };



  // const onDownload = (horoscopeurl) => {
  //   var a = document.createElement("a");
  //   a.href = horoscopeurl;
  //   a.setAttribute("download", "horoscope" + props.loggedinprofile.name);
  //   a.click();
  //   window.location.href = horoscopeurl;
  // };



  return (
    <div className="cen-div">

      <div className="center-div">
        <div className="centered-div-1">

          <div>
            <h2 style={{ textAlign: 'center' }}>Bloodgrouping Tests</h2>
            <span className='text-info fs-4 fw-bold'>Why is it important: </span> This might sound like an
            inane test, but it definitely is important. When it comes to blood groups it is essential that
            you both have the same Rh factor (Rhesus factor). That means the positive or negative sign
            that follows your blood groups ( if you have a blood group of O positive your Rh factor is
            positive, and if your partner has a blood group of B negative, he/she has a negative Rh
            factor). This information is essential especially during pregnancy, as a mother and baby of
            opposite Rh factors could lead to severe complications and even death of the baby. During
            conception or pregnancy, it is important to talk to your doctor about this possible
            complication so that he/she can take the appropriate steps to sidestep them. So, it will do
            you good to know your partner s blood group.
            What it would entail: This is a simple test and is very basic in function. All it requires is a
            drop or two of your blood (usually taken from your finger). It can be performed in any
            diagnostic lab. (Read: Blood tests: Understanding the different kinds).


            {isLength == false ?
              <div class="mb-3">

                <div
                  className={classes.mat__profilepicupload}
                  onClick={() => filepickerRef.current.click()}
                >
                  <IconButton
                    color="primary"
                    title="Upload Horoscope"
                    className={classes.mat__horoscopeupload}
                  >
                    <input
                      type="file"
                      hidden
                      onChange={(e) => addImage(e, "horoscope")}
                      ref={filepickerRef}
                      className={classes.mat__horoscopeupload}
                    />
                    <PhotoCamera />
                    Upload Bloodgroup Test File
                  </IconButton>
                </div>
                <div>{horoscopepath}</div>
                {/* <div>Bloodgroup Test File</div> */}

                {horoscopepath != "" ?
                  <div>
                    <a href={horoscopepath} target="_blank"
                      rel="noopener noreferrer" download="image.jpg">Download Bloodgroup Test File</a>
                  </div>
                  :
                  null
                }

                {/* <label for="formFileReadonly" class="form-label">Readonly file input example</label> */}
                {/* <input class="form-control" type="file" id="formFileReadonly" readonly onChange={handleFileChange} /> */}
              </div>
              :
              <div className="mb-3">
                {horoscopepath != "" ?
                  <div>
                    <a href={horoscopepath} target="_blank"
                      rel="noopener noreferrer" download="image.jpg">Download Bloodgroup Test File</a>
                  </div>
                  :
                  null
                }
              </div>
            }



            {isLength == false &&
              (
                <div>
                  <button onClick={handleSubmit} type="button" class="btn btn-primary">Submit</button>
                </div>
              )

            }
          </div>


        </div>





      </div>





















    </div>





  )
}

export default BloodgroupingTests
