import React from 'react'

const Aboutus = () => {
    return (
        <div className='p-4'>
            <h1>About Us</h1>
            <p>
                Healthy relationships are central to living a positive, productive life. The Indian matchmaking is a social network that allows you to feel empowered while you make those connections, whether you’re dating, looking for friends, or growing your professional network. One first move on this site, you could change your life.

                When members of the opposite sex match on here, both man and women are required to make the first move, encouraging equality from the start. We encourage integrity, kindness, equality, confidence, and respect during all stages of any relationship — whether online or offline. This site is where people go to learn how to establish and maintain healthier connections.


            </p>
            <h4>Our Mission</h4>
            <p>
                Are you single living in our earth? This will help you to find other grooms/brides who are located in any location of India.
            </p>
            <p>
                Browse thousands of Missions Matrimonial/dating or find someone in Mission online profiles.
            </p>
            <p>
                When we plan to start Matchmaking site, it was because we saw a problem in conservative society of India, we wish to help solve. After pandemic, but so many of the smart, wonderful women, handsome men in India were still waiting around for men/Women to ask them out, to take their numbers, or to start up a conversation on a dating app. For all the advances women had been making in workplaces and corridors of power, the gender dynamics of dating and romance still seemed so outdated. we thought, what if we could flip that on its head? What if women made the first move, and sent the first message?
            </p>
            <h4>Our Philosophy</h4>
            <p>
                As the first lockdown took its tool eventually, where we felt isolated and tried to find ways to get out of the horrible loneliness, we found ways to sabotage the beautiful relationship we had. Then the guilt of maybe overdoing it, consumed me entirely and before I knew it, we had broken up by the end of August 2020. The reason, we still not clear, so We just try to sound extremely philosophical on Matrimonial/dating site, because that’s what We can do.
            </p>
        </div>
    )
}

export default Aboutus
