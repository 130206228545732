import React from "react";
import "./WatermarkedImage.css";

const WatermarkedImage = ({ imageUrl, watermarkText }) => {
  return (
    <div className="watermarked-image-container">
      <img src={imageUrl} alt="Watermarked Image" className="image" />
      <div className="watermark">{watermarkText}</div>
    </div>
  );
};

export default WatermarkedImage;


