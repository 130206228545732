// import React, { useEffect, useState } from 'react'
// import { useLocation } from "react-router-dom";
// import * as environment from '../../coreservices/environment'

// const Chatbox = () => {



//     //  // Sort messages array by timestamp
//     //  getUmessage.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));
//     //  getUmessageopposite.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));



//     const location = useLocation();

//     const [ITEM, setITEM] = useState(location?.state?.state?.ITEM);
//     const [LOGGEDUSERDATA, setLOGGEDUSERDATA] = useState(location?.state?.state?.LOGGEDUSERDATA);
//     // localStorage.setItem('LOGGEDUSERDATA_message', location?.state?.state?.LOGGEDUSERDATA)
//     // localStorage.setItem('ITEM_message', location?.state?.state?.ITEM)

//     console.log('====================================');
//     console.log("LOGGEDUSERDATA1", LOGGEDUSERDATA);
//     console.log("ITEM1", ITEM);
//     console.log('====================================');

//     const [getUmessage, setgetUmessage] = useState([]);

//     const [getUmessage1, setgetUmessage1] = useState([]);
//     const [receiverName, setreceiverName] = useState("");
//     useEffect(() => {
//         if (LOGGEDUSERDATA?.userId != "" && ITEM?.userId != "") {
//             fetchMessages();
//         }
//     }, [LOGGEDUSERDATA?.userId, ITEM?.userId]);

//     const fetchMessages = async () => {

//         try {
//             const response = await fetch(`${environment.apiUrl}/message/findbyid/${LOGGEDUSERDATA?.userId}/${ITEM?.userId}`);

//             if (response.ok) {
//                 const data = await response.json();
//                 // setPaymentStatus(data);
//                 console.log("data_pa1111", data);
//                 setgetUmessage(data)
//                 setreceiverName(data[0]?.receiverName)
//                 fetchMessagesopposite(data)
//                 // setgetUmessage1(data)
//             } else {
//                 console.error('Failed to fetch payment status');
//             }
//         } catch (error) {
//             console.error('Error fetching payment status:', error);
//         }
//     };


//     const [getUmessageopposite, setgetUmessageopposite] = useState([]);

//     // useEffect(() => {
//     //     if (ITEM?.userId != "" && LOGGEDUSERDATA?.userId != "") {
//     //         fetchMessagesopposite();
//     //     }
//     // }, [ITEM?.userId, LOGGEDUSERDATA?.userId]);

//     const fetchMessagesopposite = async (data) => {

//         try {
//             const response = await fetch(`${environment.apiUrl}/message/findbyid/${ITEM?.userId}/${LOGGEDUSERDATA?.userId}`);

//             if (response.ok) {
//                 const res = await response.json();
//                 // setPaymentStatus(data);
//                 console.log("data_pa", res);
//                 setgetUmessageopposite(res)
//                 // getUmessage1.push(data)

//                 let mergedArray = [...res, ...data];
//                 console.log("mergedArray", mergedArray);

//                 mergedArray.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));

//                 console.log("dataArray", mergedArray);

//                 setgetUmessage1(mergedArray)
//             } else {
//                 console.error('Failed to fetch payment status');
//             }
//         } catch (error) {
//             console.error('Error fetching payment status:', error);
//         }
//     };


//     const [message, setMessage] = useState(''); // State for the input field

//     const handleMessageChange = (event) => {
//         setMessage(event.target.value);
//     };

//     const handleSendClick = async () => {
//         console.log('====================================');
//         console.log("LOGGEDUSERDATA2", LOGGEDUSERDATA);
//         console.log("ITEM2", ITEM);
//         console.log('====================================');
//         // Construct the message object
//         const messageObject = {
//             userId: LOGGEDUSERDATA?.userId,
//             receiverId: ITEM?.userId,
//             messageId: 0,
//             message: message,
//             userName: LOGGEDUSERDATA?.firstName + " " + LOGGEDUSERDATA?.lastName,
//             receiverName: ITEM?.firstName + " " + ITEM?.lastName,
//             userDp: LOGGEDUSERDATA?.profilepic,
//             receiverDp: ITEM?.profilepic,
//             timeStamp: new Date()
//         }

//         // Send POST request
//         try {
//             const response = await fetch(`${environment.apiUrl}/message`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     // Add any additional headers if needed
//                 },
//                 body: JSON.stringify(messageObject),
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             // Handle the response data as needed
//             const responseData = await response.json();
//             setMessage("")
//             fetchMessages()
//             fetchMessagesopposite()
//             console.log("responseData test", responseData);



//         } catch (error) {
//             console.error('Error during POST request:', error);
//         }
//     };

//     const getTimeFromISOString = (dateTimeString) => {
//         // Create a new Date object using the provided date string
//         const dateObj = new Date(dateTimeString);

//         // Get hours, minutes, and seconds from the date object
//         const hours = dateObj.getHours();
//         const minutes = dateObj.getMinutes();
//         const seconds = dateObj.getSeconds();

//         // Format the time
//         const formattedTime = `${hours}:${minutes}`;

//         return formattedTime;
//     };

//     return (
//         <div className="container-fluid py-5" style={{ backgroundColor: "#eee" }}>
//             <div className="row justify-content-center">
//                 <div className="col-md-10 col-lg-8 col-xl-6">
//                     <div id="chat2" style={{ borderRadius: "15px" }} className="card">
//                         <div className="card-header d-flex justify-content-between align-items-center p-3">

//                             <div className='d-flex justify-content-center align-items-center gap-1'>
//                                 <img

//                                     src={ITEM?.profilepic}
//                                     // src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                     alt="avatar 3"
//                                     style={{ width: "45px", height: "100%" }}
//                                 />
//                                 <h5 className="mb-0">{ITEM?.firstName} {ITEM?.lastName}</h5>
//                             </div>
//                             <button className="btn btn-primary btn-sm" ripplecolor="dark">
//                                 Let's Chat App
//                             </button>
//                         </div>

//                         <div
//                             suppressScrollX
//                             style={{ position: "relative", height: "400px" }}
//                             className="scrollbar"
//                         >
//                             <div className="card-body" style={{ overflowY: "scroll", height: "400px" }}>
//                                 {/* Your chat content */}

//                                 {/* Your chat content */}
//                                 {/* <div className="d-flex flex-row justify-content-start">
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                     <div>
//                                         <p
//                                             className="small p-2 ms-3 mb-1 rounded-3"
//                                             style={{ backgroundColor: "#f5f6f7" }}
//                                         >
//                                             Hi
//                                         </p>
//                                         <p
//                                             className="small p-2 ms-3 mb-1 rounded-3"
//                                             style={{ backgroundColor: "#f5f6f7" }}
//                                         >
//                                             How are you ...???
//                                         </p>
//                                         <p
//                                             className="small p-2 ms-3 mb-1 rounded-3"
//                                             style={{ backgroundColor: "#f5f6f7" }}
//                                         >
//                                             What are you doing tomorrow? Can we come up a bar?
//                                         </p>
//                                         <p className="small ms-3 mb-3 rounded-3 text-muted">
//                                             23:58
//                                         </p>
//                                     </div>
//                                 </div> */}




//                                 {/* <div className="divider d-flex align-items-center mb-4">
//                                     <p
//                                         className="text-center mx-3 mb-0"
//                                         style={{ color: "#a2aab7" }}
//                                     >
//                                         Today
//                                     </p>
//                                 </div> */}



//                                 {/* // Render messages sent by logged-in user */}
//                                 {getUmessage.map((item, index) => (
//                                     <div key={index} className="d-flex flex-row justify-content-end mb-4 pt-1">
//                                         <div>
//                                             <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                                 {item.message}
//                                             </p>
//                                         </div>
//                                         <img
//                                             src={LOGGEDUSERDATA?.profilepic}
//                                             alt="avatar 1"
//                                             style={{ width: "45px", height: "100%" }}
//                                         />
//                                     </div>
//                                 ))}

//                                 {/* // Render messages sent by opposite user */}
//                                 {/* {getUmessageopposite.map((item, index) => (
//                                     <div key={index} className="d-flex flex-row justify-content-start mb-4">
//                                         <img
//                                             src={ITEM?.profilepic}
//                                             alt="avatar 1"
//                                             style={{ width: "45px", height: "100%" }}
//                                         />
//                                         <div>
//                                             <p className="small p-2 ms-3 mb-1 rounded-3" style={{ backgroundColor: "#f5f6f7" }}>
//                                                 {item.message}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 ))} */}


//                                 {/* <div className="d-flex flex-row justify-content-end mb-4 pt-1">
//                                     <div>
//                                         {getUmessage.map((item, index) => (
//                                             <p key={index} className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                                 {item.message}
//                                             </p>
//                                         ))}

//                                     </div>
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-start mb-4">
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                     <div>
//                                         {getUmessageopposite.map((item, index) => (
//                                             <p key={index} className="small p-2 ms-3 mb-1 rounded-3"
//                                                 style={{ backgroundColor: "#f5f6f7" }}>
//                                                 {item.message}
//                                             </p>
//                                         ))}


//                                     </div>
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-end mb-4">
//                                     <div>
//                                         <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                             That's awesome!
//                                         </p>
//                                         <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                             I will meet you Sandon Square sharp at 10 AM
//                                         </p>
//                                         <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                             Is that okay?
//                                         </p>
//                                         <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
//                                             00:09
//                                         </p>
//                                     </div>
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-start mb-4">
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                     <div>
//                                         <p
//                                             className="small p-2 ms-3 mb-1 rounded-3"
//                                             style={{ backgroundColor: "#f5f6f7" }}
//                                         >
//                                             Okay i will meet you on Sandon Square
//                                         </p>
//                                         <p className="small ms-3 mb-3 rounded-3 text-muted">
//                                             00:11
//                                         </p>
//                                     </div>
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-end mb-4">
//                                     <div>
//                                         <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                             Do you have pictures of Matley Marriage?
//                                         </p>
//                                         <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
//                                             00:11
//                                         </p>
//                                     </div>
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-start mb-4">
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                     <div>
//                                         <p
//                                             className="small p-2 ms-3 mb-1 rounded-3"
//                                             style={{ backgroundColor: "#f5f6f7" }}
//                                         >
//                                             Sorry I don't have. i changed my phone.
//                                         </p>
//                                         <p className="small ms-3 mb-3 rounded-3 text-muted">
//                                             00:13
//                                         </p>
//                                     </div>
//                                 </div> */}

//                                 {/* <div className="d-flex flex-row justify-content-end">
//                                     <div>
//                                         <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                                             Okay then see you on sunday!!
//                                         </p>
//                                         <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
//                                             00:15
//                                         </p>
//                                     </div>
//                                     <img
//                                         src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
//                                         alt="avatar 1"
//                                         style={{ width: "45px", height: "100%" }}
//                                     />
//                                 </div> */}
//                             </div>
//                         </div>
//                         <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
//                             {/* Your footer content */}
//                             <img

//                                 // src={LOGGEDUSERDATA?.profilepic}
//                                 src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
//                                 alt="avatar 3"
//                                 style={{ width: "45px", height: "100%" }}
//                             />
//                             <input
//                                 type="text"
//                                 className="form-control form-control-lg"
//                                 id="exampleFormControlInput1"
//                                 placeholder="Type message"
//                                 value={message}
//                                 onChange={handleMessageChange}
//                             />
//                             <div className='btn btn-primary' onClick={handleSendClick}>
//                                 Send
//                             </div>
//                             {/* <a className="ms-1 text-muted" href="#!">
//                                 <i className="fas fa-paperclip"></i>
//                             </a>
//                             <a className="ms-3 text-muted" href="#!">
//                                 <i className="fas fa-smile"></i>
//                             </a>
//                             <a className="ms-3" href="#!">
//                                 <i className="fas fa-paper-plane"></i>
//                             </a> */}

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Chatbox
// import React from 'react'

// const chatbox = () => {
//     return (
//         <div>
//             chatbox
//         </div>
//     )
// }

// export default chatbox


// message chat  code react



import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import * as environment from '../../coreservices/environment'

const Chatbox = () => {



    //  // Sort messages array by timestamp
    //  getUmessage.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));
    //  getUmessageopposite.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));



    const location = useLocation();

    const [ITEM, setITEM] = useState(location?.state?.state?.ITEM);
    const [LOGGEDUSERDATA, setLOGGEDUSERDATA] = useState(location?.state?.state?.LOGGEDUSERDATA);
    // localStorage.setItem('LOGGEDUSERDATA_message', JSON.stringify(location?.state?.state?.LOGGEDUSERDATA))
    // localStorage.setItem('ITEM_message', JSON.stringify(location?.state?.state?.ITEM))

    console.log('====================================');
    console.log("LOGGEDUSERDATA1", LOGGEDUSERDATA);
    console.log("ITEM1", ITEM);
    console.log('====================================');

    const [getUmessage, setgetUmessage] = useState([]);

    const [getUmessage1, setgetUmessage1] = useState([]);
    const [receiverName, setreceiverName] = useState("");
    useEffect(() => {
        if (LOGGEDUSERDATA?.userId != "" && ITEM?.userId != "") {
            fetchMessages();
        }
    }, [LOGGEDUSERDATA?.userId, ITEM?.userId]);

    const fetchMessages = async () => {

        try {
            const response = await fetch(`${environment.apiUrl}/message/findbyid/${LOGGEDUSERDATA?.userId}/${ITEM?.userId}`);
            // `${environment.apiUrl}/paymentstatus/${LOGGEDUSERDATA?.userId}`
            // const response = await fetch(`http://localhost:5000/message/findbyid/${LOGGEDUSERDATA?.userId}/${ITEM?.userId}`);

            if (response.ok) {
                const data = await response.json();
                // setPaymentStatus(data);
                console.log("data_pa1111", data);
                setgetUmessage(data)
                setreceiverName(data[0]?.receiverName)
                fetchMessagesopposite(data)
                // setgetUmessage1(data)
            } else {
                console.error('Failed to fetch payment status');
            }
        } catch (error) {
            console.error('Error fetching payment status:', error);
        }
    };


    const [getUmessageopposite, setgetUmessageopposite] = useState([]);

    // useEffect(() => {
    //     if (ITEM?.userId != "" && LOGGEDUSERDATA?.userId != "") {
    //         fetchMessagesopposite();
    //     }
    // }, [ITEM?.userId, LOGGEDUSERDATA?.userId]);

    const fetchMessagesopposite = async (data) => {

        try {
            const response = await fetch(`${environment.apiUrl}/message/findbyid/${ITEM?.userId}/${LOGGEDUSERDATA?.userId}`);
            // `${environment.apiUrl}/paymentstatus/${LOGGEDUSERDATA?.userId}`
            // const response = await fetch(`http://localhost:5000/message/findbyid/${ITEM?.userId}/${LOGGEDUSERDATA?.userId}`);

            if (response.ok) {
                const res = await response.json();
                // setPaymentStatus(data);
                console.log("data_pa", res);
                setgetUmessageopposite(res)
                // getUmessage1.push(data)

                let mergedArray = [...res, ...data];
                console.log("mergedArray", mergedArray);

                mergedArray.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));

                console.log("dataArray", mergedArray);

                setgetUmessage1(mergedArray)
            } else {
                console.error('Failed to fetch payment status');
            }
        } catch (error) {
            console.error('Error fetching payment status:', error);
        }
    };


    const [message, setMessage] = useState(''); // State for the input field

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendClick = async () => {
        console.log('====================================');
        console.log("LOGGEDUSERDATA2", LOGGEDUSERDATA);
        console.log("ITEM2", ITEM);
        console.log('====================================');
        // Construct the message object
        const messageObject = {
            userId: LOGGEDUSERDATA?.userId,
            receiverId: ITEM?.userId,
            messageId: 0,
            message: message,
            userName: LOGGEDUSERDATA?.firstName + " " + LOGGEDUSERDATA?.lastName,
            receiverName: ITEM?.firstName + " " + ITEM?.lastName,
            userDp: LOGGEDUSERDATA?.profilepic,
            receiverDp: ITEM?.profilepic,
            timeStamp: new Date()
        }

        // Send POST request
        try {
            const response = await fetch(`${environment.apiUrl}/message`, {
                // `${environment.apiUrl}/paymentstatus/${LOGGEDUSERDATA?.userId}`
                // const response = await fetch('http://localhost:5000/message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(messageObject),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            setMessage("")
            fetchMessages()
            fetchMessagesopposite()
            console.log("responseData test", responseData);



        } catch (error) {
            console.error('Error during POST request:', error);
        }
    };

    const getTimeFromISOString = (dateTimeString) => {
        // Create a new Date object using the provided date string
        const dateObj = new Date(dateTimeString);

        // Get hours, minutes, and seconds from the date object
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();

        // Format the time
        const formattedTime = `${hours}:${minutes}`;

        return formattedTime;
    };

    return (
        <div className="container-fluid py-5" style={{ backgroundColor: "#eee" }}>
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-6">
                    <div id="chat2" style={{ borderRadius: "15px" }} className="card">
                        <div className="card-header d-flex justify-content-between align-items-center p-3">

                            <div className='d-flex justify-content-center align-items-center gap-1'>
                                <img

                                    src={ITEM?.profilepic}
                                    // src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                    alt="avatar 3"
                                    style={{ width: "45px", height: "100%" }}
                                />
                                <h5 className="mb-0">{ITEM?.firstName} {ITEM?.lastName}</h5>
                            </div>
                            <button className="btn btn-primary btn-sm" ripplecolor="dark">
                                Let's Chat App
                            </button>
                        </div>

                        <div
                            suppressScrollX
                            style={{ position: "relative", height: "400px" }}
                            className="scrollbar"
                        >
                            <div className="card-body" style={{ overflowY: "scroll", height: "400px" }}>
                                {/* Your chat content */}

                                {/* Your chat content */}
                                {/* <div className="d-flex flex-row justify-content-start">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                    <div>
                                        <p
                                            className="small p-2 ms-3 mb-1 rounded-3"
                                            style={{ backgroundColor: "#f5f6f7" }}
                                        >
                                            Hi
                                        </p>
                                        <p
                                            className="small p-2 ms-3 mb-1 rounded-3"
                                            style={{ backgroundColor: "#f5f6f7" }}
                                        >
                                            How are you ...???
                                        </p>
                                        <p
                                            className="small p-2 ms-3 mb-1 rounded-3"
                                            style={{ backgroundColor: "#f5f6f7" }}
                                        >
                                            What are you doing tomorrow? Can we come up a bar?
                                        </p>
                                        <p className="small ms-3 mb-3 rounded-3 text-muted">
                                            23:58
                                        </p>
                                    </div>
                                </div> */}




                                {/* <div className="divider d-flex align-items-center mb-4">
                                    <p
                                        className="text-center mx-3 mb-0"
                                        style={{ color: "#a2aab7" }}
                                    >
                                        Today
                                    </p>
                                </div> */}





                                {getUmessage1?.map((item, index) => (
                                    <div key={index}
                                        className={item.userId == LOGGEDUSERDATA.userId ? "d-flex flex-row justify-content-end mb-4 pt-1" : "d-flex flex-row justify-content-start mb-4"}
                                    // className="d-flex flex-row justify-content-end mb-4 pt-1"
                                    >
                                        <div>
                                            <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                                {item.message}
                                            </p>
                                        </div>
                                        <p>{getTimeFromISOString(item.timeStamp)}</p>
                                        {/* <img
                                            src={LOGGEDUSERDATA?.profilepic}
                                            alt="avatar 1"
                                            style={{ width: "45px", height: "100%" }}
                                        /> */}
                                    </div>
                                ))}






                                {/* // Render messages sent by logged-in user */}
                                {/* {getUmessage.map((item, index) => (
                                    <div key={index} className="d-flex flex-row justify-content-end mb-4 pt-1">
                                        <div>
                                            <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                                {item.message}
                                            </p>
                                        </div>
                                        <img
                                            src={LOGGEDUSERDATA?.profilepic}
                                            alt="avatar 1"
                                            style={{ width: "45px", height: "100%" }}
                                        />
                                    </div>
                                ))} */}

                                {/* // Render messages sent by opposite user */}
                                {/* {getUmessageopposite.map((item, index) => (
                                    <div key={index} className="d-flex flex-row justify-content-start mb-4">
                                        <img
                                            src={ITEM?.profilepic}
                                            alt="avatar 1"
                                            style={{ width: "45px", height: "100%" }}
                                        />
                                        <div>
                                            <p className="small p-2 ms-3 mb-1 rounded-3" style={{ backgroundColor: "#f5f6f7" }}>
                                                {item.message}
                                            </p>
                                        </div>
                                    </div>
                                ))} */}


                                {/* <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                    <div>
                                        {getUmessage.map((item, index) => (
                                            <p key={index} className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                                {item.message}
                                            </p>
                                        ))}
                                     
                                    </div>
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-start mb-4">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                    <div>
                                        {getUmessageopposite.map((item, index) => (
                                            <p key={index} className="small p-2 ms-3 mb-1 rounded-3"
                                                style={{ backgroundColor: "#f5f6f7" }}>
                                                {item.message}
                                            </p>
                                        ))}

                                       
                                    </div>
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-end mb-4">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                            That's awesome!
                                        </p>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                            I will meet you Sandon Square sharp at 10 AM
                                        </p>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                            Is that okay?
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                                            00:09
                                        </p>
                                    </div>
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-start mb-4">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                    <div>
                                        <p
                                            className="small p-2 ms-3 mb-1 rounded-3"
                                            style={{ backgroundColor: "#f5f6f7" }}
                                        >
                                            Okay i will meet you on Sandon Square
                                        </p>
                                        <p className="small ms-3 mb-3 rounded-3 text-muted">
                                            00:11
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-end mb-4">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                            Do you have pictures of Matley Marriage?
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                                            00:11
                                        </p>
                                    </div>
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-start mb-4">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                    <div>
                                        <p
                                            className="small p-2 ms-3 mb-1 rounded-3"
                                            style={{ backgroundColor: "#f5f6f7" }}
                                        >
                                            Sorry I don't have. i changed my phone.
                                        </p>
                                        <p className="small ms-3 mb-3 rounded-3 text-muted">
                                            00:13
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div className="d-flex flex-row justify-content-end">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                            Okay then see you on sunday!!
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                                            00:15
                                        </p>
                                    </div>
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                                        alt="avatar 1"
                                        style={{ width: "45px", height: "100%" }}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                            {/* Your footer content */}
                            <img

                                // src={LOGGEDUSERDATA?.profilepic}
                                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                alt="avatar 3"
                                style={{ width: "45px", height: "100%" }}
                            />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="exampleFormControlInput1"
                                placeholder="Type message"
                                value={message}
                                onChange={handleMessageChange}
                            />
                            <div className='btn btn-primary' onClick={handleSendClick}>
                                Send
                            </div>
                            {/* <a className="ms-1 text-muted" href="#!">
                                <i className="fas fa-paperclip"></i>
                            </a>
                            <a className="ms-3 text-muted" href="#!">
                                <i className="fas fa-smile"></i>
                            </a>
                            <a className="ms-3" href="#!">
                                <i className="fas fa-paper-plane"></i>
                            </a> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chatbox
