import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import ImageSlider from "../ImageSlider";
import flowerImg from "../assets/flower.jpg";
// import lighthouseImg from "../assets/lighthouse.jpg";
import lighthouseImg from "../assets/headimg.jpg";
import dandelion from "../assets/dandelion.jpg";

import first from "../assets/atrimony2.jpg";
import second from "../assets/image matrimony1.jpg";
import third from "../assets/matrimony11.jpg";
import fourth from "../assets/matrimony3.jpg";

import './Login.css';
import Footer from "./Footer";
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Homepagepackage from "./homepagepackage/homepagepackage";
import * as environment from '../coreservices/environment'

function Login() {
  const existing="existing";
  const newone="newone";
  const [selectedValue, setSelectedValue] = useState('existing');
  const [selectedComponent, setSelectedComponent] = useState(false);
  
  const handleChange = (event) => {
    console.log("event.target.value",event.target.value)
    setSelectedValue(event.target.value);
    if (event.target.value === 'newone') {
      history.push('/signup');
    } 
    // if (event.target.value=="newone"){
    // setSelectedComponent(true);
    // }    
  };

  const useStyles = makeStyles((theme) => ({

    login_input_box:{
      color:'#000',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
      // '-webkit-box-shadow': '0 0 0 100px #424242 inset !important',
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
      // '-webkit-text-fill-color': '#fff', // Example to change the text color
   '-webkit-text-fill-color': '#000',
    },
    },
    radioGroup: {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      gap:'2rem',
      flexDirection:'row',
    },
    radio: {
      fontWeight: 800,
      margin:0,
    },
    signup: {
      position: 'absolute',
      maxWidth: '100%',
      top: '5rem',
      right: '6rem',
      width: '24%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
        right: '2rem',
        top: '3rem',
      },
      [theme.breakpoints.down('sm')]: {
        width: '70%',
        right: '1rem',
        top: '2rem',
      },
    },
    root: {
      height: "115vh",
      position:'relative'
    },
    image: {
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxWidth:'100%',
      height:'auto'
    },
    paper: {
      margin: '10px 10px',
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    customLink: {
      color: "inherit"
    },
  }));


  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

   // Function to generate a simple random token
   const generateToken = (length = 16) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }
    return token;
  };



  const checkPaymentStatus = async (user_id) => {
    // Your subscription logic goes here
    console.log("user_id", user_id);
    try {

        const response = await fetch(`${environment.apiUrl}/paymentstatus/${user_id}`);
        if (response.ok) {
        const result = await response.json();
        console.log('====================================');
        console.log("dataPay", result);
        console.log('====================================');
        localStorage.setItem('packagePaymentStatus', result?.packagePaymentStatus);
        history.push(`/ProfileN/${btoa(email)}`,{state:{email:email}});
       
           
        window.location.reload();

        }

       

    } catch (error) {
        console.error('Error fetching data:', error);
    }

};



  const loginToapp = async (e) => { 
    e.preventDefault();

    try {
      
        // Make a GET request using fetch or axios // userdata/:email/:password
        const response = await fetch(`${environment.apiUrl}/signupprofiles/userdata/${email}/${password}`);

        // Check if the request was successful (status code 200)
        if (response.ok) {
            const data = await response.json();

            const token = generateToken(); 
            localStorage.setItem('authToken', token);
            localStorage.setItem('user_data', JSON.stringify(data));
            // Process the data as needed
            localStorage.setItem('role', 'user');
            localStorage.setItem('email', email);
            checkPaymentStatus(data?.userId)

 
            // history.push(`/ProfileN/${btoa(email)}`,{state:{email:email}});
            console.log('Response Data:', data);
           
            // window.location.reload();
            
        } else {
            // Handle error cases
            alert("Wrong credentials")
            console.error('Error:', response.statusText);
        }

    } catch (error) {
        // Handle any unexpected errors
        alert("Wrong credentials")
        console.error('Error:', error);
    }


            // console.log('====================================');
            // console.log("emailemail",email);
            // console.log('====================================');
            // history.push(`/ProfileN/${btoa(email)}`,{state:{email:email}});

  };



    const images = [
      first,
      second,
      third,
      fourth,
    // lighthouseImg,
    // flowerImg,
    // dandelion,
    // lighthouseImg,
    // flowerImg,
    // dandelion,
    // lighthouseImg,
    // Add more image URLs here
  ];
  const groupItems = [
    {
      name: "Bride",
      label: "Bride",
      // information: "Please select if you are Bride",
      disabled: false
    },
    {
      name: "Groom",
      label: "Groom",
      // information: "Please select if you are Groom",
      disabled: false
    },
    {
      name: "New Registration",
      label: "New Registration",
      // information: "Please select if you are Groom",
      disabled: false
    },
  ];
  return (
    <div>
      <Grid container component="main" className={classes.root}>
        
        
        <Grid item xs={false} sm={4} md={7} className={classes.image} >
               <div style={{padding: "10px"}}>
                <ImageSlider images={images} />
               </div>
        </Grid>
        <Grid className={classes.signup} item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
         
            
          <RadioGroup 
          value={selectedValue}
          onChange={handleChange}
          sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
          gap: '2rem',
          flexDirection: 'row',
        
      }} className={classes.radioGroup} defaultValue="female" name="radio-buttons-group">
             <Radio value={existing} label="Existing User" className={classes.radio} 
             sx={{
              marginBottom: '8px',
              // color: 'yellow', // White text color
              color: '#000',
              '&.Mui-checked': {
                // color: '#fff', // White text color when checked
                color: '#000',
              },
            }}/>
             <Radio value={newone} label="New User" className={classes.radio}
             sx={{
              marginBottom: '8px',
              // color: 'yellow', // White text color
              color: '#000',
              '&.Mui-checked': {
                // color: '#fff', // White text color when checked
                color: '#000',
              },
            }}/>
          </RadioGroup>
           
         
            <form className={classes.form} noValidate>
              <TextField
              // className="login-input-box"
               className={classes.login_input_box}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
              // className="login-input-box"
              className={classes.login_input_box}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={loginToapp}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    to="/ForgotPassword"
                    variant="body2"
                    className={classes.customLink}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>



      <Homepagepackage/>
      {/* <Footer/> */}
      {/* { selectedComponent==true && <SignupN />} */}
    </div>
  );
}

export default Login;

// src/App.js
// import "./App.css";
// import ImageSlider from "./ImageSlider";
// import flowerImg from "./images/flower.jpg";
// import lighthouseImg from "./images/lighthouse.jpg";
// import dandelion from "./images/dandelion.jpg";

// function App() {
//   const images = [
//     lighthouseImg,
//     flowerImg,
//     dandelion,
//     lighthouseImg,
//     flowerImg,
//     dandelion,
//     lighthouseImg,
//     // Add more image URLs here
//   ];
  
//   return (
//     <div className="App">
//       <div style={{padding: "10px"}}>
//         <ImageSlider images={images} />
//       </div>
//     </div>
//   );
// }

// export default App;