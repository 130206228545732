// Import necessary dependencies from React and React Router
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ViewProfileDetails from './components/ViewProfileDetails';
import SampleApp from './Watermark/SampleApp';
import Preferencepartner from './components/EditProfile/Preferencepartner';
// import TestyourselfModal from './components/TestyourselfModal';
// Import your components/pages
// import Home from './components/Home';
// import About from './components/About';
// import Contact from './components/Contact';
// import NotFound from './components/NotFound';

// Define your routes
const Routes = () => (
  <Router>
    <Switch>

    {/* <Route path="/testyourself" component={TestyourselfModal} /> */}
      {/* <Route exact path="/" component={Home} /> */}
      {/* <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} /> */}
      <Route path="/ViewProfileDetails" component={ViewProfileDetails} />
      <Route path="/SampleApp" component={SampleApp} />
      {/* <Route path="/PartPref" component={Preferencepartner} /> */}
    </Switch>
  </Router>
);

export default Routes;
