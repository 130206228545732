// import firebase from "firebase";
// const firebaseConfig = {
//   apiKey: "AIzaSyAuhurmAsHlIZAbWQANLRlVwybkUlg4Wdc",
//   authDomain: "matrimony-b3327.firebaseapp.com",
//   databaseURL: "https://matrimony-b3327-default-rtdb.firebaseio.com",
//   projectId: "matrimony-b3327",
//   storageBucket: "matrimony-b3327.appspot.com",
//   messagingSenderId: "1074479741881",
//   appId: "1:1074479741881:web:e3db4bc6e669c27ba1d468",
//   measurementId: "G-6E000SWV97"
// };

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const storage = firebase.storage();
// const db = firebaseApp.firestore();
// const auth = firebase.auth();
// const provider = new firebase.auth.GoogleAuthProvider();

// export { db, auth, provider, storage };




// Import the functions you need from the SDKs you need
import firebase from "firebase";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvr89XS458xfQdt0om6yghli_vC1T8yB4",
  authDomain: "matchmaking-a796d.firebaseapp.com",
  databaseURL: "https://matchmaking-a796d-default-rtdb.firebaseio.com/",
  projectId: "matchmaking-a796d",
  storageBucket: "matchmaking-a796d.appspot.com",
  messagingSenderId: "619777468442",
  appId: "1:619777468442:web:270eab6d4e26f23dc509a3",
  measurementId: "G-579HEZQECS"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// const firebaseApp = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const db = app.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider, storage };
// App ID
// 1:619777468442:web:270eab6d4e26f23dc509a3
// Private key
// PYh0yDDBEzZmbnZmKExWInXijB6uW65469nh2THusAg
// databaseURL: "https://matchmaking-a796d-default-rtdb.firebaseio.com/",
