import React, { useEffect, useState } from 'react';
// import payUImage from '../image/PayU.svg.png';
// import { key } from './test.js';
// import { key } from './test.js';
import * as environment from '../../coreservices/environment'

const PayUPaymentPage = ({ setToggle, form, hash, transactionId, test_of, fromPage, mob_no, nuserId, PackageData }) => {
    console.log('====================================');
    console.log("finalpage_form", form);
    console.log("test_of", test_of);
    console.log("finalpage_hash", hash);
    console.log("finalpage_transactionId", transactionId);
    console.log("fromPage", fromPage);

    console.log("nuserId", nuserId);
    console.log("PackageData", PackageData);
    console.log('====================================');


    console.log(JSON.parse(localStorage.getItem('user_data')));

    let user_data = JSON.parse(localStorage.getItem('user_data'))

    const [success_url, setsuccess_url] = useState("")
    const [failure_url, setfailure_url] = useState("")

    useEffect(() => {
        if (test_of != "" && test_of != null) {
            if (test_of == "satisfaction_test") {
                setsuccess_url("Satisfactiontest")
                setfailure_url("psychologicaltests")
            } else if (test_of == "personality_test") {
                setsuccess_url("Personalitytest")
                setfailure_url("psychologicaltests")
            } else if (test_of == "Sixteen_personality_factor") {
                setsuccess_url("Sixteenpersonalityfactor")
                setfailure_url("psychologicaltests")
            } else if (test_of == "fifty_questions") {
                setsuccess_url("Fiftyquestions")
                setfailure_url("psychologicaltests")
            } else if (test_of == "hundred_questions") {
                setsuccess_url("Hundredquestiontest")
                setfailure_url("psychologicaltests")
            }

            // else if (test_of == "BloodgroupingTests") {
            //     setsuccess_url("BloodgroupingTests")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            // else if (test_of == "ThalassemiaTests") {
            //     setsuccess_url("ThalassemiaTests")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            // else if (test_of == "SexuallytransmittedDiseases") {
            //     setsuccess_url("SexuallytransmittedDiseases")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            // else if (test_of == "InfertilityScreening") {
            //     setsuccess_url("InfertilityScreening")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            // else if (test_of == "Chronicdisorders") {
            //     setsuccess_url("Chronicdisorders")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            // else if (test_of == "HoroscopeTest") {
            //     setsuccess_url("HoroscopeTest")
            //     setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            // }

            else {
                setsuccess_url(`ProfileN/${btoa(user_data?.email)}`)
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
                // setsuccess_url("success")
                // setfailure_url("failure")
            }
        }

    }, [test_of])

    useEffect(() => {
        if (fromPage != "" && fromPage != null) {
            if (fromPage == "MoreProfileDetails") {
                setsuccess_url("MoreProfileDetails")
                setfailure_url("MoreProfileDetails")
            }


            else if (fromPage == "BloodgroupingTests") {
                setsuccess_url("BloodgroupingTests")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "ThalassemiaTests") {
                setsuccess_url("ThalassemiaTests")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "SexuallytransmittedDiseases") {
                setsuccess_url("SexuallytransmittedDiseases")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "InfertilityScreening") {
                setsuccess_url("InfertilityScreening")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "Chronicdisorders") {
                setsuccess_url("Chronicdisorders")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "HoroscopeTest") {
                setsuccess_url("HoroscopeTest")
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }

            else if (fromPage == "ProfileN") {
                setsuccess_url(`ProfileN/${btoa(user_data?.email)}`)
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


            else if (fromPage == "Login") {
                setsuccess_url(`ProfileN/${btoa(user_data?.email)}`)
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }

            else {
                setsuccess_url(`ProfileN/${btoa(user_data?.email)}`)
                setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
            }


        } else {
            setsuccess_url(`ProfileN/${btoa(user_data?.email)}`)
            setfailure_url(`ProfileN/${btoa(user_data?.email)}`)
        }


    }, [fromPage])





    return (
        <div className='main-2 container gap-5'>
            <div className='h-100 d-flex flex-column py-5 justify-content-between'>
                <img width={350} src="https://files.readme.io/fa8a25c-white-logo.png" alt="" />
                <h1 className='mt-4 fw-bold card'>Payment Gateway Integration</h1>
            </div>
            <div className='h-100 d-flex flex-column justify-content-center form'>
                <div>
                    <h4 className='p-2 text-white' >Details</h4>
                    <div className='d-flex flex-column card bg-white p-3 mt-4'>
                        <span className='py-2 fs-5'>Product Name: Test Product</span>
                        <span className='py-2 fs-5'>Name: {form?.name}</span>
                        <span className='py-2 fs-5'>Number: {mob_no}</span>
                        <span className='py-2 fs-5'>Pay Amount: {form?.amount}</span>
                        <p>{ }</p>
                    </div>
                </div>
                {/* <body>
                    <form action='https://secure.payu.in/_payment' method='post'>
                        <input type="hidden" name="key" value="JkHCpE" />
                        <input type="hidden" name="txnid" value="t6svtqtjRdl4ws" />
                        <input type="hidden" name="productinfo" value="iPhone" />
                        <input type="hidden" name="amount" value="10" />
                        <input type="hidden" name="email" value="test@gmail.com" />
                        <input type="hidden" name="firstname" value="Ashish" />
                        <input type="hidden" name="lastname" value="Kumar" />
                        <input type="hidden" name="surl" value="https://apiplayground-response.herokuapp.com/" />
                        <input type="hidden" name="furl" value="https://apiplayground-response.herokuapp.com/" />
                        <input type="hidden" name="phone" value="9988776655" />
                        <input type="hidden" name="hash" value="eabec285da28fd0e3054d41a4d24fe9f7599c9d0b66646f7a9984303fd6124044b6206daf831e9a8bda28a6200d318293a13d6c193109b60bd4b4f8b09c90972" />
                        <input type="submit" value="submit" /> </form>
                </body> */}
                <form action="https://secure.payu.in/_payment" method="POST">
                    <input type="hidden" name="key" value="JkHCpE" />
                    <input type="hidden" name="txnid" value={transactionId} />
                    <input type="hidden" name="amount" value={form?.amount} />
                    <input type="hidden" name="productinfo" value={PackageData.packageName} />
                    <input type="hidden" name="firstname" value={form?.name} />
                    <input type="hidden" name="email" value={form?.email} />
                    <input type="hidden" name="phone" value={mob_no} />
                    {/* <input type="hidden" name="surl" value={`http://localhost:5001/api/payu/${success_url}`} />
                    <input type="hidden" name="furl" value={`http://localhost:5001/api/payu/${failure_url}`} /> */}
                    <input type="hidden" name="surl" value={`${environment.apiUrl}/Paymentgateway/${success_url}`} />
                    <input type="hidden" name="furl" value={`${environment.apiUrl}/Paymentgateway/${failure_url}`} />
                    {/* http://localhost:5000/api/payu/Paymentgateway/hash */}
                    <input type="hidden" name="udf1" value={PackageData.packagePrice} />
                    <input type="hidden" name="udf2" value={PackageData.packageMobileAvailable} />
                    <input type="hidden" name="udf3" value={PackageData.packageTYSAvailable} />
                    <input type="hidden" name="udf4" value={PackageData.packageValidity} />
                    <input type="hidden" name="udf5" value={nuserId} />
                    <input type="hidden" name="hash" value={hash} />
                    <div className='d-flex justify-content-between mt-4'>
                        <button type="submit" onClick={() => { setToggle(1) }}>Back</button>
                        <button type="submit">Pay Now</button>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default PayUPaymentPage