import React, { useEffect, useState } from 'react'
import './QuestionAnswer.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const QuestionAnswers = () => {

    const searchParams = new URLSearchParams(window?.location?.search);
    const status = searchParams?.get('status');


    // Your logic for handling failure status
    console.log('Status is', status);



    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const handleRedirectProfile = () => {
        history.push('/psychologicaltests');
    }
    const handleShowModal = () => {
        setShowModal(true);
        handleStopTimer();
    }
    const handleCloseModal = () => setShowModal(false);
    const ThankYouModal = ({ showModal, handleClose }) => {
        return (
            <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="thank-you-pop">
                        <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
                        <h1>Thank You!</h1>
                        <p>Your submission is received and saved in your profile</p>
                        <div className='d-flex justify-content-center align-items-center'>

                            <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
                                Ok</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [recordedTime, setRecordedTime] = useState(null);
    const handleStopTimer = () => {
        setIsRunning(false);
        setRecordedTime(seconds);
    };
    const Timer = () => {

        useEffect(() => {
            let intervalId;

            if (isRunning) {
                intervalId = setInterval(() => {
                    setSeconds((prevSeconds) => prevSeconds + 1);
                }, 1000);
            }

            // Cleanup function to clear the interval when the component unmounts or timer is stopped
            return () => clearInterval(intervalId);
        }, [isRunning]);

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return (
            <div>
                <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
                {recordedTime !== null && (
                    <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
                )}
                {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
            </div>
        );
    };


    const [questions, setQuestions] = useState([
        {
            id: 1,
            question: 'How do you usually handle stress?',
            options: ['XYZXYZ', 'Confrontation', 'Distraction', 'Reflection'],
            selectedAnswer: null,
        },
        {
            id: 2,
            question: 'In social situations, you are...',
            options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
            selectedAnswer: null,
        },
        {
            id: 3,
            question: 'How do you usually handle stress?',
            options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
            selectedAnswer: null,
        },
        {
            id: 4,
            question: 'In social situations, you are...',
            options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
            selectedAnswer: null,
        },
        {
            id: 5,
            question: 'How do you usually handle stress?',
            options: ['Avoidance', 'Confrontation', 'Distraction', 'Reflection'],
            selectedAnswer: null,
        },
        {
            id: 6,
            question: 'In social situations, you are...',
            options: ['Outgoing', 'Reserved', 'Adaptable', 'Observant'],
            selectedAnswer: null,
        },
        // Add more questions as needed
    ]);

    const handleAnswerSelect = (questionId, selectedAnswer, question) => {
        console.log("quest id", questionId)
        console.log("sel ans", selectedAnswer)
        console.log("ques", question)
        setQuestions((prevQuestions) =>
            prevQuestions.map((question) =>
                question.id === questionId ? { ...question, selectedAnswer } : question
            )
        );
    };


    const renderQuestions = () => {
        return questions.map((question) => (
            <div key={question.id} className="question-container">
                <h3>{`Question ${question.id}: ${question.question}`}</h3>
                <ul>
                    {question.options.map((option, index) => (
                        <li key={index}>
                            <label>
                                <input
                                    type="radio"
                                    name={`question_${question.id}`}
                                    value={option}
                                    checked={question.selectedAnswer === option}
                                    onChange={() => handleAnswerSelect(question.id, option, question.question)}
                                />
                                {option}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };


    return (
        <div className="cen-div">

            <div className="center-div">
                <div className="centered-div-1">

                    <div>

                        <div>
                            <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
                            <h2 style={{ textAlign: 'center' }}>Questions</h2>
                        </div>




                        <div className="psychological-test-container">
                            <h1>Psychological Test</h1>
                            {renderQuestions()}
                            <div className='d-flex justify-content-center align-items-center'>

                                <button onClick={handleShowModal} type="button" class="btn btn-success">
                                    Submit</button>
                            </div>
                        </div>

                    </div>


                </div>

            </div>


            <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


        </div>
    )
}

export default QuestionAnswers
