import React, { useEffect, useState } from 'react';
import * as environment from '../coreservices/environment'

const ViewProfileDetails = () => {
  // State to hold user details
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    bio: '',
  });

  const [data, setData] = useState(null);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const storedData = localStorage.getItem('myData');
    console.log("storedData", storedData)
    if (storedData) {
      // setData(storedData);
    }
  }, []); // Empty dependency array to run the effect only once when the component mounts

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${environment.apiUrl}/uname/pswd/1`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures the effect runs only once (like componentDidMount)

  if (loading) {
    return <p>Loading...</p>;
  }

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to update user details (e.g., send to backend API)
    console.log('Updated user details:', userDetails);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Update Profile Details</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label style={styles.label}>
          First Name:
          <input
            type="text"
            name="firstName"
            value={userDetails.firstName}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={userDetails.lastName}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Email:
          <input
            type="email"
            name="email"
            value={userDetails.email}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Bio:
          <textarea
            name="bio"
            value={userDetails.bio}
            onChange={handleInputChange}
            style={{ ...styles.input, ...styles.textarea }}
          />
        </label>
        <button type="submit" style={styles.button}>
          Update Profile
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '50px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    margin: '10px 0',
    fontSize: '16px',
  },
  input: {
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  textarea: {
    minHeight: '80px',
  },
  button: {
    marginTop: '15px',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    borderRadius: '4px',
    border: 'none',
    fontSize: '16px',
  },
};

export default ViewProfileDetails;
