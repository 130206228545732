// Filename - components/Footer.js

import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyle";

const Footer = () => {
	return (
		<Box>
			<h1
				style={{
					color: "green",
					textAlign: "center",
					marginTop: "10px",
				}}
			>

			</h1>
			<FooterContainer>
				<Row>
					<Column>
						<Heading>Important Links</Heading>
						<FooterLink href="/Aboutus">
							About us
						</FooterLink>
						<FooterLink href="/FaqFile">
							FAQ
						</FooterLink>

						{/* <FooterLink href="/Vision">
							Vision
						</FooterLink>

						<FooterLink href="/Blogs">
							Blogs
						</FooterLink>

						<FooterLink href="/Successstories">
							Success Stories
						</FooterLink>

						<FooterLink href="/Weddingevent">
							Wedding Event
						</FooterLink>

						<FooterLink href="/Testimonials">
							Testimonials
						</FooterLink> */}
					</Column>
					<Column>
						<Heading>Policies</Heading>
						<FooterLink href="/Termsconditions">
							Terms & conditions
						</FooterLink>
						<FooterLink href="/Privacypolicy">
							Privacy policy
						</FooterLink>
						<FooterLink href="/Cookiepolicy">
							Cookie policy
						</FooterLink>
						<FooterLink href="/Refundpolicy">
							Refund & returns policy
						</FooterLink>
					</Column>
					<Column>
						<Heading>Contact Us</Heading>
						<FooterLink href="/Contactus">
							Delhi
						</FooterLink>
						{/* <FooterLink href="#">
							Delhi
						</FooterLink>
						<FooterLink href="#">
							Mumbai
						</FooterLink>
						<FooterLink href="#">
							Chennai
						</FooterLink> */}
					</Column>
					<Column>
						<Heading>Social Media</Heading>
						<FooterLink href="https://www.facebook.com/profile.php?id=100088887205587&sk=reels_tab">
							<i className="fab fa-facebook-f">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									Facebook
								</span>
							</i>
						</FooterLink>
						<FooterLink href="https://www.instagram.com/contactmatchmaking24/">
							<i className="fab fa-instagram">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									Instagram
								</span>
							</i>
						</FooterLink>
						<FooterLink href="#">
							<i className="fab fa-twitter">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									Twitter
								</span>
							</i>
						</FooterLink>
						<FooterLink href="#">
							<i className="fab fa-youtube">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									Youtube
								</span>
							</i>
						</FooterLink>
					</Column>
				</Row>
				<Row style={{ display: "flex", justifyContent: "center" }}>
					<div style={{ color: "white" }}>
						Copyright © 2023 Matrimonial, Marriage and Social networking service.. Connecting Indian
					</div>
				</Row>
			</FooterContainer>
		</Box>
	);
};
export default Footer;
