import React from 'react'

const Refundpolicy = () => {
  return (
    <div className='p-4'>
      Refund and Returns Policy
      <div>
        REFUND POLICY
        <p>

          We have a 07-day return policy, which means you have 07 days after receiving your item to request a return.

          To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.

          To start a return, you can contact us at info@indianmatchmaking.co.in. If your return is accepted, we’ll send you a return shipping label, as well as for instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.

          If you want to return the parcel then send back this parcel to our address (our address is mention on the shipment label) and send a courier copy to us by mail after receiving the parcel we will transfer the amount to your respective payment account from which payment was done.

          You can always contact us for any return question at info@indianmatchmaking.co.in.
        </p>
      </div>
      <div>

        Damages and issues
        <p>

          Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item so that we can evaluate the issue and make it right.
        </p>
      </div>
      <div>
        Exceptions / non-returnable items
        <p>

          Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item.

          Unfortunately, we cannot accept returns on sale items or gift cards.
        </p>
      </div>
      <div>

        Exchanges
        <p>

          If you want to Exchange the parcel then send back this parcel to our address (our address is mention on the shipment label) and send a courier copy to us by mail after receiving the parcel we will Exchange  the Product

          Mail ID : info@indianmatchmaking.co.in
        </p>
      </div>
      <div>

        Refunds
        <p>

          We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 5-7 days. Please remember it can take some time for your bank or credit card company to process and post the refund too.
        </p>
      </div>
      <div>
        <p>
          Need help?
          Contact us at info@indianmatchmaking.co.in for questions related to refunds and returns.
        </p>
      </div>
    </div>
  )
}

export default Refundpolicy