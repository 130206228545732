import React, { useEffect, useState } from 'react'
import './QuestionAnswer.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf';
// import html2pdf from 'html2pdf.js/dist/html2pdf';
import * as environment from '../../coreservices/environment'

const Satisfactiontest = () => {

    const searchParams = new URLSearchParams(window?.location?.search);
    const status = searchParams?.get('status');


    // Your logic for handling failure status
    console.log('Status is', status);



    const generatePDF = () => {
        const pdf = new jsPDF();

        // Get the HTML content you want to convert
        const htmlContent = document.getElementById('pdf-content');

        // Convert HTML to PDF using html2pdf
        html2pdf(htmlContent, {
            margin: 10,
            filename: 'output.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
    };





    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const handleRedirectProfile = () => {
        history.push('/psychologicaltests');
    }
    const handleShowModal = async () => {
        calculateTotalMarks()
        getAnswerReport()
        setShowModal(true);
        handleStopTimer();

        console.log('====================================');
        console.log("seconds_log", seconds)
        console.log("localStorage.getItem_userId_log", localStorage.getItem("userId"));
        console.log("questionss_log", questions);
        console.log('====================================');



        let CreateSatisfactiontestDto = {
            userId: localStorage.getItem("userId"),
            satisfactiontestId: '0',
            questions: "",
            answers: "",
            // questionsanswers: questions.map(question => { questionId: question.id; question: question.question; answer: question.selectedAnswer }),
            questionsanswers: questions,

            timer: seconds,
            timestamp: new Date(),
            testName: "satisfaction_test",
            testResult: getAnswerReport(),
            marks: calculateTotalMarks()
        }

        // let Questionsanswers = {
        //     questionId: questions.id,
        //     question: questions.question,
        //     answer: questions.selectedAnswer,
        // }
        console.log('====================================');
        console.log("CreateSatisfactiontestDto", CreateSatisfactiontestDto);
        console.log('====================================');

        try {
            const response = await fetch(`${environment.apiUrl}/satisfactiontest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(CreateSatisfactiontestDto),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle the response data as needed
            const responseData = await response.json();
            console.log("responseData", responseData);
        } catch (error) {
            console.error('Error during POST request:', error);
        }


    }
    const handleCloseModal = () => setShowModal(false);
    const ThankYouModal = ({ showModal, handleClose }) => {
        return (
            <Modal centered show={showModal} onHide={handleClose} id="ignismyModal">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="thank-you-pop">
                        <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="" />
                        <h1>Thank You!</h1>
                        <p>Answer Report: Your Satisfaction test result is {getAnswerReport()} towards life.</p>
                        <p>Your submission is received and saved in your profile</p>
                        <div className='d-flex justify-content-center align-items-center'>

                            <button onClick={handleRedirectProfile} type="button" class="btn btn-success">
                                Ok</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [recordedTime, setRecordedTime] = useState(null);
    const handleStopTimer = () => {
        setIsRunning(false);
        setRecordedTime(seconds);
    };
    const Timer = () => {

        useEffect(() => {
            let intervalId;

            if (isRunning) {
                intervalId = setInterval(() => {
                    setSeconds((prevSeconds) => prevSeconds + 1);
                }, 1000);
            }

            // Cleanup function to clear the interval when the component unmounts or timer is stopped
            return () => clearInterval(intervalId);
        }, [isRunning]);

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return (
            <div>
                <h1 style={{ fontSize: '1.5rem' }}>Timer: {minutes} minutes {remainingSeconds} seconds</h1>
                {recordedTime !== null && (
                    <p>Recorded Time: {Math.floor(recordedTime / 60)} minutes {recordedTime % 60} seconds</p>
                )}
                {/* <button onClick={handleStopTimer}>Stop Timer</button> */}
                <div style={{ textAlign: 'left' }}>
                    The Satisfaction with Life Scale (SWLS) is a commonly used scale in Positive Psychology for
                    measuring overall wellbeing. It’s a short questionnaire and provides a “temperature check”
                    of your satisfaction with life at this point in time. The scale has been scientifically shown to
                    be a valid and reliable suitable for use with a wide range of age groups and applications.
                    संतोष जीवन स्के ल (SWLS) सकारात्मक मनोववज्ञान मेंसमग्र कल्याण को मापनेकेवलए एक सामान्यत: उपयोग की जाने
                    वाली स्के ल है। यह एक संविप्त प्रश्नावली हैऔर आपके जीवन संतोष का वततमान समय में"तापमान जांच" प्रदान करती है।
                    यह स्के ल वैज्ञावनक रूप सेमान्य और ववश्वसनीय सावित हुई हैऔर इसेववविन्न आयुसमूहोंऔर अनुप्रयोगोंकेवलए उपयुक्त
                    माना गया है।
                </div>
            </div>
        );
    };











    const renderQuestions = () => {
        return questions.map((question) => (
            <div key={question.id} className="question-container">
                <h3>{`Question ${question.id}: ${question.question}`}</h3>
                <ul>
                    {question.options.map((option, index) => (
                        <li key={index}>
                            <label>
                                <input
                                    type="radio"
                                    name={`question_${question.id}`}
                                    value={option}
                                    checked={question.selectedAnswer === option}
                                    onChange={() => handleAnswerSelect(question.id, option, question.question, question.markss)}
                                />
                                {option}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };












    const [questions, setQuestions] = useState([
        {
            id: '1',
            question: 'In most ways my life is close to the ideal ?',
            options: [
                { label: 'Strongly Agree', value: 7 },
                { label: 'Agree', value: 6 },
                { label: 'Slightly Agree', value: 5 },
                { label: 'Neither agree nor disagree', value: 4 },
                { label: 'Slightly disagree', value: 3 },
                { label: 'Disagree', value: 2 },
                { label: 'Strongly disagree', value: 1 }
            ],
            selectedOption: null
        },

        {
            id: '2',
            question: ' The conditions of my life are excellent ?',
            options: [
                { label: 'Strongly Agree', value: 7 },
                { label: 'Agree', value: 6 },
                { label: 'Slightly Agree', value: 5 },
                { label: 'Neither agree nor disagree', value: 4 },
                { label: 'Slightly disagree', value: 3 },
                { label: 'Disagree', value: 2 },
                { label: 'Strongly disagree', value: 1 }
            ],
            selectedOption: null
        },

        {
            id: '3',
            question: ' I am satisfied with my life ?',
            options: [
                { label: 'Strongly Agree', value: 7 },
                { label: 'Agree', value: 6 },
                { label: 'Slightly Agree', value: 5 },
                { label: 'Neither agree nor disagree', value: 4 },
                { label: 'Slightly disagree', value: 3 },
                { label: 'Disagree', value: 2 },
                { label: 'Strongly disagree', value: 1 }
            ],
            selectedOption: null
        },

        {
            id: '4',
            question: 'So far, I have gotten the things I want in life ?',
            options: [
                { label: 'Strongly Agree', value: 7 },
                { label: 'Agree', value: 6 },
                { label: 'Slightly Agree', value: 5 },
                { label: 'Neither agree nor disagree', value: 4 },
                { label: 'Slightly disagree', value: 3 },
                { label: 'Disagree', value: 2 },
                { label: 'Strongly disagree', value: 1 }
            ],
            selectedOption: null
        },

        {
            id: '5',
            question: 'If I could live my life over I would change almost nothing ?',
            options: [
                { label: 'Strongly Agree', value: 7 },
                { label: 'Agree', value: 6 },
                { label: 'Slightly Agree', value: 5 },
                { label: 'Neither agree nor disagree', value: 4 },
                { label: 'Slightly disagree', value: 3 },
                { label: 'Disagree', value: 2 },
                { label: 'Strongly disagree', value: 1 }
            ],
            selectedOption: null
        },
        // Add more questions here
    ]);

    const handleAnswerSelect = (questionId, selectedValue) => {
        setQuestions(prevQuestions =>
            prevQuestions.map(question =>
                question.id === questionId
                    ? { ...question, selectedOption: selectedValue }
                    : question
            )
        );
    };
    // const handleAnswerSelect = (questionId, selectedAnswer, question) => {
    //     console.log("quest id log", questionId)
    //     console.log("sel ans log", selectedAnswer)
    //     console.log("ques log", question)
    //     setQuestions((prevQuestions) =>
    //         prevQuestions.map((question) =>
    //             question.id === questionId ? { ...question, selectedAnswer } : question
    //         )
    //     );
    // };
    const calculateTotalMarks = () => {
        let totalMarks = 0;
        questions.forEach(question => {
            totalMarks += question.selectedOption;
        });

        return totalMarks;
    };

    const getAnswerReport = () => {

        const totalMarks = calculateTotalMarks();
        if (totalMarks <= 35 && totalMarks >= 31) {
            return 'Extremely satisfied';
        } else if (totalMarks <= 30 && totalMarks >= 26) {
            return 'Satisfied';
        } else if (totalMarks <= 25 && totalMarks >= 21) {
            return 'Slightly satisfied';
        } else if (totalMarks == 20) {
            return 'Neutral';
        } else if (totalMarks <= 19 && totalMarks >= 15) {
            return 'Slightly dissatisfied';
        } else if (totalMarks <= 14 && totalMarks >= 10) {
            return 'Dissatisfied';
        } else {
            return 'Extremely dissatisfied';
        }
    };

    return (
        <div className="cen-div">

            {/* <button onClick={generatePDF} type="button" className="btn btn-primary">
                Generate PDF
            </button> */}



            <div id="pdf-content">
                {/* Your existing HTML content */}
                <div className="center-div">
                    <div className="centered-div-1">



                        <div>

                            <div>
                                <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
                                <h2 style={{ textAlign: 'center' }}>Questions</h2>
                            </div>




                            <div className="psychological-test-container">
                                <h1>Satisfaction Test</h1>
                                {/* {renderQuestions()} */}



                                {questions.map(question => (
                                    <div key={question.id}>
                                        <h3 style={{ color: 'black' }}>{`Question ${question.id}: ${question.question}`}</h3>
                                        {question.options.map(option => (
                                            <label key={option.label}>
                                                <input
                                                    type="radio"
                                                    name={`question_${question.id}`}
                                                    value={option.value}
                                                    checked={question.selectedOption === option.value}
                                                    onChange={() => handleAnswerSelect(question.id, option.value)}
                                                />
                                                {option.label}
                                            </label>
                                        ))}
                                    </div>
                                ))}
                                {/* <button onClick={() => alert(`Answer Report:  ${calculateTotalMarks()} and ${getAnswerReport()}`)}>
                                    Generate Answer Report
                                </button> */}


                                <div className='d-flex justify-content-center align-items-center'>



                                    <button onClick={handleShowModal} type="button" class="btn btn-success">
                                        Submit</button>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>

            {/* <div className="center-div">
                <div className="centered-div-1">


                    <button onClick={generatePDF} type="button" className="btn btn-primary">
                        Generate PDF
                    </button>


                    <div>

                        <div>
                            <h2 style={{ textAlign: 'right', fontSize: '1.5rem' }}><Timer /></h2>
                            <h2 style={{ textAlign: 'center' }}>Questions</h2>
                        </div>




                        <div className="psychological-test-container">
                            <h1>Satisfaction Test</h1>
                            {renderQuestions()}
                            <div className='d-flex justify-content-center align-items-center'>

                                <button onClick={handleShowModal} type="button" class="btn btn-success">
                                    Submit</button>
                            </div>
                        </div>

                    </div>


                </div>

            </div> */}


            <ThankYouModal showModal={showModal} handleClose={handleCloseModal} />


        </div>
    )
}

export default Satisfactiontest




// import React, { useState } from 'react';

// const Satisfactiontest = () => {
//     const [questions, setQuestions] = useState([
//         {
//             id: '1',
//             question: 'How do you usually handle stress?',
//             options: [
//                 { label: 'Strongly Agree', value: 7 },
//                 { label: 'Agree', value: 6 },
//                 { label: 'Slightly Agree', value: 5 },
//                 { label: 'Neither agree nor disagree', value: 4 },
//                 { label: 'Slightly disagree', value: 3 },
//                 { label: 'Disagree', value: 2 },
//                 { label: 'Strongly disagree', value: 1 }
//             ],
//             selectedOption: null
//         },

//         {
//             id: '2',
//             question: 'How do you usually handle stress?',
//             options: [
//                 { label: 'Strongly Agree', value: 7 },
//                 { label: 'Agree', value: 6 },
//                 { label: 'Slightly Agree', value: 5 },
//                 { label: 'Neither agree nor disagree', value: 4 },
//                 { label: 'Slightly disagree', value: 3 },
//                 { label: 'Disagree', value: 2 },
//                 { label: 'Strongly disagree', value: 1 }
//             ],
//             selectedOption: null
//         },

//         {
//             id: '3',
//             question: 'How do you usually handle stress?',
//             options: [
//                 { label: 'Strongly Agree', value: 7 },
//                 { label: 'Agree', value: 6 },
//                 { label: 'Slightly Agree', value: 5 },
//                 { label: 'Neither agree nor disagree', value: 4 },
//                 { label: 'Slightly disagree', value: 3 },
//                 { label: 'Disagree', value: 2 },
//                 { label: 'Strongly disagree', value: 1 }
//             ],
//             selectedOption: null
//         },

//         {
//             id: '4',
//             question: 'How do you usually handle stress?',
//             options: [
//                 { label: 'Strongly Agree', value: 7 },
//                 { label: 'Agree', value: 6 },
//                 { label: 'Slightly Agree', value: 5 },
//                 { label: 'Neither agree nor disagree', value: 4 },
//                 { label: 'Slightly disagree', value: 3 },
//                 { label: 'Disagree', value: 2 },
//                 { label: 'Strongly disagree', value: 1 }
//             ],
//             selectedOption: null
//         },

//         {
//             id: '5',
//             question: 'How do you usually handle stress?',
//             options: [
//                 { label: 'Strongly Agree', value: 7 },
//                 { label: 'Agree', value: 6 },
//                 { label: 'Slightly Agree', value: 5 },
//                 { label: 'Neither agree nor disagree', value: 4 },
//                 { label: 'Slightly disagree', value: 3 },
//                 { label: 'Disagree', value: 2 },
//                 { label: 'Strongly disagree', value: 1 }
//             ],
//             selectedOption: null
//         },
//         // Add more questions here
//     ]);

//     const handleAnswerSelect = (questionId, selectedValue) => {
//         setQuestions(prevQuestions =>
//             prevQuestions.map(question =>
//                 question.id === questionId
//                     ? { ...question, selectedOption: selectedValue }
//                     : question
//             )
//         );
//     };

//     const calculateTotalMarks = () => {
//         let totalMarks = 0;
//         questions.forEach(question => {
//             totalMarks += question.selectedOption;
//         });

//         return totalMarks;
//     };

//     const getAnswerReport = () => {

//         const totalMarks = calculateTotalMarks();
//         if (totalMarks >= 31) {
//             return 'Extremely satisfied';
//         } else if (totalMarks >= 26) {
//             return 'Satisfied';
//         } else if (totalMarks >= 21) {
//             return 'Slightly satisfied';
//         } else if (totalMarks >= 15) {
//             return 'Neutral';
//         } else if (totalMarks >= 10) {
//             return 'Slightly dissatisfied';
//         } else if (totalMarks >= 5) {
//             return 'Dissatisfied';
//         } else {
//             return 'Extremely dissatisfied';
//         }
//     };

//     return (
//         <div>
//             {questions.map(question => (
//                 <div key={question.id}>
//                     <h3 style={{ color: 'black' }}>{`Question ${question.id}: ${question.question}`}</h3>
//                     {question.options.map(option => (
//                         <label key={option.label}>
//                             <input
//                                 type="radio"
//                                 name={`question_${question.id}`}
//                                 value={option.value}
//                                 checked={question.selectedOption === option.value}
//                                 onChange={() => handleAnswerSelect(question.id, option.value)}
//                             />
//                             {option.label}
//                         </label>
//                     ))}
//                 </div>
//             ))}
//             <button onClick={() => alert(`Answer Report:  ${calculateTotalMarks()} and ${getAnswerReport()}`)}>
//                 Generate Answer Report
//             </button>
//         </div>
//     );
// };

// export default Satisfactiontest;
